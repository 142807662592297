import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { OrderManagement } from '../../models/order/order-management.model';
import { OrderStatus } from '../../models/order/order-status.enum';
import { OrderType } from '../../models/order/order-type.enum';
import { Order } from '../../models/order/order.model';
import { UpdatePodOfOrdersResponseModel } from '../../models/order/update-pod-of-orders-response.model';
import { OrderParkedManagement } from '../../models/order/order-parked-management.model';
import { PodType } from '../../models/pod/podType.enum';
import { Pod } from '../../models/pod/pod.model';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  ORDER_BASE_ENDPOINT = environment.settings.apiUrl + '/order';
  ORDER_MANAGEMENT_BASE_ENDPOINT = environment.settings.apiUrl + '/order/management';

  constructor(private readonly httpClient: HttpClient) {}

  /**
   * Finds the orders in progress (status=PAYMENT_AUTHORIZED and error for 24h) of the given restaurant.
   * @param restaurantRef Restaurant reference to retrieve orders.
   */
  getOrdersInProgress(restaurantRef: string): Observable<OrderManagement> {
    const params = new HttpParams().set('restaurantRef', restaurantRef);
    return this.httpClient.get<OrderManagement>(this.ORDER_MANAGEMENT_BASE_ENDPOINT + `/inProgress`, { params });
  }

  /**
   * Finds orders history of a restaurant. Can filter by status / type.
   * @param restaurantRef Restaurant reference.
   * @param startDate Date of the first call.
   * @param duration Duration in day since the startDate to retrieve orders.
   * @param status Status that orders must match (OK/ERROR). If null => ALL
   * @param orderType Type of the order (C&C/LAD/McDelivery). If null => ALL
   * @param page Wanted page to show.
   * @param nbPerPage Number of results per page.
   * @param orderNumber Searched order by orderNumber
   */
  //NOSONAR
  getOrdersHistory(
    restaurantRef: string,
    startDate: Date,
    duration: number,
    status: OrderStatus,
    orderType: OrderType,
    page: number,
    nbPerPage: number,
    orderNumber: string
  ): Observable<OrderManagement> {
    let params = new HttpParams();
    params = params.append('restaurantRef', restaurantRef);
    params = params.append('orderedDate', new Date(startDate).getTime().toString());
    params = params.append('duration', duration.toString());
    if (status && status !== OrderStatus.ALL) {
      params = params.append('status', status);
    }
    if (orderType) {
      params = params.append('type', orderType);
    }
    params = params.append('page', page.toString());
    params = params.append('nbPerPage', nbPerPage.toString());
    if (orderNumber && orderNumber !== '') {
      params = params.append('orderNumber', orderNumber.toString().toUpperCase());
    }
    return this.httpClient.get<OrderManagement>(this.ORDER_MANAGEMENT_BASE_ENDPOINT + `/history`, { params });
  }

  /**
   * Find the details (ie. items) of an order by its external reference.
   * @param orderRef Reference of the order
   */
  getOrderDetailsByRef(orderRef: string): Observable<Order> {
    const opts = { params: new HttpParams({ fromString: 'responseGroups=RG.ORDER.ITEMS&responseGroups=RG.ORDER.DETAIL' }) };
    return this.httpClient.get<Order>(this.ORDER_BASE_ENDPOINT + `/${orderRef}`, opts);
  }

  /**
   * Finds the number of new orders since the last moment we checked.
   * @param lastCheckedDate Date when we last checked the orders.
   * @param restaurantRef Restaurant reference.
   */
  getNumberOfNewOrdersSince(lastCheckedDate: Date, restaurantRef: string): Observable<number> {
    let params = new HttpParams();
    params = params.append('restaurantRef', restaurantRef);
    params = params.append('startDate', new Date(lastCheckedDate).getTime().toString());
    return this.httpClient
      .get<any>(this.ORDER_MANAGEMENT_BASE_ENDPOINT + `/refresh`, { params })
      .pipe(map((_) => _.orderCount));
  }

  /**
   * Gets the string value of an Order type
   */
  getOrderTypeValue(orderType: OrderType, longLabel = false): string {
    switch (orderType) {
      case OrderType.C_AND_C:
        if (longLabel) {
          return 'Click & Collect';
        }
        return 'C&C';
      case OrderType.MC_DELIVERY:
        return 'LAD McDo+';
      case OrderType.LAD:
        return 'LAD';
      default:
        return 'Inconnu';
    }
  }

  /**
   *
   * Update the POD of an order.
   * @param restaurantRef Reference of the attached restaurant of the order to update
   * @param orderRef Reference of the order to update
   * @param podReference Reference of the new selected Pod for the order
   */
  updatePodOfOrder(restaurantRef: string, orderRef: string, podReference: string): Observable<any> {
    let params = new HttpParams();
    params = params.append('restaurantRef', restaurantRef);
    params = params.append('podReference', podReference);
    return this.httpClient.put<any>(this.ORDER_MANAGEMENT_BASE_ENDPOINT + `/${orderRef}/updatePod`, null, { params });
  }

  /**
   * Update the POD of different orders
   * @param: restaurantRef the ref of the restaurant
   * @param: the references of the different orders
   * @param: the ref of the new Pod
   */
  updatePodOfOrders(restaurantRef: string, ordersRef: string[], newPodRef: string): Observable<UpdatePodOfOrdersResponseModel> {
    const body = { ordersRef, newPodRef, restaurantRef };
    return this.httpClient.put<UpdatePodOfOrdersResponseModel>(this.ORDER_MANAGEMENT_BASE_ENDPOINT + '/updatePod', body);
  }

  /**
   * Finds the orders in progress (status=PAYMENT_AUTHORIZED and error for 24h) of the given restaurant.
   * @param restaurantRef Restaurant reference to retrieve orders.
   */
  getParkingOrders(restaurantRef: string): Observable<OrderManagement> {
    const params = new HttpParams().set('restaurantRef', restaurantRef);
    return this.httpClient.get<OrderManagement>(this.ORDER_MANAGEMENT_BASE_ENDPOINT + `/clickAndReadyParking`, { params });
  }

  /**
   * Finds the number of new orders since the last moment we checked.
   * @param lastCheckedDate Date when we last checked the orders.
   * @param restaurantRef Restaurant reference.
   */
  getNumberOfNewParkingOrdersSince(lastCheckedDate: Date, restaurantRef: string): Observable<number> {
    let params = new HttpParams();
    params = params.append('restaurantRef', restaurantRef);
    params = params.append('startDate', new Date(lastCheckedDate).getTime().toString());
    return this.httpClient
      .get<any>(this.ORDER_MANAGEMENT_BASE_ENDPOINT + `/clickAndReadyParking/refresh`, { params })
      .pipe(map((_) => _.orderCount));
  }

  /**
   * Finds the number of new orders since the last moment we checked.
   * @param lastCheckedDate Date when we last checked the orders.
   * @param restaurantRef Restaurant reference.
   */
  getNumberOfParkedOrdersSince(lastCheckedDate: Date, restaurantRef: string): Observable<OrderParkedManagement> {
    let params = new HttpParams();
    params = params.append('restaurantRef', restaurantRef);
    if (lastCheckedDate) {
      params = params.append('startDate', new Date(lastCheckedDate).getTime().toString());
    }
    return this.httpClient.get<OrderParkedManagement>(this.ORDER_MANAGEMENT_BASE_ENDPOINT + `/clickAndReadyParking/parked`, { params });
  }

  /**
   * Update the order with the delayed information.
   * @param orderRef Reference of the order to update.
   */
  sendParkingOrderDelay(orderRef: string): Observable<any> {
    return this.httpClient.put<any>(this.ORDER_MANAGEMENT_BASE_ENDPOINT + `/clickAndReadyParking/${orderRef}/delay`, null);
  }

  /**
   * Check if the given targetString is null or empty
   * @param targetString String to check
   * @returns True if null or empty, false otherwise
   */
  private isStringNull(targetString: string): boolean {
    return targetString == null || targetString === '' || targetString === undefined;
  }

  /**
   * Returns the label of the sales channel selected for the order.
   */
  getLabel(order: Order): string {
    let label = "Inconnu";
    if (order.type === OrderType.C_AND_C) {
      label = order.podLabel;
      if (!this.isStringNull(order.collectingDetails?.tableNumber)) {
        label += ` (table n°${order.collectingDetails.tableNumber})`;
      }
    } else if (!this.isStringNull(order.partnerLabel)) {
        label = order.partnerLabel[0].toUpperCase() + order.partnerLabel.substr(1).toLowerCase();
    }
    return label;
  }

  /**
   * Get the order number or LAD number of an order
   */
  getOrderNumber(order: Order): string {
    if (order.type === OrderType.LAD) {
      return order.orderNumberLAD;
    }
    return order.orderNumber;
  }

  /**
   * Returns the order total amount in euros.
   */
  getTotalAmount(order: Order): number {
    if (!order || order === undefined || !order.totalAmount) {
      return 0;
    }
    return order.totalAmount / 100;
  }


  /**
   * Check is current order is SAT order with table number
   */
  isSatOrder(order: Order): boolean {
    return order.podTypeReference === PodType.SAT && !this.isStringNull(order.collectingDetails?.tableNumber);
  }
}
