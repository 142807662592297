<mat-accordion>
  <mat-expansion-panel class="panel" [expanded]="true">
    <mat-expansion-panel-header style="background-color: #d20000">
      <mat-panel-title>
        <div *ngIf="orderError.errorCode != 'Code erreur non remonté'" id="title">
          ERR {{ orderError.errorCode }} <br />
          {{ orderError.shortLabel }}
        </div>

        <div *ngIf="orderError.errorCode == 'Code erreur non remonté'" id="title">
          {{ orderError.errorCode }} <br />
          {{ orderError.shortLabel }}
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <div id="errorDetail">
      <p>{{ orderError.description }}</p>

      <div *ngFor="let step of orderError.steps; let i = index">
        <div class="step">
          <div class="step-number">
            <span class="stepCircle">{{ i + 1 }}</span>
          </div>
          <span class="descriptionStep">{{ step.label }}</span>
        </div>

        <ul class="option" *ngIf="step.listInformation">
          <li *ngFor="let information of step.listInformation">{{ information }}</li>
        </ul>
      </div>
    </div>

    <button id="contact-support-button">
      <a href="{{ 'tel:' + orderError.phoneNumber }}">Contacter Support</a>
    </button>
  </mat-expansion-panel>
</mat-accordion>
