<div *ngIf="isLoading">
    <app-loader></app-loader>
</div>
<div class="modal-header">
    <div class="modal-title">Commande</div>
    <a>
        <mat-icon id="close-modal-button" (click)="closeModal()">close</mat-icon>
    </a>
    <div class="order-reference">{{ this.orderService.getOrderTypeValue(selectedOrder?.type) }} {{ this.orderService.getOrderNumber(selectedOrder) }}</div>
</div>
<div class="order-summary">
    <div class="first-line">
        <div>
            <span *ngIf="selectedOrder.collectingDetails.associatedVehicleType == 'CAR'"><mat-icon class="icon-vehicle"> directions_car </mat-icon></span>
            <span *ngIf="selectedOrder.collectingDetails.associatedVehicleType == 'MOTORBIKE'"><mat-icon class="icon-vehicle"> two_wheeler </mat-icon></span>
            <span class="order-vehicle-desc">  {{ selectedOrder.collectingDetails.associatedVehicleDetails }} </span>
        </div>
        <div>
            <span *ngIf="selectedOrder.clickAndReadyParkingStatus == 'PARKED'">
                    <span class="order-parking" *ngIf="selectedOrder.collectingDetails.parkingSpace != 'NO_PLACE'">P {{ selectedOrder.collectingDetails.parkingSpace }}</span>
                    <span *ngIf="selectedOrder.collectingDetails.parkingSpace == 'NO_PLACE'"><img src="assets/images/noparking_white.svg" alt="Pas de place de parking associée" /></span>
                </span>
            <span class="order-parking-eta" *ngIf="selectedOrder.clickAndReadyParkingStatus == 'INJECTED' && selectedOrder.collectingDetails.geofencingEta !== undefined">ETA {{ selectedOrder.collectingDetails.geofencingEta * 1000 | date: 'm'  }} min</span>
        </div>
    </div>
    <div class="second-line">
            <span class="order-payment">
                {{ paymentType[selectedOrder?.paymentType] }}
            </span>
            <span class="order-date"> le {{ (selectedOrder.requestInjectedDate | date: 'dd/MM/yyyy à HH:mm') }} </span>
            <span class="order-price"> - {{ this.orderService.getTotalAmount(selectedOrder) | currency: 'EUR':'symbol':'1.2-2':'fr' }}
            </span>
    </div>
</div>

<div class="order-body">
    <app-order-error
            style="width: 95%; position: relative"
            *ngIf="orderStatusType.ERROR == selectedOrder?.status && orderError"
            [orderError]="orderError"
    ></app-order-error>
    <div *ngIf="this.delay; then delayed; else nodelay" class="delay-button">
    </div>
        <ng-template #nodelay>
            <button (click)="sendDelay()" class="order-no-delay">
                <span>SIGNALER UN RETARD</span>
            </button>
        </ng-template>
        <ng-template #delayed>
            <div class="order-delayed">
                <span style="text-transform: uppercase">retard signalé</span>
                <mat-icon id="delayed_icon">check_circle</mat-icon>
            </div>
        </ng-template>

    <div class="order-header">
        <span class="subtitle"> Détails de la commande </span>
        <span class="order-products-quantity">
            {{ selectedOrder?.items.length }} {{ selectedOrder?.items.length > 1 ? 'produits' : 'produit' }}
        </span>
    </div>
    <app-order-item-list style="width: 95%" [items]="selectedOrder.items"></app-order-item-list>
</div>
