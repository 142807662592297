export enum TimeDependency {
  /**
   * No dependency on facility time.
   * POD has its own opening slots.
   */
  NONE = 'NONE',
  /**
   * The POD depends on the facility time and availability.
   * POD uses its associated facility opening slots.
   */
  USE_FACILITY_TIME = 'USE_FACILITY_TIME',
  /**
   * POD openings slots depends on facility openings slots.
   * POD has its own opening slots but there should be within the facility ones.
   */
  WITHIN_FACILITY_TIME = 'WITHIN_FACILITY_TIME',
}
