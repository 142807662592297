<!-- HEADER -->
<div id="modal-header">
  <div class="modal-title">Frais livraison & service</div>
  <a>
    <mat-icon id="close-modal-button" (click)="closeModal()">close</mat-icon>
  </a>
  <div class="modal-subtitle">Frais livraison & service</div>
</div>

<div id="modal-body">
  <div>
    <div class="fees-wording">Frais de service</div>
    <p>
      Les frais de service sont des coûts supplémentaires liés à la livraison et facturés au client afin de lui offrir une expérience optimale.
    </p>
  </div>
  <div>
    <div class="fees-wording">Frais de livraison</div>
    <p>Les frais de livraison représentent les coûts supplémentaires associés au service de transport d’une commande depuis votre restaurant jusqu’au lieu de livraison du client.</p>
  </div>
</div>
