<div
    [ngClass]="[
              facility.nationalActivation ? 'switch-facility-national-activate' : 'switch-facility-national-desactivate',
              isUserRestaurantManager ? '' : 'switch-facility-national-notManager'
            ]"
>
    <label class='facility-switch-label' *ngIf='!isLAD'>{{ switchLabel }}</label>
    <div *ngIf='facility.nationalActivation && !isLAD && isUserRestaurantManager' class='switch'>
        <label>
            <!-- if inactive parent => we can't change status -->
            <input
                type='checkbox'
                *ngIf='enabledSwitch; else disabledSwitch'
                [(ngModel)]='facility.restaurantActivation'
                (change)='emitFacilityStatusChanged()'
            />
            <ng-template #disabledSwitch>
                <input type='checkbox' disabled='true' />
            </ng-template>
            <span class='slider'></span>
        </label>
    </div>
</div>
