import { Slot } from '../slot.model';
import { RestaurantCoordinates } from './restaurant-coordinates.model';
import { RestaurantInjectionConfig } from './restaurant-injection-config.model';

export class Restaurant {
  /**
   * Reference of the restaurant
   */
  ref: number;
  /**
   * Name of the restaurant
   */
  name: string;
  /**
   * Whether the restaurant is selected or not.
   * (when user clicked on it on the list and went back to restaurants list)
   */
  selected: boolean;

  /**
   * Manual injection configuration.
   */
  injectionConfig: RestaurantInjectionConfig;

  /**
   * National max amount for McDelivery
   */
  nationalMcDeliveryMaxOrderAmount: number;

  /**
   * Local max amount for Mcdelivery
   */
  mcDeliveryMaxOrderAmount: number;

  /**
   * Last update Date of local max amount for McDelivery
   */
  mcDeliveryAmountLastUpdateDate: Date;

  /**
   * Type of the person who last updated the max amount for McDelivery.
   */
  mcDeliveryAmountLastUpdatedPerson: string;

  /**
   * National max amount for C&C
   */
  nationalCAndCMaxOrderAmount: number;

  /**
   * Local max amount for C&C
   */
  candCMaxOrderAmount: number;

  /**
   * Last update Date of local max amount for C&C
   */
  candCAmountLastUpdateDate: Date;

  /**
   * Type of the person who last updated the max amount for C&C.
   */
  candCAmountLastUpdatedPerson: string;

  /**
   * List of slots of the Restaurant.
   */
  slots: Slot[];

  /**
   * List of payment types
   */
  availablePaymentTypes: string[];

  /**
   * List of available delivery partners IDs
   */
  availableDeliveryPartnersIds: string[];

  /**
   * Comment field use to add indications for deliverers
   */
  comment: string;

  status: string;

  ownerRef: string;
  phone: string;
  exceptionalOpening: string;
  fax: string;
  coordinates: RestaurantCoordinates;
  socialReason: string;
  constructor(
    ref: number,
    name: string,
    injectionConfig: RestaurantInjectionConfig,
    nationalMcDeliveryMaxOrderAmount: number,
    mcDeliveryMaxOrderAmount: number,
    mcDeliveryAmountLastUpdateDate: Date,
    mcDeliveryAmountLastUpdatedPerson: string,
    nationalCAndCMaxOrderAmount: number,
    candCMaxOrderAmount: number,
    candCAmountLastUpdateDate: Date,
    candCAmountLastUpdatedPerson: string,
    availablePaymentTypes: string[],
    availableDeliveryPartnersIds: string[],
    comment: string,
    status: string,
    ownerRef: string
  ) {
    this.ref = ref;
    this.name = name;
    this.injectionConfig = injectionConfig;
    this.selected = false;
    this.nationalMcDeliveryMaxOrderAmount = nationalMcDeliveryMaxOrderAmount;
    this.mcDeliveryMaxOrderAmount = mcDeliveryMaxOrderAmount;
    this.mcDeliveryAmountLastUpdateDate = mcDeliveryAmountLastUpdateDate;
    this.mcDeliveryAmountLastUpdatedPerson = mcDeliveryAmountLastUpdatedPerson;
    this.nationalCAndCMaxOrderAmount = nationalCAndCMaxOrderAmount;
    this.candCMaxOrderAmount = candCMaxOrderAmount;
    this.candCAmountLastUpdateDate = candCAmountLastUpdateDate;
    this.candCAmountLastUpdatedPerson = candCAmountLastUpdatedPerson;
    this.availablePaymentTypes = availablePaymentTypes;
    this.availableDeliveryPartnersIds = availableDeliveryPartnersIds;
    this.comment = comment;
    this.status = status;
    this.ownerRef = ownerRef;
  }
}
