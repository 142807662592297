import { Component, Input, OnInit } from '@angular/core';
import {Dish} from "../../../../models/order/dish-model";

@Component({
  selector: 'app-dish-list',
  templateUrl: './dish-list.component.html',
  styleUrls: ['./dish-list.component.scss'],
})
export class DishListComponent implements OnInit {
  @Input() dishes: Dish[];

  constructor() {}

  ngOnInit(): void {}
}
