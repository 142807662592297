export class RestaurantAddress {
    address1: string;
    address2: string;
    address3: string;
    address4: string;
    zipCode: number;
    city: string;
    country: string;
    label: string;
    addressType: string;
    id: number;
    type: string;
    code: string;
}
