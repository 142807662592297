<div class="custom-snackbar">
  <div class="check-logo">
    <img src="assets/medias/green-tick.svg" alt="Green tick" />
  </div>
  <div class="snackbar-text">
    {{ data }}
    <div class="snackbar-subText">
      {{ customSubData }}
    </div>
  </div>
  <a class="action" (click)="actionClicked()">
    {{ customActionText }}
  </a>
</div>
