import {AfterViewChecked, Component, ElementRef, EventEmitter, HostListener, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {ClickAndReadyParkingStatus} from '../../models/order/click-and-ready-parking-status.enum';
import {OrderStatus} from '../../models/order/order-status.enum';
import {Order} from '../../models/order/order.model';
import {Restaurant} from '../../models/restaurant/restaurant.model';
import {NavBarService} from '../../service/nav-bar/nav-bar.service';
import {OrderService} from '../../service/order/order.service';
import {PersistanceService} from '../../service/persistance/persistance.service';
import {forkJoin, Observable, of, Subject, timer} from "rxjs";
import {OrderErrorService} from "../../service/orderError/order-error.service";
import {OrderError} from "../../models/order/order-error.model";
import * as moment from "moment";
import {debounceTime, distinctUntilChanged, switchMap} from "rxjs/operators";

@Component({
    selector: 'app-order-parking',
    templateUrl: './order-parking.component.html',
    styleUrls: ['./order-parking.component.scss'],
})
export class OrderParkingComponent implements OnInit, AfterViewChecked, OnDestroy {
    headerTitle: string;
    inputPlaceholder: string;
    loading: boolean;
    statusFilter: { label: string; type: string; value: OrderStatus };
    inputSearch = "";
    @ViewChild('searchZone') searchZone: ElementRef;
    @ViewChild('orderZone') orderZone: ElementRef;
    allOrders: Order[];
    filteredOrders: Order[];
    dateOfOrders: string;
    timerSub;
    isRefreshEnabled: boolean;
    currentRestaurant: Restaurant;
    isLoading = true;
    headersHeight: number;
    orderNumberMaxLength = 6;
    searchTerm = new Subject<string>();
    lastCheckedDate: Date;
    @Output() showMoreOrdersEvent = new EventEmitter<boolean>();
    @Output() searchedOrderNumber = new EventEmitter<string>();
    @Output() searchedOrderStatus = new EventEmitter<OrderStatus>();

    emptyOrderError = "Aucune commande Click & Ready Parking en cours sur le restaurant sélectionné";
    noOrderFilterError = "Aucune commande trouvée";

    filters = [];
    orderErrors: OrderError[];
    private readonly commandesParking = 'Commandes Parkings';
    private readonly ordersParkingElementId = 'orders-parking';

    constructor(private readonly navBarService: NavBarService,
                private readonly orderErrorService: OrderErrorService,
                private readonly persistenceService: PersistanceService,
                private readonly orderService: OrderService) {
        this.inputPlaceholder = 'N° de commande';
        this.headerTitle = this.commandesParking;
        this.filters.push({label: 'TOUTES', value: OrderStatus.ALL, type: "OrderStatus"});
        this.filters.push({label: 'OK', value: OrderStatus.OK, type: "OrderStatus"});
        this.filters.push({label: 'ERREUR', value: OrderStatus.ERROR, type: "OrderStatus"});
        this.filters.push({label: 'CLIENT EN ROUTE', value: ClickAndReadyParkingStatus.INJECTED, type: "ClickAndReadyParkingStatus"});
        this.filters.push({label: 'CLIENT ARRIVE', value: ClickAndReadyParkingStatus.PARKED, type: "ClickAndReadyParkingStatus"});
    }

    ngOnInit(): void {
        this.navBarService.showNavBar(true);
        this.currentRestaurant = this.persistenceService.get('current_restaurant');
        this.persistenceService.set('redirectPage', '/restaurant/orders-parking');
        this.statusFilter = this.filters[0];

        this.isLoading = true;
        forkJoin([
            this.orderErrorService.getOrderErrors(),
            this.orderService.getParkingOrders(this.currentRestaurant.ref.toString())]).subscribe((rep) => {
            const orders = rep[1];
            const errors = rep[0];
            this.allOrders = orders.orders;
            this.filteredOrders = this.filterByStatus(this.allOrders, this.statusFilter);
            this.isRefreshEnabled = orders.refreshEnable;
            this.dateOfOrders = orders.resultDate;
            this.orderErrors = errors;
            this.isLoading = false;
            const momentFromDate = moment.utc(orders.resultDate).local();
            this.lastCheckedDate = momentFromDate.toDate();
            this.initiateRefreshTimer();
            this.applyStatusFilter();
        });

        this.search(this.searchTerm).subscribe((results) => {
            this.filteredOrders = results;
        });
    }

    search(terms: Observable<string>): Observable<Order[]> {
        return terms.pipe(
            debounceTime(400),
            distinctUntilChanged(),
            switchMap((term) => this.searchEntries(term))
        );
    }

    initiateRefreshTimer(): void {
        if (!this.isRefreshEnabled) {
            return;
        }
        if (this.timerSub) {
            this.timerSub.unsubscribe();
        }
        const twentySeconds = 20 * 1000;
        this.timerSub = timer(twentySeconds, twentySeconds).subscribe((_) => this.checkForNewParkingOrders());
    }

    checkForNewParkingOrders(): void {
        this.orderService.getNumberOfNewParkingOrdersSince(this.lastCheckedDate, this.currentRestaurant.ref.toString()).subscribe((res) => {
            if (res > 0) {
                this.orderService.getParkingOrders(this.currentRestaurant.ref.toString()).subscribe((rep) => {
                    const orders = rep;
                    this.allOrders = orders.orders;
                    this.filteredOrders = this.filterByStatus(this.allOrders, this.statusFilter);
                    this.isRefreshEnabled = orders.refreshEnable;
                    this.dateOfOrders = orders.resultDate;
                    const momentFromDate = moment.utc(orders.resultDate).local();
                    this.lastCheckedDate = momentFromDate.toDate();
                    this.initiateRefreshTimer();
                    this.applyStatusFilter();
                });
            }
        });
    }

    ngAfterViewChecked(): void {
        this.setPaddingTop(document.getElementById('header').offsetHeight);
    }

    ngOnDestroy(): void {
        this.navBarService.showNavBar(false);
        this.persistenceService.remove('redirectPage');

        // When component is detroyed, unsubscribe for timer.
        if (this.timerSub) {
            this.timerSub.unsubscribe();
        }
    }

    /**
     * Triggered when user pressed enter in search bar
     */
    searchValidated(): void {
        if (screen.width <= 700) {
            // mobile
            document.getElementById('nav-bar').style.display = 'flex';
        }
        if (!this.inputSearch || this.inputSearch.length < 4) {
            return;
        }
        this.searchedOrderNumber.emit(this.inputSearch);
    }

    /**
     * When search input focus is lost and search empty : display both orders-header and graph or filters
     */
    loseFocus(): void {
        if (this.inputSearch && this.inputSearch !== '') {
            return;
        }
        if (screen.width <= 700) {
            // mobile
            document.getElementById('nav-bar').style.display = 'flex';
        }
        const ordersHeader = document.getElementById('orders-header');
        const filtersZone = document.getElementById('filters-zone');
        if (ordersHeader) {
            ordersHeader.style.display = 'block';
        }
        if (filtersZone) {
            filtersZone.style.display = 'block';
        }
        this.onScroll();
    }

    /**
     * Method triggered when page is scrolling.
     */
    @HostListener('window:scroll')
    onScroll(): void {
        this.headersHeight = document.getElementById('main-header').offsetHeight
            + document.getElementById('header').offsetHeight;
        if (document.getElementById('search-zone')) {
            this.headersHeight += document.getElementById('search-zone').offsetHeight;
        }
        if (this.searchZone && this.searchZone.nativeElement) {
            if (Math.floor(this.orderZone.nativeElement.getBoundingClientRect().top) < this.headersHeight) {
                this.searchZone.nativeElement.classList.add('sticky-search');
            } else {
                this.searchZone.nativeElement.classList.remove('sticky-search');
            }
        }
    }

    getSelectedFilter(filterLabel: string): string {
        let label = '';
        if (filterLabel === OrderStatus.ALL) {
            label = 'TOUTES';
        }
        if (filterLabel === OrderStatus.OK) {
            label = 'OK';
        }
        if (filterLabel === OrderStatus.ERROR) {
            label = 'ERREUR';
        }
        if (filterLabel === ClickAndReadyParkingStatus.INJECTED) {
            label = 'CLIENT EN ROUTE';
        }
        if (filterLabel === ClickAndReadyParkingStatus.PARKED) {
            label = 'CLIENT ARRIVE';
        }
        return label;
    }

    /**
     * When search input is focused hide both orders-header and graph or filters
     */
    searchSticky(): void {
        if (screen.width <= 700) {
            // mobile
            document.getElementById('nav-bar').style.display = 'none';
        }
        const ordersHeader = document.getElementById('orders-header');
        const filtersZone = document.getElementById('filters-zone');
        if (ordersHeader) {
            ordersHeader.style.display = 'none';
        }
        if (filtersZone) {
            filtersZone.style.display = 'none';
        }
        this.onScroll();
        window.scrollTo(0, 0);
    }

    /**
     * Triggered when user search input changes.
     */
    onEnterText(): void {
        const value = this.inputSearch;

        if (!value || value === '') {
            document.getElementById('search-order-button').classList.remove('searching');
            document.getElementById('search-order-input').classList.remove('searching');
        } else {
            this.searchSticky();
            document.getElementById('search-order-button').classList.add('searching');
            document.getElementById('search-order-input').classList.add('searching');
        }
        this.searchTerm.next(value);
    }

    /**
     * When status filter changes, apply the choice to filter the list.
     */
    applyStatusFilter(): void {
        let currentOrders;
        this.searchEntries(this.inputSearch).subscribe((orders) => (currentOrders = orders));
        this.filteredOrders = this.filterByStatus(currentOrders, this.statusFilter);
    }

    searchEntries(term: string): Observable<Order[]> {
        const ordersByStatus = this.filterByStatus(this.allOrders, this.statusFilter);
        if (!term || term.length < 2) {
            return of(ordersByStatus);
        }
        term = term.toUpperCase();
        return of(ordersByStatus.filter((order: Order) => this.orderNumberMatches(order, term)));
    }

    /**
     * Filters orders matching the given status (ALL/OK/ERROR)
     * @param ordersToFilter orders list to filter
     * @param status Status to match
     */
    filterByStatus(ordersToFilter: Order[], status: any): Order[] {
        let orders;
        if (!status || status.value === OrderStatus.ALL) {
            orders = ordersToFilter.filter((order: Order) => order.status === OrderStatus.OK || order.status === OrderStatus.ERROR);
        } else {
            if (status.type === "OrderStatus") {
                orders = ordersToFilter.filter((order: Order) => order.status === status.value);
            } else {
                orders = ordersToFilter.filter((order: Order) => order.clickAndReadyParkingStatus === status.value);
            }
        }
        return orders;
    }

    /**
     * Return true if the order number matches the order. (check order number LAD if not null, else normal orderNumber)
     */
    private orderNumberMatches(order: Order, orderNumber: string): boolean {
        if (!order) {
            return false;
        }
        return order.orderNumber.indexOf(orderNumber) >= 0;
    }

    @HostListener('window:resize')
    onResize(): void {
        this.setPaddingTop(document.getElementById('header').offsetHeight);
    }

    @HostListener('click')
    onClick(): void {
        this.setPaddingTop(document.getElementById('header').offsetHeight);
    }

    setPaddingTop(restaurantHeaderHeight: number): void {
        document.getElementById(this.ordersParkingElementId).style.setProperty('top', restaurantHeaderHeight + 'px');
        let navBarHeight = 0;
        if (document.getElementById('nav-bar') && !document.getElementById('nav-bar-component').classList.contains('nav-bar-tablet-visible')) {
            navBarHeight = document.getElementById('nav-bar').offsetHeight;
            // for ios devices we need to put padding instead of margin cuz of scroll problems
            const isIOS = /iPad|iPhone|iPod/.test(navigator.platform) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
            document.getElementById(this.ordersParkingElementId).style.setProperty(isIOS ? 'padding-bottom' : 'margin-bottom', navBarHeight + 'px');
        } else {
            document.getElementById(this.ordersParkingElementId).style.setProperty('padding-bottom', '0px');
            document.getElementById(this.ordersParkingElementId).style.setProperty('margin-bottom', '0px');
        }
        const minHeight = window.innerHeight - restaurantHeaderHeight - document.getElementById('main-header').offsetHeight - navBarHeight;
        document.getElementById(this.ordersParkingElementId).style.setProperty('min-height', minHeight + 'px');
    }
}
