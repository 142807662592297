<div id="opening-hours-title" class="modal-section">
  <div class="section-title">
    <div class="section-title-text">{{ exceptionalHoursName }}</div>
    <div class="section-date-block">
      <div class="section-date" *ngIf="!isDuration">Le</div>
      <div class="section-date" *ngIf="isDuration">Du</div>
      <div class="section-date">{{ beginDate | date: 'dd/MM/yyyy' }}</div>
      <div class="section-date" *ngIf="isDuration">au</div>
      <div *ngIf="isDuration" class="section-date">{{ endDate | date: 'dd/MM/yyyy' }}</div>
    </div>
  </div>
  <div *ngFor="let facility of getKeys()" [ngClass]="getSlot(facility).active ? 'opening-hours' : 'opening-hours disabled'">
    <hr class="separator" />
    <app-exceptional-hours-facility
      [isModification]="isModification"
      [facility]="facility"
      [slot]="getSlot(facility)"
    ></app-exceptional-hours-facility>
  </div>
</div>
