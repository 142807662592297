<div id="edit-max-amount-body">
  <div id="edit-max-amount-settings">
    <div id="modification-information">{{ updatedMessage }}</div>
    <div id="subtitle">Montant maximum / commande :</div>
    <div id="amount-section">
      <input
        type="number"
        min="0"
        max="999"
        inputmode="numeric"
        pattern="[0-9]*"
        id="amount-input"
        [ngStyle]="{
          border: localAmount > nationalAmount ? 'solid 1px #d20000' : 'solid 1px #d7d7db',
          color: localAmount > nationalAmount ? '#d20000' : 'black'
        }"
        [(ngModel)]="localAmount"
        (change)="inputChange()"
        (keypress)="checkInputLength($event)"
      />€
      <p id="reset-btn" (click)="resetLocalAmount()">Réinitialiser</p>
    </div>
  </div>
  <div id="information">
    <div *ngIf="localAmount > nationalAmount" id="error-section">
      <img alt="warning" src="../../../../../assets/medias/warning.svg" class="warningImg" />
      <p id="error-text">Le montant saisi ne doit pas être supérieur au montant maximum de commandes défini au national.</p>
    </div>
    <div *ngIf="localAmount != null && localAmount < 1" id="error-section">
      <img alt="warning" src="../../../../../assets/medias/warning.svg" class="warningImg" />
      <p id="error-text">
        Le montant saisi ne peut pas être égal à 0. Veuillez saisir un montant supérieur ou cliquer sur le bouton
        <em>Réinitialiser</em> pour appliquer le montant national.
      </p>
    </div>
    <div id="clientInfos">
      <span class="infobulle">i</span>
      <p>Montant maximal national de {{ nationalAmount }}€</p>
    </div>
    <div id="separator"></div>
    <div id="clientInfos">
      <span class="infobulle">i</span>
      <p>Cliquez sur le bouton <u>Réinitialiser</u> puis enregistrez votre paramétrage pour appliquer le montant national par défaut.</p>
    </div>
  </div>
</div>
