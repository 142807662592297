import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OrderError } from '../../../models/order/order-error.model';
import { OrderStatus } from '../../../models/order/order-status.enum';
import { OrderType } from '../../../models/order/order-type.enum';
import { Order } from '../../../models/order/order.model';
import { PaymentType } from '../../../models/order/payment-type.enum';
import { OrderService } from '../../../service/order/order.service';
import { OrderErrorService } from '../../../service/orderError/order-error.service';
import { Intercom } from 'ng-intercom';
import { OrderItem } from '../../../models/order/order-item.model';

@Component({
  selector: 'app-order-modal',
  templateUrl: './order-modal.component.html',
  styleUrls: ['./order-modal.component.scss'],
})
export class OrderModalComponent implements OnInit {
  selectedOrder: Order;
  orderStatusType: typeof OrderStatus = OrderStatus;
  orderType: typeof OrderType = OrderType;
  isLoading: boolean;
  orderError: OrderError;
  paymentType: typeof PaymentType = PaymentType;
  updateList = false;

  constructor(
    public dialogRef: MatDialogRef<OrderModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly orderErrorService: OrderErrorService,
    public readonly orderService: OrderService,
    public intercom: Intercom,
  ) {}

  ngOnInit(): void {
    this.selectedOrder = this.data.currentOrder;
    if (this.selectedOrder.status === OrderStatus.ERROR) {
      this.isLoading = true;
      const errorCode = this.selectedOrder.errorCode ? this.selectedOrder.errorCode : 'Code erreur non remonté';
      this.orderErrorService.getOrderErrors().subscribe((errors) => {
        this.orderError = errors.find((err) => err.errorCode === errorCode);
        this.isLoading = false;
      });
    }
  }

  /**
   * Close the order modal
   */
  closeModal(): void {
    this.dialogRef.close(this.updateList);
    this.intercom.shutdown();
  }

  /**
   * Return tuje order type value as string
   * @param orderType The order type to convert in string
   */
  getOrderTypeValue(orderType: OrderType): string {
    return this.orderService.getOrderTypeValue(orderType);
  }

  setUpdateList(): void {
    this.updateList = true;
  }

  isStuartOrder(): boolean {
    return this.selectedOrder.partnerLabel === "stuart";
  }

  isHistoricalOrder(): boolean {
    return this.selectedOrder.status === OrderStatus.RETRIEVED;
  }

  /*
   * handling of total number of dishes display
   */
  totalDishesNumber(): number {
    let totalDishes = 0;

    for (const item of this.selectedOrder.items) {
      totalDishes += item.itemDishes.length * item.qty;
      if (item.choices && item.choices.length > 0) {
        for (const choice of item.choices) {
          totalDishes += this.handleItemChoiceDishes(choice, item.qty);
        }
      }
    }
    return totalDishes;
  }

  private handleItemChoiceDishes(item: OrderItem, parentQty: number): number {
    let choiceDishesNumber = 0;
    choiceDishesNumber += item.itemDishes.length * item.qty * parentQty;
    if (item.choices && item.choices.length > 0) {
      for (const choice of item.choices) {
        choiceDishesNumber += this.handleItemChoiceDishes(choice, parentQty * item.qty);
      }
    }

    return choiceDishesNumber;
  }


  /*
   * handling of total number of products display
   */
  totalProductsNumber(): number {
    let totalProducts = 0;

    for (const item of this.selectedOrder.items) {
      if (item.choices && item.choices.length > 0) {
        for (const choice of item.choices) {
          totalProducts += this.handleItemChoiceProducts(choice, item.qty);
        }
      } else {
        totalProducts += item.qty;
      }
    }
    return totalProducts;
  }

  private handleItemChoiceProducts(item: OrderItem, parentQty: number): number {
    let choiceProductsNumber = 0;
    if (item.choices && item.choices.length > 0) {
      for (const choice of item.choices) {
        choiceProductsNumber += this.handleItemChoiceProducts(choice, parentQty * item.qty);
      }
    } else {
      choiceProductsNumber += item.qty * parentQty;
    }
    return choiceProductsNumber;
  }
}
