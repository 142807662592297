import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';
import { ExceptionalHoursView } from '../../../../models/exceptionalHoursView/exceptional-hours-view.model';
import { Restaurant } from '../../../../models/restaurant/restaurant.model';
import { PersistanceService } from '../../../../service/persistance/persistance.service';

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'D MMM YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-exceptional-hours-edit',
  templateUrl: './exceptional-hours-edit.component.html',
  styleUrls: ['./exceptional-hours-edit.component.scss'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class ExceptionalHoursEditComponent implements OnInit {
  isLoading = false;
  restaurant: Restaurant;
  @Input()
  exceptionalHoursName: string;
  exceptionalHoursMessageMaxLength = 18;
  @Input()
  beginDate: moment.Moment;
  @Input()
  endDate: moment.Moment;
  @Output()
  updateValue = new EventEmitter<{ exceptionalHoursName: string; beginDate: moment.Moment; endDate: moment.Moment; isDuration: boolean }>();
  minDate: moment.Moment;

  NO_EARLIER_DATE = 'La date sélectionnée ne peut pas être antérieure à la date du jour.';
  TAKEN_INTO_ACCOUNT = 'Prévoir un délai de 24h pour la prise en compte des horaires exceptionnels.';
  NO_SAME_DATE = 'Vous ne pouvez pas paramétrer un horaire exceptionnel pour le jour-même.';
  MISSING_NAME = "Le nom de votre horaire doit être renseigné pour passer à l'étape suivante.";
  NO_SAME_BEGIN_END_DATE = 'La date de fin ne doit pas être identique à la date de début.';
  NO_END_BEFORE_BEGINNING = 'La date de fin ne doit pas être antérieur à la date de début.';
  EXCEPTIONAL_HOURS_ALREADY_EXISTING =
    "Une plage d'horaires exceptionnels a déjà été paramétrée sur cette période. Veuillez sélectionner une autre période.";
  NO_PERIOD_SELECTED = "Veuillez sélectionner une période pour passer à l'étape suivante.";
  errorLabelBeginDate: boolean;
  errorLabelEndDate: boolean;
  errorLabelName: boolean;
  errorMessageBeginDate: string;
  errorMessageEndDate: string;
  errorMessageName: string;
  @Input()
  isDuration: boolean;

  private readonly endDateBox = 'duration-date-box';

  constructor(
    public dialogRef: MatDialogRef<ExceptionalHoursEditComponent>,
    private readonly persistanceService: PersistanceService,
    public matDialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.restaurant = this.persistanceService.get('current_restaurant');
    this.errorLabelName = false;
    this.errorLabelEndDate = false;
    this.errorLabelBeginDate = false;
    this.minDate = moment().add(1, 'days');
    this.setMinContentHeight();
  }

  /**
   * This method sets the height of our component so that there is not available space
   */
  setMinContentHeight(): void {
    document.getElementById('modal-body').style.minHeight =
      document.getElementById('restaurant-exceptional-hours-modal').offsetHeight -
      document.getElementById('modal-header').offsetHeight -
      document.getElementById('modal-footer').offsetHeight -
      25 + // we delete the margin-top
      'px';
  }

  /**
   * Method triggered when user is resizing the window.
   */
  @HostListener('window:resize')
  onResize(): void {
    this.setMinContentHeight();
  }

  /**
   * Return the percentage of characters used in textarea.
   * Based on max length.
   */
  textareaPercentage(): number {
    return (this.exceptionalHoursName.length / this.exceptionalHoursMessageMaxLength) * 100;
  }

  addEndDate(): void {
    this.isDuration = true;
    this.endDate = this.beginDate.clone();
    this.endDate.add(1, 'days');
  }

  public validate(existingExceptionalHours: ExceptionalHoursView[], existingId?: number): boolean {
    if (!this.isDuration) {
      this.endDate = this.beginDate;
    }
    // 1. check exceptional hours label
    if (!this.exceptionalHoursName || this.exceptionalHoursName.trim().length === 0) {
      this.errorLabelName = true;
      this.errorMessageName = this.MISSING_NAME;
      document.getElementById('message-area').classList.add('error');
    } else {
      this.errorLabelName = false;
      document.getElementById('message-area').classList.remove('error');
    }
    // 2. check end date validity
    this.validateEndDate();
    // 3. check if exceptional hours already exists for the given period
    this.checkExistingExceptionalHoursOnDate(existingExceptionalHours, existingId);

    this.updateValue.emit({
      exceptionalHoursName: this.exceptionalHoursName,
      beginDate: this.beginDate,
      endDate: this.endDate,
      isDuration: this.isDuration,
    });
    return !this.errorLabelName && !this.errorLabelEndDate && !this.errorLabelBeginDate;
  }

  /**
   * Check if the date of exceptional hours we try to add/update does not already exists
   * @param existingExceptionalHours the existing exceptional hours of the restaurant
   * @param existingId the id of the exceptional hours we try to update, undefined if creation.
   */
  private checkExistingExceptionalHoursOnDate(existingExceptionalHours: ExceptionalHoursView[], existingId: number): void {
    this.errorLabelBeginDate = false;
    this.errorMessageBeginDate = null;
    this.beginDate = this.beginDate.startOf('day');
    this.endDate = this.endDate.startOf('day');
    let existingExceptionalHoursToCheck = existingExceptionalHours;
    // we remove the current exceptional hours we try to edit from the existing list
    existingExceptionalHoursToCheck = existingExceptionalHoursToCheck.filter((x) => x.id !== existingId);

    for (const existingExceptionalHour of existingExceptionalHoursToCheck) {
      const existingStart = moment(existingExceptionalHour.startDate);
      const existingEnd = existingExceptionalHour.endDate
        ? moment(existingExceptionalHour.endDate)
        : moment(existingExceptionalHour.startDate);
      if (this.dateRangeOverlaps(existingStart, existingEnd, this.beginDate, this.endDate)) {
        this.errorLabelBeginDate = true;
        this.errorMessageBeginDate = this.EXCEPTIONAL_HOURS_ALREADY_EXISTING;
        break;
      }
    }
  }

  /**
   * Check if 2 dates overlaps
   */
  dateRangeOverlaps(aStart, aEnd, bStart, bEnd): boolean {
    if (aStart <= bStart && bStart <= aEnd) {
      return true; // b starts in a
    }
    if (aStart <= bEnd && bEnd <= aEnd) {
      return true; // b ends in a
    }
    if (bStart < aStart && aEnd < bEnd) {
      return true; // a in b
    }
    return false;
  }

  private validateEndDate(): void {
    if (this.isDuration) {
      if (this.beginDate == null || this.endDate == null) {
        this.errorLabelEndDate = true;
        this.errorMessageEndDate = this.NO_PERIOD_SELECTED;
        document.getElementById(this.endDateBox).classList.add('error');
      } else if (this.endDate && this.endDate.isBefore(this.beginDate, 'day')) {
        this.errorLabelEndDate = true;
        this.errorMessageEndDate = this.NO_END_BEFORE_BEGINNING;
        document.getElementById(this.endDateBox).classList.add('error');
      } else if (this.endDate && this.endDate.isSame(this.beginDate, 'day')) {
        this.errorLabelEndDate = true;
        this.errorMessageEndDate = this.NO_SAME_BEGIN_END_DATE;
        document.getElementById(this.endDateBox).classList.add('error');
      } else {
        this.errorLabelEndDate = false;
        document.getElementById(this.endDateBox).classList.remove('error');
      }
    }
  }

  deleteEndDate(): void {
    this.isDuration = false;
    this.errorLabelEndDate = false;
  }
}
