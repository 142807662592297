import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { WidgetChatParameters } from '../../models/mcdelivery/widget-chat-parameters';
import { McDeliveryPartnerDynamicFee } from '../../models/mcdelivery/mcdelivery-partner-dynamic-fee';

@Injectable({
  providedIn: 'root',
})
export class McDeliveryService {
  DELIVERY_BASE_ENDPOINT = `${environment.settings.apiUrl}/mcdelivery`;

  constructor(private readonly httpClient: HttpClient) {}

  /**
   * Finds the widget chat parameters for a restaurant.
   * @param restaurantRef restaurant reference.
   * @param vendor vendor name (uppercase).
   */
  getWidgetChatParameter(vendor: string, restaurantRef: number): Observable<WidgetChatParameters> {
    return this.httpClient.get<WidgetChatParameters>(`${this.DELIVERY_BASE_ENDPOINT}/${vendor}/widget/chat/${restaurantRef}`);
  }

  getUberDirectPartnerDynamicFees(restaurantRef: number): Observable<McDeliveryPartnerDynamicFee[]> {
    return this.httpClient.get<McDeliveryPartnerDynamicFee[]>(`${this.DELIVERY_BASE_ENDPOINT}/uberdirect/dynamicDeliveryFees/${restaurantRef}`);
  }
}
