import { AfterViewChecked, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {Role} from '../../../models/role.enum';
import {NavBarService} from '../../../service/nav-bar/nav-bar.service';
import {UserService} from '../../../service/user/user.service';
import * as moment from 'moment';
import { PersistanceService } from '../../../service/persistance/persistance.service';
import { OrderService } from '../../../service/order/order.service';
import { timer } from 'rxjs';
import {ErrorService} from "../../../service/error/error.service";
import {ConsoleLoggerService} from "../../../service/logger/console-logger.service";

@Component({
    selector: 'app-nav-bar',
    templateUrl: './nav-bar.component.html',
    styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent implements OnInit, OnDestroy, AfterViewChecked {
    @ViewChild('audioComponent') audioComponent: ElementRef;
    showNavBar = false;
    isUserRestaurant = true;
    isUserOrder = true;
    lastCheckedDate;
    isRefreshEnabled: boolean;
    timerSub;
    nbCurrentlyParked: number;
    showParkedNotification = false;
    private readonly newParkingOrderElementId = 'new-parking-order';
    private readonly navCarOrdersParkingElementId = 'nav-car-orders-parking';
    private readonly navButtonOrdersParkingElementId = 'nav-button-orders-parking';

    constructor(private readonly router: Router,
                private readonly userService: UserService,
                private readonly navBarService: NavBarService,
                private readonly persistenceService: PersistanceService,
                private readonly orderService: OrderService,
                private readonly errorService: ErrorService,
                private readonly CONSOLE_LOGGER: ConsoleLoggerService) {
    }


    ngOnInit(): void {
        this.navBarService.showNavBar$.subscribe((value) => {
            if (value) {
                this.userService.getUserInfo().subscribe((userInfos) => {
                    if (userInfos) {
                        this.isUserRestaurant = userInfos.roles.includes(Role.MANAGER) || userInfos.roles.includes(Role.RESTAURANT_VIEWER);
                        this.isUserOrder = userInfos.roles.includes(Role.ORDER_UPDATER) || userInfos.roles.includes(Role.ORDER_VIEWER);
						// check if user has roles restaurant_manager or order to show navbar
						this.showNavBar = this.isUserRestaurant || this.isUserOrder;
                    } else {
                        this.showNavBar = false;
                    }
                });
            } else {
                this.showNavBar = false;
            }
        });
        this.router.events.subscribe((value) => {
            if (value instanceof NavigationEnd) {
                this.manageSelectedItem(value.url);
            }
        });
    }

    /**
     * When component is detroyed, unsubscribe for timer.
     */
    ngOnDestroy(): void {
        if (this.timerSub) {
            this.timerSub.unsubscribe();
        }
        this.showParkedNotification = false;
    }

    private manageSelectedItem(url: string): void {
        const navElements = document.getElementsByClassName('nav-item');
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < navElements.length; i++) {
            if (navElements[i].getAttribute('data-url') === url) {
                navElements[i].classList.add('selected');
            } else {
                navElements[i].classList.remove('selected');
            }
        }
        if (document.getElementById(this.navButtonOrdersParkingElementId) && document.getElementById(this.navButtonOrdersParkingElementId).classList.contains('selected')) {
            this.checkForNewParkedOrders();
        } else {
            this.isRefreshEnabled = false;
            this.lastCheckedDate = null;
            this.showParkedNotification = false;
            this.updateRefreshTimer();
        }
    }

    ngAfterViewChecked(): void {
        this.onResize();
    }

    goTo(event: any): void {
        const url = event.target.closest('.nav-item').getAttribute('data-url');
        if (this.audioComponent && this.audioComponent.nativeElement) {
            if (event.currentTarget.id === this.navButtonOrdersParkingElementId) {
                this.audioComponent.nativeElement.muted = false;
            } else {
                this.audioComponent.nativeElement.muted = true;
            }
        }
        this.router.navigateByUrl(url);
    }

    @HostListener('window:resize')
    onResize(): void {
        if (document.getElementById(this.newParkingOrderElementId)) {
            if (window.innerWidth < 700) {
                document.getElementById(this.newParkingOrderElementId).style.top =
                  document.getElementById(this.navCarOrdersParkingElementId).getBoundingClientRect().top - 5 + 'px';
                document.getElementById(this.newParkingOrderElementId).style.left =
                  document.getElementById(this.navCarOrdersParkingElementId).getBoundingClientRect().left + 14 + 'px';
            } else {
                document.getElementById(this.newParkingOrderElementId).style.top =
                  document.getElementById(this.navCarOrdersParkingElementId).getBoundingClientRect().top - 7 + 'px';
                document.getElementById(this.newParkingOrderElementId).style.left =
                  document.getElementById(this.navCarOrdersParkingElementId).getBoundingClientRect().left + 19 + 'px';
            }
        }
    }

    checkForNewParkedOrders(): void {
        const currentRestaurant = this.persistenceService.get('current_restaurant');
        this.orderService.getNumberOfParkedOrdersSince(this.lastCheckedDate, currentRestaurant.ref.toString()).subscribe((res) => {
            this.isRefreshEnabled = res.refreshEnable;
            if (res.newParked) {
                this.playAudioNotification();
            }
            this.nbCurrentlyParked = res.nbCurrentlyParked;
            this.showParkedNotification = this.nbCurrentlyParked > 0;
            const momentFromDate = moment.utc(res.resultDate).local();
            this.lastCheckedDate = momentFromDate.toDate();
            this.updateRefreshTimer();
        });
    }

    updateRefreshTimer(): void {
        if (this.timerSub) {
            this.timerSub.unsubscribe();
        }
        if (!this.isRefreshEnabled) {
            return;
        }
        const twentySeconds = 20 * 1000;
        this.timerSub = timer(twentySeconds, twentySeconds).subscribe((_) => this.checkForNewParkedOrders());
    }

    private playAudioNotification(): void {
        this.audioComponent.nativeElement.play().catch(reason => {
            this.CONSOLE_LOGGER.error(`audio cannot be play: ${reason}`);
            this.errorService.manageError(reason);
        });
    }
}
