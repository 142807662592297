import { Component, Input, OnInit } from '@angular/core';
import { Slot } from '../../../../models/slot.model';
import { SlotDetails } from '../../../../models/slot-details.model';
import { SlotService } from '../../../../service/slot/slot.service';

@Component({
  selector: 'app-edit-slot-hours',
  templateUrl: './edit-slot-hours.component.html',
  styleUrls: ['./edit-slot-hours.component.scss'],
})
export class EditSlotHoursComponent implements OnInit {
  @Input() slot: Slot;
  @Input() objectActive: boolean;
  // this field is optional. It is used in the case for editing pod (hour coherence)
  @Input() facilitySlot;

  mode: string;

  constructor(private readonly slotService: SlotService) {}

  ngOnInit(): void {
    // on vérifie que le pod ait au moins un slot
    if (this.slot.slotDetails.length === 0) {
      this.addSlot();
    }
    this.mode = 'edition';
  }

  addSlot(): void {
    this.slot.slotDetails.push(new SlotDetails('', ''));
  }

  removeSlotMode(): void {
    this.mode = 'removing';
  }

  removeSlot(idx: number): void {
    this.slot.slotDetails.splice(idx, 1);
    this.cancelRemoving();
  }

  cancelRemoving(): void {
    this.mode = 'edition';
  }

  /**
   * Get the string value of a day from its numeric value.
   * Example : 1=Lundi, 2=Mardi, etc...
   */
  public dayAsString(day: number): string {
    return this.slotService.dayAsString(day);
  }
}
