import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { FacilityAdministrationType } from '../../models/facility-administration-type';
import { Facility } from '../../models/facility/facility.model';
import { FacilityService } from '../../service/facility/facility.service';
import { ServiceInfoModalComponent } from '../restaurant-settings/service-info-modal/service-info-modal.component';
import { FacilityType } from '../../models/facility/facilityType.enum';

@Component({
  selector: 'app-restaurant-settings-facilities',
  templateUrl: './restaurant-settings-facilities.component.html',
  styleUrls: ['./restaurant-settings-facilities.component.scss'],
})
export class RestaurantSettingsFacilitiesComponent implements OnInit {
  constructor(public matDialog: MatDialog, private readonly facilityService: FacilityService) {}
  @Input() facilities: Facility[];
  restaurationFacilities: Facility[];
  equipmentFacilities: Facility[];
  restaurantTitle = 'RESTAURATION';
  equipmentTitle = 'EQUIPEMENTS';
  facility: Facility;

  isLoading = false;

  ngOnInit(): void {
    this.loadFacilities();
  }

  loadFacilities(): void {
    this.restaurationFacilities = this.facilityService.getFacilityByFacilityAdministrationType(
      this.facilities,
      FacilityAdministrationType.RESTAURATION
    );
    this.equipmentFacilities = this.facilityService.getFacilityByFacilityAdministrationType(
      this.facilities,
      FacilityAdministrationType.EQUIPMENT
    );
    // update candc status for children
    this.facilityService.saveStatus(this.facilityService.getFacilityByRef(this.facilities, FacilityType.C_AND_C.valueOf()));
  }

  /**
   * Open the modal allowing to display services infos
   */
  displayServicesInfos(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = 'service-info-modal-component';
    dialogConfig.maxWidth = '100vw';
    dialogConfig.height = '96%';
    dialogConfig.width = '94%';
    dialogConfig.data = {};
    this.matDialog.open(ServiceInfoModalComponent, dialogConfig);
  }
}
