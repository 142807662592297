<!-- HEADER -->
<div id="modal-header">
  <div class="modal-title">Paramètres</div>
  <a>
    <mat-icon id="close-modal-button" (click)="closeModal()">close</mat-icon>
  </a>
  <div class="consumption-type-name">Services</div>
</div>

<div id="modal-body">
  <div>
    <div class="injection-wording">Restauration</div>
    <p>
      Activez et configurez les services restauration de votre restaurant.
    </p>
  </div>
  <div>
    <div class="injection-wording">Équipements</div>
    <p>
      Activez les équipements mis en place dans votre restaurant.
    </p>
  </div>
</div>