<div *ngIf='isLoading; else loader'>
    <app-loader></app-loader>
</div>

<ng-template #loader>
    <div id='delivery-fees-list'>
        <div class='content'>
            <div class='delivery-fees-list-card'>
                <div class='title' [ngClass]="!viewFeesList ? 'border-bottom' : ''">
                    <span class='delivery-fees-label'>Frais de service & livraison</span>
                    <span class='infobulle green' (click)='displayFeesInfos()'>
                        <div class='infobulle-content'>?</div>
                      </span>
                </div>

                <div id='delivery-fees-list-details' [hidden]='!viewFeesList'>
                    <div *ngIf='hasElements(feesList); else noElements'>
                        <div class='border-bottom' *ngFor='let fees of feesList'>
                            <app-delivery-fees [fees]='fees'></app-delivery-fees>
                        </div>
                    </div>
                    <ng-template #noElements>
                        <div *ngIf='errorLoading; else noError'>
                            <div class='border-bottom'>Erreur de chargement des données.<br/>Veuillez réessayer plus tard.</div>
                        </div>
                        <ng-template #noError>
                            <div class='border-bottom'>Paliers de frais de service & livraison non configurés</div>
                        </ng-template>
                    </ng-template>
                </div>

                <div class='left-side footer'>
                    <a (click)='toggleView()'>{{ toggleLabel() }}</a>
                </div>
            </div>
        </div>
    </div>
</ng-template>

