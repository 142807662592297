<div class="order-pod">
  <div *ngIf="!toEdit; else edit">
    <span *ngIf="isUpdated()">
      <span class="oldPodLabel">{{ currentOrder.oldPodLabel }}</span>
      <img src="assets/medias/arrow-right.svg" class="arrow" alt="arrow-picture" />
    </span>
    <span>{{ getPodLabel() }}</span>
    <span *ngIf="showEditButton">
      <label class="{{ isDisabled() ? 'disabled' : 'action' }}" (click)="actionClicked()">Changer</label>
    </span>
  </div>
  <ng-template #edit>
    <div class="order-pod-header">
      <div class="first-line">
        <span class="subtitle">Choisi lors de la commande</span>
        <label class="action" (click)="actionClicked()">Annuler</label>
      </div>
      <div class="second-line">
        <span>{{ getPodLabel() }}</span>
      </div>
    </div>
    <div class="order-pod-body">
      <div *ngIf="eligiblePods && eligiblePods.length > 0; else noAvailablePods">
        <div class="subtitle">Basculer vers</div>
        <mat-select [(ngModel)]="selectedPod" [disableOptionCentering]="true">
          <mat-option *ngFor="let pod of eligiblePods" [value]="pod">{{ pod.wording }}</mat-option>
        </mat-select>
        <button (click)="updatePod()">Valider la modification</button>
      </div>
      <ng-template #noAvailablePods>
        <div class="error-section">
          <img alt="warning" src="assets/medias/warning.svg" class="warningImg" />
          <span class="error-text">Aucun point de retrait disponible</span>
        </div>
      </ng-template>
    </div>
  </ng-template>
  <div *ngIf="error">
    <div class="error-section">
      <img alt="warning" src="assets/medias/warning.svg" class="warningImg" />
      <span class="error-text">{{ error }}</span>
    </div>
  </div>
</div>
