<div *ngIf="isLoading">
  <app-loader></app-loader>
</div>

<div class="pod">
  <!-- HEADER SECTION -->

  <div class="pod-header" [ngStyle]="{ 'border-bottom': displaySlots() ? 'solid var(--border-color) 1px;' : '' }" (click)="editPod()">
    <mat-card class="primary-pod">
      <!-- FIRST ROW (LABEL AND STATUS) -->

      <mat-card-content [ngClass]="displaySlots() ? '' : 'noDisplayedSlot'">
        <div id="first-line">
          <span class="pod-wording">{{ getWording() }}</span>
          <div class="pod-status">
            <span class="pod-switch-label">{{ switchLabel }}</span>
            <span *ngIf="pod.restaurantActive && editablePod; else inactivePod">
              <svg
                width="8px"
                height="8px"
                viewBox="0 0 8 8"
                version="1.1"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 0C1.792 0 0 1.792 0 4C0 6.208 1.792 8 4 8C6.208 8 8 6.208 8 4C8 1.792 6.208 0 4 0Z"
                  id="Shape"
                  fill="#305e42"
                  stroke="none"
                />
              </svg>
            </span>
            <ng-template #inactivePod>
              <span>
                <svg
                  width="8px"
                  height="8px"
                  viewBox="0 0 8 8"
                  version="1.1"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4 0C1.792 0 0 1.792 0 4C0 6.208 1.792 8 4 8C6.208 8 8 6.208 8 4C8 1.792 6.208 0 4 0Z"
                    id="Shape"
                    fill="#c4c4c4"
                    stroke="none"
                  />
                </svg>
              </span>
            </ng-template>

            <mat-icon *ngIf="isUserRestaurantManager" [ngClass]="editablePod ? 'keyboardRightActive' : 'keyboardRightDesactive'">
              keyboard_arrow_right
            </mat-icon>
          </div>
        </div>
      </mat-card-content>

      <!-- DAYS SLOTS -->

      <mat-card-content *ngIf="displaySlots()">
        <app-days-section [slots]="pod.slots" [objectIsActive]="editablePod && pod.restaurantActive"></app-days-section>
      </mat-card-content>

      <!-- CUSTOMER MESSAGE -->

      <mat-card-content [ngStyle]="{ 'margin-top': displaySlots() ? '0px' : '16px' }" *ngIf="!pod.nationalActive">
        <app-important-information [infoBulleColor]="'#d20000'" [message]="NATIONAL_MESSAGE"></app-important-information>
      </mat-card-content>
      <mat-card-content *ngIf="!mcDriveActive" [ngStyle]="{ 'margin-top': displaySlots() ? '0px' : '16px' }">
        <app-important-information [infoBulleColor]="'#d20000'" [message]="MCDRIVE_DESACTIVATE_MESSAGE"></app-important-information>
      </mat-card-content>
      <mat-card-content *ngIf="!drivePietonActive" [ngStyle]="{ 'margin-top': displaySlots() ? '0px' : '16px' }">
        <app-important-information [infoBulleColor]="'#d20000'" [message]="DRIVE_PIETON_DESACTIVATE_MESSAGE"></app-important-information>
      </mat-card-content>
      <mat-card-content *ngIf="!eatInKioskActive" [ngStyle]="{ 'margin-top': displaySlots() ? '0px' : '16px' }">
        <app-important-information [infoBulleColor]="'#d20000'" [message]="KIOSK_DESACTIVATE_MESSAGE"></app-important-information>
      </mat-card-content>
      <mat-card-content *ngIf="!eatInComptoirActive" [ngStyle]="{ 'margin-top': displaySlots() ? '0px' : '16px' }">
        <app-important-information [infoBulleColor]="'#d20000'" [message]="COMPTOIR_DESACTIVATE_MESSAGE"></app-important-information>
      </mat-card-content>
      <mat-card-content *ngIf="!facilitySatActive" [ngStyle]="{ 'margin-top': displaySlots() ? '0px' : '16px' }">
        <app-important-information [infoBulleColor]="'#d20000'" [message]="SAT_DESACTIVATE_MESSAGE"></app-important-information>
      </mat-card-content>
    </mat-card>
  </div>

  <!-- HOUR SECTION -->

  <div *ngIf="seeHoursActive && pod.restaurantActive && editablePod && displaySlots()">
    <mat-card class="pod-hour-content">
      <mat-card-content>
        <app-hours-section [slots]="pod.slots"></app-hours-section>
      </mat-card-content>
    </mat-card>
  </div>

  <!-- EDIT SECTION -->

  <div class="edit-pod">
    <mat-card *ngIf="pod.restaurantActive && editablePod && displaySlots(); else inactiveHours" class="edit-pod-content">
      <mat-card-content class="actions-section">
        <a (click)="seeHours()">{{ seeHoursLabel }}</a>
      </mat-card-content>
    </mat-card>
    <ng-template #inactiveHours>
      <div class="edit-pod"></div>
    </ng-template>
  </div>
</div>
