import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { forkJoin, Observable } from 'rxjs';
import { EligiblePodsOfOrdersModel } from '../../../models/order/eligible-pods-of-orders.model';
import { Order } from '../../../models/order/order.model';
import { UpdatePodOfOrdersResponseModel } from '../../../models/order/update-pod-of-orders-response.model';
import { Pod } from '../../../models/pod/pod.model';
import { Restaurant } from '../../../models/restaurant/restaurant.model';
import { OrderService } from '../../../service/order/order.service';
import { PersistanceService } from '../../../service/persistance/persistance.service';
import { PodService } from '../../../service/pod/pod.service';
import { ConfirmModalComponent } from '../../common/confirm-modal/confirm-modal.component';
import {DialogConfigUtilsService} from "../../../service/utils/dialog.utils.service";

@Component({
  selector: 'app-modal-multi-selection',
  templateUrl: './modal-multi-selection.component.html',
  styleUrls: ['./modal-multi-selection.component.scss'],
})
export class ModalMultiSelectionComponent implements OnInit {
  selectedOrders: Order[];
  restaurant: Restaurant;
  eatInOrders: Order[];
  takeOutOrders: Order[];
  eligiblePodsOfOrders: EligiblePodsOfOrdersModel;
  newPodTakeOut: Pod;
  newPodEatIn: Pod;
  ordersWithBeer: Order[] = [];

  isLoading = true;
  isLoading2 = true;
  error: string;

  private readonly errorLabel = 'Les commandes ont déjà été retirées par le client. Le changement ne sera pas effectif';

  constructor(
    public dialogRef: MatDialogRef<ModalMultiSelectionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly persistanceService: PersistanceService,
    private readonly podService: PodService,
    private readonly orderService: OrderService,
    public matDialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.selectedOrders = this.data.selectedOrders;
    this.restaurant = this.persistanceService.get('current_restaurant');
    this.toSortOrders();
  }

  /**
   * Method which allows to sort the selected orders depending on eat_type
   */
  toSortOrders(): void {
    this.retrievePod().subscribe((eligiblePodsOfOrders) => {
      this.eligiblePodsOfOrders = eligiblePodsOfOrders;
      const selectedOrdersWithoutBeers = this.selectedOrders.filter((order) => !eligiblePodsOfOrders.ordersWithBeerRef.includes(order.ref));
      const ordersWithBeerObservable: Observable<Order>[] = [];
      eligiblePodsOfOrders.ordersWithBeerRef.forEach((order) => {
        ordersWithBeerObservable.push(this.orderService.getOrderDetailsByRef(order));
      });
      forkJoin(ordersWithBeerObservable).subscribe((rep) => {
        rep.forEach((order) => {
          this.ordersWithBeer.push(order);
        });
        this.isLoading2 = false;
      });
      this.takeOutOrders = selectedOrdersWithoutBeers.filter((order) => order.eatType === 'TAKE_OUT');
      this.eatInOrders = selectedOrdersWithoutBeers.filter((order) => order.eatType === 'EAT_IN');
      this.isLoading = false;
    });
  }
  /**
   * When clicking on cross in right top corner it show a popup to confirm the exit of POD settings.
   */
  closeModal(): void {
    const title = 'Si vous quittez le paramétrage, aucune modification ne sera enregistrée.';
    const modalDialog = this.openConfirmModal(title, 'QUITTER SANS SAUVEGARDER', 'ANNULER', 'close', '100%', false);

    modalDialog.afterClosed().subscribe((result) => {
      if (result) {
        this.dialogRef.close();
      }
    });
  }

  /**
   * Allows to open the modal to quit
   * @param title
   * @param confirmText
   * @param cancelText
   * @param panelClass
   * @param width
   * @param showWarningText
   * @param topText
   * @private
   */
  private openConfirmModal(
    title: string,
    confirmText: string,
    cancelText: string,
    panelClass: string,
    width: string,
    showWarningText: boolean,
    topText?: string
  ): MatDialogRef<ConfirmModalComponent> {
    const dialogConfig = new DialogConfigUtilsService().getDialogConfig('confirm-modal', title, confirmText, cancelText, panelClass, width, showWarningText, topText);

    return this.matDialog.open(ConfirmModalComponent, dialogConfig);
  }

  /**
   * Allow to get the different eligible pod
   */
  private retrievePod(): Observable<EligiblePodsOfOrdersModel> {
    return this.podService.getEligiblePodsOfOrders(
      this.restaurant.ref.toString(),
      this.selectedOrders.map((order) => order.ref)
    );
  }

  /**
   * Method which allows to do the pod Change and manage errors
   */
  toValidate(): void {
    let updatePodTakeOutOrdersObservable: Observable<UpdatePodOfOrdersResponseModel>;
    let updatePodEatInOrdersObservable: Observable<UpdatePodOfOrdersResponseModel>;
    const updateObservable: Observable<UpdatePodOfOrdersResponseModel>[] = [];
    if (this.newPodTakeOut) {
      updatePodTakeOutOrdersObservable = this.orderService.updatePodOfOrders(
        this.restaurant.ref.toString(),
        this.takeOutOrders.map((order) => order.ref),
        this.newPodTakeOut.reference
      );
      updateObservable.push(updatePodTakeOutOrdersObservable);
    }
    if (this.newPodEatIn) {
      updatePodEatInOrdersObservable = this.orderService.updatePodOfOrders(
        this.restaurant.ref.toString(),
        this.eatInOrders.map((order) => order.ref),
        this.newPodEatIn.reference
      );
      updateObservable.push(updatePodEatInOrdersObservable);
    }
    this.joinCallAndHandleReturn(updateObservable);
  }

  private joinCallAndHandleReturn(updateObservable: Observable<UpdatePodOfOrdersResponseModel>[]): void {
    forkJoin(updateObservable).subscribe(
      (rep) => {
        // Traiter le cas order in error
        if (this.newPodTakeOut && this.newPodEatIn) {
          const orderInErrorTakeOut: string[] = rep[0].ordersInError;
          const orderInErrorEatIn: string[] = rep[1].ordersInError;
          if (orderInErrorTakeOut.length === this.takeOutOrders.length && orderInErrorEatIn.length === this.eatInOrders.length) {
            this.error = this.errorLabel;
          } else {
            this.dialogRef.close(true);
          }
        } else if (this.newPodTakeOut) {
          const orderInErrorTakeOut: string[] = rep[0].ordersInError;
          if (orderInErrorTakeOut.length === this.takeOutOrders.length) {
            this.error = this.errorLabel;
          } else {
            this.dialogRef.close(true);
          }
        } else if (this.newPodEatIn) {
          const orderInErrorEatIn: string[] = rep[0].ordersInError;
          if (orderInErrorEatIn.length === this.eatInOrders.length) {
            this.error = this.errorLabel;
          } else {
            this.dialogRef.close(true);
          }
        }
      },
      (err) => {
        this.error = 'Une erreur est survenue';
      }
    );
  }

  /**
   * Set the new selected pod for eat in
   * @param refNewPod for eat in
   */
  setNewPodEatIn(refNewPod: Pod): void {
    this.newPodEatIn = refNewPod;
  }

  /**
   * Set the new selected pod for take out
   * @param refNewPod for take out
   */

  setNewPodTakeOut(refNewPod: Pod): void {
    this.newPodTakeOut = refNewPod;
  }

  /**
   * Method which allows to conjugates verbs to beer message dependingon the number of orders with beer
   * @param text the text to conjugate
   */

  displayedOrderText(text: string): string {
    if (text === 'order') {
      return this.isOrderPlural();
    }
    if (text === 'contain') {
      return this.isWordContainOrderWithBeerPlural();
    }
    if (text === 'have') {
      return this.isHavePlural();
    }
    if (text === 'taken') {
      return this.isTakenPlural();
    }
    if (text === 'this order') {
      return this.isThisOrderPlural();
    }

    return '';
  }

  private isThisOrderPlural(): string {
    if (this.ordersWithBeer.length === 1) {
      return 'cette commande';
    } else {
      return 'ces commandes';
    }
  }

  private isTakenPlural(): string {
    if (this.ordersWithBeer.length === 1) {
      return 'prise';
    } else {
      return 'prises';
    }
  }

  private isHavePlural(): string {
    if (this.ordersWithBeer.length === 1) {
      return 'a';
    } else {
      return 'ont';
    }
  }

  private isWordContainOrderWithBeerPlural(): string {
    if (this.ordersWithBeer.length === 1) {
      return 'contient';
    } else {
      return 'contiennent';
    }
  }

  private isOrderPlural(): string {
    if (this.ordersWithBeer.length === 1) {
      return 'La commande';
    } else {
      return 'Les commandes';
    }
  }

  /**
   * Method which allows to quit the modal and indicate to reload data
   */

  exitConfiguration(): void {
    this.dialogRef.close(true);
  }
}
