export enum PaymentType {
  NONE = 'Aucun',
  AMX = 'Amx',
  CARD = 'Carte bancaire',
  CASH_EUR = 'Cash Eur',
  CASH_FCH = 'Cash Fch',
  CASH_GBP = 'Cash Gbp',
  CASH_USD = 'Cash uSd',
  CHECK = 'Check',
  CHECK_HOLIDAY = 'Check Holiday',
  DEMATERIALIZED_MEAL_VOUCHER = 'Dematerialized Meal Voucher',
  UNKNOWN = 'Inconnu',
  MULTIPLE = 'Multi-Paiement',
  PAYPAL = 'Paypal',
  RESTO_FLASH = 'Resto Flash',
  MEAL_VOUCHER = 'Ticket Restaurant',
  UBER_EATS = 'Ubereats',
  DELIVEROO = 'Deliveroo',
  JUST_EAT = 'JustEat',
  LYVEAT = 'Lyveat',
  IN_APP = 'InApp CB',
  IN_APP_APPLEPAY = 'ApplePay',
}
