export enum EatType {
  /**
   * The customer wants to eat in the restaurant.
   */
  EAT_IN = 'EAT_IN',

  /**
   * The customer wants to take out the order.
   */
  TAKE_OUT = 'TAKE_OUT',

  /**
   * The customer wants the order to be delivered.
   */
  DELIVERY = 'DELIVERY',

  /**
   * The customer wants the order to be delivered (LAD Evol).
   */
  HOME_DELIVERY = 'HOME_DELIVERY',
}
