export class RestaurantMetadata {
    key: string;
    javaClass: string;
    value: any;

    constructor(key: string, javaClass: string, value: any) {
        this.key = key;
        this.javaClass = javaClass;
        this.value = value;
    }
}
