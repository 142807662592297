import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {HttpClient, HttpParams} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {MultimediaView} from "../../models/multimedia/multimedia-view.model";

@Injectable({
    providedIn: 'root'
})
export class WoosmapService {
    WOOSMAP_BASE_ENDPOINT = `${environment.settings.woosmapApiUrl}`;

    constructor(private readonly httpClient: HttpClient) {
    }

    autocompleteAddress(address: string): Observable<any> {
        const args = {
            key: environment.settings.woosmapKey,
            input: address,
            types: 'address',
            components: 'country:fr',
            no_deprecated_fields: 'true',
        };
        return this.httpClient.get<any>(`${this.WOOSMAP_BASE_ENDPOINT}/localities/autocomplete?${this.buildQueryString(args)}`);
    }

    getDetailsAddress(publicId: string): Observable<any> {
        const args = {
            key: environment.settings.woosmapKey,
            public_id: publicId
        };
        return this.httpClient.get<any>(`${this.WOOSMAP_BASE_ENDPOINT}/localities/details/?${this.buildQueryString(args)}`);
    }

    buildQueryString(params): string {
        const queryStringParts = [];
        for (const key in params) {
            if (params.hasOwnProperty(key)) {
                const value = params[key];
                queryStringParts.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
            }
        }
        return queryStringParts.join('&');
    }
}
