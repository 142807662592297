import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {OrderErrorService} from "../../../service/orderError/order-error.service";
import {OrderService} from "../../../service/order/order.service";
import {Order} from "../../../models/order/order.model";
import {OrderStatus} from "../../../models/order/order-status.enum";
import {OrderType} from "../../../models/order/order-type.enum";
import {OrderError} from "../../../models/order/order-error.model";
import {PaymentType} from "../../../models/order/payment-type.enum";
import {ConfirmModalComponent} from "../../common/confirm-modal/confirm-modal.component";
import {DialogConfigUtilsService} from "../../../service/utils/dialog.utils.service";

@Component({
  selector: 'app-order-modal-parking',
  templateUrl: './order-modal-parking.component.html',
  styleUrls: ['./order-modal-parking.component.scss']
})
export class OrderModalParkingComponent implements OnInit {

  selectedOrder: Order;
  orderStatusType: typeof OrderStatus = OrderStatus;
  orderType: typeof OrderType = OrderType;
  isLoading: boolean;
  orderError: OrderError;
  paymentType: typeof PaymentType = PaymentType;
  updateList = false;
  delay: boolean;

  constructor(
      public dialogRef: MatDialogRef<OrderModalParkingComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any,
      public matDialog: MatDialog, //NOSONAR
      private readonly orderErrorService: OrderErrorService,
      public readonly orderService: OrderService

  ) { }

  ngOnInit(): void {
    this.selectedOrder = this.data.currentOrder;
    if (this.selectedOrder.status === OrderStatus.ERROR) {
      this.isLoading = true;
      const errorCode = this.selectedOrder.errorCode ? this.selectedOrder.errorCode : 'Code erreur non remonté';
      this.orderErrorService.getOrderErrors().subscribe((errors) => {
        this.orderError = errors.find((err) => err.errorCode === errorCode);
        this.isLoading = false;
      });
    }
    this.delay = this.selectedOrder.delayed;
  }

  /**
   * Close the order modal
   */
  closeModal(): void {
    this.dialogRef.close(this.updateList);
  }

  sendDelay(): void {
    const modalDialog = this.openConfirmModal('Signalement <b>d\'un retard</b>', 'Confirmer', 'PRÉCÉDENT',
        'delay', '94%', 'Une notification sera envoyée au client pour l\'informer de ce retard');
    modalDialog.afterClosed().subscribe((message) => {
      if (message === true) {
        this.orderService.sendParkingOrderDelay(this.selectedOrder.ref).subscribe(() => {
          this.delay = true;
        }, () => {
          this.isLoading = false;
          const title = "En raison d'une erreur technique, vos paramètres n'ont pas été enregistrés. Veuillez réessayer ultérieurement";
          this.openConfirmModal(title, 'QUITTER', null, 'save-error', '94%');
        });
      }
    });
  }

  private openConfirmModal(
      title: string,
      confirmText: string,
      cancelText: string,
      panelClass: string,
      width: string,
      topText?: string,
    ): MatDialogRef<ConfirmModalComponent> {
    const dialogConfig = new DialogConfigUtilsService().getDialogConfig('confirm-modal', title, confirmText, cancelText, panelClass, width, false, topText);

    return this.matDialog.open(ConfirmModalComponent, dialogConfig);
  }

}
