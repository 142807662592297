<div class="access-denied-message" [style.height.px]="windowInnerHeight()">
  <div class="access-denied-img">
    <div class="access-denied-div">
      <img src="assets/medias/error-role.svg" alt="mcdo_logo_error" id="mcdo-icon-error" class="restaurant-list-empty-icon" />
    </div>
    <p class="access-denied-text">Accès refusé.</p>
    <p class="access-denied-text">
      Pour savoir comment accéder à la web app veuillez consulter la <a routerLink="/faq">FAQ</a> via le menu de navigation transversal.
    </p>
  </div>
</div>
