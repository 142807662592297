import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Log } from '../../models/log/log.model';
import { HttpClient } from '@angular/common/http';
import { LoggerService } from './logger.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FileLoggerService extends LoggerService {
  constructor(private readonly httpClient: HttpClient) {
    super(environment.settings.logFileLevel);
  }
  // Add log entry to back end data store
  protected log(log: Log): Observable<string> {
    // ws not created yet
    return this.httpClient.post<string>(environment.settings.apiUrl + '/logs/mystore', log);
  }
}
