import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConnectService } from '../../service/connect/connect.service';
import { PersistanceService } from '../../service/persistance/persistance.service';

@Component({
  selector: 'app-oauth',
  templateUrl: './oauth.component.html',
  styleUrls: ['./oauth.component.scss'],
})
export class OauthComponent implements OnInit {
  constructor(
    private readonly connectService: ConnectService,
    private readonly router: Router,
    private readonly persistanceService: PersistanceService
  ) {}

  ngOnInit(): void {
    const i = window.location.href.indexOf('code');
    if (!this.isNull(this.persistanceService.get('redirectPage'))) {
      this.router.navigate([this.persistanceService.get('redirectPage')]);
      this.persistanceService.remove('redirectPage');
    } else if (!this.connectService.hasValidToken() && i !== -1) {
      this.connectService.retrieveToken(window.location.href.substring(i + 5).split('&')[0]);
    } else {
      this.router.navigate(['/restaurant-list']);
    }
  }

  private isNull(label: string): boolean {
    return label == null || label === '' || label === undefined;
  }
}
