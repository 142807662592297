<script src="my-room-facility.component.ts"></script>
<app-facility
  [parentIsActive]="true"
  [facilityType]="facilityType.MY_ROOM"
  [facility]="facility"
  [isSuperParent]="true"
  [numberMyRoomChildrenChange]="numberMyRoomChildrenChange"
  [facilitiesList]="facilitiesList"
  [exceptionalHoursTabInput]="exceptionalHoursTabInput"
  (reloadFacilities)="emitReloadFacilities()"
></app-facility>

<div class="salePoint">
  <p class="title">Points de vente</p>

  <div *ngIf="facility.childrenFacilities.length != 0">
    <div *ngFor="let childFacility of facility.childrenFacilities">
      <app-facility
        *ngIf="childFacility.reference != facilityType.EAT_IN.valueOf()"
        [parentIsActive]="facilityActive()"
        [facility]="childFacility"
        [isSuperParent]="false"
        [facilityType]="getFacilityType(childFacility)"
        (changeChildfacilityStatus)="changeChildFacilityStatus($event)"
        (reloadFacilities)="emitReloadFacilities()"
      ></app-facility>
    </div>
  </div>
</div>
