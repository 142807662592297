import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ExceptionalHoursView } from '../../../../models/exceptionalHoursView/exceptional-hours-view.model';
import { Facility } from '../../../../models/facility/facility.model';
import { FacilityType } from '../../../../models/facility/facilityType.enum';
import { FacilityService } from '../../../../service/facility/facility.service';

@Component({
  selector: 'app-mcdelivery-facility',
  templateUrl: './mcdelivery-facility.component.html',
  styleUrls: ['./mcdelivery-facility.component.scss'],
})
export class McdeliveryFacilityComponent implements OnInit {
  @Input() facility: Facility;
  @Input() facilitiesList: Facility[];
  @Input() exceptionalHoursTabInput: ExceptionalHoursView[];
  @Output() reloadFacilities: EventEmitter<boolean> = new EventEmitter<boolean>();
  facilityType: typeof FacilityType = FacilityType;
  private readonly OTHER_PARTNER_IS_ACTIVATED = "Partenaire impossible à activer. Un seul doit être activé pour LAD McDo+.";

  constructor(private readonly facilityService: FacilityService) {}

  ngOnInit(): void {}

  /**
   * Emit to parent to reload Facilities
   */
  emitReloadFacilities(): void {
    this.reloadFacilities.emit(true);
  }

  /**
   * Check if facility status is active or not
   * @returns true if nationalActivation AND restaurantActivation
   */
  public facilityActive(): boolean {
    return this.facilityService.facilityActive(this.facility);
  }

  /**
   * Return the type of the facility
   */
  getFacilityType(facility: Facility): FacilityType {
    return this.facilityService.getFacilityType(facility);
  }

  /**
   * Change the status of child facility
   * @param facility facility to modify
   */
  changeChildFacilityStatus(facility: Facility): void {
    const childFacility = this.facilityService.getFacilityByRef(this.facility.childrenFacilities, facility.reference);
    childFacility.restaurantActivation = facility.restaurantActivation;
  }

  /**
   * Check the status of the second McDelivery child facility. Only one child can be activated at a time.
   *
   * @param childFacility The child that we need to know if it can be activated
   */
  getChildFacilityActivationAuthorization(childFacility: Facility): boolean {
    const secondChild = this.facility.childrenFacilities.filter(f => f !== childFacility);
    const isSecondChildActive = secondChild !== undefined && secondChild.length > 0 && secondChild[0].restaurantActivation;
    return !isSecondChildActive;
  }

  /**
   * Get error message is the second McDelivery child is already activated. (only one can be activated at a time)
   *
   * @param childFacility The child that we need to know if it can be activated
   */
  getImportantInformation(childFacility: Facility): string {
    // check if the other child is already activated
    let importantInformation: string;
    const secondChild = this.facility.childrenFacilities.filter(f => f !== childFacility);
    if (this.facility.restaurantActivation && secondChild !== undefined && secondChild.length > 0 && secondChild[0].restaurantActivation) {
      importantInformation = this.OTHER_PARTNER_IS_ACTIVATED;
    }
    return importantInformation;
  }

}
