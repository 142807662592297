import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ExceptionalHoursView } from '../../../../models/exceptionalHoursView/exceptional-hours-view.model';
import { Facility } from '../../../../models/facility/facility.model';
import { FacilityType } from '../../../../models/facility/facilityType.enum';
import { FacilityService } from '../../../../service/facility/facility.service';

@Component({
  selector: 'app-lad-facility',
  templateUrl: './lad-facility.component.html',
  styleUrls: ['./lad-facility.component.scss'],
})
export class LadFacilityComponent implements OnInit {
  @Input() facility: Facility;
  @Input() facilitiesList: Facility[];
  @Input() exceptionalHoursTabInput: ExceptionalHoursView[];
  facilityType: typeof FacilityType = FacilityType;
  facilityParentType = FacilityType.LAD_EXTERNE;
  @Output() reloadFacilities: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private readonly facilityService: FacilityService) {}

  ngOnInit(): void {}

  /**
   * Check if facility status is active or not
   * @returns true if nationalActivation AND restaurantActivation
   */
  public facilityActive(): boolean {
    return this.facilityService.facilityActive(this.facility);
  }

  /**
   * This method allow to update the facility
   * @param newFacility the updated facility
   */
  updateFacility(newFacility: Facility): void {
    this.facility = newFacility;
  }

  /**
   * Return the type of the facility
   */
  getFacilityType(facility: Facility): FacilityType {
    return this.facilityService.getFacilityType(facility);
  }

  /**
   * Emit to parent to reload Facilities
   */
  emitReloadFacilities(): void {
    this.reloadFacilities.emit(true);
  }
}
