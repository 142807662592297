<div *ngIf="isLoading && isLoading2; else loaded">
  <app-loader></app-loader>
</div>

<ng-template #loaded>
  <div class="modal-header">
    <div class="modal-title">Point de retraits</div>
    <a>
      <mat-icon id="close-modal-button" (click)="closeModal()">close</mat-icon>
    </a>
    <div class="order-reference">{{ selectedOrders.length }} commandes C&C</div>
    <div class="restaurant-name">
      {{ restaurant?.name }}
    </div>
  </div>

  <div class="order-body">
    <div *ngIf="eligiblePodsOfOrders.ordersWithBeerRef.length !== 0" class="error-section">
      <img alt="warning" src="assets/medias/warning.svg" class="warningImg" />
      <span class="error-text">
        {{ displayedOrderText('order') }} n°
        <ng-container *ngFor="let order of ordersWithBeer; let i = index"
          >{{ order.orderNumber }}
          <ng-container *ngIf="i + 1 != eligiblePodsOfOrders.ordersWithBeerRef.length"> / </ng-container>
        </ng-container>
        {{ displayedOrderText('contain') }} le produit "bière" et n'{{ displayedOrderText('have') }} pas été
        {{ displayedOrderText('taken') }} en compte. Vous pouvez basculer {{ displayedOrderText('this order') }} unitairement via le détail
        de la commande.
      </span>
    </div>

    <div *ngIf="eatInOrders.length > 0">
      <div class="subtitle-type-order">Commandes sur place</div>
      <app-order-multi-pod
        *ngIf="!error; else noEatInOrdersToModify"
        [eligiblePods]="eligiblePodsOfOrders.eatInPodsRef"
        [orders]="eatInOrders"
        [isEatIn]="true"
        (outputEatIn)="setNewPodEatIn($event)"
      ></app-order-multi-pod>
      <ng-template #noEatInOrdersToModify>
        <div class="error-section">
          <img alt="warning" src="assets/medias/warning.svg" class="warningImg" />
          <span class="error-text">{{ error }}</span>
        </div>
      </ng-template>
    </div>

    <div *ngIf="takeOutOrders.length > 0">
      <div class="subtitle-type-order">Commandes à emporter</div>
      <app-order-multi-pod
        *ngIf="!error; else noTakeOutOrdersToModify"
        [eligiblePods]="eligiblePodsOfOrders.takeOutPodsRef"
        [orders]="takeOutOrders"
        [isEatIn]="false"
        (outpputTakeOut)="setNewPodTakeOut($event)"
      ></app-order-multi-pod>
      <ng-template #noTakeOutOrdersToModify>
        <div class="error-section">
          <img alt="warning" src="assets/medias/warning.svg" class="warningImg" />
          <span class="error-text">{{ error }}</span>
        </div>
      </ng-template>
    </div>
    <button *ngIf="!error" class="updateModification" (click)="toValidate()">Valider la modification</button>
    <button *ngIf="error" class="updateModification" (click)="exitConfiguration()">Quitter le paramétrage</button>
  </div>
</ng-template>
