<div [ngClass]="slot.active && objectActive ? 'openingHour-content' : 'openingHour-content disabled'" [ngSwitch]="mode">
  <div *ngSwitchCase="'edition'">
    <div
      class="first-line"
      [ngClass]="slot.activationError ? 'background-error' : ''"
      [ngClass]="!objectActive ? 'checkbox-background-desactivate' : 'checkbox-background-activate'"
    >
      <mat-checkbox [(ngModel)]="slot.active" [disabled]="!objectActive">
        {{ dayAsString(slot.day) }}
      </mat-checkbox>
      <div class="first-slot">
        <span class="from">de</span>
        <input
          type="time"
          [ngClass]="slot.slotDetails[0].startError ? 'error' : ''"
          [disabled]="!slot.active || !objectActive"
          [(ngModel)]="slot.slotDetails[0].beginHour"
          required
        />
        <span class="to">à</span>
        <input
          type="time"
          [ngClass]="slot.slotDetails[0].endError ? 'error' : ''"
          [disabled]="!slot.active || !objectActive"
          [(ngModel)]="slot.slotDetails[0].endHour"
          required
        />
      </div>
    </div>
    <div class="second-line" *ngIf="slot.slotDetails.length === 2">
      <div class="second-slot">
        <span class="from">de</span>
        <input
          type="time"
          [ngClass]="slot.slotDetails[1].startError ? 'error' : ''"
          [disabled]="!slot.active || !objectActive"
          [(ngModel)]="slot.slotDetails[1].beginHour"
          required
        />
        <span class="to">à</span>
        <input
          type="time"
          [ngClass]="slot.slotDetails[1].endError ? 'error' : ''"
          [disabled]="!slot.active || !objectActive"
          [(ngModel)]="slot.slotDetails[1].endHour"
          required
        />
      </div>
    </div>
    <div class="last-line">
      <label class="openingHour-status" *ngIf="slot?.active">ACTIF</label>
      <label class="openingHour-status" *ngIf="!slot?.active">INACTIF</label>
      <label class="action" *ngIf="!slot.slotDetails[1]" (click)="slot.active && objectActive && addSlot()">Ajouter un créneau</label>
      <label class="action" *ngIf="slot.slotDetails[1]" (click)="slot.active && objectActive && removeSlotMode()"
        >Supprimer un créneau</label
      >
    </div>
    <div *ngFor="let error of slot.configError" id="error-section">
      <img alt="warning" src="../../../../../assets/medias/warning.svg" class="warningImg" />
      <p>{{ error }}</p>
    </div>
  </div>
  <div *ngSwitchCase="'removing'">
    <div class="first-line">
      <div class="first-slot">
        <span class="from">de</span>
        <input type="time" [disabled]="!slot.active && objectActive" [(ngModel)]="slot.slotDetails[0].beginHour" required />
        <span class="to">à</span>
        <input type="time" [disabled]="!slot.active && objectActive" [(ngModel)]="slot.slotDetails[0].endHour" required />
        <button mat-raised-button class="remove-slot-button" (click)="removeSlot(0)">SUPPRIMER</button>
      </div>
    </div>
    <div class="second-line" *ngIf="slot.slotDetails.length === 2">
      <div class="second-slot">
        <span class="from">de</span>
        <input type="time" [disabled]="!slot.active && objectActive" [(ngModel)]="slot.slotDetails[1].beginHour" required />
        <span class="to">à</span>
        <input type="time" [disabled]="!slot.active && objectActive" [(ngModel)]="slot.slotDetails[1].endHour" required />
        <button mat-raised-button class="remove-slot-button" (click)="removeSlot(1)">SUPPRIMER</button>
      </div>
    </div>
    <div class="last-line">
      <label class="action" (click)="cancelRemoving()">Annuler</label>
    </div>
  </div>
</div>
