export const environment = {
  production: false,
  application: {
    environment: 'dev',
  },
  settings: {
    connect: {
      issuer: '',
      loginUrl: '',
      manageTokenEndpoint: '',
      tokenEndpoint: '',
      logoutUrl: '',
      clientId: '',
      clientSecret: '',
      showDebug: false,
      redirectUrl: '',
    },
    apiUrl: '',
    woosmapUrl: '',
    woosmapApiUrl: '',
    woosmapKey: '',
    woosmapDotMarkerUrl: '',
    orderHistoryNumber: 1,
    baseUrl: '',
    mediaUrl: '',
    mcdofrBaseUrl: '',
    logConsoleLevel: 3,
    logFileLevel: 3,
  },
  onboardingVersion: '2',
};
