<!-- RESTURANT HEADER -->
<app-restaurant-header [forceCollapse]="false"></app-restaurant-header>

<!-- MENU -->
<div id="restaurant-deliveries-header" #header>
  <div id="tabs-section">
    <button
      (click)="switchTabView(facilityType.MC_DELIVERY)"
      [ngClass]="isTabActive(facilityType.MC_DELIVERY) ? 'orders-section-tab selected' : 'orders-section-tab'"
      style="margin-right: 1%"
    >
      LAD McDo+
    </button>
    <button
      (click)="switchTabView(facilityType.LAD_EXTERNE)"
      [ngClass]="isTabActive(facilityType.LAD_EXTERNE) ? 'orders-section-tab selected' : 'orders-section-tab'"
    >
      LAD Partenaires
    </button>
  </div>
</div>

<!-- MAIN CONTENT -->
<div id="main-content" #content>
  <!-- LOADER -->
  <div *ngIf="isLoading; else loader">
    <app-loader></app-loader>
  </div>

  <!-- ONGLET CONTENT -->
  <ng-template #loader>
    <div [ngSwitch]="currentTab">
      <!-- MCDELIVERY -->
      <div *ngSwitchCase="facilityType.MC_DELIVERY">
        <div *ngIf="hasMcDelivery; else noMcDelivery">
          <app-mcdelivery-facility
            (reloadFacilities)="loadFacilities()"
            *ngSwitchCase="facilityType.MC_DELIVERY"
            [facility]="mcDeliveryFacility"
            [facilitiesList]="allFacilities"
            [exceptionalHoursTabInput]="exceptionalHoursTab"
          ></app-mcdelivery-facility>
        </div>
        <!-- if no McDelivery partner -->
        <ng-template #noMcDelivery>
          <div class="orders-list-empty">
            <div class="no-result-img">
              <div class="empty-image-div">
                <img src="assets/medias/no-orders.svg" alt="mcdo_orders_empty" class="orders-empty-icon" />
              </div>
              <p class="no-result-text">
                Aucun partenaire de livraison LAD McDo+ n'est associé à votre restaurant, le paramétrage n'est pas accessible. Veuillez
                contacter <strong>HELPLINE</strong>
              </p>
            </div>
          </div>
        </ng-template>
      </div>

      <!-- LAD -->
      <div *ngSwitchCase="facilityType.LAD_EXTERNE">
        <div *ngIf="hasLAD; else noLAD">
          <app-lad-facility-list
            [facilities]="ladFacilities"
            (reloadFacilities)="loadFacilities()"
            [facilitiesList]="allFacilities"
            [exceptionalHoursTabInput]="exceptionalHoursTab"
          ></app-lad-facility-list>
        </div>
        <!-- if no LAD partner -->
        <ng-template #noLAD>
          <div class="orders-list-empty">
            <div class="no-result-img">
              <div class="empty-image-div">
                <img src="assets/medias/no-orders.svg" alt="mcdo_orders_empty" class="orders-empty-icon" />
              </div>
              <p class="no-result-text">
                Aucun partenaire de livraison LAD Partenaires n'est associé à votre restaurant, le paramétrage n'est pas accessible.
                Veuillez contacter <strong>HELPLINE</strong>
              </p>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </ng-template>
</div>
