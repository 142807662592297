import { Component, Input, OnInit } from '@angular/core';
import { ExceptionalHoursView } from '../../../../models/exceptionalHoursView/exceptional-hours-view.model';
import { Facility } from '../../../../models/facility/facility.model';
import { FacilityExceptionalHours } from '../../../../models/facilityExceptionalHours/facility-exceptional-hours.model';
import { SlotDetails } from '../../../../models/slot-details.model';
import { FacilityService } from '../../../../service/facility/facility.service';
import { SlotService } from '../../../../service/slot/slot.service';

@Component({
  selector: 'app-exceptional-hours-facilities-section',
  templateUrl: './exceptional-hours-facilities-section.component.html',
  styleUrls: ['./exceptional-hours-facilities-section.component.scss'],
})
export class ExceptionalHoursFacilitiesSectionComponent implements OnInit {
  @Input() exceptionalHoursTabInput: ExceptionalHoursView[];
  @Input() facilities: Facility[];

  newExceptionalHours: ExceptionalHoursView = new ExceptionalHoursView();
  facilitiesByRef: Facility[] = []; // list of my facilities

  constructor(public facilityService: FacilityService, private readonly slotService: SlotService) {}

  ngOnInit(): void {
    this.initNewExceptional();
    // get all facilities compatible with exceptional hours
    this.facilitiesByRef = this.facilityService.getFacilitiesCompatibleWithExceptionalHours(this.facilities);

    for (const exceptionalDays of this.exceptionalHoursTabInput) {
      for (const facilitySlot of exceptionalDays.slots) {
        if (!this.inReturnTab(facilitySlot.facilityRef)) {
          this.newExceptionalHours.slots.push(facilitySlot);
        }
      }
    }

    // add element without exceptional hours
    for (const facility of this.facilitiesByRef) {
      if (!this.inReturnTab(facility.reference)) {
        const facilityTmp = new FacilityExceptionalHours();
        facilityTmp.slotsDetails = [];
        facilityTmp.facilityRef = facility.reference;
        facilityTmp.active = false;
        this.newExceptionalHours.slots.push(facilityTmp);
      }
    }

    // sort
    this.sortNewExceptionalHoursSlots();
  }

  initNewExceptional(): void {
    this.newExceptionalHours.slots = [];
  }

  slotIsActive(slot: SlotDetails[]): boolean {
    return slot[0] != null;
  }

  slotIsExceptionalClosing(slot: FacilityExceptionalHours): boolean {
    return slot.exceptionalClose;
  }

  private inReturnTab(reference: string): boolean {
    return this.newExceptionalHours.slots.find((element) => element.facilityRef === reference) !== undefined;
  }

  getFacilityLegendLabel(facilityRef: string): string {
    return this.facilityService.getExceptionalFacilityLegendLabel(facilityRef);
  }

  getFacilityDetailLabel(facilityRef: string): string {
    return this.facilityService.getExceptionalFacilityDetailLabel(facilityRef);
  }

  private sortNewExceptionalHoursSlots(): void {
    const tupleArray = [];
    for (const slot of this.newExceptionalHours.slots) {
      tupleArray.push(slot);
    }

    tupleArray.sort((a, b) => {
      return a.exceptionalHoursPosition - b.exceptionalHoursPosition;
    });

    this.newExceptionalHours.slots = tupleArray;
  }

  /**
   * Check if the facility is opened 24h/24 on exceptional hours
   * @param slotDetails the slotDetails to check
   */
  public is24h(slotDetails: SlotDetails[]): boolean {
    return this.slotService.is24h(slotDetails);
  }
}
