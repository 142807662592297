import { Location } from '@angular/common';
import { AfterViewChecked, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, Subject } from 'rxjs';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { Restaurant } from '../../models/restaurant/restaurant.model';
import { UserInfo } from '../../models/user-info';
import { ConnectService } from '../../service/connect/connect.service';
import { PersistanceService } from '../../service/persistance/persistance.service';
import { UserService } from '../../service/user/user.service';

@Component({
  selector: 'app-restaurant',
  templateUrl: './restaurant-list.component.html',
  styleUrls: ['./restaurant-list.component.css'],
})
export class RestaurantListComponent implements OnInit, AfterViewChecked {
  @ViewChild('headerRestaurant') restaurantHeader: ElementRef;
  @ViewChild('restaurantList') restaurantList: ElementRef;

  inputPlaceholder: string;
  restaurantTitle: string;

  restaurants: Restaurant[]; // All user restaurants
  filteredRestaurants: Restaurant[]; // The filtered restaurants by search bar
  searchTerm = new Subject<string>();
  inputSearch: any;

  isLoading = true;
  isFromRestaurantPage = false;

  private readonly headerRestaurant = 'header-restaurant';

  constructor(
    private readonly router: Router,
    private readonly location: Location,
    private readonly connectService: ConnectService,
    private readonly userService: UserService,
    private readonly persistanceService: PersistanceService
  ) {
    if (this.persistanceService.get('restaurant_page_visited')) {
      this.isFromRestaurantPage = true;
    }
  }

  ngOnInit(): void {
    this.inputPlaceholder = 'Rechercher (code ou nom)';
    this.restaurants = [];
    this.connectService.isDoneLoading$.subscribe(async () => {
      let userInfos: UserInfo;
      this.userService.getUserInfo().subscribe((userData) => {
        userInfos = userData;
        this.userService.getUserRestaurants().subscribe((data) => {
          this.sortRestaurants(data);
          this.restaurants = data;

          // si un seul resto => on va directement sur la page /restaurant
          if (data.length === 1) {
            this.goToRestaurant(data[0]);
          }

          // then, we subscribe to "this.search" to filter list based on input search.
          this.search(this.searchTerm).subscribe((results) => (this.filteredRestaurants = results));
          this.searchTerm.next('');
          this.isLoading = false;
        });
      });
      this.restaurantTitle = 'Mes restaurants (' + userInfos.restaurantRefs.length + ')';
    });
  }

  private sortRestaurants(data: Restaurant[]): void {
    data
      .sort((a, b) => {
        return a.name < b.name ? -1 : 1; // sort by restaurant name
      })
      .sort((a, b) => {
        return a.selected === b.selected ? 0 : a.selected ? -1 : 1; // selected restaurants first
      });
  }

  /**
   * Store chosen restaurant in storage and redirect user to restaurant page.
   * @param restaurant Chosen restaurant
   */
  goToRestaurant(restaurant: Restaurant): void {
    // remets tous les autres restaurants en non selectionné (non grisé)
    this.restaurants.forEach((obj) => (obj.selected = false));

    // mets l'attribut selected a true sur le resto choisi
    restaurant.selected = true;
    const itemIndex = this.restaurants.findIndex((item) => item.ref === restaurant.ref);
    this.restaurants[itemIndex] = restaurant;

    // update la liste des restos dans le storage
    this.persistanceService.set('user_restaurants', this.restaurants);
    this.persistanceService.set('current_restaurant', restaurant);
    this.router.navigateByUrl(this.userService.getRestaurantPageDefaultView());
  }

  /**
   * Triggered when user search input changes.
   */
  onEnterText(): void {
    const value = this.inputSearch;

    if (!value || value === '') {
      document.getElementById('search-button-restaurant').classList.remove('searching');
      document.getElementById('search-input-restaurant').classList.remove('searching');
    } else {
      document.getElementById('search-button-restaurant').classList.add('searching');
      document.getElementById('search-input-restaurant').classList.add('searching');
    }
    this.searchTerm.next(value);

    // Ré-initialise le padding-top à chaque recherche permettant d'éviter des espaces non désirés entres les items de la liste
    if (this.restaurantList !== undefined) {
      this.restaurantList.nativeElement.style.setProperty('padding-top', '0px');
    }
  }

  private search(text: Observable<string>): Observable<Restaurant[]> {
    return text.pipe(
      distinctUntilChanged(),
      switchMap((term) => this.searchEntries(term))
    );
  }

  /**
   * Filters restaurants list if its name or ref contains the given term.
   * @param term Searched term
   */
  private searchEntries(term: string): Observable<Restaurant[]> {
    if (!term || term === '') {
      return of(this.restaurants);
    }

    term = term.toUpperCase();
    return of(this.restaurants.filter((x) => x.name.indexOf(term) >= 0 || x.ref.toString().indexOf(term) >= 0)).pipe(
      map((data) => {
        data
          .sort((a, b) => {
            return a.name < b.name ? -1 : 1; // sort by restaurant name
          })
          .sort((a, b) => {
            return a.selected === b.selected ? 0 : a.selected ? -1 : 1; // sort by restaurant selected
          });
        return data;
      })
    );
  }

  /**
   * Method triggered when page is scrolling.
   */
  @HostListener('window:scroll')
  onScroll(): void {
    if (document.body.scrollTop > 25 || document.documentElement.scrollTop > 25) {
      document.getElementById('title-restaurant').classList.add('collapsed');
      document.getElementById(this.headerRestaurant).classList.add('collapsed');
    } else {
      document.getElementById('title-restaurant').classList.remove('collapsed');
      document.getElementById(this.headerRestaurant).classList.remove('collapsed');
    }
  }

  /**
   * Method triggered when user is resizing the window.
   */
  @HostListener('window:resize')
  onResize(): void {
    this.setMarginTop();
  }

  /**
   * Calculates the height of the empty block when there is no result.
   */
  windowInnerHeight(): number {
    return window.innerHeight;
  }

  headerHeight(): number {
    return this.restaurantHeader.nativeElement.offsetHeight;
  }

  /**
   * Back to previous restaurant page.
   */
  backToRestaurant(): void {
    this.location.back();
  }

  ngAfterViewChecked(): void {
    this.setMarginTop();
  }

  setMarginTop(): void {
    if (this.restaurantList !== undefined) {
      let height = 0;
      height += document.getElementById(this.headerRestaurant).offsetHeight;
      this.restaurantList.nativeElement.style.setProperty('padding-top', height + 'px');
    }
  }
}
