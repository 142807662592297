<div (scroll)="onScroll()" id="header">
  <img src="assets/medias/logo_mystore_white.svg" alt="mcdo_logo" id="restaurant-icon" />

  <div style="width: 100%">
    <div id="restaurant-label">
      <span id="restaurant-label-adress">{{ restaurant.name }}</span>
      <a (click)="showRestaurantHours()" id="restaurant-hours-link">Consulter les horaires d'ouverture</a>
    </div>
  </div>

  <button *ngIf="showHeaderButton" id="restaurant-button" (click)="goToRestaurantList()">
    {{ headerButtonLabel }}
  </button>
</div>
