import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PersistanceService } from '../../../../../../../service/persistance/persistance.service';

@Component({
  selector: 'app-delivery-fees-infos-modal',
  templateUrl: './delivery-fees-infos-modal.component.html',
  styleUrls: ['./delivery-fees-infos-modal.component.scss'],
})
export class DeliveryFeesInfosModalComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<DeliveryFeesInfosModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly persistanceService: PersistanceService,
    private readonly router: Router
  ) {}

  ngOnInit(): void {}

  closeModal(): void {
    this.dialogRef.close();
  }
}
