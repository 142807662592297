import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ExceptionalHoursView } from '../../../../models/exceptionalHoursView/exceptional-hours-view.model';
import { Facility } from '../../../../models/facility/facility.model';
import { FacilityType } from '../../../../models/facility/facilityType.enum';
import { ExceptionalHoursInfosModalComponent } from '../../exceptional-hours-information/exceptional-hours-infos-modal/exceptional-hours-infos-modal.component';

@Component({
  selector: 'app-important-information',
  templateUrl: './important-information.component.html',
  styleUrls: ['./important-information.component.scss'],
})
export class ImportantInformationComponent implements OnInit {
  @Input() message: string;
  @Input() backgroundColor: string;
  @Input() infoBulleColor: string;
  @Input() withHours: boolean;
  @Input() facilities: Facility[];
  @Input() facilityType: FacilityType;
  @Input() exceptionalHoursTabInput: ExceptionalHoursView[];

  constructor(public matDialog: MatDialog) {}

  ngOnInit(): void {}

  showExceptionalHours(): void {
    const dialogConfig = new MatDialogConfig();
    // The user can't close the dialog by clicking outside its body
    dialogConfig.disableClose = true;
    dialogConfig.id = 'restaurant-exceptional-hours-modal';
    dialogConfig.maxWidth = '100vw';
    dialogConfig.width = '92%';
    dialogConfig.position = { top: '3em' };
    dialogConfig.data = {
      facilityType: this.facilityType,
      exceptionalHoursTabInput: this.exceptionalHoursTabInput,
      facilities: this.facilities,
      exceptionalHoursMessage: this.message,
    };

    this.matDialog.open(ExceptionalHoursInfosModalComponent, dialogConfig); // change component to ExceptionalHours, McDelivery screens
  }
}
