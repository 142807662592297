<div *ngIf="loading">
  <app-loader></app-loader>
</div>
<div *ngIf="!select" id="search-zone" #searchZone>
  <!-- Filtre par statut -->
  <div id="status-filter">
    <mat-select
      class="mat-select-filter"
      (selectionChange)="applyStatusFilter()"
      [(ngModel)]="statusFilter"
      [disableOptionCentering]="true"
    >
      <mat-select-trigger>
        <div class="option-content-status">
          <img src="assets/medias/sliders.svg" alt="mcdo_orders_empty" class="icon-filter" />
          <span> {{ getSelectedFilter(statusFilter) }}</span>
        </div>
      </mat-select-trigger>
      <mat-option *ngFor="let filter of filters" [value]="filter.orderStatus">
        <div class="option-content-status">
          <span> {{ getSelectedFilter(filter.orderStatus) }}</span>
        </div>
      </mat-option>
    </mat-select>
  </div>
  <!-- Fin Filtre par statut -->

  <!-- Recherche -->
  <div class="search-order">
    <button mat-icon-button id="search-order-button">
      <mat-icon>search</mat-icon>
    </button>
    <input
      (click)="searchSticky()"
      (focusout)="loseFocus()"
      matInput
      type="search"
      placeholder="{{ inputPlaceholder }}"
      [(ngModel)]="inputSearch"
      (ngModelChange)="onEnterText()"
      (keyup.enter)="searchValidated()"
      [maxlength]="orderNumberMaxLength"
      id="search-order-input"
    />
  </div>
  <!-- Fin Recherche -->
  <button *ngIf="!historyMode && canUpdate" (click)="applySelect()" id="multiSelectButton">Sélect.</button>
</div>

<!-- Header pour la sélection -->

<div *ngIf="select" id="select-zone" #searchZone>
  <span> Sélectionner</span>
  <button (click)="selectAll()" class="all-button">{{ allLabel }}</button>
  <button
    [disabled]="selectedOrders.length < 1"
    (click)="edit()"
    [ngClass]="selectedOrders.length > 0 ? 'edit-button' : 'edit-button-disabled'"
  >
    Éditer
  </button>
  <button (click)="cancel()" class="cancel-button">ANNULER</button>
</div>

<ng-container *ngIf="filteredOrders && filteredOrders.length; else noOrders">
  <div class="orders-list">
    <div *ngIf="this.historyMode; else progressMode">
      <div *ngFor="let order of filteredOrders">
        <div class="day-separator" *ngIf="order.dayFilter && order.dayFilter.name">
          <span class="day">{{ order.dayFilter.name }}</span>
          <span class="date">{{ order.dayFilter.startDate | date: 'EEEE d MMMM':'':'fr' }}</span>
        </div>
        <app-order-line [orderErrors]="orderErrors" [order]="order"></app-order-line>
      </div>
    </div>
    <ng-template #progressMode>
      <div *ngFor="let order of filteredOrders">
        <app-order-line
          (orderSelectChange)="updateSelectedOrder(order)"
          (podChange)="emitPodChange()"
          [select]="select"
          [orderErrors]="orderErrors"
          [order]="order"
        ></app-order-line>
      </div>
    </ng-template>
    <div id="show-more" *ngIf="historyMode && isSeeMore">
      <button (click)="showMoreOrders()" id="show-more-btn">
        <mat-icon style="margin-left: auto">keyboard_arrow_down</mat-icon>
        <span id="show-more-txt">Voir plus</span>
      </button>
    </div>
  </div>
</ng-container>
<ng-template #noOrders>
  <div class="search-list-empty">
    <div class="no-result-img">
      <div class="empty-image-div">
        <img src="assets/medias/search-no-order.svg" alt="orders_list_empty" class="orders-list-empty-icon" />
      </div>
      <p class="no-result-text">Aucune commande trouvée.</p>
    </div>
  </div>
</ng-template>
