import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-restaurant-about-modal',
    templateUrl: './restaurant-about-modal.component.html',
    styleUrls: ['./restaurant-about-modal.component.scss'],
})
export class RestaurantAboutModalComponent implements OnInit {
    constructor(
        public dialogRef: MatDialogRef<RestaurantAboutModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    }

    ngOnInit(): void {
    }

    closeModal(): void {
        this.dialogRef.close();
    }
}
