<div class="drive">
    <app-facility
            [facilityType]="facilityType.MCDRIVE"
            (reloadFacilities)="emitReloadFacilities()"
            [parentIsActive]="true"
            [isSuperParent]="true"
            [facility]="facility"
            [facilitiesList]="facilitiesList"
            [exceptionalHoursTabInput]="exceptionalHoursTabInput"
    >
    </app-facility>
    <app-facility
            [facilityType]="facilityType.DRIVE_PIETON"
            (reloadFacilities)="emitReloadFacilities()"
            [parentIsActive]="true"
            [isSuperParent]="true"
            [facility]="drivePietonfacility"
            [facilitiesList]="facilitiesList"
            [exceptionalHoursTabInput]="exceptionalHoursTabInput"
    >
    </app-facility>

</div>

