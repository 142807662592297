<app-restaurant-header [forceCollapse]="false"></app-restaurant-header>
<div id="main-content" #content>
  <div id="dashboard-header">
    <div id="title">
      <span class="title-welcome">BIENVENUE</span>
      <span class="title-question">Que souhaitez-vous faire aujourd'hui ?</span>
    </div>
  </div>
  <div id="dashboard-content">
    <div *ngIf="isUserOrder" class="dashboard-tile" (click)="goTo('/restaurant/orders')">
      <mat-card class="dashboard-tile-card">
        <mat-card-content class="dashboard-tile-card-content">
          <mat-icon class="dashboard-tile-icon">receipt</mat-icon>
          <div class="tileText">
            <span class="dashboard-tile-label">Suivre mes commandes</span>
            <span class="dashboard-tile-description">Suivez et gérez en direct les commandes digitales de votre restaurant</span>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div *ngIf="isUserOrder" class="dashboard-tile" (click)="goTo('/restaurant/orders-parking')">
      <mat-card class="dashboard-tile-card">
        <mat-card-content class="dashboard-tile-card-content">
          <mat-icon class="dashboard-tile-icon">directions_car</mat-icon>
          <div class="tileText">
            <span class="dashboard-tile-label">Click & Ready Parking</span>
            <span class="dashboard-tile-description">Gérez en direct la remise des commandes Click & Ready Parking</span>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div *ngIf="isUserRestaurant" class="dashboard-tile" (click)="goTo('/restaurant/facilities')">
      <mat-card class="dashboard-tile-card">
        <mat-card-content class="dashboard-tile-card-content">
          <mat-icon class="dashboard-tile-icon">storefront</mat-icon>
          <div class="tileText">
            <span class="dashboard-tile-label">Gérer mes canaux de vente</span>
            <span class="dashboard-tile-description">Paramétrez les horaires de vos canaux de vente Salle, Click&Collect et McDrive</span>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div *ngIf="isUserRestaurant" class="dashboard-tile" (click)="goTo('/restaurant/deliveries')">
      <mat-card class="dashboard-tile-card">
        <mat-card-content class="dashboard-tile-card-content">
          <mat-icon class="dashboard-tile-icon">place</mat-icon>
          <div class="tileText">
            <span class="dashboard-tile-label">Gérer ma livraison à domicile</span>
            <span class="dashboard-tile-description">Paramétrez les horaires du McDelivery et de vos partenaires de livraison</span>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div *ngIf="isUserRestaurant" class="dashboard-tile" (click)="goTo('/restaurant/settings')">
      <mat-card class="dashboard-tile-card">
        <mat-card-content class="dashboard-tile-card-content">
          <mat-icon class="dashboard-tile-icon">tune</mat-icon>
          <div class="tileText">
            <span class="dashboard-tile-label">Paramétrer mon restaurant</span>
            <span class="dashboard-tile-description">Planifiez vos horaires exceptionnels et paramétrez vos commandes digitales</span>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="dashboard-tile" (click)="goTo('/faq')">
      <mat-card class="dashboard-tile-card-faq">
        <mat-card-content class="dashboard-tile-card-content-faq">
          <span class="dashboard-tile-label-faq">QUESTIONS FRÉQUENTES</span>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
