import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PersistanceService } from '../../../service/persistance/persistance.service';

@Component({
  selector: 'app-error-role',
  templateUrl: './error-role.component.html',
  styleUrls: ['./error-role.component.css'],
})
export class ErrorRoleComponent implements OnInit {
  constructor(private readonly persistanceService: PersistanceService, private readonly router: Router) {}

  ngOnInit(): void {
    this.persistanceService.set('faq_return_path', this.router.url);
  }

  windowInnerHeight(): number {
    return window.innerHeight - document.getElementById('main-header').clientHeight;
  }
}
