<div *ngFor="let day of slots" style="margin-left: 5px">
  <div style="display: inline-flex; margin-bottom: 10px">
    <div *ngIf="day.active; then activeDay else inactiveDay"></div>
    <ng-template #activeDay>
      <span id="hours-day-open">{{ dayAsString(day.day) }}</span>
    </ng-template>
    <ng-template #inactiveDay>
      <div *ngIf="!isExceptionalClosing(day); else closingDay">
      <span id="hours-day-close">{{ dayAsString(day.day) }}</span>
      </div>
    </ng-template>
    <ng-template #closingDay>
      <span id="hours-day-red">{{ dayAsString(day.day) }}</span>
    </ng-template>
    <div *ngIf="day.active; then exceptionalSchedule else inactiveSchedule" style="display: contents">
    </div>
      <ng-template #exceptionalSchedule>
        <div *ngIf="day24h24[day.day - 1]; else details" class="restaurant-slots">
          <span class="restaurant-slot">24h/24</span>
          <div class="circle" *ngIf="day.exceptionalDay"></div>
        </div>
      </ng-template>
      <ng-template #details>
        <div *ngFor="let slot of day.slotDetails">
          <div *ngIf="slot != null" class="restaurant-slots">
            <span class="restaurant-slot"> {{ slot.beginHour }} - {{ slot.endHour }} </span>
            <div class="circle" *ngIf="day.exceptionalDay"></div>
            <!-- isExceptional -> day.exceptionalDay -->
          </div>
        </div>
      </ng-template>
    <ng-template #inactiveSchedule>
      <div *ngIf="!isExceptionalClosing(day); else exceptionalClosing">
      <div class="inactive-day">
        <div *ngIf="isRush; else notRush">
          <span>Aucun horaire de rush</span>
        </div>
        <ng-template #notRush>
          <span>Inactif</span>
        </ng-template>
      </div>
      </div>
    </ng-template>
    <ng-template #exceptionalClosing>
        <span class="restaurant-slot-closing">Fermé</span>
        <div class="circle" *ngIf="day.exceptionalDay"></div>
    </ng-template>
  </div>
</div>
