import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Pod } from '../../../../models/pod/pod.model';
import { Restaurant } from '../../../../models/restaurant/restaurant.model';
import { PersistanceService } from '../../../../service/persistance/persistance.service';
import { PodService } from '../../../../service/pod/pod.service';
import { EditPodModalComponent } from '../../edit-pod/edit-pod-modal/edit-pod-modal.component';
import { SlotService } from '../../../../service/slot/slot.service';
import { PodType } from '../../../../models/pod/podType.enum';
import { FacilityService } from '../../../../service/facility/facility.service';
import { EatType } from '../../../../models/eat-type.enum';
import { Role } from '../../../../models/role.enum';
import { UserService } from '../../../../service/user/user.service';

@Component({
  selector: 'app-pod',
  templateUrl: './pod.component.html',
  styleUrls: ['./pod.component.css'],
})
export class PodComponent implements OnInit, OnChanges {
  @Input() pod: Pod;
  @Input() consumptionTypeActive: boolean;
  @Input() eatType: EatType;

  restaurant: Restaurant;
  switchLabel: string;
  seeHoursLabel: string;
  seeHoursActive: boolean;
  displayInfobulle = false;

  mcDriveActive = true;
  drivePietonActive = true;
  eatInKioskActive = true;
  eatInComptoirActive = true;
  facilitySatActive = true;
  editablePod: boolean;

  isUserRestaurantManager = false;

  isLoading: boolean;

  COMPTOIR_DESACTIVATE_MESSAGE = 'En raison de la désactivation du canal de vente Comptoir (onglet Salle), le paramétrage est impossible.';
  KIOSK_DESACTIVATE_MESSAGE = 'En raison de la désactivation du canal de vente Kiosk (onglet Salle), le paramétrage est impossible.';
  SAT_DESACTIVATE_MESSAGE = 'En raison de la désactivation du service “service à table” (onglet Paramètres), le paramétrage est impossible.';
  MCDRIVE_DESACTIVATE_MESSAGE = 'En raison de la désactivation du canal de vente McDrive, le paramétrage est impossible.';
  DRIVE_PIETON_DESACTIVATE_MESSAGE = 'En raison de la désactivation du canal de vente Drive Piéton, le paramétrage est impossible.';
  NATIONAL_MESSAGE = 'En raison d’une mesure nationale, le paramétrage n’est pas disponible.';

  constructor(
    public matDialog: MatDialog,
    private readonly snackBar: MatSnackBar,
    private readonly persistanceService: PersistanceService,
    private readonly podService: PodService,
    private readonly slotService: SlotService,
    private readonly facilityService: FacilityService,
    private readonly userService: UserService
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.checkUserRole();
    this.seeHoursActive = true;
    this.seeHours();
    this.restaurant = this.persistanceService.get('current_restaurant');
    this.setFacilityStatus();
    this.isLoading = false;
  }

  // This method checks the changes and is called before the method ngOnInit()
  ngOnChanges(simplesChanges: SimpleChanges): void {
    if (simplesChanges.consumptionTypeActive) {
      this.setEditablePod();
      this.switchLabelUpdate();
    }
  }

  /**
   * Opens the edit pod modal to edit pod informations
   */
  editPod(): void {
    if (this.isUserRestaurantManager && this.editablePod && this.pod.nationalActive) {
      const dialogConfig = new MatDialogConfig();
      // The user can't close the dialog by clicking outside its body
      dialogConfig.disableClose = true;
      dialogConfig.id = 'edit-pod-modal-component';
      dialogConfig.maxWidth = '100vw';
      dialogConfig.height = '96%';
      dialogConfig.width = '94%';
      dialogConfig.data = { currentPod: this.pod };
      this.matDialog
        .open(EditPodModalComponent, dialogConfig)
        .afterClosed()
        .subscribe((updatedPod) => {
          if (updatedPod !== undefined) {
            // if pod has been updated, use it for the view
            this.pod = updatedPod;
            this.switchLabelUpdate();
          }
        });
    }
  }

  /**
   * Check if pod status is active or not
   * @returns true if nationalActive AND restaurantActive
   */
  private podActive(): boolean {
    return this.pod.nationalActive && this.pod.restaurantActive;
  }

  seeHours(): void {
    this.seeHoursActive = !this.seeHoursActive;
    this.seeHoursLabel = this.seeHoursActive ? 'Voir moins' : 'Voir les horaires';
  }

  switchLabelUpdate(): void {
    this.switchLabel = this.editablePod && this.podActive() ? 'actif' : 'inactif';
  }

  triggerInfobulle(): void {
    this.displayInfobulle = !this.displayInfobulle;
  }

  /**
   * This method allows to know if a pod is editable (consumption type active and parent facility active (ex mcdrive) )
   * A pod is editable if:
   * --> it's national active
   * --> the consumption type is active
   * --> the facility parent is active
   */
  setEditablePod(): void {
    const dependencyStatus = this.facilityService.retrieveStatusFacilityParent(this.pod.reference);
    this.editablePod = dependencyStatus && this.consumptionTypeActive && this.pod.nationalActive;
  }

  /**
   * Set facility status variable
   */
  setFacilityStatus(): void {
    if (this.pod.reference === PodType.MCDRIVE.valueOf()) {
      this.mcDriveActive = this.facilityService.retrieveStatusFacilityParent(this.pod.reference);
    }
    if (this.pod.reference === PodType.DRIVE_PIETON.valueOf()) {
      this.drivePietonActive = this.facilityService.retrieveStatusFacilityParent(this.pod.reference);
    }
    if (this.pod.reference === PodType.KIOSK_EAT_IN.valueOf()) {
      this.eatInKioskActive = this.facilityService.retrieveStatusFacilityParent(this.pod.reference);
    }
    if (this.pod.reference === PodType.COMPTOIR_EAT_IN.valueOf()) {
      this.eatInComptoirActive = this.facilityService.retrieveStatusFacilityParent(this.pod.reference);
    }
    if (this.pod.reference === PodType.SAT.valueOf()) {
      this.facilitySatActive = this.facilityService.retrieveStatusFacilityParent(this.pod.reference);
    }
  }

  /**
   * returns if we display the slots
   */
  displaySlots(): boolean {
    return this.pod.reference === PodType.CURBSIDE || this.pod.reference === PodType.PARKING;
  }

  getWording(): string {
    if (this.pod.wording === 'Kiosk') {
      return 'Borne de commande';
    } else {
      return this.pod.wording;
    }
  }

  /**
   * Check if the user has edit role or not
   */
  private checkUserRole(): void {
    this.userService.getUserInfo().subscribe((userInfos) => {
      if (userInfos) {
        this.isUserRestaurantManager = userInfos.roles.includes(Role.MANAGER);
      }
    });
  }
}
