import {Injectable} from "@angular/core";
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Tracking} from "../../models/tracking/tracking";

@Injectable({
  providedIn: 'root',
})
export class TrackingService {
  DELIVERY_BASE_ENDPOINT = `${environment.settings.apiUrl}/delivery`;

  constructor(private readonly httpClient: HttpClient) {}

  /**
   * Finds the order tracking url from an order.
   * @param orderRef order reference.
   */
  getTrackingUrl(orderRef: string): Observable<Tracking> {
    return this.httpClient.get<Tracking>(`${this.DELIVERY_BASE_ENDPOINT}/${orderRef}/tracking`);
  }
}
