import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Facility } from '../../../../../models/facility/facility.model';

@Component({
  selector: 'app-facility-status-switch',
  templateUrl: './facility-status-switch.component.html',
  styleUrls: ['./facility-status-switch.component.scss'],
})
export class FacilityStatusSwitchComponent implements OnInit {
  @Input() facility: Facility;
  @Input() isUserRestaurantManager: boolean;
  @Input() isLAD: boolean;
  @Input() switchLabel: any;
  @Input() enabledSwitch: boolean;

  @Output() facilityStatusChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {
  }

  ngOnInit(): void {
  }

  emitFacilityStatusChanged(): void {
    this.facilityStatusChanged.emit(true);
  }
}
