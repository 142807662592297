import { Component, OnInit, Inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-custom-snackbar',
  templateUrl: './custom-snackbar.component.html',
  styleUrls: ['./custom-snackbar.component.css'],
})
export class CustomSnackbarComponent implements OnInit {
  /**
   * The label for the action button in the snack bar.
   */
  public static actionText: string;
  public static subData: string;

  constructor(public sbRef: MatSnackBarRef<CustomSnackbarComponent>, @Inject(MAT_SNACK_BAR_DATA) public data: any) {}

  ngOnInit(): void {}

  /**
   * Triggered when the user clicks on action link in the snackbar.
   */
  actionClicked(): void {
    this.sbRef.dismissWithAction();
  }

  /**
   * Gets the custom action text.
   */
  get customActionText(): string {
    return CustomSnackbarComponent.actionText;
  }

  /**
   * Gets the custom sub data.
   */
  get customSubData(): string {
    return CustomSnackbarComponent.subData;
  }
}
