export enum OrderStatus {
  OK = 'OK',
  ERROR = 'ERROR',
  ALL = 'ALL',
  NEW = 'NEW',
  PAYMENT_ERROR = 'PAYMENT_ERROR',
  PAYMENT_AUTHORIZED = 'PAYMENT_AUTHORIZED',
  EXPIRED = 'EXPIRED',
  RETRIEVED = 'RETRIEVED',
  CASH_IN_ERROR = 'CASH_IN_ERROR',
  PAID = 'PAID',
  TO_CANCEL = 'TO_CANCEL',
  CANCELED = 'CANCELED',
  FINISHED = 'FINISHED',
}
