<app-restaurant-header [forceCollapse]="false"></app-restaurant-header>

<div id="main-content" #content>
  <div *ngIf="isLoading; else loaded">
    <app-loader></app-loader>
  </div>
  <ng-template #loaded>
    <h2 id="settings-title">Paramètres</h2>
    <div class="align-subtitle-infobulle">
      <h1 class="settings-subtitle">Page restaurant</h1>
    </div>

    <app-restaurant-information></app-restaurant-information>

    <div *ngIf="haveFacilities">
      <app-restaurant-settings-facilities [facilities]="allFacilities"></app-restaurant-settings-facilities>
    </div>

    <div class="align-subtitle-infobulle">
      <h1 class="settings-subtitle">Horaires exceptionnels</h1>
    </div>

    <app-exceptional-hours-add [facilities]="facilities" [existingExceptionalHours]="exceptionalHoursTab"
      (exceptionalHours)="toReloadExceptionalHours()"></app-exceptional-hours-add>

    <div *ngIf="haveExceptionalHours" class="exceptional-hours-subtitle">
      <hr class="separator" />
      <button *ngIf="downArrow; else upArrow" class="button-see-exceptional-hours" (click)="toSeeExceptionalHours()">
        <mat-icon>keyboard_arrow_down</mat-icon>
      </button>
      <ng-template #upArrow>
        <button class="button-see-exceptional-hours" (click)="toSeeExceptionalHours()">
          <mat-icon>keyboard_arrow_up</mat-icon>
        </button>
      </ng-template>
      <div class="exceptional-hours-title">Horaires exceptionnels paramétrés</div>
    </div>

    <div *ngIf="!downArrow">
      <app-exceptional-hours-configured-list
        [facilities]="facilities"
        [exceptionalHoursTab]="exceptionalHoursTab"
        (exceptionalHoursUpdate)="toReloadExceptionalHours()"
      ></app-exceptional-hours-configured-list>
    </div>

    <div class="align-subtitle-infobulle" *ngIf="restaurant.injectionConfig.manualInjectionAllowed">
      <h1 class="settings-subtitle">Injection commandes</h1>
      <span class="infobulle green" (click)="displayManualInjectionInfos()">
        <div class="infobulle-content">?</div>
      </span>
    </div>

    <app-restaurant-manual-injection
      *ngIf="restaurant.injectionConfig.manualInjectionAllowed"
      [restaurant]="restaurant"
      (refreshRestaurant)="getRestaurantInfos()"
      (hideTitle)="hideTitle($event)"
    ></app-restaurant-manual-injection>

    <h1 class="settings-subtitle">Montant maximum</h1>

    <app-restaurant-max-amount
      (newMaxAmount)="reloadRestaurantInfos($event)"
      [isActivated]="salesChannels.clickAndCollectEnabled"
      [restaurant]="restaurant"
      [orderType]="orderType.C_AND_C"
    ></app-restaurant-max-amount>
    <app-restaurant-max-amount
      (newMaxAmount)="reloadRestaurantInfos($event)"
      [isActivated]="salesChannels.deliveryEnabled"
      [restaurant]="restaurant"
      [orderType]="orderType.MC_DELIVERY"
    ></app-restaurant-max-amount>
  </ng-template>
</div>
