<div class="pod-list">
  <div class="pod-list-card">
    <div *ngIf="hasElements(pods)">
      <div *ngFor="let pod of pods">
        <app-pod [eatType]="eatType" [consumptionTypeActive]="consumptionTypeActive" [pod]="pod"></app-pod>
      </div>
    </div>
    <br />
  </div>
</div>
