import { Component, Input } from '@angular/core';
import { Facility } from '../../../models/facility/facility.model';
import { FacilityType } from '../../../models/facility/facilityType.enum';
import { FacilityService } from '../../../service/facility/facility.service';

@Component({
  selector: 'app-restaurant-settings-facilities-list',
  templateUrl: './restaurant-settings-facilities-list.component.html',
  styleUrls: ['./restaurant-settings-facilities-list.component.scss'],
})
export class RestaurantSettingsFacilitiesListComponent {
  constructor(private readonly facilityService: FacilityService) {}
  @Input() facilities: Facility[];
  @Input() title: string;
  isLoading = false;
  downArrow = true;
  CANDC_DESACTIVATE_MESSAGE = 'Activation McCafé impossible. Canal Click&Collect désactivé.\nVeuillez activer le canal Click&Collect afin de paramétrer le service McCafé';

  getFacilityType(facility: Facility): FacilityType {
    return this.facilityService.getFacilityType(facility);
  }

  displayFacilities(): void {
    this.downArrow = !this.downArrow;
  }

  getImportantInformation(facility: Facility): string {
    // check C&C activation for McCafé
    let importantInformation: string;
    if (facility.reference === FacilityType.MC_CAFE && !this.facilityService.cAndCStatus) {
      importantInformation = this.CANDC_DESACTIVATE_MESSAGE;
    }
    return importantInformation;
  }

  isParentActive(facility: Facility): boolean {
    // check C&C activation for McCafé
    return facility.reference !== FacilityType.MC_CAFE || this.facilityService.cAndCStatus;
  }
}
