import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { OrderError } from '../../models/order/order-error.model';

@Injectable({
  providedIn: 'root',
})
export class OrderErrorService {
  constructor(private readonly httpClient: HttpClient) {}

  getOrderErrors(): Observable<OrderError[]> {
    return this.httpClient.get<any>('assets/order-error.json');
  }
}
