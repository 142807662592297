import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ExceptionalHoursView } from '../../../../models/exceptionalHoursView/exceptional-hours-view.model';
import { Facility } from '../../../../models/facility/facility.model';
import { Role } from '../../../../models/role.enum';
import { UserService } from '../../../../service/user/user.service';
import { ExceptionalHoursAddModalComponent } from '../exceptional-hours-add-modal/exceptional-hours-add-modal.component';

@Component({
  selector: 'app-exceptional-hours-add',
  templateUrl: './exceptional-hours-add.component.html',
  styleUrls: ['./exceptional-hours-add.component.scss'],
})
export class ExceptionalHoursAddComponent implements OnInit {
  @Input() facilities: Facility[];
  @Input() existingExceptionalHours: ExceptionalHoursView[];
  @Output() exceptionalHours: EventEmitter<boolean> = new EventEmitter<boolean>();

  isLoading = false;

  isUserRestaurantManager = false;

  constructor(public matDialog: MatDialog, private readonly userService: UserService) {}

  ngOnInit(): void {
    this.checkUserRole();
  }

  clickToAdd(): void {
    if (this.isUserRestaurantManager) {
      this.addExceptionalHours();
    }
  }

  /**
   * Check if the user has edit role or not
   */
  private checkUserRole(): void {
    this.userService.getUserInfo().subscribe((userInfos) => {
      if (userInfos) {
        this.isUserRestaurantManager = userInfos.roles.includes(Role.MANAGER);
      }
    });
  }

  addExceptionalHours(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = 'restaurant-exceptional-hours-modal';
    dialogConfig.maxWidth = '100vw';
    dialogConfig.height = '96%';
    dialogConfig.width = '96%';
    dialogConfig.data = {
      facilities: this.facilities,
      existingExceptionalHours: this.existingExceptionalHours,
    };

    this.matDialog
      .open(ExceptionalHoursAddModalComponent, dialogConfig)
      .afterClosed()
      .subscribe((response) => {
        this.exceptionalHours.emit(true);
      });
  }
}
