import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ExceptionalHoursView } from '../../../models/exceptionalHoursView/exceptional-hours-view.model';
import { Facility } from '../../../models/facility/facility.model';
import { FacilityType } from '../../../models/facility/facilityType.enum';

@Component({
  selector: 'app-exceptional-hours-information',
  templateUrl: './exceptional-hours-information.component.html',
  styleUrls: ['./exceptional-hours-information.component.scss'],
})
export class ExceptionalHoursInformationComponent implements OnInit {
  @Input() facilityType: FacilityType;
  @Input() facilities: Facility[];
  @Input() infoBulleColor: string;
  @Input() exceptionalHoursTabInput: ExceptionalHoursView[];
  @Input() withHours: boolean;
  @Input() endDate: Date;
  @Input() startDate: Date;
  EXCEPTIONAL_HOURS_MESSAGE: string;
  endExceptionalHours: string;

  private readonly DATE_PATTERN = 'dd/MM/yyyy';

  constructor(private readonly datePipe: DatePipe) {}

  ngOnInit(): void {
    this.getEndExceptionalHours();
    this.setExceptionalHoursMessage();
  }

  getEndExceptionalHours(): void {
    this.endExceptionalHours = this.datePipe.transform(this.endDate, this.DATE_PATTERN);
  }

  setExceptionalHoursMessage(): void {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    this.startDate.setHours(0, 0, 0, 0);
    this.endDate.setHours(0, 0, 0, 0);
    if (today.getTime() === this.startDate.getTime()) {
      this.EXCEPTIONAL_HOURS_MESSAGE = `Votre canal de vente est actuellement en période d'horaires exceptionnels jusqu'au ${this.endExceptionalHours}.`;
    } else {
      if (this.endDate.getTime() === this.startDate.getTime()) {
        this.EXCEPTIONAL_HOURS_MESSAGE = `Votre canal de vente sera en période d'horaires exceptionnels le ${this.endExceptionalHours}.`;
      } else {
        const startExceptionalHours = this.datePipe.transform(this.startDate, this.DATE_PATTERN);
        this.EXCEPTIONAL_HOURS_MESSAGE = `Votre canal de vente sera en période d'horaires exceptionnels du ${startExceptionalHours} au ${this.endExceptionalHours}.`;
      }
    }
  }
}
