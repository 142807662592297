<div *ngIf="isLoading">
  <app-loader></app-loader>
</div>
<div [ngSwitch]="screenToShow">
  <div class="modal-header">
    <div class="modal-title">{{ title }}</div>
    <a>
      <mat-icon id="close-modal-button" (click)="closeModal()">close</mat-icon>
    </a>
    <div class="facility-label">
      {{ facilityService.retrieveFacilityLabel(facilityCopy) }}
    </div>
    <div class="restaurant-name">
      {{ restaurant?.name }}
    </div>
  </div>

  <!-- Edition des informations du service (horaires) -->
  <app-edit-facility-information
    *ngSwitchCase="'facility-info'"
    [isDeliveryPartner]="isLADFacility()"
    [isRushHours]="isRushHours()"
    [oneDayActive]="oneDayActive"
    [facility]="facilityCopy"
    [lastUpdateMessage]="getLastUpdateMessage()"
  ></app-edit-facility-information>
  <!-- OU au click sur suivant (sauf profil mono-restaurant) -->
  <!--Application des changements sur plusieurs restaurants -->
  <app-edit-multi-restaurants
    style="box-sizing: border-box; overflow-x: hidden"
    *ngSwitchCase="'apply-to-restaurants'"
    [baseRestaurant]="restaurant.ref"
    [chosenRestaurants]="restaurantsToSave"
    [modalName]="'edit-facility-modal-component'"
    [testPaymentMethod]="isCandCFacility()"
    [isLADOrMcDelivery]="isLADOrMcDeliveryFacility()"
    [facilityType]="facilityType"
  ></app-edit-multi-restaurants>

  <div *ngSwitchCase="'facility-info'" class="modal-footer" id="modal-footer">
    <div class="footer-content">
      <button id="footer-next-button" (click)="next()">Suivant</button>
    </div>
  </div>
  <div *ngSwitchCase="'apply-to-restaurants'" class="modal-footer two-btn" id="modal-footer">
    <div class="footer-content">
      <button id="footer-next-button" (click)="next()">Suivant</button>
      <button id="footer-back-button" (click)="backToEditInfos()">Précédent</button>
    </div>
  </div>
</div>
