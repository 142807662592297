import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { SlotDetails } from '../../../../models/slot-details.model';
import { SlotService } from '../../../../service/slot/slot.service';
import { Slot } from '../../../../models/slot.model';

@Component({
  selector: 'app-hours-section',
  templateUrl: './hours-section.component.html',
  styleUrls: ['./hours-section.component.scss'],
})
export class HoursSectionComponent implements OnInit, OnChanges {
  @Input() slots: Slot[];
  day24h24: boolean[];
  @Input() isRush: boolean;

  constructor(private readonly slotService: SlotService) {}

  ngOnInit(): void {}

  ngOnChanges(): void {
    this.check24h24();
  }

  /**
   * Get the string value of a day from its numeric value.
   * Example : 1=Lundi, 2=Mardi, etc...
   */
  public dayAsString(day: number): string {
    return this.slotService.dayAsString(day);
  }

  /**
   * Check if the slot day is opened 24h/24
   * @param slotDetails the hours detail of the slot
   */
  public is24h(slotDetails: SlotDetails[]): boolean {
    return this.slotService.is24h(slotDetails);
  }

  /**
   * Check if the exceptional schedule of the slot is opening or closing
   */
  public isExceptionalClosing(slot: Slot): boolean {
    return slot.exceptionalDay;
  }

  private check24h24(): void {
    this.day24h24 = [];
    for (const day of this.slots) {
      this.day24h24.push(this.slotService.is24h(day.slotDetails));
    }
  }
}
