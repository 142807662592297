import { Component, Input, OnInit } from '@angular/core';
import { Dish } from '../../../../models/order/dish-model';

@Component({
  selector: 'app-dish-line',
  templateUrl: './dish-line.component.html',
  styleUrls: ['./dish-line.component.scss'],
})
export class DishLineComponent implements OnInit {
  @Input() dish: Dish;

  constructor() {}

  ngOnInit(): void {}
}
