import { AfterViewChecked, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavBarService } from '../../service/nav-bar/nav-bar.service';
import { PersistanceService } from '../../service/persistance/persistance.service';

@Component({
  selector: 'app-restaurant-orders',
  templateUrl: './restaurant-orders.component.html',
  styleUrls: ['./restaurant-orders.component.scss'],
})
export class RestaurantOrdersComponent implements OnInit, OnDestroy, AfterViewChecked {
  @ViewChild('content') content: ElementRef;
  headerTitle: string;
  currentTab: string;
  isSubCategoryPage: boolean;
  pageLevel = 0;
  previousFilter: string;
  orderTypeFilter: string;

  private readonly commandesDigitales = 'Commandes Digitales';

  constructor(private readonly navBarService: NavBarService, private readonly persistanceService: PersistanceService) {
    this.headerTitle = this.commandesDigitales;
    this.currentTab = 'orders-in-progress';
    this.isSubCategoryPage = false;
  }

  ngOnInit(): void {
    this.navBarService.showNavBar(true);
    this.persistanceService.set('redirectPage', '/restaurant/orders');
  }

  /**
   * Switch tab view to the given one (in progress or history)
   * @param tabView Tab to show
   */
  switchTabView(tabView: string): void {
    this.currentTab = tabView;
    this.headerTitle = this.commandesDigitales;
    this.isSubCategoryPage = false;
    this.pageLevel = 0;
  }

  /**
   * Checks if the given tab view is the one active
   * @param tabView tab view
   * @returns true if this is the current shown view, false otherwise.
   */
  isTabActive(tabView: string): boolean {
    return this.currentTab === tabView;
  }

  ngOnDestroy(): void {
    this.navBarService.showNavBar(false);
    this.persistanceService.remove('redirectPage');
  }

  ngAfterViewChecked(): void {
    this.setPaddingTop(document.getElementById('header').offsetHeight);
  }

  @HostListener('window:resize')
  onResize(): void {
    this.setPaddingTop(document.getElementById('header').offsetHeight);
  }

  @HostListener('window:scroll')
  onScroll(): void {
    this.setPaddingTop(document.getElementById('header').offsetHeight);
  }

  @HostListener('click')
  onClick(): void {
    this.setPaddingTop(document.getElementById('header').offsetHeight);
  }

  setPaddingTop(restaurantHeaderHeight: number): void {
    this.content.nativeElement.style.setProperty('top', restaurantHeaderHeight + 'px');
    let navBarHeight = 0;
    if (document.getElementById('nav-bar') && !document.getElementById('nav-bar-component').classList.contains('nav-bar-tablet-visible')) {
      navBarHeight = document.getElementById('nav-bar').offsetHeight;
      // for ios devices we need to put padding instead of margin cuz of scroll problems
      const isIOS = /iPad|iPhone|iPod/.test(navigator.platform) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
      this.content.nativeElement.style.setProperty(isIOS ? 'padding-bottom' : 'margin-bottom', navBarHeight + 'px');
    } else {
      this.content.nativeElement.style.setProperty('padding-bottom', '0px');
      this.content.nativeElement.style.setProperty('margin-bottom', '0px');
    }
    const minHeight = window.innerHeight - restaurantHeaderHeight - document.getElementById('main-header').offsetHeight - navBarHeight;
    this.content.nativeElement.style.setProperty('min-height', minHeight + 'px');
  }

  toUpdateSubCategoryPage(event: any[]): void {
    if (event) {
      if (this.headerTitle !== event[0]) {
        // application d'un filtre sur le graphique
        this.previousFilter = this.headerTitle;
        this.headerTitle = event[0];
        this.pageLevel = event[1];
        this.isSubCategoryPage = this.pageLevel >= 1;
      }
    } else {
      this.pageLevel = this.pageLevel - 1;
      // click sur le bouton back dans le header et qu'on est sur le level 1 (C&C/McDelivery/LAD)
      if (this.pageLevel === 1 && this.previousFilter) {
        this.orderTypeFilter = this.previousFilter;
        this.headerTitle = this.previousFilter;
      } else {
        this.pageLevel = 0;
        this.headerTitle = this.commandesDigitales;
      }
      this.isSubCategoryPage = this.pageLevel >= 1;
    }
  }
}
