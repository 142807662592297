<div class="modal-section">
  <!-- INFO SECTION -->
  <div *ngIf="!oneDayActive" class="info-section">
    <app-important-information [infoBulleColor]="'#237e47'" [message]="NO_SLOT_SELECTED"> </app-important-information>
  </div>

  <!-- HEADER SECTION -->
  <div id="header-section">
    <div id="opening-time">
      <div *ngIf="isRushHours; else notRushHours">
        <div>Horaires de rush</div>
      </div>
      <ng-template #notRushHours>
        <div>Horaires d'ouverture</div>
      </ng-template>
    </div>

    <div id="update-message">{{ lastUpdateMessage }}</div>
  </div>

  <!-- HOUR SECTION -->
  <div>
    <div *ngFor="let slot of facility?.slots" [ngClass]="slot.active ? 'opening-hours' : 'opening-hours disabled'">
      <hr class="separator" />
      <app-edit-slot-hours
        [objectActive]="facility.restaurantActivation && facility.nationalActivation"
        [slot]="slot"
      ></app-edit-slot-hours>
    </div>
  </div>
</div>
