import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { ConsoleLoggerService } from '../logger/console-logger.service';
import { FileLoggerService } from '../logger/file-logger.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  private readonly MESSAGE_ID_TAG = 'messageId:';
  messageId: string;

  constructor(private readonly CONSOLE_LOGGER: ConsoleLoggerService, private readonly FILE_LOGGER: FileLoggerService) {}

  manageError(err: HttpResponse<any>): void {
    this.messageId = undefined;
    if (err instanceof HttpErrorResponse) {
      this.CONSOLE_LOGGER.error('server side error ', err);
      if (err.error) {
        if (typeof err.error === 'string') {
          this.messageId = this.extractMessageIdFromStringError(err.error);
        } else {
          this.messageId = err.error.messageId;
          this.CONSOLE_LOGGER.error('Error with messageId ' + this.messageId, err.error);
        }
        if (!this.messageId) {
          this.CONSOLE_LOGGER.error('Error without messageId');
          this.FILE_LOGGER.error('Error without messageId ' + this.messageId, err).subscribe((value) => (this.messageId = value));
        }
      } else {
        this.CONSOLE_LOGGER.error('Server side error without infos');
        this.FILE_LOGGER.error('Server side error without infos ' + this.messageId, err).subscribe((value) => (this.messageId = value));
      }
    } else {
      this.CONSOLE_LOGGER.error('Client side error ', err);
      this.FILE_LOGGER.error('Client side error ' + this.messageId, err).subscribe((value) => (this.messageId = value));
    }
  }

  private extractMessageIdFromStringError(error: string): string {
    let messageId;
    const indexBeginMessageIdTag = error.indexOf(this.MESSAGE_ID_TAG);
    if (indexBeginMessageIdTag && indexBeginMessageIdTag > 0) {
      const indexBeginMessageId = indexBeginMessageIdTag + this.MESSAGE_ID_TAG.length;
      let indexEndMessageId = error.lastIndexOf('|');
      indexEndMessageId = indexEndMessageId > indexBeginMessageId ? indexEndMessageId : error.length;
      messageId = error.slice(indexBeginMessageId, indexEndMessageId);
    }
    return messageId;
  }
}
