<app-restaurant-header [forceCollapse]="true"></app-restaurant-header>
<div class="main-content" #content>
  <div id="orders-header">
    <div id="title">
      <button *ngIf="isSubCategoryPage" mat-icon-button (click)="toUpdateSubCategoryPage(null)" class="button-back">
        <mat-icon style="font-size: 30px">keyboard_arrow_left</mat-icon>
      </button>
      <span class="title-text">{{ headerTitle }}</span>
    </div>
    <div id="tabs-section">
      <button
        (click)="switchTabView('orders-in-progress')"
        [ngClass]="isTabActive('orders-in-progress') ? 'orders-section-tab selected' : 'orders-section-tab'"
        style="margin-right: 1%"
      >
        En cours
      </button>
      <button
        (click)="switchTabView('orders-history')"
        [ngClass]="isTabActive('orders-history') ? 'orders-section-tab selected' : 'orders-section-tab'"
      >
        Historique
      </button>
    </div>
  </div>

  <div [ngSwitch]="currentTab">
    <app-orders-in-progress
      [pageLevel]="pageLevel"
      [orderTypeFilter]="orderTypeFilter"
      [subPageMode]="isSubCategoryPage"
      (isSubCategoryPage)="toUpdateSubCategoryPage($event)"
      *ngSwitchCase="'orders-in-progress'"
    ></app-orders-in-progress>
    <app-orders-history *ngSwitchCase="'orders-history'"></app-orders-history>
  </div>
</div>
<app-scroll-to-top></app-scroll-to-top>
