import { AfterViewChecked, Component, HostListener, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Restaurant } from '../../models/restaurant/restaurant.model';
import { PersistanceService } from '../../service/persistance/persistance.service';
import { UserService } from '../../service/user/user.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { RestaurantHoursModalComponent } from './restaurant-hours-modal/restaurant-hours-modal.component';
import { RestaurantService } from '../../service/restaurant/restaurant.service';
import { ConsoleLoggerService } from '../../service/logger/console-logger.service';

@Component({
  selector: 'app-restaurant-header',
  templateUrl: './restaurant-header.component.html',
  styleUrls: ['./restaurant-header.component.scss'],
})
export class RestaurantHeaderComponent implements OnInit, AfterViewChecked {
  @Input() forceCollapse: boolean;

  headerButtonLabel: string;
  showHeaderButton = false;

  restaurant: Restaurant;

  constructor(
    private readonly router: Router,
    private readonly persistanceService: PersistanceService,
    private readonly userService: UserService,
    private readonly restaurantService: RestaurantService,
    public matDialog: MatDialog,
    private readonly CONSOLE_LOGGER: ConsoleLoggerService
  ) {}

  ngOnInit(): void {
    this.headerButtonLabel = 'CHANGER';

    this.restaurant = this.persistanceService.get('current_restaurant');
    if (this.restaurant === undefined) {
      this.CONSOLE_LOGGER.error('restaurant is undefined !! see what to do in this case !');
    }
    this.userService.getUserRestaurants().subscribe((data) => (this.showHeaderButton = data.length > 1));
    if (!this.persistanceService.get('restaurant_page_visited')) {
      this.persistanceService.set('restaurant_page_visited', true);
    }
  }

  ngAfterViewChecked(): void {
    if (this.forceCollapse) {
      this.collapseHeader();
    }
  }

  @HostListener('window:scroll')
  onScroll(): void {
    if (this.forceCollapse || document.documentElement.scrollTop > 1) {
      this.collapseHeader();
    } else {
      document.getElementById('header').classList.remove('collapsed');
      document.getElementById('restaurant-icon').classList.remove('collapsed');
      document.getElementById('restaurant-label').classList.remove('collapsed');
      document.getElementById('restaurant-label-adress').classList.remove('collapsed');
      if (this.showHeaderButton) {
        document.getElementById('restaurant-button').classList.remove('collapsed');
      }
      document.getElementById('restaurant-hours-link').classList.remove('collapsed');
    }
  }

  goToRestaurantList(): void {
    this.router.navigateByUrl('/restaurant-list', { state: { isFromRestaurantPage: true } });
  }

  private collapseHeader(): void {
    document.getElementById('header').classList.add('collapsed');
    document.getElementById('restaurant-icon').classList.add('collapsed');
    document.getElementById('restaurant-label').classList.add('collapsed');
    document.getElementById('restaurant-label-adress').classList.add('collapsed');
    if (this.showHeaderButton) {
      document.getElementById('restaurant-button').classList.add('collapsed');
    }
    document.getElementById('restaurant-hours-link').classList.add('collapsed');
  }

  showRestaurantHours(): void {
    const dialogConfig = new MatDialogConfig();
    // The user can't close the dialog by clicking outside its body
    dialogConfig.disableClose = true;
    dialogConfig.id = 'restaurant-hours-modal';
    dialogConfig.maxWidth = '100vw';
    dialogConfig.width = '92%';
    dialogConfig.position = { top: '3em' };

    this.restaurantService.getRestaurantSlots(this.restaurant.ref.toString()).subscribe((slots) => {
      this.restaurant.slots = slots;
      dialogConfig.data = { restaurant: this.restaurant };
      this.matDialog.open(RestaurantHoursModalComponent, dialogConfig);
    });
  }
}
