import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { Facility } from '../../../../models/facility/facility.model';
import { Restaurant } from '../../../../models/restaurant/restaurant.model';
import { SlotDetails } from '../../../../models/slot-details.model';
import { Slot } from '../../../../models/slot.model';
import { FacilityService } from '../../../../service/facility/facility.service';
import { PersistanceService } from '../../../../service/persistance/persistance.service';
import { RestaurantService } from '../../../../service/restaurant/restaurant.service';
import { SlotService } from '../../../../service/slot/slot.service';
import { ConfirmModalComponent } from '../../../common/confirm-modal/confirm-modal.component';
import { ExceptionalHoursEditComponent } from '../exceptional-hours-edit/exceptional-hours-edit.component';
import { ExceptionalHoursFacilitiesComponent } from '../exceptional-hours-facilites/exceptional-hours-facilities.component';
import { ErrorService } from '../../../../service/error/error.service';
import { ConsoleLoggerService } from '../../../../service/logger/console-logger.service';
import {DialogConfigUtilsService} from "../../../../service/utils/dialog.utils.service";

@Component({
  selector: 'app-exceptional-hours-add-modal',
  templateUrl: './exceptional-hours-add-modal.component.html',
  styleUrls: ['./exceptional-hours-add-modal.component.scss'],
})
export class ExceptionalHoursAddModalComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ExceptionalHoursAddModalComponent>,
    private readonly persistanceService: PersistanceService,
    public matDialog: MatDialog,
    private readonly slotService: SlotService,
    private readonly restaurantService: RestaurantService,
    private readonly facilityService: FacilityService,
    private readonly errorService: ErrorService,
    private readonly CONSOLE_LOGGER: ConsoleLoggerService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
  isLoading = false;
  restaurant: Restaurant;
  screenToShow: string;

  @ViewChild('exceptionalHoursEditComponent')
  exceptionalHoursEditComponent: ExceptionalHoursEditComponent;
  @ViewChild('exceptionalHoursFacilityComponent')
  exceptionalHoursFacilityComponent: ExceptionalHoursFacilitiesComponent;
  exceptionalHoursName = "Nom de l'horaire";

  beginDate: moment.Moment;
  endDate: moment.Moment;
  facilities: Map<Facility, Slot>;
  isDuration = false;

  exceptionalSlots: Slot[];
  exceptionalFacilities: Facility[] = [];

  private readonly createHours = 'create-hours';
  private readonly format = 'DD/MM/yyyy';

  ngOnInit(): void {
    // When the modal is shown, we want a fixed body
    document.body.style.overflow = 'hidden';
    this.restaurant = this.persistanceService.get('current_restaurant');
    this.screenToShow = this.createHours;
    this.beginDate = moment().add(1, 'days');
    this.endDate = this.beginDate;

    const facilitiesByRef = this.facilityService.getFacilitiesCompatibleWithExceptionalHours(this.data.facilities);

    this.facilities = new Map<Facility, Slot>();

    for (const facilityByRef of facilitiesByRef) {
      this.facilities.set(facilityByRef, new Slot(0, false, [new SlotDetails('', '')]));
    }
  }

  /**
   * Reset body to be able to scroll in it.
   */
  private resetBodyScroll(): void {
    // When the modal is hidden...
    document.body.style.overflow = 'auto';
  }

  closeModal(): void {
    const title = 'Si vous quittez le paramétrage, aucune modification ne sera enregistrée.';
    const modalDialog = this.openConfirmModal(title, 'QUITTER SANS SAUVEGARDER', 'ANNULER', 'close', '100%', false);
    modalDialog.afterClosed().subscribe((result) => {
      if (result) {
        // TODO clean data
        this.resetBodyScroll();
        this.dialogRef.close();
      }
    });
  }

  private openConfirmModal(
    title: string,
    confirmText: string,
    cancelText: string,
    panelClass: string,
    width: string,
    showWarningText: boolean,
    topText?: string,
    applyText?: string
  ): MatDialogRef<ConfirmModalComponent> {
    const dialogConfig = new DialogConfigUtilsService().getDialogConfig('confirm-modal', title, confirmText, cancelText, panelClass, width, showWarningText, topText, applyText);

    return this.matDialog.open(ConfirmModalComponent, dialogConfig);
  }

  next(): void {
    if (this.screenToShow === this.createHours && this.exceptionalHoursEditComponent.validate(this.data.existingExceptionalHours)) {
      this.screenToShow = 'select-facilities';
    } else if (this.screenToShow === 'select-facilities' && this.validateSlot()) {
      let title: string;
      if (this.isDuration) {
        title = `Du ${this.beginDate.format(this.format)} au ${this.endDate.format(this.format)}`;
      } else {
        title = `Le ${this.beginDate.format(this.format)}`;
      }
      this.createModalDialog('Planification des <strong>horaires exceptionnels</strong>', 'ENREGISTER ET QUITTER', title, null);
    }
  }

  private validateSlot(): boolean {
    let atLeastOneSlotActive = false;
    this.facilities.forEach((value: Slot, key: Facility) => {
      if (value.active) {
        atLeastOneSlotActive = true;
      }
    });
    let isSlotsValide = true;
    this.facilities.forEach((value: Slot, key: Facility) => {
      this.slotService.resetSlotOldErrors(value);
      if (value.exceptionalClose === false && !this.slotService.checkCurrentDayValidity(value, key)) {
        isSlotsValide = false;
      } else if (value.active && (value.exceptionalClose === undefined || value.exceptionalClose === null)) {
        isSlotsValide = false;
      }
    });
    return atLeastOneSlotActive && isSlotsValide;
  }

  updateValue($event: { exceptionalHoursName: string; beginDate: moment.Moment; endDate: moment.Moment; isDuration: boolean }): void {
    this.beginDate = $event.beginDate;
    this.endDate = $event.endDate;
    this.exceptionalHoursName = $event.exceptionalHoursName;
    this.isDuration = $event.isDuration;
    if (!this.isDuration) {
      this.endDate = $event.beginDate.clone().endOf('day');
    }
  }

  /**
   * When clicking on back button it goes back to edit information screen and scroll to top.
   */
  backToEditInfos(): void {
    this.screenToShow = this.createHours;
  }

  private createModalDialog(title: string, confirmText: string, topText: string, applyText): void {
    const modalDialog = this.openConfirmModal(title, confirmText, 'PRÉCÉDENT', 'save', '94%', false, topText, applyText);
    modalDialog.afterClosed().subscribe((message) => {
      if (message === true) {
        this.isLoading = true;
        this.saveExceptional();
      }
    });
  }

  sameSlotDetails(slotDetails1: SlotDetails, slotDetails2: SlotDetails): boolean {
    return (
      slotDetails1.beginHour === slotDetails2.beginHour &&
      slotDetails1.endHour === slotDetails2.endHour &&
      slotDetails1.startError === slotDetails2.startError &&
      slotDetails1.endError === slotDetails2.endError
    );
  }

  saveExceptional(): void {
    const compareSlotDetails = new SlotDetails('', '');
    // remove facility with no data
    this.facilities.forEach((value: Slot, key: Facility) => {
      if (this.sameSlotDetails(value.slotDetails[0], compareSlotDetails)) {
        // value.slotDetails[0] === compareSlotDetails return false, create sameSlotDetails to get around
        this.facilities.delete(key);
      }
    });

    // set value of a exceptionalHoursView
    const tmpExceptionalHoursView = this.restaurantService.transformMapIntoExceptionalHoursView(this.facilities);
    tmpExceptionalHoursView.label = this.exceptionalHoursName;
    tmpExceptionalHoursView.startDate = this.beginDate.toDate();
    tmpExceptionalHoursView.endDate = this.endDate.toDate();

    // create
    this.restaurantService.createExceptionalHoursForRestaurants(tmpExceptionalHoursView, this.restaurant.ref.toString()).subscribe(
      (data) => {
        this.isLoading = false;
        // close modal
        this.resetBodyScroll();
        this.dialogRef.close();
      },
      (error) => {
        this.CONSOLE_LOGGER.error('error during ExceptionalHours creation !', error);
        this.errorService.manageError(error);
        this.isLoading = false;
        const title = 'En raison d’une erreur technique, vos paramètres n’ont pas été enregistrés. Veuillez réessayer ultérieurement';
        this.openConfirmModal(title, 'QUITTER', null, 'save-error', '94%', false, null);
      }
    );
  }
}
