import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Facility } from '../../../../../../models/facility/facility.model';
import { Pod } from '../../../../../../models/pod/pod.model';

@Component({
  selector: 'app-pod-infos-modal',
  templateUrl: './pod-infos-modal.component.html',
  styleUrls: ['./pod-infos-modal.component.scss'],
})
export class PodInfosModalComponent implements OnInit {
  consumptionType: Facility;
  pods: Pod[];

  constructor(public dialogRef: MatDialogRef<PodInfosModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(): void {
    this.pods = this.data.pods;
    this.consumptionType = this.data.consumptionType;
  }

  closeModal(): void {
    this.dialogRef.close();
  }
}
