import { Component, Input, OnInit } from '@angular/core';
import { OrderItem } from '../../../../models/order/order-item.model';

@Component({
  selector: 'app-order-item-list',
  templateUrl: './order-item-list.component.html',
  styleUrls: ['./order-item-list.component.scss'],
})
export class OrderItemListComponent implements OnInit {
  @Input() items: OrderItem[];

  constructor() {}

  ngOnInit(): void {}
}
