import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { AfterViewChecked, NgZone, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Restaurant } from '../../../../../../models/restaurant/restaurant.model';
import { UserService } from '../../../../../../service/user/user.service';
import { ConfirmModalComponent } from '../../../../../common/confirm-modal/confirm-modal.component';
import { take } from 'rxjs/operators';
import { RestaurantService } from '../../../../../../service/restaurant/restaurant.service';
import { FacilityType } from '../../../../../../models/facility/facilityType.enum';
import {ErrorService} from "../../../../../../service/error/error.service";
import {ConsoleLoggerService} from "../../../../../../service/logger/console-logger.service";
import {DialogConfigUtilsService} from "../../../../../../service/utils/dialog.utils.service";

@Component({
  selector: 'app-edit-deliverer-infos-modal',
  templateUrl: './edit-deliverer-infos-modal.component.html',
  styleUrls: ['./edit-deliverer-infos-modal.component.scss'],
})
export class EditDelivererInfosModalComponent implements OnInit, AfterViewChecked {
  @ViewChild('autosize') autosize: CdkTextareaAutosize;
  restaurant: Restaurant;
  screenToShow: string;
  restaurantsToSave: string[] = new Array();
  isLoading = false;
  commentMaxLength = 500;
  oldCommentValue: string;
  private readonly applyToRestaurants = 'apply-to-restaurants';
  private readonly delivererInfosScreen = 'deliverer-infos';
  private readonly editDelivererInfosModalComponent = 'edit-deliverer-infos-modal-component';
  facilityType: typeof FacilityType = FacilityType;

  constructor(
    private readonly userService: UserService,
    private readonly restaurantService: RestaurantService,
    public dialogRef: MatDialogRef<EditDelivererInfosModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public matDialog: MatDialog,
    private readonly ngZone: NgZone,
    private readonly errorService: ErrorService,
    private readonly CONSOLE_LOGGER: ConsoleLoggerService
  ) {}

  ngOnInit(): void {
    // When the modal is shown, we want a fixed body
    document.body.style.overflow = 'hidden';

    this.screenToShow = this.delivererInfosScreen;
    this.restaurant = this.data.restaurant;
    this.oldCommentValue = this.restaurant.comment;
    this.restaurantsToSave.push(this.restaurant.ref.toString());
  }

  ngAfterViewChecked(): void {
    if (this.screenToShow === this.delivererInfosScreen) {
      this.setMinContentHeigth();
    }
  }

  /**
   * This method sets the height of our component so that there is not available space
   */
  setMinContentHeigth(): void {
    document.getElementById('edit-information-body').style.minHeight =
      document.getElementById(this.editDelivererInfosModalComponent).offsetHeight -
      document.getElementById('modal-header').offsetHeight -
      document.getElementById('modal-footer').offsetHeight +
      'px';
  }

  triggerResize(): void {
    // Wait for changes to be applied, then trigger textarea resize.
    this.ngZone.onStable.pipe(take(1)).subscribe(() => this.autosize.resizeToFitContent(true));
  }

  /**
   * Return the percentage of characters used in textarea.
   * Based on max length.
   */
  textareaPercentage(): number {
    return (this.restaurant.comment?.length / this.commentMaxLength) * 100;
  }

  /**
   * When clicking on cross in right top corner it show a popup to confirm the exit of settings.
   */
  closeModal(): void {
    const title = 'Si vous quittez le paramétrage, aucune modification ne sera enregistrée.';
    const modalDialog = this.openConfirmModal(title, 'QUITTER SANS SAUVEGARDER', 'ANNULER', 'close', '100%');

    modalDialog.afterClosed().subscribe((result) => {
      if (result) {
        this.restaurant.comment = this.oldCommentValue;
        this.dialogRef.close();
        this.resetBodyScroll();
      }
    });
  }

  /**
   * When clicking on next button :
   *    - if we are in mono-restaurant profile or on 2nd screen (apply-to-restaurants) => show save popup and save infos to EDD.
   *    - else show the 2nd screen (apply-to-restaurants) to allow user to save configuration on several restaurants.
   */
  next(): void {
    let confirmText = 'ENREGISTRER';
    let applyText = null;
    if (
      !(this.userService.isMonoRestaurantProfile() || this.screenToShow === this.applyToRestaurants) &&
      this.haveRestaurantsWithDeliveryPartner()
    ) {
      applyText = 'APPLIQUER À PLUSIEURS';
    } else {
      confirmText = 'ENREGISTRER ET QUITTER';
    }
    let title = `Modification du canal de vente <b>LAD McDo+</b>`;

    const modalDialog = this.openConfirmModal(title, confirmText, 'PRÉCÉDENT', 'save', '94%', null, applyText);
    modalDialog.afterClosed().subscribe((message) => {
      if (message === true) {
        this.isLoading = true;

        this.dialogRef.close();
        this.restaurantService.updateDelivererInformationForRestaurants(this.restaurant.comment, this.restaurantsToSave).subscribe(
          () => {
            this.isLoading = false;
            this.resetBodyScroll();
            this.dialogRef.close();
          },
          (error) => {
            this.CONSOLE_LOGGER.error('error during update of deliverer information', error);
            this.errorService.manageError(error);
            this.restaurant.comment = this.oldCommentValue;
            this.isLoading = false;
            title = "En raison d'une erreur technique, vos paramètres n'ont pas été enregistrés. Veuillez réessayer ultérieurement";
            this.openConfirmModal(title, 'QUITTER', null, 'save-error', '94%', null);
          }
        );
      }
      if (message === this.applyToRestaurants) {
        this.screenToShow = this.applyToRestaurants;
        document.getElementById(this.editDelivererInfosModalComponent).scrollTop = 0;
        this.isLoading = false;
      }
    });
  }

  /**
   * When clicking on back button it goes back to edit information screen and scroll to top.
   */
  backToEditInfos(): void {
    this.restaurantsToSave = [this.restaurant.ref.toString()]; // reset restaurants to save on back
    this.screenToShow = this.delivererInfosScreen;
    document.getElementById(this.editDelivererInfosModalComponent).scrollTop = 0;
  }

  private openConfirmModal(
    title: string,
    confirmText: string,
    cancelText: string,
    panelClass: string,
    width: string,
    topText?: string,
    applyText?: string
  ): MatDialogRef<ConfirmModalComponent> {
    const dialogConfig = new DialogConfigUtilsService().getDialogConfig('confirm-modal', title, confirmText, cancelText, panelClass, width, false, topText, applyText);

    return this.matDialog.open(ConfirmModalComponent, dialogConfig);
  }

  /**
   * Reset body to be able to scroll in it.
   */
  private resetBodyScroll(): void {
    // When the modal is hidden...
    document.body.style.overflow = 'auto';
  }

  haveRestaurantsWithDeliveryPartner(): boolean {
    let restaurants: Restaurant[];
    this.userService.getUserRestaurants().subscribe((data) => {
      restaurants = data
        .filter((x) => x.ref !== this.restaurant.ref)
        .filter((x) => this.haveMcDeliveryPartner(x.availableDeliveryPartnersIds));
    });
    return restaurants && restaurants.length > 0;
  }

  haveMcDeliveryPartner(idFacilities: string[]): boolean {
    for (const id of idFacilities) {
      if (this.isMcDeliveryPartner(id)) {
        return true;
      }
    }
    return false;
  }

  private isMcDeliveryPartner(ref: string): boolean {
    return ref === FacilityType.MC_DELIVERY_STUART;
  }
}
