<div class='delivery-fees content'>
    <div class='title'>
        <div class='text distance'>>{{fees.distanceMin}}Km à {{fees.distance}}Km</div>
    </div>
    <div class='fee-details'>
        <div class='group left'>
            <div class='text subtitle'>Frais de service</div>
            <div class='text value'>{{getFeePrice(fees.productPriceDeliveryServiceFee)}}</div>
            <div class='text subtitle'>Frais de livraison</div>
            <div class='text value'>{{getFeePrice(fees.productPriceDeliveryFee)}}</div>
        </div>
        <div class='group left'>
            <div class='text subtitle'>Code PMIX</div>
            <div class='text value'>{{getFeePMIX(fees.productRefDeliveryServiceFee)}}</div>
            <div class='text subtitle'>Code PMIX’</div>
            <div class='text value'>{{getFeePMIX(fees.productRefDeliveryFee)}}</div>
        </div>
        <div class='group'>
            <div class='text subtitle'>Statut PMIX</div>
            <div class='status'>
                <div class='status-value'
                     [ngClass]="fees.deliveryServiceFeeAvailable ? 'available':'unavailable'"></div>
                <div class='text value'>{{getFeeStatus(fees.deliveryServiceFeeAvailable)}}</div>
            </div>
            <div class='text subtitle'>Statut PMIX’</div>
            <div style=' align-items: center; gap: 8px; display: inline-flex'>
                <div class='status-value'
                     [ngClass]="fees.deliveryFeeAvailable ? 'available':'unavailable'"></div>
                <div class='text value'>{{getFeeStatus(fees.deliveryFeeAvailable)}}</div>
            </div>
        </div>
    </div>
</div>