import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";

declare var woosmap;

@Component({
    selector: 'app-restaurant-textarea',
    templateUrl: './restaurant-textarea.component.html',
    styleUrls: ['./restaurant-textarea.component.scss']
})
export class RestaurantTextareaComponent implements OnInit {
    @Input()
    maxLength: number;
    @Input()
    text: string;
    @Input()
    textAreaLabel: string;

    @Output()
    textOutput: EventEmitter<string> = new EventEmitter<string>();

    constructor() {
    }

    ngOnInit(): void {
    }

    percentage(): number {
        return (this.text.length / this.maxLength) * 100;
    }

    updateText(): void {
        this.textOutput.emit(this.text);
    }
}
