import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Restaurant } from '../../models/restaurant/restaurant.model';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  ORDER_BASE_ENDPOINT = environment.settings.apiUrl + '/payment';

  constructor(private readonly httpClient: HttpClient) {}

  /**
   * Return the payment types for a restaurant
   * @param restaurantRef the reference of a restaurant
   */

  getPaymentTypes(restaurantRef: number): Observable<any> {
    const params = new HttpParams().set('restaurantRef', restaurantRef.toString());
    return this.httpClient.get<any>(this.ORDER_BASE_ENDPOINT + `/paymentTypes/`, { params });
  }

  /**
   * return if one restaurant has method type
   */
  getRestaurantWithPaymentMethods(restaurants: Restaurant[]): Restaurant[] {
    const restaurantList = [];
    for (const restaurant of restaurants) {
      if (restaurant.availablePaymentTypes.length !== 0) {
        restaurantList.push(restaurant);
      }
    }
    return restaurantList;
  }
}
