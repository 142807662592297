import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Log } from '../../models/log/log.model';
import { LoggerService } from './logger.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ConsoleLoggerService extends LoggerService {
  constructor() {
    super(environment.settings.logConsoleLevel);
  }

  protected log(log: Log): Observable<string> {
    console.log(log.buildLogString());
    return;
  }
}
