import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ExceptionalHoursView } from '../../../models/exceptionalHoursView/exceptional-hours-view.model';
import { Facility } from '../../../models/facility/facility.model';

@Component({
  selector: 'app-lad-facility-list',
  templateUrl: './lad-facility-list.component.html',
  styleUrls: ['./lad-facility-list.component.scss'],
})
export class LadFacilityListComponent implements OnInit {
  @Input() facilities: Facility[];
  @Input() facilitiesList: Facility[];
  @Input() exceptionalHoursTabInput: ExceptionalHoursView[];

  @Output() reloadFacilities: EventEmitter<boolean> = new EventEmitter<boolean>();

  exceptionalHoursTab: ExceptionalHoursView[];

  constructor() {}

  ngOnInit(): void {}

  /**
   * Emit to parent to reload Facilities
   */
  emitReloadFacilities(): void {
    this.reloadFacilities.emit(true);
  }
}
