<div
  class="order-line"
  [ngStyle]="{
    color: !isOrderSelectable(order) && select ? 'lightgrey' : '#00001e'
  }"
  (click)="onOrderClicked()"
>
  <div class="checkbox-select">
    <mat-checkbox
      (change)="orderSelectChanged(order)"
      [(ngModel)]="order.isSelected"
      [disabled]="!isOrderSelectable(order)"
      *ngIf="select"
    ></mat-checkbox>
  </div>
  <div id="order-content">
    <div id="first-line">
      <span class="order-number">{{ this.orderService.getOrderNumber(order) }}</span>
      <span class="order-hour"> {{ order?.orderedDate | date: 'HH:mm' }}</span>
      <span class="order-price"> {{ this.orderService.getTotalAmount(order) | currency: 'EUR':'symbol':'1.2-2':'fr' }}</span>
    </div>

    <div id="last-line">
      <span [ngClass]="!isOrderSelectable(order) && select ? order?.type + '_disabled' : order?.type" class="order-canal"
        >{{ this.orderService.getOrderTypeValue(order?.type) }} {{ this.orderService.getLabel(order) | titlecase }}</span
      >
      <span class="order-updated-status" *ngIf="order?.orderPodUpdated"> (modifié)</span>
      <span class="order-error" *ngIf="orderHasError">
        <svg
          width="8px"
          height="8px"
          viewBox="0 0 8 8"
          version="1.1"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4 0C1.792 0 0 1.792 0 4C0 6.208 1.792 8 4 8C6.208 8 8 6.208 8 4C8 1.792 6.208 0 4 0Z"
            id="Shape"
            fill="#D20000"
            stroke="none"
          />
        </svg>
        {{ getErrorCause() }}
      </span>
    </div>
  </div>
</div>
