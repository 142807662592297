<div *ngIf="isLoading; else loader">
  <app-loader></app-loader>
</div>

<ng-template #loader>
  <div id="add-exceptional-hours">
    <div class="content">
      <div class="settings">
        <div class="left-side" (click)="clickToAdd()">
          <span [ngClass]="isUserRestaurantManager ? 'settings-label' : 'grey-settings-label'">Ajouter un horaire</span>
          <mat-icon [ngClass]="isUserRestaurantManager ? 'mat-icon-add' : 'grey-mat-icon-add'">add</mat-icon>
        </div>
      </div>
    </div>
  </div>
</ng-template>
