import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Pod } from '../../models/pod/pod.model';
import { EligiblePodsOfOrdersModel } from '../../models/order/eligible-pods-of-orders.model';
import { EatType } from '../../models/eat-type.enum';

@Injectable({
  providedIn: 'root',
})
export class PodService {
  constructor(private readonly httpClient: HttpClient) {}

  /**
   * Find all pods of a restaurant by its reference.
   * @param ref Reference of the restaurant
   */
  getPodsOfRestaurant(ref: string): Observable<Pod[]> {
    return this.httpClient.get<Pod[]>(environment.settings.apiUrl + `/restaurant/${ref}/pods/management`).pipe(
      // Adapt each item in the raw data array
      tap((data: Pod[]) => data.map((item) => (item.lastUpdatedPersonType = this.mapPersonType(item.lastUpdatedPersonType))))
    );
  }

  /**
   * Update the activation status of a POD for a specified restaurant.
   * @param restaurantRef Reference of the restaurant to update
   * @param podRef Reference of the POD to update
   * @param active Whether the POD should be active or not for the restaurant.
   */
  updatePodRestaurantActivation(restaurantRef: string, podRef: string, active: boolean): Observable<any> {
    return this.httpClient.put<any>(
      environment.settings.apiUrl + `/restaurant/${restaurantRef}/pod/${podRef}/management/activation/${active}`,
      null
    );
  }

  /**
   * Update a POD for the given restaurants.
   * @param pod POD to update
   * @param restaurantRefs References of restaurant to update
   */
  updatePodForRestaurants(pod: Pod, restaurantRefs: string[]): Observable<Pod> {
    const body = { restaurantRefs, podManagementUpdateDto: pod };
    return this.httpClient
      .put<Pod>(environment.settings.apiUrl + `/restaurant/pod/${pod?.reference}/management/restaurants`, body)
      .pipe(tap((data: Pod) => (data.lastUpdatedPersonType = this.mapPersonType(data.lastUpdatedPersonType))));
  }

  /**
   * Map a person type from EDD to a displayable string.
   * @param personType Person type from EDD
   */
  private mapPersonType(personType: string): string {
    switch (personType.toUpperCase()) {
      case 'ADMIN':
        return 'Administrateur';
      case 'FRANCHISEE':
        return 'Franchisé';
      case 'EMPLOYEE':
        return 'Employé';
      case 'COLABORATOR':
        return 'Collaborateur';
      default:
        return 'Inconnu';
    }
  }

  /**
   * Clone a POD and its child objects
   * @param pod Pod to clone
   * @returns the copy of given Pod
   */
  public clonePod(pod: Pod): Pod {
    const podCopy = Object.assign({}, pod) as Pod;
    podCopy.slots = podCopy.slots.map((slot) => {
      return Object.assign({}, slot);
    });

    podCopy.slots.forEach((x) => {
      x.slotDetails = x.slotDetails.map((slotDetail) => Object.assign({}, slotDetail));
    });

    return podCopy;
  }

  /**
   * Find all eligible pods for an order by its reference.
   * @param ref Reference of the order
   */
  getEligiblePodsOfOrder(ref: string): Observable<Pod[]> {
    return this.httpClient.get<Pod[]>(environment.settings.apiUrl + `/restaurant/eligiblePodsOfOrder/${ref}`).pipe(
      // Adapt each item in the raw data array
      tap((data: Pod[]) => data.map((item) => (item.lastUpdatedPersonType = this.mapPersonType(item.lastUpdatedPersonType))))
    );
  }

  /**
   * Find all eligible pods for an list of orders for one restaurant.
   * @param restaurantRef reference of restaurant
   * @param orders references of different orders
   */

  getEligiblePodsOfOrders(restaurantRef: string, orders: string[]): Observable<EligiblePodsOfOrdersModel> {
    const body = { orders, restaurantRef };
    return this.httpClient.post<EligiblePodsOfOrdersModel>(environment.settings.apiUrl + '/restaurant/eligiblePodsOfOrders', body);
  }

  /**
   * returns pods for a certain eatType
   * @param eatType the eatType
   * @param the list of pods to filter
   */
  getPodsByEatType(eatType: EatType, pods: Pod[]): Pod[] {
    return pods.filter((pod) => pod.eatType === eatType);
  }
}
