import { ErrorHandler, Injectable } from '@angular/core';
import { ErrorService } from './error.service';
import { ConsoleLoggerService } from '../logger/console-logger.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService implements ErrorHandler {
  constructor(private readonly errorService: ErrorService, private readonly CONSOLE_LOGGER: ConsoleLoggerService) {}

  handleError(err: any): void {
    this.CONSOLE_LOGGER.error('Error handling {}', err);
    this.errorService.manageError(err);
  }
}
