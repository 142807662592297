import { Component, Input, OnInit } from '@angular/core';
import { ExcludedIngredient } from '../../../../models/order/excluded-ingredient.model';

@Component({
  selector: 'app-excluded-ingredient-line',
  templateUrl: './excluded-ingredient-line.component.html',
  styleUrls: ['./excluded-ingredient-line.component.scss'],
})
export class ExcludedIngredientLineComponent implements OnInit {
  @Input() excludedIngredient: ExcludedIngredient;

  constructor() {}

  ngOnInit(): void {}
}
