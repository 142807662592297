import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EatType } from '../../../../models/eat-type.enum';
import { ExceptionalHoursView } from '../../../../models/exceptionalHoursView/exceptional-hours-view.model';
import { Facility } from '../../../../models/facility/facility.model';
import { FacilityType } from '../../../../models/facility/facilityType.enum';
import { Pod } from '../../../../models/pod/pod.model';
import { Restaurant } from '../../../../models/restaurant/restaurant.model';
import { PersistanceService } from '../../../../service/persistance/persistance.service';
import { PodService } from '../../../../service/pod/pod.service';

@Component({
  selector: 'app-candc-facility',
  templateUrl: './candc-facility.component.html',
  styleUrls: ['./candc-facility.component.scss'],
})
export class CandcFacilityComponent implements OnInit {
  @Input() facility: Facility;
  @Input() facilitiesList: Facility[];
  @Input() exceptionalHoursTabInput: ExceptionalHoursView[];
  @Output() reloadFacilities: EventEmitter<boolean> = new EventEmitter<boolean>();

  pods: Pod[];
  facilityType: typeof FacilityType = FacilityType;
  currentRestaurant: Restaurant;
  isLoading = true;

  facilityChildrenRef: string[] = [];

  constructor(private readonly podService: PodService, private readonly persistanceService: PersistanceService) {}

  ngOnInit(): void {
    this.currentRestaurant = this.persistanceService.get('current_restaurant');
    this.podService.getPodsOfRestaurant(this.currentRestaurant.ref.toString()).subscribe((pods) => {
      this.pods = pods;
      this.isLoading = false;
    });
    this.getFacilityChildrenRef();
  }

  /**
   * This method allow to update the facility
   * @param newFacility the updated facility
   */
  updateFacility(newFacility: Facility): void {
    this.facility = newFacility;
  }

  /**
   * filter the pods by eatType
   * @param eatType the eat type
   * @param pods the list of pods
   */
  filterPodsByEatType(eatType: EatType, pods: Pod[]): Pod[] {
    return this.podService.getPodsByEatType(eatType, pods);
  }

  /**
   * Emit to parent to reload Facilities
   */
  emitReloadFacilities(): void {
    this.reloadFacilities.emit(true);
  }

  /**
   * Get all ref of this.facility.childrenFacilities
   */
  getFacilityChildrenRef(): void {
    for (const facilityChild of this.facility.childrenFacilities) {
      this.facilityChildrenRef.push(facilityChild.label);
    }
  }
}
