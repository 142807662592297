<script src="orders-in-progress.component.ts"></script>
<div *ngIf="isLoading; else loaded">
  <app-loader></app-loader>
</div>
<ng-template #loaded>
  <div *ngIf="hasActiveSalesChannels(); else noSalesChannels">
    <div *ngIf="filteredOrders && filteredOrders.length; else noOrders">
      <!-- Graphe -->
      <div id="graph-zone">
        <app-graphique
          (isSubCategoryPage)="toUpdateSubCategoryPage($event)"
          [salesChannel]="salesChannels"
          [pageLevel]="pageLevel"
          [isSubCategory]="subPageMode"
          [orders]="filteredOrders"
          (filteredOrdersChange)="filteredOrdersChange($event)"
          [newOrders]="nbOfNewOrders"
          (refreshOrdersEvent)="refreshOrders()"
        ></app-graphique>
      </div>
      <!-- Fin Graphe -->
      <div id="orders-zone">
        <!-- Liste des commandes -->
        <app-orders-list
          (podChange)="podIsChange($event)"
          [orderErrors]="orderErrors"
          [orders]="filteredOrders"
          [historyMode]="false"
        ></app-orders-list>
        <!-- Fin des commandes -->
      </div>
    </div>
    <ng-template #noOrders>
      <div class="orders-list-empty">
        <div class="no-result-img">
          <div class="empty-image-div">
            <img src="assets/medias/no-orders.svg" alt="mcdo_orders_empty" class="orders-empty-icon" />
          </div>
          <p class="no-result-text">Aucune commande digitale en cours sur le restaurant sélectionné.</p>
        </div>
      </div>
    </ng-template>
  </div>
  <ng-template #noSalesChannels>
    <div class="orders-list-empty">
      <div class="no-result-img">
        <div class="empty-image-div">
          <img src="assets/medias/no-orders.svg" alt="mcdo_orders_empty" class="orders-empty-icon" />
        </div>
        <p class="no-result-text">
          Aucun canal de vente digital n’est paramétré. Veuillez activer vos canaux de ventes sur MyLSM - modules pages locales.
        </p>
      </div>
    </div>
  </ng-template>
</ng-template>
