<div *ngIf="isLoading">
  <app-loader></app-loader>
</div>

<div id="modal-header">
  <div class="modal-title">Montant Maximum</div>
  <a>
    <mat-icon id="close-modal-button" (click)="closeModal()">close</mat-icon>
  </a>
  <div class="sale-channel">{{ saleChannel }}</div>
  <div class="restaurant-name">
    {{ restaurant?.name }}
  </div>
</div>

<div [ngSwitch]="screenToShow">
  <app-edit-max-amount-information
    *ngSwitchCase="'edit-restaurant-max-amount-information'"
    [localAmount]="localAmount"
    [nationalAmount]="nationalAmount"
    [updatedMessage]="updatedMessage"
    (maxAmount)="changeLocalAmount($event)"
    (resetAmount)="resetLocalAmount()"
  >
  </app-edit-max-amount-information>
  <app-edit-multi-restaurants
    style="box-sizing: border-box; overflow-x: hidden"
    *ngSwitchCase="'apply-to-restaurants'"
    [baseRestaurant]="restaurant.ref"
    [chosenRestaurants]="restaurantsToSave"
    [modalName]="'edit-max-amount-modal-component'"
  ></app-edit-multi-restaurants>

  <div *ngSwitchCase="'edit-restaurant-max-amount-information'" class="modal-footer" id="modal-footer">
    <div class="footer-content">
      <button id="footer-next-button" (click)="next()">Suivant</button>
    </div>
  </div>
  <div *ngSwitchCase="'apply-to-restaurants'" class="modal-footer two-btn" id="modal-footer">
    <div class="footer-content">
      <button id="footer-next-button" (click)="next()">Suivant</button>
      <button id="footer-back-button" (click)="backToEditInfos()">Précédent</button>
    </div>
  </div>
</div>
