<div class="content">
  <p class="title">Livraison à domicile</p>
  <p class="highTitle">Partenaires</p>

  <div *ngIf="facilities.length != 0">
    <div *ngFor="let facility of facilities">
      <app-lad-facility
        [facility]="facility"
        [facilitiesList]="facilitiesList"
        [exceptionalHoursTabInput]="exceptionalHoursTabInput"
        (reloadFacilities)="emitReloadFacilities()"
      ></app-lad-facility>
    </div>
  </div>
</div>
