import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HttpCancelService {
  private readonly cancelPendingRequests$ = new Subject<boolean>();

  constructor() {}

  /**
   * Cancels all pending Http requests.
   */
  public cancelPendingRequests(): void {
    this.cancelPendingRequests$.next(true);
  }

  public onCancelPendingRequests(): Observable<boolean> {
    return this.cancelPendingRequests$.asObservable();
  }
}
