import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NavBarService {
  private readonly showNavBarSubject$ = new ReplaySubject<boolean>();
  public showNavBar$ = this.showNavBarSubject$.asObservable();

  constructor() {}

  showNavBar(shouldShow: boolean): void {
    this.showNavBarSubject$.next(shouldShow);
  }
}
