import { Component, Input, OnInit, Output } from '@angular/core';
import { Facility } from '../../../../models/facility/facility.model';
import { SlotDetails } from '../../../../models/slot-details.model';
import { Slot } from '../../../../models/slot.model';
import { FacilityService } from '../../../../service/facility/facility.service';

@Component({
  selector: 'app-exceptional-hours-facility',
  templateUrl: './exceptional-hours-facility.component.html',
  styleUrls: ['./exceptional-hours-facility.component.scss'],
})
export class ExceptionalHoursFacilityComponent implements OnInit {
  @Input()
  facility: Facility;
  @Input()
  slot: Slot;
  @Input()
  isModification: boolean;
  mode: string;
  date = new Date();

  CLOSE_FACILITY_MESSAGE = 'Le canal sera fermé durant cette période.';
  NO_STATE_FACILITY_MESSAGE = "L'état d’ouverture ou de fermeture du canal doit être renseigné et configuré pour passer à l'étape suivante.";

  constructor(private readonly facilityService: FacilityService) {}

  ngOnInit(): void {
    this.mode = 'edition';
    if (this.slot.active) {
      this.slot.exceptionalClose = !this.slot.slotDetails[0].active;
    }
  }

  addSlot(): void {
    this.slot.slotDetails.push(new SlotDetails('', ''));
  }

  removeSlotMode(): void {
    this.mode = 'removing';
  }

  removeSlot(idx: number): void {
    this.slot.slotDetails.splice(idx, 1);
    this.cancelRemoving();
  }

  /**
   * Deletes the second slot and puts the first slot as a new one
   */
  resetSlots(): void {
    this.removeSlot(1);
    this.createSlot();
  }

  /**
   * Puts slotDetails as default
   */
  deleteSlots(): void {
    this.removeSlot(1);
    this.slot.slotDetails[0].beginHour = '';
    this.slot.slotDetails[0].endHour = '';
    this.slot.slotDetails[0].active = false;
  }

  /**
   * Creates a default new slot
   */
  createSlot(): void {
    this.slot.slotDetails[0].beginHour = '00:00';
    this.slot.slotDetails[0].endHour = '23:59';
    this.slot.slotDetails[0].active = false;
  }

  cancelRemoving(): void {
    this.mode = 'edition';
  }

  /**
   * Puts the slot and the facility view as default or creates a new slot when activate
   */
  changeView(): void {
    if (this.slot.active) {
      this.deleteSlots();
      this.slot.exceptionalClose = null;
    }
  }

  /**
   * Manages the behavior of the select button when changing options
   */
  optionSelected(event: any): void {
    this.slot.exceptionalClose = event;
    if (event) {
      this.resetSlots();
    } else {
      this.deleteSlots();
      this.slot.slotDetails[0].active = true;
    }
    this.slot.slotDetails[0].startError = false;
    this.slot.slotDetails[0].endError = false;
    this.slot.configError = [];
    if (this.mode !== 'edition') {
      this.cancelRemoving();
    }
  }

  /**
   * Label to display for the facility
   */
  getFacilityLabel(reference: string): string {
    return this.facilityService.getExceptionalFacilityLegendLabel(reference);
  }
}
