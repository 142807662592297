<div id="header-restaurant" #headerRestaurant>
  <div id="title-restaurant">
    <button *ngIf="isFromRestaurantPage" mat-icon-button class="button-back-restaurant" (click)="backToRestaurant()">
      <mat-icon style="font-size: 30px">keyboard_arrow_left</mat-icon>
    </button>
    <span class="title-text-restaurant">{{ restaurantTitle }}</span>
  </div>
  <div class="search-restaurant">
    <input
      matInput
      type="search"
      placeholder="{{ inputPlaceholder }}"
      [(ngModel)]="inputSearch"
      (ngModelChange)="onEnterText()"
      id="search-input-restaurant"
    />
    <button mat-icon-button id="search-button-restaurant">
      <mat-icon>search</mat-icon>
    </button>
  </div>
</div>

<div *ngIf="isLoading; else loaded">
  <app-loader></app-loader>
</div>
<ng-template #loaded>
  <ng-container *ngIf="filteredRestaurants && filteredRestaurants.length; else noRestaurants">
    <div *ngFor="let restaurant of filteredRestaurants" [ngClass]="restaurant.selected ? 'restaurant-selected' : ''" #restaurantList>
      <div id="restaurant-list">
        <div class="restaurant-list-line" (click)="goToRestaurant(restaurant)">
          <div class="restaurant-list-icon-background">
            <span class="restaurant-list-icon-helper"></span
            ><img src="assets/medias/logo.svg" alt="mcdo_logo" class="restaurant-list-icon" />
          </div>
          <div class="restaurant-list-id-background">
            <span class="restaurant-list-id">{{ restaurant.ref }}</span>
          </div>
          <div style="display: inline-block; width: 80%">
            <span>{{ restaurant.name }}</span>
          </div>
          <button mat-icon-button class="restaurant-list-button">
            <mat-icon class="restaurant-list-icon">keyboard_arrow_right</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template #noRestaurants>
    <div class="restaurant-list-empty" [style.height.px]="windowInnerHeight()">
      <div class="no-result-img" [style.paddingTop.px]="headerHeight()">
        <div class="empty-image-div">
          <img src="assets/medias/restaurants-no-result.svg" alt="mcdo_list_empty" class="restaurant-list-empty-icon" />
        </div>
        <p class="no-result-text">Aucun restaurant trouvé</p>
      </div>
    </div>
  </ng-template>
</ng-template>
