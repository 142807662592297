import { DatePipe, registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import { ErrorHandler, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSelectModule } from '@angular/material/select';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import 'd3'; //NOSONAR
import { NgCircleProgressModule } from 'ng-circle-progress';
import { NvD3Module } from 'ng2-nvd3';
import { CookieService } from 'ngx-cookie-service';
import 'nvd3'; //NOSONAR
import { AppRoutingModule } from './app-routing.module';
/**
 * Components
 */
import { AppComponent } from './app.component';
import { EditDelivererInfosModalComponent } from './component/restaurant-deliveries/onglets/mcdelivery-facility/\
mcdelivery-settings/edit-deliverer-infos-modal/edit-deliverer-infos-modal.component';
import { ConfirmModalComponent } from './component/common/confirm-modal/confirm-modal.component';
import { CustomSnackbarComponent } from './component/common/custom-snackbar/custom-snackbar.component';
import { ImportantInformationComponent } from './component/common/customer-information/important-information/important-information.component';
import { WarningMessageComponent } from './component/common/customer-information/warning-message/warning-message.component';
import { EditMultiRestaurantsComponent } from './component/common/edit-multi-restaurants/edit-multi-restaurants.component';
import { ErrorRoleComponent } from './component/common/error-role/error-role.component';
import { ErrorComponent } from './component/common/error/error.component';
import { ExceptionalHoursInformationComponent } from './component/common/exceptional-hours-information/exceptional-hours-information.component';
import { ExceptionalHoursInfosModalComponent } from './component/common/exceptional-hours-information/exceptional-hours-infos-modal/exceptional-hours-infos-modal.component';
import { HeaderComponent } from './component/common/header/header.component';
import { LoaderComponent } from './component/common/loader/loader.component';
import { NavBarComponent } from './component/common/nav-bar/nav-bar.component';
import { ScrollToTopComponent } from './component/common/scroll-to-top/scroll-to-top.component';
import { DaysSectionComponent } from './component/common/slot-management/days-section/days-section.component';
import { EditSlotHoursComponent } from './component/common/slot-management/edit-slot-hours/edit-slot-hours.component';
import { ExceptionalHoursFacilitiesSectionComponent } from './component/common/slot-management/exceptional-hours-facilities-section/exceptional-hours-facilities-section.component';
import { HoursSectionComponent } from './component/common/slot-management/hours-section/hours-section.component';
import { DashboardComponent } from './component/dashboard/dashboard.component';
import { FaqComponent } from './component/faq/faq.component';
import { OauthComponent } from './component/oauth/oauth.component';
import { OnboardingComponent } from './component/onboarding/onboarding.component';
import { LadFacilityListComponent } from './component/restaurant-deliveries/lad-facility-list/lad-facility-list.component';
import { LadFacilityComponent } from './component/restaurant-deliveries/onglets/lad-facility/lad-facility.component';
import { McdeliveryFacilityComponent } from './component/restaurant-deliveries/onglets/mcdelivery-facility/mcdelivery-facility.component';
import { McDeliverySettingsComponent } from './component/restaurant-deliveries/onglets/mcdelivery-facility/mcdelivery-settings/mcdelivery-settings.component';
import { RestaurantDeliveriesComponent } from './component/restaurant-deliveries/restaurant-deliveries.component';
import { EditFacilityInformationComponent } from './component/restaurant-facilities/edit-facility/edit-facility-information/edit-facility-information.component';
import { EditFacilityModalComponent } from './component/restaurant-facilities/edit-facility/edit-facility-modal/edit-facility-modal.component';
import { EditPodInformationsComponent } from './component/restaurant-facilities/edit-pod/edit-pod-informations/edit-pod-informations.component';
import { EditPodModalComponent } from './component/restaurant-facilities/edit-pod/edit-pod-modal/edit-pod-modal.component';
import { FacilityComponent } from './component/restaurant-facilities/facility/facility.component';
import { CandcFacilityComponent } from './component/restaurant-facilities/onglets/candc-facility/candc-facility.component';
import { ConsumptionTypeComponent } from './component/restaurant-facilities/onglets/candc-facility/consumption-type/consumption-type.component';
import { PodInfosLineComponent } from './component/restaurant-facilities/onglets/candc-facility/consumption-type/pod-infos-modal/pod-infos-line/pod-infos-line.component';
import { PodInfosModalComponent } from './component/restaurant-facilities/onglets/candc-facility/consumption-type/pod-infos-modal/pod-infos-modal.component';
import { McdriveFacilityComponent } from './component/restaurant-facilities/onglets/mcdrive-facility/mcdrive-facility.component';
import { MyRoomFacilityComponent } from './component/restaurant-facilities/onglets/my-room-facility/my-room-facility.component';
import { PodListComponent } from './component/restaurant-facilities/pod-list/pod-list.component';
import { PodComponent } from './component/restaurant-facilities/pod-list/pod/pod.component';
import { RestaurantFacilitiesComponent } from './component/restaurant-facilities/restaurant-facilities.component';
import { RestaurantHeaderComponent } from './component/restaurant-header/restaurant-header.component';
import { RestaurantHoursModalComponent } from './component/restaurant-header/restaurant-hours-modal/restaurant-hours-modal.component';
import { RestaurantListComponent } from './component/restaurant-list/restaurant-list.component';
import { ExcludedIngredientLineComponent } from './component/restaurant-orders/excluded-ingredient/excluded-ingredient-line/excluded-ingredient-line.component';
import { ExcludedIngredientListComponent } from './component/restaurant-orders/excluded-ingredient/excluded-ingredient-list/excluded-ingredient-list.component';
import { GraphiqueComponent } from './component/restaurant-orders/graphique/graphique.component';
import { ModalMultiSelectionComponent } from './component/restaurant-orders/modal-multi-selection/modal-multi-selection.component';
import { OrderErrorComponent } from './component/restaurant-orders/order-error/order-error.component';
import { OrderItemLineComponent } from './component/restaurant-orders/order-item/order-item-line/order-item-line.component';
import { OrderItemListComponent } from './component/restaurant-orders/order-item/order-item-list/order-item-list.component';
import { OrderLineComponent } from './component/restaurant-orders/order-line/order-line.component';
import { OrderModalComponent } from './component/restaurant-orders/order-modal/order-modal.component';
import { OrderMultiPodComponent } from './component/restaurant-orders/order-multi-pod/order-multi-pod.component';
import { OrderPodComponent } from './component/restaurant-orders/order-pod/order-pod.component';
import { OrdersHistoryComponent } from './component/restaurant-orders/orders-history/orders-history.component';
import { OrdersInProgressComponent } from './component/restaurant-orders/orders-in-progress/orders-in-progress.component';
import { OrdersListComponent } from './component/restaurant-orders/orders-list/orders-list.component';
import { RestaurantOrdersComponent } from './component/restaurant-orders/restaurant-orders.component';
import { EditExceptionalHoursConfiguredModalComponent } from './component/restaurant-settings/edit-exceptional-hours-configured-modal/edit-exceptional-hours-configured-modal.component'; //NOSONAR
import { DishLineComponent } from './component/restaurant-orders/dish/dish-line/dish-line.component';
import { DishListComponent } from './component/restaurant-orders/dish/dish-list/dish-list.component';
import { EditMaxAmountInformationComponent } from './component/restaurant-settings/edit-max-amount/edit-max-amount-information/edit-max-amount-information.component';
import { EditMaxAmountModalComponent } from './component/restaurant-settings/edit-max-amount/edit-max-amount-modal/edit-max-amount-modal.component';
import { ExceptionalHoursAddModalComponent } from './component/restaurant-settings/exceptional-hours/exceptional-hours-add-modal/exceptional-hours-add-modal.component';
import { ExceptionalHoursAddComponent } from './component/restaurant-settings/exceptional-hours/exceptional-hours-add/exceptional-hours-add.component';
import { ExceptionalHoursConfiguredListComponent } from './component/restaurant-settings/exceptional-hours/exceptional-hours-configured-list/exceptional-hours-configured-list.component'; //NOSONAR
import { ExceptionalHoursConfiguredComponent } from './component/restaurant-settings/exceptional-hours/exceptional-hours-configured/exceptional-hours-configured.component';
import { ExceptionalHoursEditComponent } from './component/restaurant-settings/exceptional-hours/exceptional-hours-edit/exceptional-hours-edit.component';
import { ExceptionalHoursFacilitiesComponent } from './component/restaurant-settings/exceptional-hours/exceptional-hours-facilites/exceptional-hours-facilities.component';
import { ExceptionalHoursFacilityComponent } from './component/restaurant-settings/exceptional-hours/exceptional-hours-facility/exceptional-hours-facility.component';
import { ManualInjectionInfosModalComponent } from './component/restaurant-settings/manual-injection-infos-modal/manual-injection-infos-modal.component';
import { RestaurantManualInjectionComponent } from './component/restaurant-settings/restaurant-manual-injection/restaurant-manual-injection.component';
import { RestaurantMaxAmountComponent } from './component/restaurant-settings/restaurant-max-amount/restaurant-max-amount.component';
import { RestaurantSettingsComponent } from './component/restaurant-settings/restaurant-settings.component';
import { ServiceInfoModalComponent } from './component/restaurant-settings/service-info-modal/service-info-modal.component';
import { HeaderHttpInterceptor } from './interceptor/header-http.interceptor';
import { OrderParkingComponent } from './component/restaurant-order-parking/order-parking.component';
import { OrderModalParkingComponent } from './component/restaurant-order-parking/order-modal-parking/order-modal-parking.component';
import { RestaurantInformationEditModalComponent } from "./component/restaurant-settings/restaurant-information/restaurant-information-edit-modal/restaurant-information-edit-modal.component"; //NOSONAR
import { RestaurantInformationComponent } from "./component/restaurant-settings/restaurant-information/restaurant-information.component";


/**
 * All material modules and components should be stored in material specific module for visibility
 */
import { MaterialModule } from './material.module';

/**
 * Services
 */
import { RestaurantSettingsFacilitiesListComponent } from './component/restaurant-settings-facilities/restaurant-settings-facilities-list/restaurant-settings-facilities-list.component'; //NOSONAR
import { RestaurantSettingsFacilitiesComponent } from './component/restaurant-settings-facilities/restaurant-settings-facilities.component';
import { ConnectService } from './service/connect/connect.service';
import { ErrorHandlerOauthService } from './service/error/error-handler-oauth.service';
import { ErrorHandlerService } from './service/error/error-handler.service';
import { PersistanceService } from './service/persistance/persistance.service';
import { OrderParkingLineComponent } from './component/restaurant-order-parking/order-parking-line/order-parking-line.component';
import {OrderTrackingComponent} from "./component/restaurant-orders/order-tracking/order-tracking.component";
import {OrderWidgetChatComponent} from "./component/restaurant-orders/order-widget-chat/order-widget-chat.component";
import {IntercomModule} from "ng-intercom";
import {ErrorService} from "./service/error/error.service";
import {MultimediaService} from "./service/multimedia/multimedia.service";
import { RestaurantInformationPinDragModalComponent } from './component/restaurant-settings/restaurant-information/restaurant-information-edit-modal/restaurant-information-pin-drag-modal/restaurant-information-pin-drag-modal.component';
import {WoosmapService} from "./service/woosmap/woosmap.service";
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import {
  RestaurantAdditionalInformationModalComponent
} from "./component/restaurant-settings/restaurant-information/restaurant-information-edit-modal/tooltip/restaurant-additional-information-modal.component";
import {
  RestaurantAboutModalComponent
} from "./component/restaurant-settings/restaurant-information/restaurant-information-edit-modal/tooltip/restaurant-about-modal.component";
import {
  RestaurantTextareaComponent
} from "./component/restaurant-settings/restaurant-information/restaurant-information-textarea/restaurant-textarea.component";
import { FacilityStatusSwitchComponent } from './component/restaurant-facilities/facility/status-switch/facility-status-switch/facility-status-switch.component';
import {
  DeliveryFeesListComponent
} from './component/restaurant-deliveries/onglets/mcdelivery-facility/mcdelivery-settings/delivery-fees-list/delivery-fees-list.component';
import {
  DeliveryFeesComponent
} from './component/restaurant-deliveries/onglets/mcdelivery-facility/mcdelivery-settings/delivery-fees-list/delivery-fees/delivery-fees.component';
import {
  DeliveryFeesInfosModalComponent
} from './component/restaurant-deliveries/onglets/mcdelivery-facility/mcdelivery-settings/delivery-fees-list/delivery-fees-infos-modal/delivery-fees-infos-modal.component';

registerLocaleData(localeFr, 'fr');

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    RestaurantHeaderComponent,
    ErrorComponent,
    RestaurantListComponent,
    CustomSnackbarComponent,
    LoaderComponent,
    EditPodModalComponent,
    EditSlotHoursComponent,
    ConfirmModalComponent,
    EditPodInformationsComponent,
    EditMultiRestaurantsComponent,
    FaqComponent,
    OnboardingComponent,
    ErrorRoleComponent,
    RestaurantOrdersComponent,
    NavBarComponent,
    ScrollToTopComponent,
    OrdersListComponent,
    OrderLineComponent,
    GraphiqueComponent,
    OrdersInProgressComponent,
    OrdersHistoryComponent,
    OrderModalComponent,
    OrderItemLineComponent,
    OrderItemListComponent,
    OrderErrorComponent,
    ExcludedIngredientListComponent,
    ExcludedIngredientLineComponent,
    OrderPodComponent,
    OauthComponent,
    RestaurantSettingsComponent,
    RestaurantManualInjectionComponent,
    ModalMultiSelectionComponent,
    OrderMultiPodComponent,
    RestaurantMaxAmountComponent,
    EditMaxAmountModalComponent,
    EditMaxAmountInformationComponent,
    RestaurantFacilitiesComponent,
    McdriveFacilityComponent,
    MyRoomFacilityComponent,
    CandcFacilityComponent,
    FacilityComponent,
    RestaurantHoursModalComponent,
    PodListComponent,
    PodComponent,
    HoursSectionComponent,
    EditFacilityModalComponent,
    EditFacilityInformationComponent,
    DaysSectionComponent,
    ConsumptionTypeComponent,
    PodInfosModalComponent,
    PodInfosLineComponent,
    ImportantInformationComponent,
    RestaurantDeliveriesComponent,
    LadFacilityComponent,
    McdeliveryFacilityComponent,
    ManualInjectionInfosModalComponent,
    LadFacilityListComponent,
    ExceptionalHoursInformationComponent,
    ExceptionalHoursInfosModalComponent,
    ExceptionalHoursAddComponent,
    ExceptionalHoursAddModalComponent,
    ExceptionalHoursFacilitiesSectionComponent,
    DashboardComponent,
    WarningMessageComponent,
    ExceptionalHoursEditComponent,
    ExceptionalHoursEditComponent,
    ExceptionalHoursFacilitiesComponent,
    ExceptionalHoursFacilityComponent,
    ExceptionalHoursConfiguredComponent,
    ExceptionalHoursConfiguredListComponent,
    EditExceptionalHoursConfiguredModalComponent,
    McDeliverySettingsComponent,
    EditDelivererInfosModalComponent,
    OrderTrackingComponent,
    OrderWidgetChatComponent,
    OrderParkingComponent,
    OrderParkingLineComponent,
    OrderModalParkingComponent,
    DishListComponent,
    DishLineComponent,
    RestaurantInformationComponent,
    RestaurantInformationEditModalComponent,
    RestaurantInformationPinDragModalComponent,
    RestaurantAdditionalInformationModalComponent,
    RestaurantAboutModalComponent,
    RestaurantTextareaComponent,
    ServiceInfoModalComponent,
    RestaurantSettingsFacilitiesComponent,
    RestaurantSettingsFacilitiesListComponent,
    FacilityStatusSwitchComponent,
    DeliveryFeesListComponent,
    DeliveryFeesComponent,
    DeliveryFeesInfosModalComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MaterialModule,
    NvD3Module,
    NgCircleProgressModule.forRoot({}),
    MatExpansionModule,
    MatSelectModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatMomentDateModule,
    IntercomModule.forRoot({
      appId: '',
    }),
    MatAutocompleteModule,
  ],
  providers: [
    PersistanceService,
    { provide: HTTP_INTERCEPTORS, useClass: HeaderHttpInterceptor, multi: true },
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    { provide: ErrorHandler, useClass: ErrorHandlerOauthService },
    CookieService,
    DatePipe,
    ErrorService,
    MultimediaService,
    WoosmapService,
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private readonly connectService: ConnectService) {
    this.connectService.runInitialLoginSequence();
  }
}
