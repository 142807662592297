import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { EditMaxAmountModalComponent } from '../edit-max-amount/edit-max-amount-modal/edit-max-amount-modal.component';
import { Restaurant } from '../../../models/restaurant/restaurant.model';
import { OrderType } from '../../../models/order/order-type.enum';
import { OrderService } from '../../../service/order/order.service';
import { UserService } from '../../../service/user/user.service';
import { Role } from '../../../models/role.enum';

@Component({
  selector: 'app-restaurant-max-amount',
  templateUrl: './restaurant-max-amount.component.html',
  styleUrls: ['./restaurant-max-amount.component.scss'],
})
export class RestaurantMaxAmountComponent implements OnInit {
  @Input() orderType: OrderType;
  @Input() restaurant: Restaurant;
  @Input() isActivated: boolean;

  @Output() newMaxAmount: EventEmitter<boolean> = new EventEmitter<boolean>();

  nationalAmount: number;
  localAmount: number;
  amount: number;
  isNationalAmount = true;

  isUserRestaurantManager = false;

  constructor(public matDialog: MatDialog, private readonly orderService: OrderService, private readonly userService: UserService) {}

  ngOnInit(): void {
    this.checkUserRole();
    this.setAmount();
  }

  /**
   * Check if the user has edit role or not
   */
  private checkUserRole(): void {
    this.userService.getUserInfo().subscribe((userInfos) => {
      if (userInfos) {
        this.isUserRestaurantManager = userInfos.roles.includes(Role.MANAGER);
      }
    });
  }

  /**
   * set the amount to display for C&C
   */
  setAmount(): void {
    switch (this.orderType) {
      case OrderType.C_AND_C:
        this.nationalAmount = this.restaurant.nationalCAndCMaxOrderAmount / 100;
        if (this.restaurant.candCMaxOrderAmount) {
          this.isNationalAmount = false;
          this.localAmount = this.restaurant.candCMaxOrderAmount / 100;
          this.amount = this.localAmount;
        } else {
          this.amount = this.nationalAmount;
        }
        break;
      case OrderType.MC_DELIVERY:
        this.nationalAmount = this.restaurant.nationalMcDeliveryMaxOrderAmount / 100;
        if (this.restaurant.mcDeliveryMaxOrderAmount) {
          this.isNationalAmount = false;
          this.localAmount = this.restaurant.mcDeliveryMaxOrderAmount / 100;
          this.amount = this.localAmount;
        } else {
          this.amount = this.nationalAmount;
        }
        break;
    }
  }

  /**
   * This method allows to open the model to edit the local max amount
   */
  openModalEditAmount(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = 'edit-max-amount-modal-component';
    dialogConfig.maxWidth = '100vw';
    dialogConfig.height = '96%';
    dialogConfig.width = '94%';
    dialogConfig.data = {
      saleChannel: this.getOrderTypeValue(this.orderType, true),
      nationalAmount: this.nationalAmount,
      localAmount: this.localAmount,
      restaurant: this.restaurant,
      orderType: this.orderType,
    };
    this.matDialog
      .open(EditMaxAmountModalComponent, dialogConfig)
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.newMaxAmount.emit(true);
        }
      });
  }

  getOrderTypeValue(orderType: OrderType, longLabel: boolean): string {
    return this.orderService.getOrderTypeValue(orderType, longLabel);
  }
}
