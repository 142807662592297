<div *ngIf="isLoading">
  <app-loader></app-loader>
</div>
<div class="modal-header">
  <div class="modal-title">Horaires d'ouverture</div>
  <a>
    <mat-icon id="close-modal-button" (click)="closeModal()">close</mat-icon>
  </a>
  <div class="subtitle">Restaurant</div>
</div>
<div class="body">
  <span class="restaurant-name">{{ restaurant.name }}</span>
  <div class="restaurant-days">
    <app-days-section [objectIsActive]="true" [slots]="restaurant.slots"></app-days-section>
  </div>
  <div class="restaurant-hours">
    <app-hours-section [slots]="restaurant.slots"></app-hours-section>
  </div>
  <div class="restaurant-hours-notice">
    <div style="display: contents"><span class="infobulle">i</span></div>
    <span class="notice"
      >Ces horaires correspondent : <br />- à l’amplitude horaires la plus large entre les horaires de la salle de votre restaurant, du
      Click & Collect et du McDrive <br />- aux horaires présents sur les moteurs de recherche <br />Pour plus d’informations,
      <span style="text-decoration: underline" (click)="goToFAQ()">consulter la FAQ</span>.
    </span>
  </div>
</div>
