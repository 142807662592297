import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Restaurant } from '../../../models/restaurant/restaurant.model';
import { PersistanceService } from '../../../service/persistance/persistance.service';
import { Router } from '@angular/router';
import { SlotService } from '../../../service/slot/slot.service';

@Component({
  selector: 'app-restaurant-hours-modal',
  templateUrl: './restaurant-hours-modal.component.html',
  styleUrls: ['./restaurant-hours-modal.component.scss'],
})
export class RestaurantHoursModalComponent implements OnInit {
  isLoading: boolean;
  restaurant: Restaurant;

  constructor(
    public dialogRef: MatDialogRef<RestaurantHoursModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly slotService: SlotService,
    private readonly persistanceService: PersistanceService,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.restaurant = this.data.restaurant;
  }

  /**
   * Close the current modal
   */
  closeModal(): void {
    this.dialogRef.close();
  }

  /**
   * Redirect to the faq page.
   */
  goToFAQ(): void {
    this.closeModal();
    this.persistanceService.set('faq_return_path', this.router.url);
    this.router.navigateByUrl('/faq');
  }
}
