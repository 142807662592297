import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { FacilityAdministrationType } from '../../models/facility-administration-type';
import { Facility } from '../../models/facility/facility.model';
import { FacilityType } from '../../models/facility/facilityType.enum';
import { PodType } from '../../models/pod/podType.enum';

const drivePieton = 'Drive Piéton';

@Injectable({
  providedIn: 'root',
})
export class FacilityService {
  public myRoomStatus = false;
  public cAndCStatus = false;
  public mcDriveStatus = false;
  public drivePietonStatus = false;
  public eatInComptoirStatus = false;
  public eatInKioskStatus = false;
  public facilitySatActive = false;
  public rushHoursStatus = false;

  constructor(private readonly httpClient: HttpClient) {}

  /**
   * return the list of facilities by restaurant
   * @param restaurantRef the ref of a restaurant
   */
  getFacilities(restaurantRef: number): Observable<Facility[]> {
    return this.httpClient.get<any>(environment.settings.apiUrl + `/restaurant/management/${restaurantRef}/facilities`);
  }

  /**
   * Get a specific facility of a by restaurant
   * @param restaurantRef the reference of the restaurant
   * @param facilityRef the reference of the facility
   */
  getRestaurantFacilityByRef(restaurantRef: number, facilityRef: string): Observable<Facility> {
    return this.httpClient.get<any>(environment.settings.apiUrl + `/restaurant/management/${restaurantRef}/facility/${facilityRef}`);
  }

  /**
   * Get a facility from a list of facilities by its reference
   * @param facilities a list of facilities
   * @param facilityRef the reference of the facility
   */
  getFacilityByRef(facilities: Facility[], facilityRef: string): Facility {
    // we check
    let facilityRep = facilities.find((facility) => facility.reference === facilityRef);
    if (facilityRep !== undefined) {
      return facilityRep;
    } else {
      for (const facility of facilities) {
        facilityRep = facility.childrenFacilities.find((facilityFind) => facilityFind.reference === facilityRef);
        if (facilityRep !== undefined) {
          return facilityRep;
        }
      }
    }
    return facilityRep;
  }

  /**
   * Get a list of facilities by its administrative type
   * @param facilities a list of facilities
   * @param facilityAdministrationType the administrative type of the facility
   */
  getFacilityByFacilityAdministrationType(facilities: Facility[], facilityAdministrationType: FacilityAdministrationType): Facility[] {
    // we check
    let filteredFacilities: Facility[] = [];
    if (facilities !== undefined && facilities.length > 0) {
      filteredFacilities = facilities.filter((facility) => facility.administrationFacilityType === facilityAdministrationType);
    }
    return filteredFacilities;
  }
  /**
   * Check if facility status is active or not
   * @returns true if nationalActivation AND restaurantActivation
   */
  public facilityActive(facility: Facility): boolean {
    return facility.nationalActivation && facility.restaurantActivation;
  }

  /**
   * Update the activation status of a facility for a specified restaurant.
   * @param restaurantRef Reference of the restaurant to update
   * @param facilityRef Reference of the POD to update
   * @param active Whether the facility should be active or not for the restaurant.
   */
  updateFacilityRestaurantActivation(restaurantRef: string, facilityRef: string, active: boolean): Observable<any> {
    return this.httpClient.put<any>(
      environment.settings.apiUrl + `/restaurant/${restaurantRef}/facility/${facilityRef}/activation/${active}`,
      null
    );
  }

  /**
   * Update a facility for the given restaurants.
   * @param facility facility to update
   * @param restaurantRefs References of restaurant to update
   */
  updateFacilityForRestaurants(facility: Facility, restaurantRefs: string[]): Observable<Facility> {
    const body = { restaurantRefs, restaurantFacilityManagementUpdateDto: facility };
    return this.httpClient.put<Facility>(
      environment.settings.apiUrl + `/restaurant/facility/${facility?.reference}/management/restaurants`,
      body
    );
  }

  /**
   * Clone a facility and its child objects
   * @param facility facility to clone
   * @returns the copy of given Facility
   */
  public cloneFacility(facility: Facility): Facility {
    const facilityCopy = Object.assign({}, facility) as Facility;
    facilityCopy.slots = facilityCopy.slots.map((slot) => {
      return Object.assign({}, slot);
    });

    facilityCopy.slots.forEach((x) => {
      x.slotDetails = x.slotDetails.map((slotDetail) => Object.assign({}, slotDetail));
    });

    facilityCopy.defaultSlots = facilityCopy.defaultSlots.map((slot) => {
      return Object.assign({}, slot);
    });

    facilityCopy.defaultSlots.forEach((x) => {
      x.slotDetails = x.slotDetails.map((slotDetail) => Object.assign({}, slotDetail));
    });

    return facilityCopy;
  }

  /**
   * Return label of a facility for front
   * @param facility to get the ref of a facility
   */
  getFacilityLabel(facility: Facility): string {
    switch (facility.reference) {
      case FacilityType.KIOSK.valueOf():
        return 'Kiosk';
      case FacilityType.MY_ROOM.valueOf():
        return 'Ma Salle';
      default:
        return facility.label;
    }
  }

  /**
   * return the facility type
   * @param facility the facility
   */
  getFacilityType(facility: Facility): FacilityType {
    switch (facility.reference) {
      case '2':
        return FacilityType.C_AND_C;
      case '3':
        return FacilityType.MC_CAFE;
      case '5':
        return FacilityType.MCDRIVE;
      case '13':
        return FacilityType.MY_ROOM;
      case '14':
        return FacilityType.RUSH;
      case '44':
        return FacilityType.MC_DELIVERY;
      case '40':
        return FacilityType.MC_DELIVERY_STUART;
      case '41':
        return FacilityType.LAD_EXTERNE_LYVEAT;
      case '11':
        return FacilityType.EAT_IN;
      case '12':
        return FacilityType.TAKE_OUT;
      case '31':
        return FacilityType.KIOSK;
      case '47':
        return FacilityType.COMPTOIR;
      case '38':
        return FacilityType.LAD_EXTERNE_DELIVEROO;
      case '39':
        return FacilityType.LAD_EXTERNE_JUST_EAT;
      case '37':
        return FacilityType.LAD_EXTERNE_UBER_EATS;
      case '48':
        return FacilityType.DRIVE_PIETON;
      case '26':
        return FacilityType.PTIT_DEJ;
      default:
        return null;
    }
  }

  /**
   * Save the status facility in the service (for using in different component)
   */
  saveStatus(facility: Facility): void {
    if (facility.reference === FacilityType.MCDRIVE) {
      this.mcDriveStatus = this.facilityActive(facility);
    } else if (facility.reference === FacilityType.DRIVE_PIETON) {
      this.drivePietonStatus = this.facilityActive(facility);
    } else if (facility.reference === FacilityType.MY_ROOM) {
      this.myRoomStatus = this.facilityActive(facility);
    } else if (facility.reference === FacilityType.C_AND_C) {
      this.cAndCStatus = this.facilityActive(facility);
    } else if (facility.reference === FacilityType.KIOSK) {
      this.eatInKioskStatus = this.facilityActive(facility);
    } else if (facility.reference === FacilityType.COMPTOIR) {
      this.eatInComptoirStatus = this.facilityActive(facility);
    } else if (facility.reference === FacilityType.SAT) {
      this.facilitySatActive = this.facilityActive(facility);
    } else if (facility.reference === FacilityType.RUSH) {
      this.rushHoursStatus = this.facilityActive(facility);
    }
  }

  /**
   * retrieve the parent of a facility
   * @param facilities array of facilities
   * @param facilityRef the reference of the facility
   */
  retrieveFacilityParent(facilities: Facility[], facilityRef: string): Facility {
    switch (facilityRef) {
      case FacilityType.TAKE_OUT.valueOf():
        return this.getFacilityByRef(facilities, FacilityType.C_AND_C.valueOf());
      case FacilityType.KIOSK.valueOf():
        return this.getFacilityByRef(facilities, FacilityType.MY_ROOM.valueOf());
      case FacilityType.COMPTOIR.valueOf():
        return this.getFacilityByRef(facilities, FacilityType.MY_ROOM.valueOf());
      default:
        return null;
    }
  }

  /**
   * return the status of dependency
   * @param podReference the reference of the pod
   */

  retrieveStatusFacilityParent(podReference): boolean {
    switch (podReference) {
      case PodType.MCDRIVE.valueOf():
        return this.mcDriveStatus;
      case PodType.DRIVE_PIETON.valueOf():
        return this.drivePietonStatus;
      case PodType.KIOSK_EAT_IN.valueOf():
        return this.eatInKioskStatus;
      case PodType.COMPTOIR_EAT_IN.valueOf():
        return this.eatInComptoirStatus;
      case PodType.SAT.valueOf():
        return this.facilitySatActive;
      default:
        return true;
    }
  }

  retrieveFacilityLabel(facility: Facility): string {
    const channelLabels = new Map([
      [FacilityType.MY_ROOM.valueOf(), 'Salle'],
      [FacilityType.C_AND_C.valueOf(), 'Click & Collect'],
      [FacilityType.MCDRIVE.valueOf(), 'McDrive'],
      [FacilityType.DRIVE_PIETON.valueOf(), drivePieton],
      [FacilityType.RUSH.valueOf(), 'Click & Ready'],
      [FacilityType.MC_DELIVERY.valueOf(), 'LAD McDo+'],
      [FacilityType.MC_DELIVERY_STUART.valueOf(), 'Stuart'],
      [FacilityType.LAD_EXTERNE_LYVEAT.valueOf(), 'LyvEat'],
    ]);
    let label = facility.label;
    if (channelLabels.has(facility.reference)) {
      label = channelLabels.get(facility.reference);
    }
    return label;
  }

  /**
   * Get the label to display in exceptional hours facility legend
   */
  getExceptionalFacilityLegendLabel(facilityRef: string): string {
    switch (facilityRef) {
      case FacilityType.C_AND_C:
        return 'C&C';
      case FacilityType.MY_ROOM:
        return 'SALLE';
      case FacilityType.MCDRIVE:
        return 'McDRIVE';
      case FacilityType.DRIVE_PIETON:
        return 'DRIVE PIETON';
      case FacilityType.MC_DELIVERY:
        return 'LAD McDO+';
      case FacilityType.LAD_EXTERNE:
        return 'LAD';
      case FacilityType.LAD_EXTERNE_UBER_EATS:
        return 'UBEREATS';
      case FacilityType.LAD_EXTERNE_DELIVEROO:
        return 'DELIVEROO';
      case FacilityType.LAD_EXTERNE_JUST_EAT:
        return 'JUSTEAT';
      case FacilityType.LAD_EXTERNE_LYVEAT:
        return 'LYVEAT';
      default:
        return 'Inconnu';
    }
  }

  /**
   * Get the label to display in exceptional hours facility hours details
   */
  getExceptionalFacilityDetailLabel(facilityRef: string): string {
    switch (facilityRef) {
      case FacilityType.C_AND_C:
        return 'Click & Collect';
      case FacilityType.MY_ROOM:
        return 'Salle';
      case FacilityType.MCDRIVE:
        return 'McDrive';
      case FacilityType.DRIVE_PIETON:
        return drivePieton;
      case FacilityType.MC_DELIVERY:
        return 'LAD McDo+';
      case FacilityType.LAD_EXTERNE:
        return 'LAD';
      case FacilityType.LAD_EXTERNE_UBER_EATS:
        return 'UberEats';
      case FacilityType.LAD_EXTERNE_DELIVEROO:
        return 'Deliveroo';
      case FacilityType.LAD_EXTERNE_JUST_EAT:
        return 'JustEat';
      case FacilityType.LAD_EXTERNE_LYVEAT:
        return 'LYVEAT';
      default:
        return 'Inconnu';
    }
  }

  /**
   * Return the facilities that are compatible with exceptional hours from the given list.
   * Ie : facilities that match the FacilityType, have time and partner manage hours
   */
  getFacilitiesCompatibleWithExceptionalHours(availableFacilities: Facility[]): Facility[] {
    // filter active facilities that can have exceptional hours, time and partner manage hours
    return availableFacilities.filter(
      (f) =>
        f !== undefined &&
        f.nationalActivation &&
        f.restaurantActivation &&
        f.exceptionalHoursEligible &&
        f.hasTime &&
        f.partnerManageOpeningHours
    );
  }
}
