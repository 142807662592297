<mat-toolbar class="header" id="main-header">
  <mat-icon style="margin-right: 10px">person</mat-icon>
  <span>{{ name | async }}</span>
  <span class="example-spacer"></span>
  <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu" class="menu">
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item class="menu-border" (click)="goToFAQ()">
      <mat-icon class="text green">contact_support</mat-icon>
      <span class="text green">{{ menuQuestions }}</span>
    </button>
    <button mat-menu-item class="menu-border" (click)="showConsent()">
      <mat-icon class="text green">info</mat-icon>
      <span class="text green">Consent preferences</span>
    </button>
    <button mat-menu-item class="menu-border" (click)="logout()">
      <mat-icon class="text green">exit_to_app</mat-icon>
      <span class="text green">{{ menuDisconnect }}</span>
    </button>
      <button mat-menu-item [disabled]="true">
          <span class="text"> Version : {{ version }}</span>
      </button>
  </mat-menu>
</mat-toolbar>
