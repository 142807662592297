import { AfterViewChecked, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ExceptionalHoursView } from '../../models/exceptionalHoursView/exceptional-hours-view.model';
import { Facility } from '../../models/facility/facility.model';
import { FacilityType } from '../../models/facility/facilityType.enum';
import { Restaurant } from '../../models/restaurant/restaurant.model';
import { FacilityService } from '../../service/facility/facility.service';
import { NavBarService } from '../../service/nav-bar/nav-bar.service';
import { PersistanceService } from '../../service/persistance/persistance.service';
import { PodService } from '../../service/pod/pod.service';
import { RestaurantService } from '../../service/restaurant/restaurant.service';

@Component({
  selector: 'app-restaurant-facilities',
  templateUrl: './restaurant-facilities.component.html',
  styleUrls: ['./restaurant-facilities.component.scss'],
})
export class RestaurantFacilitiesComponent implements OnInit, OnDestroy, AfterViewChecked {
  @ViewChild('content') content: ElementRef;
  @ViewChild('header') header: ElementRef;

  currentRestaurant: Restaurant;

  currentTab: FacilityType;
  isLoading = true;
  // The list of facilities
  facilities: Facility[];
  facilityType: typeof FacilityType = FacilityType;

  // true if the restaurant has payment method
  hasPaymentMethod = true;

  // the different facilities
  myRoomFacility: Facility;
  cAndcFacility: Facility;
  mcDriveFacility: Facility;
  drivePietonFacility: Facility;
  satFacility: Facility;

  exceptionalHoursTab: ExceptionalHoursView[];

  private readonly restaurantFacilitiesHeader = `restaurant-facilities-header`;

  constructor(
    private readonly persistanceService: PersistanceService,
    private readonly podService: PodService,
    private readonly navBarService: NavBarService,
    private readonly facilityService: FacilityService,
    private readonly restaurantService: RestaurantService
  ) {}

  ngOnInit(): void {
    // Initialize
    this.navBarService.showNavBar(true);
    this.persistanceService.set('redirectPage', '/restaurant/facilities');

    this.currentRestaurant = this.persistanceService.get('current_restaurant');

    this.loadFacilities();

    this.currentTab = this.facilityType.MY_ROOM;
  }

  ngOnDestroy(): void {
    this.navBarService.showNavBar(false);
    this.persistanceService.remove('redirectPage');
  }

  ngAfterViewChecked(): void {
    this.setHeaderMarginTop(document.getElementById('header').offsetHeight);
    this.setMarginTopFromHeader();
  }

  private setMarginTopFromHeader(): void {
    this.setMarginTop(
      document.getElementById('header').offsetHeight + document.getElementById(this.restaurantFacilitiesHeader).offsetHeight
    );
  }

  @HostListener('window:resize')
  onResize(): void {
    setTimeout(() => {
      this.setMarginTopFromHeader();
    }, 50);
  }

  @HostListener('window:scroll')
  onScroll(): void {
    this.setMarginTopFromHeader();
    if (document.documentElement.scrollTop > 1) {
      document.getElementById('restaurant-facilities').style.paddingBottom = '150px';
    } else {
      document.getElementById('restaurant-facilities').style.paddingBottom = '42px';
    }
  }

  @HostListener('click')
  onClick(): void {
    this.setMarginTopFromHeader();
  }

  /**
   * set the margin top of main content
   * @param height the height of marin-top
   */
  setMarginTop(height: number): void {
    this.content.nativeElement.style.setProperty('margin-top', height + 'px');
    if (document.getElementById('nav-bar') && !document.getElementById('nav-bar-component').classList.contains('nav-bar-tablet-visible')) {
      this.content.nativeElement.style.setProperty('margin-bottom', document.getElementById('nav-bar').offsetHeight + 'px');
    } else {
      this.content.nativeElement.style.setProperty('margin-bottom', '0px');
    }
  }

  setHeaderMarginTop(height: number): void {
    this.header.nativeElement.style.setProperty('margin-top', height + 'px');
  }

  /**
   * Switch tab view to the given one (in progress or history)
   * @param tabView Tab to show
   */
  switchTabView(tabView: FacilityType): void {
    if (tabView === FacilityType.C_AND_C) {
      this.restaurantService.getRestaurantById(this.currentRestaurant.ref.toString()).subscribe(r => {
        this.currentRestaurant = r;
        if (this.currentRestaurant.availablePaymentTypes.length) {
          this.hasPaymentMethod = this.currentRestaurant.availablePaymentTypes.filter(type => type !== "IN_APP_APPLEPAY").length !== 0;
        } else {
          this.hasPaymentMethod = false;
        }
      });
    }
    this.currentTab = tabView;
  }

  /**
   * Checks if the given tab view is the one active
   * @param tabView tab view
   * @returns true if this is the current shown view, false otherwise.
   */
  isTabActive(tabView: FacilityType): boolean {
    return this.currentTab === tabView;
  }

  /**
   *
   */
  loadFacilities(): void {
    this.facilityService.getFacilities(this.currentRestaurant.ref).subscribe(
      (facilities) => {
        this.facilities = facilities;
        this.myRoomFacility = this.facilityService.getFacilityByRef(this.facilities, FacilityType.MY_ROOM.valueOf());
        this.facilityService.saveStatus(this.myRoomFacility);
        this.cAndcFacility = this.facilityService.getFacilityByRef(this.facilities, FacilityType.C_AND_C.valueOf());
        this.facilityService.saveStatus(this.cAndcFacility);
        this.mcDriveFacility = this.facilityService.getFacilityByRef(this.facilities, FacilityType.MCDRIVE.valueOf());
        this.facilityService.saveStatus(this.mcDriveFacility);
        this.drivePietonFacility = this.facilityService.getFacilityByRef(this.facilities, FacilityType.DRIVE_PIETON.valueOf());
        this.facilityService.saveStatus(this.drivePietonFacility);
        this.satFacility = this.facilityService.getFacilityByRef(this.facilities, FacilityType.SAT.valueOf());
        this.facilityService.saveStatus(this.satFacility);

        this.restaurantService.getRestaurantExceptionalHours(this.currentRestaurant.ref.toString()).subscribe(
          (exceptionalHoursViewTabTmp) => {
            exceptionalHoursViewTabTmp.sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime());
            this.exceptionalHoursTab = exceptionalHoursViewTabTmp;
            this.isLoading = false;
          },
          (error) => {
            this.isLoading = false;
          }
        );
      },
      (error) => {
        this.isLoading = false;
      }
    );
  }
}
