import { AfterViewChecked, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Role } from '../../models/role.enum';
import { NavBarService } from '../../service/nav-bar/nav-bar.service';
import { PersistanceService } from '../../service/persistance/persistance.service';
import { UserService } from '../../service/user/user.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, AfterViewChecked, OnDestroy {
  @ViewChild('content') content: ElementRef;
  isUserRestaurant = false;
  isUserOrder = false;

  constructor(
    private readonly navBarService: NavBarService,
    private readonly persistanceService: PersistanceService,
    private readonly router: Router,
    private readonly userService: UserService
  ) {}

  ngOnInit(): void {
    this.navBarService.showNavBar(true);
    this.persistanceService.set('redirectPage', '/restaurant/dashboard');
    this.persistanceService.set('faq_return_path', '/restaurant/dashboard');
    this.userService.getUserInfo().subscribe((userInfos) => {
      this.isUserRestaurant = userInfos.roles.includes(Role.MANAGER) || userInfos.roles.includes(Role.RESTAURANT_VIEWER);
      this.isUserOrder = userInfos.roles.includes(Role.ORDER_UPDATER) || userInfos.roles.includes(Role.ORDER_VIEWER);
    });
    (async () => {
      await new Promise((resolve) => setTimeout(resolve, 500));
    })();
  }

  ngOnDestroy(): void {
    this.navBarService.showNavBar(false);
    this.persistanceService.remove('redirectPage');
  }

  ngAfterViewChecked(): void {
    this.setPaddingTop(document.getElementById('header').offsetHeight);
  }

  @HostListener('window:resize')
  onResize(): void {
    this.setPaddingTop(document.getElementById('header').offsetHeight);
  }

  @HostListener('window:scroll')
  onScroll(): void {
    if (document.documentElement.scrollTop > 1) {
      document.getElementById('main-content').style.paddingBottom = '150px';
    } else {
      document.getElementById('main-content').style.paddingBottom = '42px';
    }
    this.setPaddingTop(document.getElementById('header').offsetHeight);
  }

  @HostListener('click')
  onClick(): void {
    this.setPaddingTop(document.getElementById('header').offsetHeight);
  }

  setPaddingTop(height: number): void {
    this.content.nativeElement.style.setProperty('margin-top', height + 'px');
    if (document.getElementById('nav-bar') && !document.getElementById('nav-bar-component').classList.contains('nav-bar-tablet-visible')) {
      this.content.nativeElement.style.setProperty('margin-bottom', document.getElementById('nav-bar').offsetHeight + 'px');
    } else {
      this.content.nativeElement.style.setProperty('margin-bottom', '0px');
    }
  }

  goTo(url: string): void {
    this.router.navigateByUrl(url);
  }
}
