import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { DatePipe } from '@angular/common';
import { Component, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { take } from 'rxjs/operators';
import { Pod } from '../../../../models/pod/pod.model';
import { Facility } from '../../../../models/facility/facility.model';
import { TimeDependency } from '../../../../models/pod/timeDependency.enum';

@Component({
  selector: 'app-edit-pod-informations',
  templateUrl: './edit-pod-informations.component.html',
  styleUrls: ['./edit-pod-informations.component.scss'],
  providers: [DatePipe],
})
export class EditPodInformationsComponent implements OnInit {
  @ViewChild('autosize') autosize: CdkTextareaAutosize;

  @Input() lastUpdateMessage: string;
  @Input() facility: Facility;
  @Input() oneDayActive: boolean;
  @Input() currentPod: Pod;

  podMessageMaxLength = 140;

  timeDependency: typeof TimeDependency = TimeDependency;

  NO_SLOT_SELECTED = 'Veuillez paramétrer les jours et heures de disponibilité de votre canal de vente.';

  constructor(private readonly ngZone: NgZone) {}

  ngOnInit(): void {
    this.setMinContentHeigth();
  }

  /**
   * This method sets the height of our component so that there is not available space
   */
  setMinContentHeigth(): void {
    document.getElementById('edit-pod-information-body').style.minHeight =
      document.getElementById('edit-pod-modal-component').offsetHeight -
      document.getElementById('modal-header').offsetHeight -
      document.getElementById('modal-footer').offsetHeight +
      'px';
  }

  triggerResize(): void {
    // Wait for changes to be applied, then trigger textarea resize.
    this.ngZone.onStable.pipe(take(1)).subscribe(() => this.autosize.resizeToFitContent(true));
  }

  /**
   * Return the percentage of characters used in textarea.
   * Based on max length.
   */
  textareaPercentage(): number {
    return (this.currentPod.restaurantDescription.length / this.podMessageMaxLength) * 100;
  }
}
