import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {Multimedia} from "../../models/multimedia/multimedia.model";
import {MultimediaView} from "../../models/multimedia/multimedia-view.model";

@Injectable({
  providedIn: 'root'
})
export class MultimediaService {
  MULTIMEDIA_BASE_ENDPOINT = `${environment.settings.apiUrl}/multimedia`;

  constructor(private readonly httpClient: HttpClient) {}

  createMultimedia(className: string, restaurantRef: string, multimedia: Multimedia): Observable<MultimediaView> {
    const opts = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      responseType: 'arraybuffer' as 'json',
      params: new HttpParams({ fromString: `owner=${className}&owner_ref=${restaurantRef}`})
    };
    return this.httpClient.post<MultimediaView>(this.MULTIMEDIA_BASE_ENDPOINT, multimedia, opts);
  }

  deleteMultimedia(className: string, restaurantRef: string, multimediaId: number): any {
    const opts = { params: new HttpParams({ fromString: `owner=${className}&owner_ref=${restaurantRef}`}) };
    return this.httpClient.delete<any>(this.MULTIMEDIA_BASE_ENDPOINT + `/${multimediaId}`, opts);
  }
}
