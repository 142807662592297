<div *ngIf="isLoading; else loader">
  <app-loader></app-loader>
</div>
<ng-template #loader>
  <mat-card style="border-radius: 0; border-top-left-radius: 4px; border-top-right-radius: 4px">
    <mat-card-content>
      <div class="section-title">
        <div class="section-title-text">{{ exceptionalHour.label }}</div>
        <div class="section-date-block">
          <div class="section-date" *ngIf="!isDuration">Le</div>
          <div class="section-date" *ngIf="isDuration">Du</div>
          <div class="section-date">{{ exceptionalHour.startDate | date: 'dd/MM/yyyy' }}</div>
          <div class="section-date" *ngIf="isDuration">au</div>
          <div *ngIf="isDuration" class="section-date">{{ exceptionalHour.endDate | date: 'dd/MM/yyyy' }}</div>
        </div>
      </div>
    </mat-card-content>

    <div class="exceptional-hours-view">
      <div *ngFor="let facility of facilities" style="display: inline-block; margin-left: 2px">
        <div *ngIf="facilityIsActive(facility) then exceptional; else inactive" class="facility-day-letter-list">
        </div>
        <ng-template #exceptional>
          <div id="{{ facilityIsExceptionalClosing(facility) ? 'service-exceptional-hours-closing' : 'service-exceptional-hours-active' }}"
               class="facility-day-letter-list">
            <p class="service-exceptional-hours">{{ getFacilityLegendLabel(facility) }}</p>
          </div>
        </ng-template>
        <ng-template #inactive>
          <div id="service-exceptional-hours-inactive" class="facility-day-letter-list">
            <p class="service-exceptional-hours">{{ getFacilityLegendLabel(facility) }}</p>
          </div>
        </ng-template>
        </div>
    </div>
  </mat-card>

  <mat-card id="margin-bottom" style="border-radius: 0; border-bottom-left-radius: 4px; border-bottom-right-radius: 4px">
    <div *ngIf="seeHoursActive" class="exceptional-hours-details" style="color: var(--white-text-color)">
      <div *ngFor="let facility of facilities" style="margin-left: 5px">
        <div style="display: inline-flex; margin-bottom: 10px">
            <div *ngIf="facilityIsActive(facility); then exceptionalBlock else inactiveBlock">
            </div>
          <ng-template #exceptionalBlock>
            <span id="{{ facilityIsExceptionalClosing(facility) ? 'service-exceptional-active-fermeture' : 'service-exceptional-active'}}">
            {{ getFacilityDetailLabel(facility) }}
          </span>
          </ng-template>
          <ng-template #inactiveBlock>
            <span id="service-exceptional-inactive">
            {{ getFacilityDetailLabel(facility) }}
          </span>
          </ng-template>

          <div class="exceptional-slots">
            <div *ngIf="facilityIsActive(facility); then exceptionalDay else inactiveDay" style="display: contents"></div>
            <ng-template #exceptionalDay>
              <div *ngIf="facilityIsExceptionalClosing(facility); else details" class="restaurant-slots">
                <span id="service-exceptional-active-fermeture-details">Fermé</span>
              </div>
            </ng-template>
              <ng-template #details>
                <div *ngIf="is24h(facility); else detailsHours" class="restaurant-slots">
                  <span class="exceptional-slot">24h/24</span>
                </div>
              </ng-template>
            <ng-template #detailsHours>
                <div *ngFor="let slotD of getSlotsDetails(facility)" style="margin-right: 2%">
                  <div class="exceptional-slot">{{ slotD.beginHour }} - {{ slotD.endHour }}</div>
                </div>
              </ng-template>
            <ng-template #inactiveDay>
              <span class="exceptional-slot-inactive">Par défaut</span>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
    <mat-card-content>
      <a (click)="seeHours()"> {{ seeHoursLabel }} </a>
      <button id="edit-exceptional-hours-button" (click)="editExceptionalHours()" *ngIf="isUserRestaurantManager">Modifier</button>
    </mat-card-content>
  </mat-card>
</ng-template>
