import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PersistanceService } from '../../../service/persistance/persistance.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-manual-injection-infos-modal',
  templateUrl: './manual-injection-infos-modal.component.html',
  styleUrls: ['./manual-injection-infos-modal.component.scss'],
})
export class ManualInjectionInfosModalComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ManualInjectionInfosModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly persistanceService: PersistanceService,
    private readonly router: Router
  ) {}

  ngOnInit(): void {}

  closeModal(): void {
    this.dialogRef.close();
  }

  showFaq(): void {
    this.persistanceService.set('faq_return_path', this.router.url);
    this.router.navigateByUrl('/faq');
    this.closeModal();
  }
}
