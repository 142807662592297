<div class="item-detail">
  <div [class]="level == 1 ? 'principal-item' : 'additional-item'" [ngStyle]="{ 'padding-left': 'calc(' + level + ' * 15px)' }">
    <div class="first-line">
      <span class="quantity">
        {{ item?.qty }}
      </span>
      <span class="label">
        {{ item?.label }}
      </span>
      <span class="price">
        {{ level == 1 ? (getTotalAmount() | currency: 'EUR':'symbol':'1.2-2':'fr') : '- €' }}
      </span>
    </div>
    <div class="second-line">
      <span class="item-reference">{{ item?.productRef }}</span>
      <span>
        <app-excluded-ingredient-list [excludedIngredients]="item.excludedIngredients"></app-excluded-ingredient-list>
      </span>
    </div>
    <div class="third-line">
      <div *ngIf="item?.itemDishes.length > 0">
      <span>
        Vaisselle :
        <app-dish-list [dishes]="item.itemDishes"></app-dish-list>
      </span>
      </div>
    </div>
  </div>
  <div class="item-choices" *ngIf="item?.choices != null && item?.choices.length > 0">
    <div *ngFor="let choice of item.choices">
      <app-order-item-line [item]="choice" [level]="level + 1"></app-order-item-line>
    </div>
  </div>
  <div class="product-detail">
    <div *ngIf="item?.primaryOrderItem != null">
      <app-order-item-line [item]="item?.primaryOrderItem" [level]="level + 1"></app-order-item-line>
    </div>
    <div *ngIf="item?.secondaryOrderItem != null">
      <app-order-item-line [item]="item?.secondaryOrderItem" [level]="level + 1"></app-order-item-line>
    </div>
  </div>
</div>
