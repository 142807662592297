import { SlotDetails } from './slot-details.model';

export class Slot {
  /**
   * Day of week (1 to 7)
   */
  day: number;
  /**
   * Determine if the day is active
   */
  active: boolean;
  /**
   * slotPeriod of one day
   */
  slotDetails: SlotDetails[];
  /**
   * Variable to store if there is an exceptional hour.
   */
  exceptionalDay: boolean;
  /**
   * Variable to store if a config error happened in edit-pod-modal.
   */
  configError: string[];
  /**
   * Variable uses in the case where the slot can't activated (false by default)
   */
  activationError = false;

  /**
   * Variable used for knowing if an exceptional hour is for opening or closing
   */
  exceptionalClose: boolean;

  constructor(day: number, active: boolean, slotDetails: SlotDetails[]) {
    this.day = day;
    this.active = active;
    this.slotDetails = slotDetails;
  }
}
