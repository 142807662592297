import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Faq } from '../../models/faq/faq.model';
import { Question } from '../../models/faq/question.model';
import { PersistanceService } from '../../service/persistance/persistance.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss', './../../../assets/faq/css/styles.css'],
})
export class FaqComponent implements OnInit {
  faq: Faq;
  isAnswerView: boolean;
  currentQuestion: Question;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly persistanceService: PersistanceService,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.faq = new Faq(null);
    this.jsonToModel();
    this.isAnswerView = false;
  }

  /**
   * Gets FAQ content from json file and convert it in a model.
   */
  jsonToModel(): void {
    this.httpClient.get<Faq>('assets/faq/content.json').subscribe((data) => {
      this.faq = new Faq(data.categories);
    });
  }

  /**
   * Go the answer view of the selected question
   * @param question selected question
   */
  goAnswerView(question: Question): void {
    this.currentQuestion = question;
    this.isAnswerView = true;
  }

  /**
   * Return to the questions view
   */
  goQuestionsView(): void {
    this.isAnswerView = false;
  }

  /**
   * Close FAQ and return to the last page seen.
   */
  quitFaq(): void {
    this.router.navigateByUrl(this.persistanceService.get('faq_return_path'));
  }
}
