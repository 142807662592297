import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { environment } from '../../../environments/environment';
import { DayFilter } from '../../../models/order/day-filter.model';
import { OrderError } from '../../../models/order/order-error.model';
import { OrderStatus } from '../../../models/order/order-status.enum';
import { OrderType } from '../../../models/order/order-type.enum';
import { Order } from '../../../models/order/order.model';
import { Restaurant } from '../../../models/restaurant/restaurant.model';
import { OrderService } from '../../../service/order/order.service';
import { OrderErrorService } from '../../../service/orderError/order-error.service';
import { PersistanceService } from '../../../service/persistance/persistance.service';
import { RestaurantService } from '../../../service/restaurant/restaurant.service';

@Component({
  selector: 'app-orders-history',
  templateUrl: './orders-history.component.html',
  styleUrls: ['./orders-history.component.scss'],
  providers: [DatePipe],
})
export class OrdersHistoryComponent implements OnInit {
  currentRestaurant: Restaurant;
  orders: Order[];
  orderErrors: OrderError[];
  filteredOrders: Order[];

  dateOfFirstRequest: Date;
  page: number;
  nbPerPage = environment.settings.orderHistoryNumber;

  listLoading = false;

  searchedOrderNumber: string;
  searchedOrderStatus: OrderStatus;
  timeFilterOptions = [];
  selectedDurationFilter = 1;
  htmlMessage: string;

  channelFilter: string;
  salesChannels: OrderType[] = [];

  isSeeMore = true;
  isFirstCall = true;

  constructor(
    public datepipe: DatePipe,
    private readonly persistanceService: PersistanceService,
    private readonly orderService: OrderService,
    private readonly restaurantService: RestaurantService,
    private readonly orderErrorService: OrderErrorService
  ) {
    this.currentRestaurant = this.persistanceService.get('current_restaurant');

    this.htmlMessage = 'du {1} au {2}';
    const dateNow = new Date();
    this.timeFilterOptions.push({ duration: 1, text: this.getTimeFilterMessage(1, dateNow) });
    this.timeFilterOptions.push({ duration: 2, text: this.getTimeFilterMessage(2, dateNow) });
    this.timeFilterOptions.push({ duration: 7, text: this.getTimeFilterMessage(7, dateNow) });
  }

  ngOnInit(): void {
    this.dateOfFirstRequest = new Date();
    this.page = 0;
    this.salesChannels = this.restaurantService.getActivatedOrderTypeOfRestaurant(this.currentRestaurant.ref.toString());
    this.getOrdersHistory();
  }

  getOrdersHistory(): void {
    this.listLoading = true;
    forkJoin([
      this.orderService.getOrdersHistory(
        this.currentRestaurant.ref.toString(),
        this.dateOfFirstRequest,
        this.selectedDurationFilter,
        this.searchedOrderStatus,
        this.channelFilter as OrderType,
        this.page,
        this.nbPerPage,
        this.searchedOrderNumber
      ),
      this.orderErrorService.getOrderErrors(),
    ]).subscribe((rep) => {
      const orders = rep[0];
      const errors = rep[1];

      orders.orders.sort((order1, order2) => {
        return order1.orderedDate > order2.orderedDate ? -1 : 1; // sort by orderedDate desc
      });
      this.orders = orders.orders;
      if (this.channelFilter) {
        this.filteredOrders = this.orders.filter((order: Order) => order.type === this.channelFilter);
      } else {
        this.filteredOrders = this.orders;
      }
      if (orders.orders.length < this.nbPerPage) {
        this.isSeeMore = false;
      } else {
        this.isSeeMore = true;
      }
      this.manageDaySeparators(this.selectedDurationFilter, this.filteredOrders);
      this.orderErrors = errors;
      this.listLoading = false;
    });
  }

  /**
   * Call WS to get next page of orders and concat them to the previous ones.
   */
  showMoreOrders(): void {
    this.page = this.page + 1;
    this.listLoading = true;
    this.orderService
      .getOrdersHistory(
        this.currentRestaurant.ref.toString(),
        this.dateOfFirstRequest,
        this.selectedDurationFilter,
        this.searchedOrderStatus,
        this.channelFilter as OrderType,
        this.page,
        this.nbPerPage,
        this.searchedOrderNumber
      )
      .subscribe((_) => {
        this.orders = this.orders.concat(_.orders);
        if (this.channelFilter) {
          this.filteredOrders = this.orders.filter((order: Order) => order.type === this.channelFilter);
        } else {
          this.filteredOrders = this.orders;
        }
        if (_.orders.length < this.nbPerPage) {
          this.isSeeMore = false;
        } else {
          this.isSeeMore = true;
        }
        this.manageDaySeparators(this.selectedDurationFilter, this.filteredOrders);
        this.listLoading = false;
      });
  }

  private manageDaySeparators(duration: number, orders: Order[]): void {
    const dayFilters: DayFilter[] = [];
    dayFilters.push({
      // today
      name: null,
      startDate: this.getFilterDate(0),
      endDate: new Date(),
    });
    if (duration === 1) {
      const now = new Date();
      const startDate = new Date(now.setDate(now.getDate() - 1));
      dayFilters.push({
        // yesterday but in last 24 hours
        name: 'Hier',
        startDate,
        endDate: this.getFilterDate(0),
      });
    } else if (duration > 1) {
      dayFilters.push({
        name: 'Hier',
        startDate: this.getFilterDate(1),
        endDate: this.getFilterDate(0),
      });
    }
    for (let i = 2; i <= duration; i++) {
      dayFilters.push({
        name: 'Il y a ' + i + ' jours',
        startDate: this.getFilterDate(i),
        endDate: this.getFilterDate(i - 1),
      });
    }
    const dayAlreadySetted: string[] = [];
    orders.forEach((order) => {
      dayFilters.forEach((x) => {
        if (
          new Date(order.orderedDate).getTime() >= new Date(x.startDate).getTime() &&
          new Date(order.orderedDate).getTime() < new Date(x.endDate).getTime() &&
          dayAlreadySetted.indexOf(x.name) === -1
        ) {
          dayAlreadySetted.push(x.name);
          order.dayFilter = x;
        }
      });
    });
  }

  private getFilterDate(xDays: number): Date {
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    date.setDate(date.getDate() - xDays);
    return date;
  }

  getTimeFilterMessage(duration: number, now: Date): string {
    const tmpNow = new Date(now);
    const nbDays = duration > 1 ? `${duration} JOURS` : `${duration} JOUR`;
    const end = this.datepipe.transform(tmpNow, 'dd/MM/yy à HH:mm');
    const startDate = tmpNow.setDate(tmpNow.getDate() - duration);
    const start = this.datepipe.transform(startDate, 'dd/MM/yy à HH:mm');
    return this.formatString(this.htmlMessage, nbDays, start, end);
  }

  selectTime(): void {
    this.page = 0; // reset page when changing duration
    this.getOrdersHistory();
  }

  checkChannelState(event: any, el: any): void {
    event.preventDefault();
    if (this.channelFilter && this.channelFilter === el.value) {
      el.checked = false;
      this.channelFilter = null;
    } else {
      this.channelFilter = el.value;
      el.checked = true;
    }
    this.page = 0; // reset page
    this.getOrdersHistory();
  }

  formatString(str: string, ...val: string[]): string {
    for (let index = 0; index < val.length; index++) {
      str = str.replace(`{${index}}`, val[index]);
    }
    return str;
  }

  /**
   * gets the string value of an order type
   */
  getValueOfChannel(orderType: OrderType): string {
    return this.orderService.getOrderTypeValue(orderType);
  }

  getSelectedOptionText(duration: number): string {
    return this.timeFilterOptions.filter((_) => _.duration === duration)[0].text;
  }

  searchedOrderNumberChanged(searchedOrderNumber: string): void {
    if (this.searchedOrderNumber !== searchedOrderNumber) {
      this.searchedOrderNumber = searchedOrderNumber;
      this.page = 0;
      this.getOrdersHistory();
    }
  }

  searchedOrderStatusChanged(searchedOrderStatus: OrderStatus): void {
    this.isFirstCall = false;
    this.searchedOrderStatus = searchedOrderStatus;
    this.page = 0;
    this.getOrdersHistory();
  }

  showNoContent(): boolean {
    return (
      !this.salesChannels ||
      this.salesChannels.length === 0 ||
      ((!this.orders || this.orders.length === 0) &&
        (!this.searchedOrderNumber || this.searchedOrderNumber.length === 0) &&
        this.isFirstCall)
    );
  }
}
