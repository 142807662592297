import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-restaurant-additional-information-modal',
    templateUrl: './restaurant-additional-information-modal.component.html',
    styleUrls: ['./restaurant-additional-information-modal.component.scss'],
})
export class RestaurantAdditionalInformationModalComponent implements OnInit {
    constructor(
        public dialogRef: MatDialogRef<RestaurantAdditionalInformationModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    }

    ngOnInit(): void {
    }

    closeModal(): void {
        this.dialogRef.close();
    }
}
