import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Restaurant } from '../../models/restaurant/restaurant.model';
import { Role } from '../../models/role.enum';
import { UserInfo } from '../../models/user-info';
import { PersistanceService } from '../persistance/persistance.service';
import { RestaurantService } from '../restaurant/restaurant.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  userInfo: UserInfo;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly persistanceService: PersistanceService,
    private readonly restaurantService: RestaurantService
  ) {}

  /**
   * Call EDD WS to get user informations and get the roles and restaurants of the authentified person.
   */
  getUserInfo(): Observable<UserInfo> {
    const userInfos = this.persistanceService.get('user_infos');
    if (userInfos) {
      return of(userInfos);
    }
    return this.httpClient.get<UserInfo>(environment.settings.apiUrl + '/people/roles').pipe(
      tap((userInfo) => {
        this.userInfo = userInfo;
        this.persistanceService.set('user_infos', userInfo);
      })
    );
  }

  /**
   * Gets the restaurants of the user.
   */
  getUserRestaurants(): Observable<Restaurant[]> {
    const userRestaurants = this.persistanceService.get('user_restaurants');
    if (userRestaurants) {
      return of(userRestaurants);
    }

    return this.restaurantService.getRestaurantsByRefs(this.userInfo.restaurantRefs).pipe(
      tap((restaurants) => {
        this.persistanceService.set('user_restaurants', restaurants);
      })
    );
  }

  /**
   * Checks whether the user has a mono restaurant profile or not.
   */
  isMonoRestaurantProfile(): boolean {
    let monoRestaurant = false;
    this.getUserRestaurants().subscribe((restaurants) => (monoRestaurant = restaurants.length === 1));
    return monoRestaurant;
  }

  /**
   * Si COMMANDES + GEST REST > accès aux commandes
   * Si COMMANDES (only) > accès aux commandes
   * Si GEST REST (only) > accès à la gestion des PODs
   */
  getRestaurantPageDefaultView(): string {
    let userInfos;
    this.getUserInfo().subscribe((data) => (userInfos = data));
    if (userInfos) {
      // redirect the user depending on his roles
      if (
        userInfos.roles.indexOf(Role.ORDER_UPDATER) > -1 ||
        userInfos.roles.indexOf(Role.ORDER_VIEWER) > -1 ||
        userInfos.roles.indexOf(Role.MANAGER) > -1 ||
        userInfos.roles.indexOf(Role.RESTAURANT_VIEWER) > -1
      ) {
        return '/restaurant/dashboard';
      } else {
        return '/error-role';
      }
    } else {
      return '/error';
    }
  }
}
