<div *ngFor="let day of slots" style="display: inline-block">
  <div *ngIf="objectIsActive && day.active; then facilityOpen else facilityClose" class="facility-day-letter-list"></div>
  <ng-template #facilityOpen>
    <div id="facility-day-open" class="facility-day-letter-list">
    <p class="facility-day-letter">{{ dayAsChar(day.day) }}</p>
    </div>
  </ng-template>
  <ng-template #facilityClose>
    <div *ngIf="!day.exceptionalDay; else facilityExceptional" id="facility-day-close" class="facility-day-letter-list">
    <p class="facility-day-letter">{{ dayAsChar(day.day) }}</p>
  </div>
  </ng-template>
  <ng-template #facilityExceptional>
    <div id="facility-day-exceptional" class="facility-day-letter-list">
      <p class="facility-day-letter">{{ dayAsChar(day.day) }}</p>
    </div>
  </ng-template>
</div>
