import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { get } from 'scriptjs';
import { environment } from '../../../../environments/environment';
import { MultimediaView } from '../../../../models/multimedia/multimedia-view.model';
import { Multimedia } from '../../../../models/multimedia/multimedia.model';
import { RestaurantAddress } from '../../../../models/restaurant/restaurant-address.model';
import { RestaurantCoordinates } from '../../../../models/restaurant/restaurant-coordinates.model';
import { RestaurantMetadata } from '../../../../models/restaurant/restaurant-metadata.model';
import { Restaurant } from '../../../../models/restaurant/restaurant.model';
import { ErrorService } from '../../../../service/error/error.service';
import { ConsoleLoggerService } from '../../../../service/logger/console-logger.service';
import { MultimediaService } from '../../../../service/multimedia/multimedia.service';
import { PersistanceService } from '../../../../service/persistance/persistance.service';
import { RestaurantService } from '../../../../service/restaurant/restaurant.service';
import { DialogConfigUtilsService } from '../../../../service/utils/dialog.utils.service';
import { WoosmapService } from '../../../../service/woosmap/woosmap.service';
import { ConfirmModalComponent } from '../../../common/confirm-modal/confirm-modal.component';
import { RestaurantInformationPinDragModalComponent } from './restaurant-information-pin-drag-modal/restaurant-information-pin-drag-modal.component';
import {forkJoin, Observable} from "rxjs";
import {RestaurantData} from "../../../../models/restaurant/restaurant-data.model";

declare var woosmap;

@Component({
  selector: 'app-restaurant-information-edit-modal',
  templateUrl: './restaurant-information-edit-modal.component.html',
  styleUrls: ['./restaurant-information-edit-modal.component.scss'],
})
export class RestaurantInformationEditModalComponent implements OnInit {
  TVAPatternWithSpaces = "^[A-Z]{2}\\s[0-9]{2}\\s[0-9]{3}\\s[0-9]{3}\\s[0-9]{3}$";
  RCSPatternWithSpaces = "^RCS\\s[A-Z \\- \\s]+\\s[A-B]\\s[0-9]{3}\\s[0-9]{3}\\s[0-9]{3}$";
  TVAPatternWithoutSpaces = "^[A-Z]{2}[0-9]{2}[0-9]{3}[0-9]{3}[0-9]{3}$";
  RCSPatternWithoutSpaces = "^RCS[A-Z]+[A-B][0-9]{3}[0-9]{3}[0-9]{3}$";
  phoneSize = 10;

  restaurant: Restaurant;

  address: string;
  addressAlreadyExist: boolean;
  restaurantAddress: RestaurantAddress;
  zipCode: number;
  city: string;
  country: string;
  latitude: number;
  longitude: number;
  coordinates: RestaurantCoordinates;
  additionalInformation = '';
  maxAdditionalInformationLength = 255;
  phone: string;
  TVANumber: string;
  RCSNumber: string;
  about = '';
  maxAboutLength = 255;
  pictures: Array<MultimediaView> = [];

  isLoading = false;
  myMap;
  markerAddress;
  localities: any[];
  control = new FormControl('');

  picturesToSave: Array<Multimedia> = [];
  imagesToSave: string[] = [];
  private errorAddress: boolean;
  private errorMessageAddress: string;
  private errorLongitude: boolean;
  private errorMessageLongitude: string;
  private errorLatitude: boolean;
  private errorMessageLatitude: string;
  private errorPhoneNumber: boolean;
  private errorMessagePhoneNumber: string;
  private errorTVA: boolean;
  private errorMessageTVA: string;
  private errorRCS: boolean;
  private errorMessageRCS: string;


  constructor(
    public matDialog: MatDialog,
    public dialogRef: MatDialogRef<RestaurantInformationEditModalComponent>,
    private readonly persistanceService: PersistanceService,
    private readonly restaurantService: RestaurantService,
    private readonly errorService: ErrorService,
    private readonly CONSOLE_LOGGER: ConsoleLoggerService,
    private readonly multimediaService: MultimediaService,
    private readonly woosmapService: WoosmapService
  ) {
  }

  ngOnInit(): void {
    this.isLoading = true;
    // When the modal is shown, we want a fixed body
    document.body.style.overflow = 'hidden';
    this.restaurant = this.persistanceService.get('current_restaurant');
    get(`${environment.settings.woosmapUrl}?key=${environment.settings.woosmapKey}`, () => {
      this.initRestaurantInformation();
    });
    this.restaurantService.getRestaurantById((this.persistanceService.get('current_restaurant') as Restaurant).ref.toString()).subscribe(r => {
      this.restaurant = r; 
      this.isLoading = false;
    });
   
  }

  save(): void {
    this.validate();
    if (!this.formHasErrors()) {
      const title = 'Modification des informations restaurant</br><strong>Informations restaurant</strong>';
      const modalDialog = this.openConfirmModal(title, 'ENREGISTRER ET QUITTER', 'PRÉCÉDENT', 'save', '100%', false);
      modalDialog.afterClosed().subscribe((result) => {
        if (result) {
          this.isLoading = true;
          let observables: Observable<any>[] = this.savePictures();
          if (observables === undefined) {
            observables = [];
          }
          observables.push(this.saveMetadatas());
          observables.push(this.saveRestaurantInformation());
          observables.push(this.saveRestaurantAddress());
          forkJoin(observables).subscribe(value => {
            this.resetBodyScroll();
            this.dialogRef.close();
            this.isLoading = false;
          });
        }
      });
    }
  }

  private saveMetadatas(): Observable<any> {
    // tva, rcs, localisation_motorway, description
    const javaClass = 'java.lang.String';
    const metadatas: RestaurantMetadata[] = [];
    metadatas.push(new RestaurantMetadata('tva', javaClass, this.TVANumber));
    metadatas.push(new RestaurantMetadata('rcs', javaClass, this.RCSNumber));
    metadatas.push(new RestaurantMetadata('localisation_motorway', javaClass, this.additionalInformation));
    metadatas.push(new RestaurantMetadata('description', javaClass, this.about));
    return this.restaurantService.saveRestaurantMetadata(this.restaurant.ref.toString(), metadatas);
  }

  private saveRestaurantInformation(): Observable<any> {
    const restaurantData = new RestaurantData();
    restaurantData.phone = this.phone;
    restaurantData.coordinates = this.coordinates;
    return this.restaurantService.updateRestaurant(this.restaurant.ref.toString(), restaurantData);
  }

  private savePictures(): Observable<any>[] {
    const observables: Observable<any>[] = [];
    for (const multimedia of this.picturesToSave) {
      observables.push(this.savePicture(multimedia));
    }
    return observables;
  }

  private initRestaurantInformation(): void {
    this.restaurantService.getRestaurantInformationById(this.restaurant.ref.toString()).subscribe(
      (restaurantInformationTmp) => {
        this.isLoading = false;

        this.restaurantAddress = restaurantInformationTmp.restaurantAddress[0];
        this.address = restaurantInformationTmp?.restaurantAddress && restaurantInformationTmp?.restaurantAddress.length > 0 ? this.parseOnlyAddressFromRestaurantAddress(restaurantInformationTmp.restaurantAddress[0]) : "";
        this.addressAlreadyExist = this.address !== "";
        this.zipCode = restaurantInformationTmp?.restaurantAddress && restaurantInformationTmp?.restaurantAddress.length > 0 ? restaurantInformationTmp.restaurantAddress[0].zipCode : null;
        this.city = restaurantInformationTmp?.restaurantAddress && restaurantInformationTmp?.restaurantAddress.length > 0 ? restaurantInformationTmp.restaurantAddress[0].city : "";

        this.coordinates = restaurantInformationTmp?.coordinates ?? new RestaurantCoordinates();
        this.latitude = this.coordinates.latitude;
        this.longitude = this.coordinates.longitude;
        this.initMap(this.coordinates);
        this.phone = restaurantInformationTmp?.phone ?? "";
        this.getMetadatas(restaurantInformationTmp.metadatas);
        for (const picture of restaurantInformationTmp.pictures) {
          if (picture.types.includes('illustration0') || picture.types.includes('illustration1')) {
            this.pictures.push(picture);
          }
        }
      },
      (error) => {
        this.CONSOLE_LOGGER.error('error during update of restaurant informations', error);
        this.errorService.manageError(error);
        this.displayTechnicalErrorPopup();
      }
    );
  }

  private getMetadatas(metadatas: any): void {
    if (metadatas !== undefined) {
      this.TVANumber = metadatas.find((obj) => obj.key === 'tva')?.value ?? "";
      this.RCSNumber = metadatas.find((obj) => obj.key === 'rcs')?.value ?? "";
      this.additionalInformation = metadatas.find((obj) => obj.key === 'localisation_motorway')?.value ?? "";
      this.about = metadatas.find((obj) => obj.key === 'description')?.value ?? "";
    }
  }
  
  private displayTechnicalErrorPopup(): void {
    this.isLoading = false;
    const title = "En raison d'une erreur technique, vos paramètres n'ont pas été enregistrés. Veuillez réessayer ultérieurement";
    this.openConfirmModal(title, 'QUITTER', null, 'save-error', '94%', false, null);
  }

  // Return only the address, without zip code and city
  private parseOnlyAddressFromRestaurantAddress(restaurantAddress: RestaurantAddress): string {
    let address = '';
    address = address + restaurantAddress.address1;
    if (restaurantAddress.address2 && restaurantAddress.address2 !== '') {
      address = ' ' + address + restaurantAddress.address2;
    }
    if (restaurantAddress.address3 && restaurantAddress.address3 !== '') {
      address = ' ' + address + restaurantAddress.address3;
    }
    if (restaurantAddress.address4 && restaurantAddress.address4 !== '') {
      address = ' ' + address + restaurantAddress.address4;
    }
    return address;
  }

  closeModal(): void {
    const title = 'Si vous quittez le paramétrage, aucune modification ne sera enregistrée.';
    const modalDialog = this.openConfirmModal(title, 'QUITTER SANS SAUVEGARDER', 'ANNULER', 'close', '100%', false);
    modalDialog.afterClosed().subscribe((result) => {
      if (result) {
        this.resetBodyScroll();
        this.dialogRef.close();
      }
    });
  }

  initMap(coordinates: RestaurantCoordinates): void {
    if (coordinates) {
      try {
        const mapElement = document.getElementById('woosmap-edit');
        this.myMap = new woosmap.map.Map(mapElement, {
          center: {
            lat: coordinates?.latitude ?? 0,
            lng: coordinates?.longitude ?? 0,
          },
          zoom: 18,
        });

        this.markerAddress = new woosmap.map.Marker({
          position: this.myMap.getCenter(),
          icon: {
            url: environment.settings.woosmapDotMarkerUrl,
            scaledSize: {
              height: 64,
              width: 46
            },
          },
        });
        this.markerAddress.setMap(this.myMap);
      } catch (e) {
        this.CONSOLE_LOGGER.error('error during update of woosmap', e);
        this.errorService.manageError(e);
      }
    }
  }

  private openConfirmModal(
    title: string,
    confirmText: string,
    cancelText: string,
    panelClass: string,
    width: string,
    showWarningText: boolean,
    topText?: string,
    applyText?: string
  ): MatDialogRef<ConfirmModalComponent> {
    const dialogConfig = new DialogConfigUtilsService().getDialogConfig(
      'confirm-modal',
      title,
      confirmText,
      cancelText,
      panelClass,
      width,
      showWarningText,
      topText,
      applyText
    );
    return this.matDialog.open(ConfirmModalComponent, dialogConfig);
  }

  /**
   * Reset body to be able to scroll in it.
   */
  private resetBodyScroll(): void {
    // When the modal is hidden...
    document.body.style.overflow = 'auto';
  }

  getPicture(picture: MultimediaView): string {
    return environment.settings.mediaUrl + picture.url;
  }

  deletePicture(picture: MultimediaView): void {
    const title = "Suppression de l'image " + picture.label;
    const modalDialog = this.openConfirmModal(title, 'CONFIRMER LA SUPPRESSION', 'PRÉCÉDENT', 'delete', '100%', false);
    modalDialog.afterClosed().subscribe((result) => {
      if (result) {
        this.resetBodyScroll();
        this.multimediaService.deleteMultimedia('Restaurant', this.restaurant.ref.toString(), picture.id).subscribe(() => {
          this.pictures.splice(this.pictures.indexOf(picture), 1);
        });
      }
    });
  }

  deleteImage(index: number): void {
    this.imagesToSave.splice(index, 1);
    this.picturesToSave.splice(index, 1);
  }

  createPicture(files: FileList): void {
    const file = files.item(0);

    if (file) {
      if (this.isValidImageType(file)) {
        this.fileToByteArray(file).then((value) => {
          this.readImageSize(file).then((size) => {
            if (size.width >= 390 && size.height >= 268) {
              const multimedia = new Multimedia();
              multimedia.ref = this.restaurant.ref.toString();
              multimedia.label = file.name;
              multimedia.originalFilename = file.name;
              multimedia.buttonPresent = false;
              multimedia.uploadedFile = this.encodeByteArrayToJson(value);
              multimedia.mimeType = file.type;
              multimedia.activationDate = new Date();
              multimedia.expirationDate = null;
              multimedia.types = [this.getTypeString()];

              this.picturesToSave.push(multimedia);
            } else {
              this.imagesToSave.pop();
              this.openImageErrorModal('Les images ajoutées doivent faire au minimum :</br>390x268');
            }
          });
        });
      } else {
        this.openImageErrorModal('Extensions de fichier autorisées :</br>.png, .jpg.');
      }
    }
  }

  canAddImage(): boolean {
    return this.imagesToSave.length + this.pictures.length < 2;
  }

  private isValidImageType(file: File): boolean {
    const allowedTypes = ['image/jpeg', 'image/png'];
    return allowedTypes.includes(file.type);
  }

  private savePicture(multimedia: Multimedia): Observable<any> {
    return this.multimediaService.createMultimedia('Restaurant', this.restaurant.ref.toString(), multimedia);
  }

  private saveRestaurantAddress(): Observable<any> {
    if (this.restaurantAddress === undefined) {
      this.restaurantAddress = new RestaurantAddress();
    }
    this.restaurantAddress.zipCode = this.zipCode;
    this.restaurantAddress.city = this.city;
    this.restaurantAddress.address1 = this.address;
    this.restaurantAddress.address2 = "";
    this.restaurantAddress.address3 = "";
    this.restaurantAddress.address4 = "";
    this.restaurantAddress.country = this.country;
    this.restaurantAddress.addressType = "RESTAURANT";
    this.restaurantAddress.label = "RESTAURANT";
    if (this.addressAlreadyExist) {
      return this.restaurantService.updateRestaurantAddress(this.restaurant.ref.toString(), this.restaurantAddress.id, this.restaurantAddress);
    } else {
      return this.restaurantService.createRestaurantAddress(this.restaurant.ref.toString(), this.restaurantAddress);
    }
  }

  private readImageSize(file: File): Promise<{ width: number; height: number }> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        const img = new Image();
        img.onload = () => {
          const width = img.width;
          const height = img.height;
          resolve({ width, height });
        };
        img.src = e.target.result as string;
        this.imagesToSave.push(img.src);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  }

  private fileToByteArray(file: File): Promise<Uint8Array> {
    return new Promise<Uint8Array>((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const arrayBuffer = reader.result as ArrayBuffer;
        const byteArray = new Uint8Array(arrayBuffer);
        resolve(byteArray);
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    });
  }

  private encodeByteArrayToJson(byteArray: Uint8Array): string {
    const encodedArray = Array.from(byteArray)
      .map((byte) => String.fromCharCode(byte))
      .join('');
    return btoa(encodedArray);
  }

  private getTypeString(): string {
    if (this.pictures.length > 0 && this.pictures.some((multimedia) => multimedia.types.includes('illustration0'))) {
      return 'illustration1';
    } else {
      if (this.picturesToSave.length > 0 && this.picturesToSave.some((multimedia) => multimedia.types.includes('illustration0'))) {
        return 'illustration1';
      }
      return 'illustration0';
    }
  }

  private openImageErrorModal(title: string): void {
    const dialogConfig = new MatDialogConfig();
    // The user can't close the dialog by clicking outside its body
    dialogConfig.disableClose = true;
    dialogConfig.id = 'confirm-modal';
    dialogConfig.maxWidth = '100vw';
    dialogConfig.height = '224px';
    dialogConfig.width = '94%';
    dialogConfig.autoFocus = false;
    dialogConfig.panelClass = 'update-error';

    const confirmText = 'OK';

    dialogConfig.data = { title, confirmText, panelClass: dialogConfig.panelClass };
    this.matDialog
      .open(ConfirmModalComponent, dialogConfig)
      .afterClosed()
      .subscribe(() => {});
  }

  requestDetailsAddress(publicId: string): void {
    this.woosmapService.getDetailsAddress(publicId).subscribe((addressDetails) => {
      const lat = addressDetails.result.geometry.location.lat;
      this.coordinates.latitude = lat;
      this.latitude = lat;
      const lng = addressDetails.result.geometry.location.lng;
      this.coordinates.longitude = lng;
      this.longitude = lng;
      const position = {
        lat,
        lng,
      };
      if (addressDetails.result.geometry.viewport) {
        const viewport = addressDetails.result.geometry.viewport;
        const bounds = {
          east: viewport.northeast.lng,
          south: viewport.southwest.lat,
          north: viewport.northeast.lat,
          west: viewport.southwest.lng,
        };
        this.myMap.fitBounds(bounds);
        this.myMap.panTo(position);
      } else {
        this.myMap.setZoom(18);
        this.myMap.panTo(position);
      }

      this.markerAddress.setPosition(position);
      this.markerAddress.setMap(this.myMap);

      const streetNumber = this.getAddressComponent(addressDetails.result.address_components, 'street_number');
      const route = this.getAddressComponent(addressDetails.result.address_components, 'route');
      this.address = streetNumber + ' ' + route;
      this.city = this.getAddressComponent(addressDetails.result.address_components, 'locality');
      this.zipCode = addressDetails.result.address_components.find(
        (addressComponent) => addressComponent.types.includes('postal_codes') || addressComponent.types.includes('postal_code')
      ).long_name;
      this.country = this.getAddressComponent(addressDetails.result.address_components, 'country');
    });
  }

  private getAddressComponent(addressComponent: any[], componentName: string): any {
    const foundComponent = addressComponent.find((component) => component.types.includes(componentName));
    return foundComponent !== undefined ? foundComponent.long_name : '';
  }

  private displayNewLocation(): void {
    this.coordinates.latitude = this.latitude;
    this.coordinates.longitude = this.longitude;
    this.initMap(this.coordinates);
  }

  private clickToDrag(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = 'restaurant-information-pin-drag-modal';
    dialogConfig.width = '100%';
    dialogConfig.maxWidth = '96vw';
    dialogConfig.data = { restaurantCoordinates: this.coordinates };
    this.matDialog
      .open(RestaurantInformationPinDragModalComponent, dialogConfig)
      .afterClosed()
      .subscribe((response) => {
        if (response) {
          this.latitude = response._lat;
          this.longitude = response._lng;
          this.displayNewLocation();
        }
      });
  }

  private getLocalities(): void {
    this.woosmapService.autocompleteAddress(this.address).subscribe((response) => {
      this.localities = response.localities;
    });
  }

    selectLocality(locality): void {
        this.requestDetailsAddress(locality.public_id);
        this.localities = [];
    }

    updateAdditionalInformation($event: string): void {
        this.additionalInformation = $event;
    }

    updateAbout($event: string): void {
        this.about = $event;
    }

    formHasErrors(): any {
        return this.errorAddress || this.errorLatitude || this.errorLongitude || this.errorPhoneNumber || this.errorTVA || this.errorRCS;
    }

    private keyPressNumeric(event): boolean {
      const inp = String.fromCharCode(event.keyCode);

      if (/[0-9 ]/.test(inp)) {
        return true;
      } else {
        event.preventDefault();
        return false;
      }
    }

    private keyPressAlphaNumeric(event): boolean {
      const inp = String.fromCharCode(event.keyCode);

      if (/[a-zA-Z0-9 ]/.test(inp)) {
        return true;
      } else {
        event.preventDefault();
        return false;
      }
    }

    private validateAddress(): void {
      if (!this.address || this.address.length === 0) {
        this.errorAddress = true;
        this.errorMessageAddress = "L'adresse doit être renseignée";
      } else {
        this.errorAddress = false;
      }
    }

    private validateCoordinates(): void {
      if (!this.longitude || this.longitude === 0) {
        this.errorLongitude = true;
        this.errorMessageLongitude = "La longitude doit être correctement renseignée";
      } else {
        this.errorLongitude = false;
      }
      if (!this.latitude || this.latitude === 0) {
        this.errorLatitude = true;
        this.errorMessageLatitude = "La longitude doit être correctement renseignée";
      } else {
        this.errorLatitude = false;
      }
    }

    private validatePhoneNumber(): void {
      if (!this.phone) {
        this.errorPhoneNumber = true;
        this.errorMessagePhoneNumber = "Le numéro de téléphone doit être renseigné";
      } else if (!this.phone.startsWith('0')) {
        this.errorPhoneNumber = true;
        this.errorMessagePhoneNumber = "Le numéro de téléphone doit commencer par 0";
      } else if (this.phone.length !== this.phoneSize) {
        this.errorPhoneNumber = true;
        this.errorMessagePhoneNumber = "Le numéro de téléphone doit être composé de 10 chiffres";
      } else {
          this.errorPhoneNumber = false;
      }
    }

    private validateTVA(): void {
      if (this.TVANumber && !(this.TVANumber.match(this.TVAPatternWithSpaces) || this.TVANumber.match(this.TVAPatternWithoutSpaces))) {
        this.errorTVA = true;
        this.errorMessageTVA = "Le numéro de TVA doit avoir un format valide (ex : 'FR 12 342 522 658' ou 'FR12342522658')";
      } else {
        this.errorTVA = false;
      }
      }

    private validateRCS(): void {
      if (this.RCSNumber && !(this.RCSNumber.match(this.RCSPatternWithSpaces) || this.RCSNumber.match(this.RCSPatternWithoutSpaces))) {
        this.errorRCS = true;
        this.errorMessageRCS = "Le numéro RCS doit avoir un format valide (ex : 'RCS VILLE A 321 654 987' ou 'RCSVILLEA321654987')";
      }  else {
        this.errorRCS = false;
      }
    }

    private validate(): void {
      this.validateAddress();
      this.validateCoordinates();
      this.validatePhoneNumber();
      this.validateTVA();
      this.validateRCS();
    }
}
