<div class="order-pod">
  <div class="order-pod-header">
    <div class="first-line">
      <span class="subtitle">Choisi lors de la commande</span>
    </div>
    <div class="second-line">
      <ng-container *ngFor="let pod of podDatas">
        <div class="pod-element">{{ pod.name }} ({{ pod.value }})</div>
      </ng-container>
    </div>
  </div>
  <div class="order-pod-body">
    <div *ngIf="eligiblePods && eligiblePods.length > 0; else noAvailablePods">
      <div class="subtitle">Basculer vers</div>
      <mat-select (selectionChange)="selectNewPod()" [(ngModel)]="selectedPod" [disableOptionCentering]="true">
        <mat-option *ngFor="let pod of eligiblePods" [value]="pod">{{ pod.wording }}</mat-option>
      </mat-select>
    </div>
    <ng-template #noAvailablePods>
      <div class="error-section">
        <img alt="warning" src="assets/medias/warning.svg" class="warningImg" />
        <span class="error-text">Aucun point de retrait disponible</span>
      </div>
    </ng-template>
  </div>
</div>
