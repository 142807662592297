<div *ngIf="isLoading">
  <app-loader></app-loader>
</div>

<div [ngSwitch]="screenToShow">
  <div id="modal-header">
    <div class="modal-title">INDICATIONS LIVREURS</div>
    <a>
      <mat-icon id="close-modal-button" (click)="closeModal()">close</mat-icon>
    </a>
    <div class="screen-name">LAD McDo+</div>
    <div class="restaurant-name">
      {{ restaurant?.name }}
    </div>
  </div>

  <!-- Edition des informations pour les livreurs -->
  <div id="edit-information-body" *ngSwitchCase="'deliverer-infos'">
    <!-- Message d'information -->
    <div id="message" class="modal-section">
      <div id="message-content">
        <div id="customer-infos-status">
          <span class="title-text">Infos livreurs</span>
          <div id="characters-count" [ngClass]="restaurant?.comment?.length >= commentMaxLength ? 'full' : ''">
            <span>{{ commentMaxLength }} caractères max.</span>
            <circle-progress
              style="height: 32px"
              [percent]="textareaPercentage()"
              [maxPercent]="100"
              [radius]="140"
              [outerStrokeWidth]="30"
              [innerStrokeWidth]="15"
              [space]="-10"
              [outerStrokeColor]="restaurant?.comment?.length >= commentMaxLength ? 'var(--red)' : '#5d5d5d'"
              [innerStrokeColor]="'#e7e8ea'"
              [showSubtitle]="false"
              [showUnits]="false"
              [showTitle]="false"
              [animation]="true"
              [animationDuration]="300"
              [startFromZero]="false"
              [responsive]="true"
            ></circle-progress>
          </div>
        </div>

        <textarea
          [(ngModel)]="restaurant.comment"
          id="message-area"
          matInput
          [maxlength]="commentMaxLength"
          cdkTextareaAutosize
          #autosize="cdkTextareaAutosize"
          cdkAutosizeMinRows="3"
        ></textarea>
      </div>
    </div>
  </div>

  <!-- OU au click sur suivant (sauf profil mono-restaurant) -->
  <!-- Application des changements sur plusieurs restaurants  -->
  <app-edit-multi-restaurants
    style="box-sizing: border-box; overflow-x: hidden"
    *ngSwitchCase="'apply-to-restaurants'"
    [baseRestaurant]="restaurant.ref"
    [chosenRestaurants]="restaurantsToSave"
    [modalName]="'edit-deliverer-infos-modal-component'"
    [isLADOrMcDelivery]="true"
    [facilityType]="facilityType.MC_DELIVERY"
  ></app-edit-multi-restaurants>

  <div *ngSwitchCase="'deliverer-infos'" class="modal-footer" id="modal-footer">
    <div class="footer-content">
      <button id="footer-next-button" (click)="next()">Suivant</button>
    </div>
  </div>
  <div *ngSwitchCase="'apply-to-restaurants'" class="modal-footer two-btn" id="modal-footer">
    <div class="footer-content">
      <button id="footer-next-button" (click)="next()">Suivant</button>
      <button id="footer-back-button" (click)="backToEditInfos()">Précédent</button>
    </div>
  </div>
</div>
