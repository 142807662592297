import { HttpResponse } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { ConnectService } from '../connect/connect.service';
import { ErrorService } from './error.service';
import { ConsoleLoggerService } from '../logger/console-logger.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerOauthService {
  constructor(
    private readonly connectService: ConnectService,
    private readonly router: Router,
    private readonly ngZone: NgZone,
    private readonly errorService: ErrorService,
    private readonly CONSOLE_LOGGER: ConsoleLoggerService
  ) {}

  handleError(err: HttpResponse<any>): Observable<any> {
    this.CONSOLE_LOGGER.error('Error handling oauth', err);

    if ([403, 404, 500].indexOf(err.status) >= 0) {
      this.CONSOLE_LOGGER.error('erreur ' + err.status);
    }
    if (err.status === 401) {
      // try to refresh token or redirect to connect login page if we are not trying to delete oauth token
      this.connectService.refreshToken(err.url.toLocaleLowerCase() !== environment.settings.connect.manageTokenEndpoint);
    }

    this.errorService.manageError(err);

    this.ngZone.run(() => this.router.navigate(['/error'])).then();
    return throwError(err);
  }
}
