import { SlotDetails } from '../slot-details.model';

export class FacilityExceptionalHours {
  /**
   * Unique reference of the facility.
   */
  facilityRef: string;
  /**
   * status of the facility to save or not with WS
   */
  active: boolean;
  /**
   * List of exceptional slots of the facility.
   */
  slotsDetails: SlotDetails[];
  /**
   * Active if there is an exceptional close for a facility
   */
  exceptionalClose: boolean;
}
