import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Facility } from '../../../../models/facility/facility.model';
import { Pod } from '../../../../models/pod/pod.model';
import { TimeDependency } from '../../../../models/pod/timeDependency.enum';
import { Restaurant } from '../../../../models/restaurant/restaurant.model';
import { FacilityService } from '../../../../service/facility/facility.service';
import { PersistanceService } from '../../../../service/persistance/persistance.service';
import { PodService } from '../../../../service/pod/pod.service';
import { SlotService } from '../../../../service/slot/slot.service';
import { UserService } from '../../../../service/user/user.service';
import { ConfirmModalComponent } from '../../../common/confirm-modal/confirm-modal.component';
import { ErrorService } from '../../../../service/error/error.service';
import { ConsoleLoggerService } from '../../../../service/logger/console-logger.service';
import {DialogConfigUtilsService} from "../../../../service/utils/dialog.utils.service";

@Component({
  selector: 'app-edit-pod-modal',
  templateUrl: './edit-pod-modal.component.html',
  styleUrls: ['./edit-pod-modal.component.scss'],
})
export class EditPodModalComponent implements OnInit {
  podCopy: Pod;
  restaurant: Restaurant;
  screenToShow: string;
  restaurantsToSave: string[] = new Array();
  isLoading = false;
  oneDayActive: boolean;
  labelShow: string;

  facility: Facility;
  private readonly applyToRestaurants = 'apply-to-restaurants';

  //NOSONAR
  constructor(
    private readonly userService: UserService, //NOSONAR
    private readonly persistanceService: PersistanceService, //NOSONAR
    private readonly podService: PodService, //NOSONAR
    public dialogRef: MatDialogRef<EditPodModalComponent>, //NOSONAR
    @Inject(MAT_DIALOG_DATA) public data: any, //NOSONAR
    public matDialog: MatDialog, //NOSONAR
    private readonly datePipe: DatePipe, //NOSONAR
    private readonly slotService: SlotService, //NOSONAR
    private readonly facilityService: FacilityService, //NOSONAR
    private readonly errorService: ErrorService,
    private readonly CONSOLE_LOGGER: ConsoleLoggerService
  ) {}

  ngOnInit(): void {
    // When the modal is shown, we want a fixed body
    document.body.style.overflow = 'hidden';
    // store a copy of the chosen POD to make modifications on it
    this.podCopy = this.podService.clonePod(this.data.currentPod);

    this.screenToShow = 'pod-infos';
    this.restaurant = this.persistanceService.get('current_restaurant');
    if (this.restaurant === undefined) {
      this.CONSOLE_LOGGER.error('restaurant is undefined !! see what to do in this case !');
    }
    this.restaurantsToSave.push(this.restaurant.ref.toString());
    this.oneDayActive = this.slotService.hasActiveOneDay(this.podCopy.slots);
    // ADD call facility
    if (this.podCopy.timeDependency !== TimeDependency.USE_FACILITY_TIME) {
      this.facilityService.getFacilities(this.restaurant.ref).subscribe((facilities) => {
        this.facility = this.facilityService.getFacilityByRef(facilities, this.podCopy.facilityId);
        if (this.facility && this.facility.useParentTime) {
          this.facility = this.facilityService.retrieveFacilityParent(facilities, this.facility.reference);
        }
      });
    }
    this.labelShow = 'point de retrait';
  }

  /**
   * When clicking on cross in right top corner it show a popup to confirm the exit of POD settings.
   */
  closeModal(): void {
    const title = 'Si vous quittez le paramétrage, aucune modification ne sera enregistrée.';
    const modalDialog = this.openConfirmModal(title, 'QUITTER SANS SAUVEGARDER', 'ANNULER', 'close', '100%', false);

    modalDialog.afterClosed().subscribe((result) => {
      if (result) {
        if (
          this.data.currentPod.restaurantActive &&
          !this.slotService.hasActiveOneDay(this.data.currentPod.slots) &&
          this.podCopy.timeDependency !== TimeDependency.USE_FACILITY_TIME
        ) {
          // if pod is non-active when click.
          this.data.currentPod.restaurantActive = !this.data.currentPod.restaurantActive;
        }
        this.dialogRef.close(this.data.currentPod);
        this.resetBodyScroll();
      }
    });
  }

  /**
   * When clicking on next button :
   *    - if we are in mono-restaurant profile or on 2nd screen (apply-to-restaurants) => show save popup and save POD to EDD.
   *    - else show the 2nd screen (apply-to-restaurants) to allow user to save configuration on several restaurants.
   */
  next(): void {
    this.oneDayActive = this.slotService.hasActiveOneDay(this.podCopy.slots);
    if (this.validatePodInformation()) {
      let confirmText = 'ENREGISTRER';
      let applyText = null;
      if (!(this.userService.isMonoRestaurantProfile() || this.screenToShow === this.applyToRestaurants)) {
        applyText = 'APPLIQUER À PLUSIEURS';
      } else {
        confirmText = 'ENREGISTRER ET QUITTER';
      }
      let title = `Modification du ${this.labelShow} <b>${this.getWording()}</b>`;
      const topText = this.getLastUpdateMessage();
      const modalDialog = this.openConfirmModal(title, confirmText, 'PRÉCÉDENT', 'save', '94%', false, topText, applyText);
      modalDialog.afterClosed().subscribe((message) => {
        if (message === true) {
          this.isLoading = true;
          this.podService.updatePodForRestaurants(this.podCopy, this.restaurantsToSave).subscribe(
            (updatedPod) => {
              this.isLoading = false;
              this.resetBodyScroll();
              this.dialogRef.close(updatedPod);
            },
            (error) => {
              this.CONSOLE_LOGGER.error('error during pod update !', error);
              this.errorService.manageError(error);
              this.isLoading = false;
              title = 'En raison d’une erreur technique, vos paramètres n’ont pas été enregistrés. Veuillez réessayer ultérieurement';
              this.openConfirmModal(title, 'QUITTER', null, 'save-error', '94%', false, null);
            }
          );
        }
        if (message === this.applyToRestaurants) {
          this.screenToShow = this.applyToRestaurants;
          document.getElementById('edit-pod-modal-component').scrollTop = 0;
          this.isLoading = false;
        }
      });
    }
  }

  /**
   * When clicking on back button it goes back to edit information screen and scroll to top.
   */
  backToEditInfos(): void {
    this.restaurantsToSave = [this.restaurant.ref.toString()]; // reset restaurants to save on back
    this.screenToShow = 'pod-infos';
    document.getElementById('edit-pod-modal-component').scrollTop = 0;
  }

  private openConfirmModal(
    title: string,
    confirmText: string,
    cancelText: string,
    panelClass: string,
    width: string,
    showWarningText: boolean,
    topText?: string,
    applyText?: string
  ): MatDialogRef<ConfirmModalComponent, any> {
    const dialogConfig = new DialogConfigUtilsService().getDialogConfig('confirm-modal', title, confirmText, cancelText, panelClass, width, showWarningText, topText, applyText);

    return this.matDialog.open(ConfirmModalComponent, dialogConfig);
  }

  /**
   * Reset body to be able to scroll in it.
   */
  private resetBodyScroll(): void {
    // When the modal is hidden...
    document.body.style.overflow = 'auto';
  }

  private validatePodInformation(): boolean {
    // Valentin TimeDependency ?
    const tmpPod = this.podCopy as Pod;
    if (tmpPod.restaurantDescription.length > 140) {
      return false;
    }
    if (tmpPod.timeDependency === TimeDependency.USE_FACILITY_TIME) {
      // le pod depend des horaires du service associé, on ne check pas les horaires
      return true;
    }

    const isValid = this.slotService.validateSlotInformation(tmpPod.slots, tmpPod.restaurantActive && tmpPod.nationalActive);

    if (tmpPod.restaurantActive) {
      return isValid && this.slotService.respectFacilitySlot(this.facility, tmpPod.slots);
    }

    return isValid;
  }

  /**
   * This method returns the message for last update (if exists)
   */
  getLastUpdateMessage(): string {
    return this.podCopy.lastUpdatedDate
      ? `Modifié le ${this.datePipe.transform(this.podCopy.lastUpdatedDate, 'dd/MM/yyyy')} par ${this.podCopy.lastUpdatedPersonType}`
      : '';
  }

  getWording(): string {
    if (this.podCopy.wording === 'Kiosk') {
      return 'Borne de commande';
    } else {
      return this.podCopy.wording;
    }
  }
}
