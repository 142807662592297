<div id="mcdelivery-content">
  <app-facility
    [facilityType]="facilityType.MC_DELIVERY"
    [parentIsActive]="true"
    [isSuperParent]="true"
    [facility]="facility"
    [facilitiesList]="facilitiesList"
    [exceptionalHoursTabInput]="exceptionalHoursTabInput"
    (reloadFacilities)="emitReloadFacilities()"
  >
  </app-facility>

  <div class="deliveryPartners" *ngIf="facility.childrenFacilities.length != 0">
    <p class="title">LAD MCDO+</p>
    <p class="highTitle">Partenaires</p>

    <div *ngFor="let childFacility of facility.childrenFacilities">
      <app-facility
        *ngIf="childFacility.reference != facilityType.EAT_IN.valueOf()"
        [parentIsActive]="facilityActive()"
        [facility]="childFacility"
        [isSuperParent]="false"
        [facilityType]="getFacilityType(childFacility)"
        (changeChildfacilityStatus)="changeChildFacilityStatus($event)"
        [enabledSwitch] = "getChildFacilityActivationAuthorization(childFacility)"
        [importantInformation]="getImportantInformation(childFacility)"
      ></app-facility>
    </div>

    <app-mcdelivery-settings [mcdeliveryFacility]="facility"></app-mcdelivery-settings>
  </div>
</div>
