import { HttpClient } from '@angular/common/http';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../environments/environment';
import { OnBoarding } from '../../models/onboarding/onboarding.model';
import { OnBoardingRole } from '../../models/onboarding/role.model';
import { UserInfo } from '../../models/user-info';
import { UserService } from '../../service/user/user.service';

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss'],
})
export class OnboardingComponent implements OnInit, OnDestroy {
  content: OnBoarding;
  carouselHeight: string;
  carouselWidth: string;
  userInfos: UserInfo;

  private readonly COOKIE_EXPIRE = 365;

  constructor(
    private readonly cookieService: CookieService,
    private readonly httpClient: HttpClient,
    private readonly router: Router,
    private readonly userService: UserService
  ) {
    document.body.classList.add('bg-green');
  }

  ngOnInit(): void {
    if (this.cookieService.get('onboarding-seen') === environment.onboardingVersion) {
      this.router.navigateByUrl('restaurant-list');
    }
    this.userService.getUserInfo().subscribe((data) => (this.userInfos = data));

    this.content = new OnBoarding(null);
    this.jsonToModel();

    this.updateHeightAndWidth();

    this.cookieService.set('onboarding-seen', environment.onboardingVersion, this.COOKIE_EXPIRE);
  }

  ngOnDestroy(): void {
    document.body.classList.remove('bg-green');
  }

  /**
   * Method triggered when user is resizing the window.
   */
  @HostListener('window:resize')
  onResize(): void {
    this.updateHeightAndWidth();
  }

  /**
   * Calculate the appropriate carousel height based on screen size.
   */
  getCarouselHeight(): string {
    const height = window.innerHeight;
    if (height < 600) {
      return '45vh';
    } else if (height < 650) {
      return '50vh';
    } else if (height < 750) {
      return '60vh';
    } else if (height < 850) {
      return '60vh';
    } else if (height < 1000) {
      return '65vh';
    } else {
      return '70vh';
    }
  }

  /**
   * Calculate the appropriate carousel height based on screen size.
   */
  getCarouselWidth(): string {
    const width = window.innerWidth;
    if (width < 400) {
      return '80vw';
    } else if (width < 1400) {
      return '30vw';
    } else {
      return '20vw';
    }
  }

  /**
   * Redirect to the restaurant list page.
   */
  goRestaurantList(): void {
    document.body.classList.remove('bg-green');
    this.router.navigateByUrl('restaurant-list');
  }

  /**
   * Converts the json file to the associated OnBoarding model.
   */
  jsonToModel(): void {
    this.httpClient.get<OnBoarding>('assets/onboarding/images.json').subscribe((data) => {
      this.content = new OnBoarding(data.images.filter((image) => this.containsRole(image.roles)));
    });
  }

  /**
   * Checks if an image contains at least one of the current roles
   */
  containsRole(roles: OnBoardingRole[]): boolean {
    for (const role of roles) {
      if (this.userInfos.roles.includes(role.name)) {
        return true;
      }
    }
    return false;
  }

  updateHeightAndWidth(): void {
    const width = window.innerWidth;
    this.carouselHeight = '65vh';
    if (width < 500) {
      this.carouselWidth = '80vw';
    } else if (width < 1400) {
      this.carouselWidth = '30vw';
    } else {
      this.carouselWidth = '20vw';
    }
  }
}
