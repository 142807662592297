<div
  id="clientInfos"
  [ngStyle]="{ 'background-color': backgroundColor ? backgroundColor : '#f6f6f6' }"
  [ngClass]="withHours ? 'exceptional' : ''"
>
  <span id="bulleInfos" [ngStyle]="{ 'background-color': infoBulleColor }" class="infobulle">i</span>
  <div class="message-content">
    <p [ngClass]="withHours ? 'specialmessage' : 'message'">{{ message }}</p>
    <span *ngIf="withHours" id="see-hours" (click)="showExceptionalHours()">
      <a style="text-decoration: underline">Voir les horaires</a>
    </span>
  </div>
</div>
