<div id="search-header" [style.width.px]="modalInnerWidth()">
  <div id="text-infos">
    <span id="optional">OPTIONNEL</span>
    <span id="apply-to">Appliquer également à</span>
  </div>
  <div class="search-restaurant" id="search-restaurant">
    <input
      matInput
      type="search"
      placeholder="{{ inputPlaceholder }}"
      [(ngModel)]="inputSearch"
      (ngModelChange)="onEnterText()"
      id="search-input-restaurant"
    />
    <button mat-icon-button id="clear-button-restaurant" *ngIf="inputSearch" (click)="onDeleteText()">
      <mat-icon>close</mat-icon>
    </button>
    <button mat-icon-button id="search-button-restaurant">
      <mat-icon>search</mat-icon>
    </button>
  </div>
</div>

<div id="list-content">
  <div *ngIf="!inputSearch && filteredRestaurants && filteredRestaurants.length > 2" id="restaurant-list" #allRestaurantsDiv>
    <div class="restaurant-list-line">
      <img src="../../../../assets/medias/all-restaurants-logo.svg" alt="mcdo_all_restaurants_logo" class="all-restaurants-icon" />

      <div style="display: inline-block">
        <span>Tous mes restaurants</span>
      </div>
      <mat-checkbox
        id="all-restaurants-checbox"
        [(ngModel)]="allChecked"
        (change)="selectAllRestaurants()"
        class="select-checkbox-restaurant"
      ></mat-checkbox>
    </div>
  </div>

  <ng-container *ngIf="filteredRestaurants && filteredRestaurants.length; else noRestaurants">
    <div *ngFor="let restaurant of filteredRestaurants" #restaurantList>
      <div id="restaurant-list">
        <div class="restaurant-list-line">
          <div class="restaurant-list-icon-background">
            <span class="restaurant-list-icon-helper"></span
            ><img src="../../../../assets/medias/logo.svg" alt="mcdo_logo" class="restaurant-list-icon" />
          </div>
          <div class="restaurant-list-id-background">
            <span class="restaurant-list-id">{{ restaurant.ref }}</span>
          </div>
          <div style="display: inline-block">
            <span>{{ restaurant.name }}</span>
          </div>
          <mat-checkbox
            class="select-checkbox-restaurant"
            [checked]="isRestaurantCheck(restaurant.ref)"
            (change)="selectRestaurant(restaurant.ref.toString())"
          ></mat-checkbox>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template #noRestaurants>
    <div class="restaurant-list-empty" [style.height.px]="windowInnerHeight()">
      <div class="no-result-img">
        <div class="empty-image-div">
          <img src="../../../../assets/medias/restaurants-no-result.svg" alt="mcdo_list_empty" class="restaurant-list-empty-icon" />
        </div>
        <p class="no-result-text">Aucun restaurant trouvé</p>
      </div>
    </div>
  </ng-template>
</div>
