<div *ngIf="isLoading; else loader">
  <app-loader></app-loader>
</div>

<ng-template #loader>
  <div id="manual-injection">
    <div class="content">
      <div class="settings">
        <div class="left-side">
          <span class="settings-label">Click & Ready</span>
        </div>
        <div
          id="{{ restaurant.injectionConfig.nationalManualInjection ? 'switch-national-activate' : 'switch-national-desactivate' }}"
          class="right-side"
          [ngClass]="isUserRestaurantManager ? '' : 'right-side-noManager'"
        >
          <label class="switch-label">{{ getInjectionStatusLabel(restaurant.injectionConfig.manualInjection) }}</label>
          <div *ngIf="restaurant.injectionConfig.nationalManualInjection && isUserRestaurantManager" class="switch">
            <label>
              <input type="checkbox" [(ngModel)]="restaurant.injectionConfig.manualInjection" (change)="manualInjectionStatusChanged()" />
              <span class="slider"></span>
            </label>
          </div>
        </div>
      </div>

      <div class="clientInfos" *ngIf="!restaurant.injectionConfig.nationalManualInjection">
        <span class="infobulle red">i</span>
        <p>En raison d'une mesure nationale, l'activation n'est pas autorisée.</p>
      </div>
    </div>
  </div>
  <!-- add "or restaurant.injectionConfig.nationalManualInjection" ? -->
  <app-facility
    *ngIf="restaurant.injectionConfig.manualInjection && restaurant.injectionConfig.nationalManualInjection"
    [facility]="rushHoursFacility"
    [isSuperParent]="false"
    [isRushHours]="true"
    [facilityType]="facilityType.RUSH"
    [parentIsActive]="true"
    [facilityParentType]="facilityType.RUSH"
  ></app-facility>

  <div id="sat-injection">
    <div class="content">
      <div class="settings">
        <div class="left-side">
          <span class="settings-label">C&C Service à table</span>
        </div>
        <div class="sat-injection-status">
          <label class="switch-label">{{ getInjectionStatusLabel(restaurant.injectionConfig.satInjection) }}</label>
          <div *ngIf="isUserRestaurantManager" class="status">
            <span *ngIf="getSatInjectionStatus(); else inactive">
                <svg
                    width="8px"
                    height="8px"
                    viewBox="0 0 8 8"
                    version="1.1"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                      d="M4 0C1.792 0 0 1.792 0 4C0 6.208 1.792 8 4 8C6.208 8 8 6.208 8 4C8 1.792 6.208 0 4 0Z"
                      id="Shape"
                      fill="#305e42"
                      stroke="none"
                  />
                </svg>
              </span>
            <ng-template #inactive>
                <span>
                  <svg
                      width="8px"
                      height="8px"
                      viewBox="0 0 8 8"
                      version="1.1"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                        d="M4 0C1.792 0 0 1.792 0 4C0 6.208 1.792 8 4 8C6.208 8 8 6.208 8 4C8 1.792 6.208 0 4 0Z"
                        id="Shape"
                        fill="#c4c4c4"
                        stroke="none"
                    />
                  </svg>
                </span>
            </ng-template>
          </div>
        </div>
      </div>

      <div class="clientInfos" *ngIf="!restaurant.injectionConfig.nationalManualInjection">
        <span class="infobulle red">i</span>
        <p>En raison d'une mesure nationale, l'activation n'est pas autorisée.</p>
      </div>
    </div>
  </div>
</ng-template>
