<!-- HEADER -->
<div id="modal-header">
  <div class="modal-title">Gestion page locale</div>
  <a>
    <mat-icon id="close-modal-button" (click)="closeModal()">close</mat-icon>
  </a>
  <div class="consumption-type-name">A propos</div>
</div>

<div id="modal-body">
  <div>
    <div class="injection-wording">A propos du restaurant</div>
    <p>
      Ce champ correspond à une courte description de votre restaurant. Elle est affichée sur votre page locale ainsi que dans les moteurs de recherche internet.
    </p>
  </div>
</div>
