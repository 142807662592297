import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ExceptionalHoursView } from '../../../../models/exceptionalHoursView/exceptional-hours-view.model';
import { Facility } from '../../../../models/facility/facility.model';
import { Role } from '../../../../models/role.enum';
import { SlotDetails } from '../../../../models/slot-details.model';
import { FacilityService } from '../../../../service/facility/facility.service';
import { SlotService } from '../../../../service/slot/slot.service';
import { UserService } from '../../../../service/user/user.service';
import { EditExceptionalHoursConfiguredModalComponent } from '../../edit-exceptional-hours-configured-modal/edit-exceptional-hours-configured-modal.component';

@Component({
  selector: 'app-exceptional-hours-configured',
  templateUrl: './exceptional-hours-configured.component.html',
  styleUrls: ['./exceptional-hours-configured.component.scss'],
})
export class ExceptionalHoursConfiguredComponent implements OnInit {
  @Input() exceptionalHour: ExceptionalHoursView;
  @Input() existingExceptionalHours: ExceptionalHoursView[];
  @Input() facilities: Facility[];

  @Output() exceptionalHoursUpdate: EventEmitter<boolean> = new EventEmitter<boolean>();

  isLoading: boolean;
  isDuration: boolean;
  seeHoursActive = false;
  seeHoursLabel = 'Voir les horaires';

  isUserRestaurantManager = false;

  constructor(
    public matDialog: MatDialog,
    private readonly userService: UserService,
    private readonly slotService: SlotService,
    private readonly facilityService: FacilityService
  ) {}

  ngOnInit(): void {
    this.isDuration = this.exceptionalHour.endDate != null;
    this.checkUserRole();
    this.isLoading = false;
    this.sortFacilities();
  }

  seeHours(): void {
    this.seeHoursActive = !this.seeHoursActive;
    this.seeHoursLabel = this.seeHoursActive ? 'Voir moins' : 'Voir les horaires';
  }

  /**
   * Check if the user has edit role or not
   */
  private checkUserRole(): void {
    this.userService.getUserInfo().subscribe((userInfos) => {
      if (userInfos) {
        this.isUserRestaurantManager = userInfos.roles.includes(Role.MANAGER);
      }
    });
  }

  editExceptionalHours(): void {
    const dialogConfig = new MatDialogConfig();
    // The user can't close the dialog by clicking outside its body
    dialogConfig.disableClose = true;
    dialogConfig.id = 'restaurant-exceptional-hours-modal';
    dialogConfig.maxWidth = '100vw';
    dialogConfig.height = '96%';
    dialogConfig.width = '94%';
    dialogConfig.data = {
      exceptionalHours: this.exceptionalHour,
      facilities: this.facilities,
      existingExceptionalHours: this.existingExceptionalHours,
    };

    this.matDialog
      .open(EditExceptionalHoursConfiguredModalComponent, dialogConfig)
      .afterClosed()
      .subscribe((updateFacility) => {
        this.exceptionalHoursUpdate.emit(true);
      });
  }

  slotIsActive(slot: SlotDetails[]): boolean {
    return slot[0] != null;
  }

  facilityIsActive(facilityTmp: Facility): boolean {
    return this.exceptionalHour.slots.some((facilityToSome) => facilityToSome.facilityRef === facilityTmp.reference);
  }

  facilityIsExceptionalClosing(facilityTmp: Facility): boolean {
    return this.exceptionalHour.slots.some(
      (facilityToSome) => facilityToSome.facilityRef === facilityTmp.reference && facilityToSome.exceptionalClose && !facilityToSome.active
    );
  }

  getFacilityLegendLabel(facility: Facility): string {
    return this.facilityService.getExceptionalFacilityLegendLabel(facility.reference);
  }

  getFacilityDetailLabel(facility: Facility): string {
    return this.facilityService.getExceptionalFacilityDetailLabel(facility.reference);
  }

  getSlotsDetails(facilityTmp: Facility): SlotDetails[] {
    const exceptionalHourTmp = this.exceptionalHour.slots.find((x) => x.facilityRef === facilityTmp.reference);
    if (exceptionalHourTmp) {
      return exceptionalHourTmp.slotsDetails;
    }
    return [];
  }

  /**
   * Sort the facilities by its exceptional hours position
   */
  sortFacilities(): void {
    const tupleArray = [];
    for (const facility of this.facilities) {
      tupleArray.push(facility);
    }

    tupleArray.sort((a, b) => {
      return a.exceptionalHoursPosition - b.exceptionalHoursPosition;
    });

    this.facilities = tupleArray;
  }

  /**
   * Check if the facility is opened 24h/24 on exceptional hours
   * @param facility the facility to check
   */
  public is24h(facility: Facility): boolean {
    if (facility === undefined || facility === null) {
      return false;
    }
    const slotDetails = this.getSlotsDetails(facility);
    return this.slotService.is24h(slotDetails);
  }
}
