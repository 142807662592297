<div (click)="onOrderClicked()" [ngStyle]="{color: '#00001e'}" class="order-line">
    <div id="order-content">
        <div id="first-line">
            <span class="order-number"> C&R {{ order.orderNumber }}</span>
            <span class="order-hour"> {{ order?.requestInjectedDate | date: 'HH:mm' }}</span>
            <span class="order-price"> {{ this.orderService.getTotalAmount(order) | currency: 'EUR':'symbol':'1.2-2':'fr' }}</span>
            <span [ngClass]="{'center-right-element' : !orderHasError}" class="order-parking-place" *ngIf="order.clickAndReadyParkingStatus == 'PARKED'">
                <span class="order-parking-place-detail"
                      *ngIf="order.collectingDetails.parkingSpace != 'NO_PLACE'">P {{ order.collectingDetails.parkingSpace }}</span>
                <span *ngIf="order.collectingDetails.parkingSpace == 'NO_PLACE'"> <img class="no-parking-img"
                                                                                       alt="no_parking_grey"
                                                                                       src="assets/images/noparking_grey.svg"></span>
            </span>
            <span [ngClass]="{'center-right-element' : !orderHasError}" class="order-parking-eta"
                  *ngIf="order.clickAndReadyParkingStatus == 'INJECTED' && order.collectingDetails.geofencingEta !== undefined">ETA {{ order.collectingDetails.geofencingEta * 1000 | date: 'm'  }}
                min</span>
        </div>
        <div id="last-line">
            <div [ngClass]="order.clickAndReadyParkingStatus.toLowerCase()">
                <div class="truncated">
                    <span *ngIf="order.collectingDetails.associatedVehicleType == 'CAR'"><mat-icon> directions_car </mat-icon></span>
                    <span *ngIf="order.collectingDetails.associatedVehicleType == 'MOTORBIKE'"><mat-icon class="motorbike"> two_wheeler </mat-icon></span>
                    <span class="align-center"> {{ vehiculeName}} </span>
                </div>
                <span *ngIf="orderHasError" class="order-error">
                    <svg height="8px" version="1.1" viewBox="0 0 8 8" width="8px" xmlns="http://www.w3.org/2000/svg">
                      <path d="M4 0C1.792 0 0 1.792 0 4C0 6.208 1.792 8 4 8C6.208 8 8 6.208 8 4C8 1.792 6.208 0 4 0Z" fill="#D20000" id="Shape" stroke="none"/>
                    </svg>
                    {{ getErrorCause() }}
                </span>
            </div>
        </div>
    </div>
</div>
