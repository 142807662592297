import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ExceptionalHoursView } from '../../../../models/exceptionalHoursView/exceptional-hours-view.model';
import { Facility } from '../../../../models/facility/facility.model';
import { FacilityType } from '../../../../models/facility/facilityType.enum';
import { Restaurant } from '../../../../models/restaurant/restaurant.model';
import { PersistanceService } from '../../../../service/persistance/persistance.service';

@Component({
  selector: 'app-exceptional-hours-infos-modal',
  templateUrl: './exceptional-hours-infos-modal.component.html',
  styleUrls: ['./exceptional-hours-infos-modal.component.scss'],
})
export class ExceptionalHoursInfosModalComponent implements OnInit {
  facilities: Facility[];
  facilityType: FacilityType;
  restaurant: Restaurant;
  exceptionalHoursTabInput: ExceptionalHoursView[];
  exceptionalHoursMessage: string;

  constructor(
    public dialogRef: MatDialogRef<ExceptionalHoursInfosModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly persistanceService: PersistanceService
  ) {}

  ngOnInit(): void {
    this.facilities = this.data.facilities;
    this.facilityType = this.data.facilityType;
    this.exceptionalHoursTabInput = this.data.exceptionalHoursTabInput;
    this.exceptionalHoursMessage = this.data.exceptionalHoursMessage;
    this.restaurant = this.persistanceService.get('current_restaurant');
  }

  closeModal(): void {
    this.dialogRef.close();
  }
}
