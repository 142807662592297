<div *ngIf="isLoading; else loader">
  <app-loader></app-loader>
</div>

<ng-template #loader>
  <app-facility
    [isSuperParent]="true"
    [parentIsActive]="true"
    [facility]="facility"
    [facilityType]="facilityType.C_AND_C"
    [facilitiesList]="facilitiesList"
    [exceptionalHoursTabInput]="exceptionalHoursTabInput"
    (reloadFacilities)="emitReloadFacilities()"
  ></app-facility>

  <div class="facility-content" *ngFor="let facilityItem of facility.childrenFacilities">
    <app-consumption-type
      [isParentActive]="facility.nationalActivation && facility.restaurantActivation"
      [consumptionType]="facilityItem"
      [pods]="filterPodsByEatType(facilityItem.eatType, pods)"
      (reloadFacilities)="emitReloadFacilities()"
    >
    </app-consumption-type>
  </div>
</ng-template>
