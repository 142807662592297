<div *ngIf="isLoading; else loader">
  <app-loader></app-loader>
</div>

<ng-template #loader>
  <div id="all-settings">
    <p class="highTitle">Paramètres</p>
    <!-- Frais de service et livraison -->
    <app-delivery-fees-list></app-delivery-fees-list>
    <!-- Indications livreurs  -->
    <div id="deliverer-infos">
      <div class="content">
        <div class="settings">
          <div class="left-side">
            <span class="settings-label">Indications livreurs</span>
          </div>
          <div></div>
          <button
            class="modifyButton"
            [ngClass]="isUserRestaurantManager && mcdeliveryFacility.nationalActivation ? 'modifyButton' : 'modifyButton disabled'"
            (click)="openModalEditInfos()"
          >
            ÉDITER
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
