import { Component, Input, OnInit } from '@angular/core';
import { Pod } from '../../../../../../../models/pod/pod.model';

@Component({
  selector: 'app-pod-infos-line',
  templateUrl: './pod-infos-line.component.html',
  styleUrls: ['./pod-infos-line.component.scss'],
})
export class PodInfosLineComponent implements OnInit {
  @Input() pod: Pod;

  constructor() {}

  ngOnInit(): void {}
}
