<!-- HEADER -->

<div id="modal-body">
  <div class="body-section-title padding-content">
    <div class="hour-name">Nom de l'horaire</div>
    <div class="characters-count">
      <div class="max-caracter">{{ exceptionalHoursMessageMaxLength }} caractères max.</div>
      <circle-progress
        [percent]="textareaPercentage()"
        [maxPercent]="100"
        [radius]="140"
        [outerStrokeWidth]="30"
        [innerStrokeWidth]="15"
        [space]="-10"
        [outerStrokeColor]="exceptionalHoursName.length >= exceptionalHoursMessageMaxLength ? 'var(--red)' : '#5d5d5d'"
        [innerStrokeColor]="'#e7e8ea'"
        [showSubtitle]="false"
        [showUnits]="false"
        [showTitle]="false"
        [animation]="true"
        [animationDuration]="300"
        [startFromZero]="false"
        [responsive]="true"
      ></circle-progress>
    </div>
  </div>
  <!-- add .label to link -->
  <textarea
    [(ngModel)]="exceptionalHoursName"
    id="message-area"
    matInput
    [maxlength]="exceptionalHoursMessageMaxLength"
    cdkTextareaAutosize
    spellcheck="false"
    #autosize="cdkTextareaAutosize"
    cdkAutosizeMaxRows="2"
  ></textarea>

  <mat-card-content *ngIf="errorLabelName">
    <app-warning-message [infoBulleColor]="'#d20000'" [message]="errorMessageName"></app-warning-message>
  </mat-card-content>

  <div class="separator-margin">
    <hr class="separator" />
  </div>

  <div class="body-section-title padding-content">
    <div class="hour-name">Planification</div>
  </div>
  <div *ngIf="!isDuration" class="start-date-block padding-content">
    <div id="start-date-box" class="start-date padding-content" (click)="picker.open()">
      <input readonly class="input-date" [min]="minDate" matInput [matDatepicker]="picker" [(ngModel)]="beginDate" />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </div>
  </div>
  <div *ngIf="isDuration" class="start-date-block padding-content">
    <div id="duration-date-box" class="duration-date padding-content" (click)="picker.open()">
      <mat-date-range-input [rangePicker]="picker" [min]="minDate">
        <input readonly class="input-date" matStartDate placeholder="Start date" [(ngModel)]="beginDate" />
        <input readonly class="input-date" matEndDate placeholder="End date" [(ngModel)]="endDate" />
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </div>
  </div>
  <div *ngIf="!isDuration" class="add-end-date padding-content">
    <span (click)="addEndDate()"> Paramétrer une période </span>
  </div>

  <div *ngIf="isDuration" class="add-end-date padding-content">
    <span (click)="deleteEndDate()">Paramétrer un jour </span>
  </div>

  <mat-card-content *ngIf="errorLabelBeginDate">
    <app-warning-message [infoBulleColor]="'#d20000'" [message]="errorMessageBeginDate"></app-warning-message>
  </mat-card-content>
  <mat-card-content *ngIf="errorLabelEndDate">
    <app-warning-message [infoBulleColor]="'#d20000'" [message]="errorMessageEndDate"></app-warning-message>
  </mat-card-content>

  <mat-card-content>
    <app-important-information [infoBulleColor]="'#237e47'" [message]="TAKEN_INTO_ACCOUNT"></app-important-information>
  </mat-card-content>
</div>

<br />
