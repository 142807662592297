<div id="clientInfos" [ngStyle]="{ 'background-color': backgroundColor ? backgroundColor : '#f6f6f6' }">
  <img alt="warning" src="assets/medias/warning.svg" class="warningImg" />
  <div>
    <p [ngClass]="withHours ? 'specialmessage' : 'message'">{{ message }}</p>
    <span *ngIf="withHours" (click)="showExceptionalHours()">
      <a style="text-decoration: underline">Voir les horaires</a>
      <!-- Mcdelivery screen -->
    </span>
  </div>
</div>
