<div *ngIf="data.length != 0" id="main">
  <div>
    <nvd3 [options]="options" [data]="data"></nvd3>
  </div>

  <div id="titleGraph">
    <span id="total">{{ orderTotal }}</span>
    <span id="timePeriod">{{ todayDate | date: 'd MMMM yyyy':'':'fr-FR' }}</span>
  </div>

  <div [ngClass]="data.length > 4 ? 'legend' : 'legendWithFourElementsOrLess'">
    <ng-container *ngFor="let dataElement of data">
      <button
        *ngIf="data.y != 0"
        style="color:{{ dataElement.color }};border-color:{{ dataElement.color }}"
        class="legendButton"
        (click)="goToChannelDetails(dataElement)"
      >
        {{ dataElement.key }} {{ dataElement.y }} +
      </button>
    </ng-container>
  </div>

  <div id="refresh-section" *ngIf="newOrders > 0">
    <button id="refresh-button" (click)="refreshOrders()">
      <mat-icon>refresh</mat-icon>
    </button>
    <div id="new-orders-info">
      <span>+{{ newOrders }}</span>
    </div>
  </div>
</div>
