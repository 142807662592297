import { EatType } from '../eat-type.enum';
import { FacilityAdministrationType } from '../facility-administration-type';
import { Slot } from '../slot.model';

export class Facility {
  /**
   * Administrative type of the facility
   */
  administrationFacilityType: FacilityAdministrationType;
  /**
   * Unique reference of the facility.
   */
  reference: string;

  /**
   * Unique reference of the associated restaurant
   */
  restaurantRef: string;

  /**
   * label of the facility use in front.
   */
  label: string;

  /**
   * Description of the facility use in front as infobulle
   */
  infobulle: string;

  /**
   * Determine if the facility has pods
   */
  hasPod: boolean;

  /**
   * Determine if the facility has opening hours.
   */
  hasTime: boolean;

  /**
   * Determine if the facility must use the opening hours of its parents.
   */
  useParentTime: boolean;

  /**
   * 	Determine if the facility must use the status of its parents.
   */
  useParentStatus: boolean;

  /**
   * Eat type of the facility.
   */
  eatType: EatType;
  /**
   * Determine if the facility Type is active nationally.
   */
  nationalActivation: boolean;
  /**
   * Determine if the facility Type is active for the restaurant.
   */
  restaurantActivation: boolean;
  /**
   * Last updated date of the facility.
   */
  lastUpdatedDate: Date;
  /**
   * Type of the person who last updated the facility.
   */
  lastUpdatedPerson: string;
  /**
   * List of slots of the facility containing exceptional hours.
   */
  slots: Slot[];
  /**
   * List of default slots of the facility.
   */
  defaultSlots: Slot[];
  /**
   * children facilities
   */
  childrenFacilities: Facility[];
  /**
   * determine if the facility has to display
   */
  managementVisible: boolean;
  /**
   * determine if the facility  to display
   */
  partnerManageOpeningHours: boolean;

  /**
   * Determine if the facility can have exceptional hours
   */
  exceptionalHoursEligible: boolean;

  /**
   * Displaying order of the facility in exceptional hours view
   */
  exceptionalHoursPosition: number;
}
