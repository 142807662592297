<!-- SUPER facility -->

<div *ngIf="isSuperParent && partnerManageOpeningHours; else facilityChildren"
  [ngClass]="isLAD || isRushHours || isDrive() || displayChildrenSection ? 'facility long-facility-children' : 'facility long-facility'">
  <!--HEADER SECTION -->

  <div class="long-header" [ngStyle]="{ 'border-bottom': facilityActive() ? 'solid var(--border-color) 1px' : 'none' }">
    <mat-card class="primary-facility">
      <mat-card-content *ngIf="haveExceptional" id="exceptional-infos">
        <app-exceptional-hours-information
          [facilityType]="facilityType"
          [infoBulleColor]="'#237e47'"
          [withHours]="haveExceptional"
          [facilities]="facilitiesList"
          [startDate]="startDateExceptional"
          [endDate]="endDateExceptional"
          [exceptionalHoursTabInput]="exceptionalHoursTabInput"
        ></app-exceptional-hours-information>
      </mat-card-content>
      <mat-card-content [ngClass]="!facility.hasTime && displayChildrenSection ? 'no-margin' : ''">
        <app-facility-status-switch
            [facility]='facility'
            [isUserRestaurantManager]='isUserRestaurantManager'
            [isLAD]='isLAD'
            [switchLabel]='switchLabel'
            [enabledSwitch]='(parentIsActive && !isLAD) && enabledSwitch'
            (facilityStatusChanged)='facilityStatusChanged()'
        ></app-facility-status-switch>
        <div class="facilityName">
          <div *ngIf="isLAD || isRushHours || useFacilityName; else notUseFacilityName">
            <span class="facility-label">{{ facility.label }}</span>
          </div>
          <ng-template #notUseFacilityName>
            <span class="facility-label">{{ seeFacilityLabel }}</span>
          </ng-template>
        </div>
      </mat-card-content>

      <mat-card-content>
        <app-days-section [slots]="facility.slots" [objectIsActive]="facilityActive()"></app-days-section>
      </mat-card-content>

      <mat-card-content *ngIf="!facility.nationalActivation">
        <app-important-information [infoBulleColor]="'#d20000'" [message]="NATIONAL_MESSAGE"></app-important-information>
      </mat-card-content>

      <mat-card-content *ngIf="importantInformation">
        <app-important-information [infoBulleColor]="'#d20000'" [message]="importantInformation"></app-important-information>
      </mat-card-content>
    </mat-card>
  </div>

  <!-- HOUR SECTION -->

  <div *ngIf="seeHoursActive && facilityActive()">
    <mat-card class="facility-hours">
      <mat-card-content>
        <app-hours-section [slots]="facility.slots" [isRush]="isRushHours"></app-hours-section>
        <div class="exceptional-hours-legend" *ngIf="haveExceptional">
          <div class="circle"></div>
          <div class="exceptional-hours-legend-content">Horaires exceptionnels</div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

  <!-- EDIT SECTION -->

  <div class="edit-facility">
    <mat-card *ngIf="facilityActive()" class="edit-facility-content">
      <mat-card-content class="actions-section">
        <a (click)="seeHours()">{{ seeHoursLabel }}</a>
        <button *ngIf="isUserRestaurantManager" id="edit-facility-button" (click)="editFacility()">Editer</button>
      </mat-card-content>
    </mat-card>
  </div>
</div>

<!-- SHORT facility -->

<ng-template #facilityChildren>
  <div class="facility short-facility">
    <div class="short-header">
      <mat-card class="primary-facility">
        <mat-card-content>
          <app-facility-status-switch
              [facility]='facility'
              [isUserRestaurantManager]='isUserRestaurantManager'
              [isLAD]='isLAD'
              [switchLabel]='switchLabel'
              [enabledSwitch]='(parentIsActive && !isLAD) && enabledSwitch'
              (facilityStatusChanged)='facilityStatusChanged()'
          ></app-facility-status-switch>
          <div class="facilityName">
            <span class="facility-label">{{ facilityService.retrieveFacilityLabel(facility) }}</span>
          </div>
        </mat-card-content>

        <mat-card-content *ngIf="!facility.nationalActivation">
          <app-important-information [infoBulleColor]="'#d20000'" [message]="NATIONAL_MESSAGE"></app-important-information>
        </mat-card-content>

        <mat-card-content *ngIf="facility.hasTime && !partnerManageOpeningHours" class="partner-without-hours">
          <app-important-information [infoBulleColor]="'#237e47'" [message]="NO_PARTNER_MANAGE_OPENING_MESSAGE"></app-important-information>
        </mat-card-content>

        <mat-card-content *ngIf="importantInformation">
          <app-important-information [infoBulleColor]="'#d20000'" [message]="importantInformation"></app-important-information>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</ng-template>
