<div id="edit-pod-information-body">
  <!-- INFO SECTION -->
  <div
    class="info-section"
    *ngIf="currentPod.restaurantActive && !oneDayActive && currentPod.timeDependency !== timeDependency.USE_FACILITY_TIME.valueOf()"
  >
    <app-important-information [infoBulleColor]="'#237e47'" [message]="NO_SLOT_SELECTED"> </app-important-information>
  </div>

  <!-- Statut du POD (actif/inactif) -->
  <div id="pod-status" class="modal-section">
    <label class="status-text" *ngIf="currentPod?.restaurantActive">ACTIF</label>
    <label class="status-text" *ngIf="!currentPod?.restaurantActive">INACTIF</label>
    <div id="switch-wrapper">
      <label class="switch">
        <input type="checkbox" [(ngModel)]="currentPod.restaurantActive" />
        <span class="slider"></span>
      </label>
    </div>
  </div>

  <hr class="separator" />

  <!-- Message d'information client du POD -->
  <div id="message" class="modal-section">
    <div class="section-title">
      <div class="section-title-text">Message</div>
      <div class="section-update-date">{{ lastUpdateMessage }}</div>
    </div>
    <div id="message-content" [ngClass]="currentPod.restaurantDescriptionActive && currentPod.restaurantActive ? '' : 'disabled'">
      <div
        id="customer-infos-status"
        [ngClass]="!currentPod.restaurantActive ? 'checkbox-background-desactivate' : 'checkbox-background-activate'"
      >
        <mat-checkbox [(ngModel)]="currentPod.restaurantDescriptionActive" [disabled]="!currentPod.restaurantActive" id="info-checkbox">
          Info client
        </mat-checkbox>
        <div id="characters-count" [ngClass]="currentPod?.restaurantDescription.length >= podMessageMaxLength ? 'full' : ''">
          <span>{{ podMessageMaxLength }} caractères max.</span>
          <circle-progress
            style="height: 32px"
            [percent]="textareaPercentage()"
            [maxPercent]="100"
            [radius]="140"
            [outerStrokeWidth]="30"
            [innerStrokeWidth]="15"
            [space]="-10"
            [outerStrokeColor]="currentPod?.restaurantDescription.length >= podMessageMaxLength ? 'var(--red)' : '#5d5d5d'"
            [innerStrokeColor]="'#e7e8ea'"
            [showSubtitle]="false"
            [showUnits]="false"
            [showTitle]="false"
            [animation]="true"
            [animationDuration]="300"
            [startFromZero]="false"
            [responsive]="true"
          ></circle-progress>
        </div>
      </div>
      <textarea
        [(ngModel)]="currentPod.restaurantDescription"
        [disabled]="!currentPod.restaurantDescriptionActive || !currentPod.restaurantActive"
        id="message-area"
        matInput
        [maxlength]="podMessageMaxLength"
        cdkTextareaAutosize
        #autosize="cdkTextareaAutosize"
        cdkAutosizeMaxRows="5"
      ></textarea>
    </div>
  </div>

  <!-- Horaires d'ouverture par jours -->
  <div id="opening-hours-title" class="modal-section" *ngIf="currentPod.timeDependency !== timeDependency.USE_FACILITY_TIME.valueOf()">
    <div class="section-title">
      <div class="section-title-text">Horaires d’ouverture</div>
      <div class="section-update-date">{{ lastUpdateMessage }}</div>
    </div>
    <div
      *ngFor="let slot of currentPod?.slots; let i = index"
      [ngClass]="slot.active && currentPod.restaurantActive ? 'opening-hours' : 'opening-hours disabled'"
    >
      <hr class="separator" />
      <app-edit-slot-hours
        [objectActive]="currentPod.restaurantActive && currentPod.nationalActive"
        [facilitySlot]="facility ? facility.slots[i] : null"
        [slot]="slot"
      ></app-edit-slot-hours>
    </div>
  </div>
</div>
