<!-- RESTURANT HEADER -->
<app-restaurant-header [forceCollapse]="false"></app-restaurant-header>

<!-- MENU -->
<div id="restaurant-facilities-header" #header>
  <div id="tabs-section">
    <button
      (click)="switchTabView(facilityType.MY_ROOM)"
      [ngClass]="isTabActive(facilityType.MY_ROOM) ? 'orders-section-tab selected' : 'orders-section-tab'"
      style="margin-right: 1%"
    >
      Salle
    </button>
    <button
      (click)="switchTabView(facilityType.C_AND_C)"
      [ngClass]="isTabActive(facilityType.C_AND_C) ? 'orders-section-tab selected' : 'orders-section-tab'"
      style="margin-right: 1%"
    >
      Click & Collect
    </button>
    <button
      (click)="switchTabView(facilityType.MCDRIVE)"
      [ngClass]="isTabActive(facilityType.MCDRIVE) ? 'orders-section-tab selected' : 'orders-section-tab'"
    >
      Drive
    </button>
  </div>
</div>

<!-- MAIN CONTENT -->
<div id="restaurant-facilities" #content>
  <!-- LOADER -->
  <div *ngIf="isLoading; else loader">
    <app-loader></app-loader>
  </div>

  <!-- ONGLET CONTENT -->

  <ng-template #loader>
    <div [ngSwitch]="currentTab">
      <!-- SALLE -->
      <app-my-room-facility
        (reloadFacilities)="loadFacilities()"
        *ngSwitchCase="facilityType.MY_ROOM"
        [facility]="myRoomFacility"
        [facilitiesList]="facilities"
        [exceptionalHoursTabInput]="exceptionalHoursTab"
      ></app-my-room-facility>
      <!-- C&C -->
      <div *ngSwitchCase="facilityType.C_AND_C">
        <div *ngIf="hasPaymentMethod; else noPaymentMethod">
          <app-candc-facility
            (reloadFacilities)="loadFacilities()"
            [facility]="cAndcFacility"
            [facilitiesList]="facilities"
            [exceptionalHoursTabInput]="exceptionalHoursTab"
          ></app-candc-facility>
        </div>
        <!-- if no payment methods -->
        <ng-template #noPaymentMethod>
          <div class="orders-list-empty">
            <div class="no-result-img">
              <div class="empty-image-div">
                <img src="assets/medias/no-orders.svg" alt="mcdo_orders_empty" class="orders-empty-icon" />
              </div>
              <p class="no-result-text">
                Aucun mode de paiement n'est paramétré sur votre restaurant, vous ne pouvez pas activer le Click & Collect. Veuillez
                contacter <strong>HELPLINE</strong>
              </p>
            </div>
          </div>
        </ng-template>
      </div>
      <!-- MCDRIVE-->
      <app-mcdrive-facility
        (reloadFacilities)="loadFacilities()"
        [facility]="mcDriveFacility"
        [drivePietonfacility]="drivePietonFacility"
        [facilitiesList]="facilities"
        [exceptionalHoursTabInput]="exceptionalHoursTab"
        *ngSwitchCase="facilityType.MCDRIVE"
      ></app-mcdrive-facility>
    </div>
  </ng-template>
</div>
