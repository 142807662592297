<div [ngSwitch]="mode" [ngClass]="!slot.active ? 'content disabled' : 'content'">
  <div *ngSwitchCase="'edition'">
    <div
      class="first-line"
      [ngClass]="slot.activationError ? 'background-error' : ''"
      [ngClass]="!slot.active ? 'checkbox-background-desactivate' : 'checkbox-background-activate'"
    >
      <mat-checkbox [(ngModel)]="slot.active" (click)="changeView()">
        {{ getFacilityLabel(facility.reference) }}
      </mat-checkbox>
      <div class="right-div">
      <div class="select-state" *ngIf="slot.active">
        <mat-select (selectionChange)="optionSelected($event.value)" [disableOptionCentering]="true" placeholder="État" [(ngModel)]="slot.exceptionalClose">
          <mat-option [value]="false">Ouverture</mat-option>
          <mat-option [value]="true">Fermeture</mat-option>
        </mat-select>
      </div>
      <div [ngClass]="{'second-line-with-button': slot.exceptionalClose === false ,
           'first-slot': !slot.active}">
        <div *ngIf="!slot.active || slot.exceptionalClose === false">
        <span class="from">de</span>
        <input
          type="time"
          [ngClass]="slot.slotDetails[0].startError ? 'error' : ''"
          [disabled]="!slot.active"
          [(ngModel)]="slot.slotDetails[0].beginHour"
          required
        />
        <span class="to">à</span>
        <input
          type="time"
          [ngClass]="slot.slotDetails[0].endError ? 'error' : ''"
          [disabled]="!slot.active"
          [(ngModel)]="slot.slotDetails[0].endHour"
          required
        />
        </div>
      </div>
    <div class="second-line" *ngIf="slot.slotDetails.length === 2">
      <div *ngIf="!slot.active || slot.exceptionalClose === false">
      <div class="second-slot">
        <span class="from">de</span>
        <input
          type="time"
          [ngClass]="slot.slotDetails[1].startError ? 'error' : ''"
          [disabled]="!slot.active"
          [(ngModel)]="slot.slotDetails[1].beginHour"
          required
        />
        <span class="to">à</span>
        <input
          type="time"
          [ngClass]="slot.slotDetails[1].endError ? 'error' : ''"
          [disabled]="!slot.active"
          [(ngModel)]="slot.slotDetails[1].endHour"
          required
        />
      </div>
      </div>
    </div>
      <div class="manage-slot" *ngIf="!slot.active || slot.exceptionalClose === false">
        <label class="action" *ngIf="!slot.slotDetails[1]" (click)="slot.active && addSlot()">Ajouter un créneau</label>
        <label class="action" *ngIf="slot.slotDetails[1]" (click)="slot.active && removeSlotMode()">Supprimer un créneau</label>
      </div>
        </div>
    </div>
    <div class="last-line">
      <div *ngIf="!slot.active || slot.exceptionalClose === false || slot.exceptionalClose === true">
      <label class="openingHour-status" *ngIf="!slot?.active">PAR DÉFAUT</label>
      <label class="openingHour-status-ouverture" *ngIf="slot.active && slot.exceptionalClose === false">OUVERTURE</label>
        <label class="openingHour-status-fermeture" *ngIf="slot.active && slot.exceptionalClose === true">FERMETURE</label>
      </div>
    </div>
    <div class="fermeture-line" *ngIf="slot.exceptionalClose === true && slot.active">
      <app-important-information [infoBulleColor]="'#d20000'" [message]="CLOSE_FACILITY_MESSAGE"> </app-important-information>
    </div>
    <div *ngIf="slot.active && slot.slotDetails.length === 2 && slot.exceptionalClose === false" class="blank-space-twoline"></div>
      <div *ngIf="slot.active && slot.slotDetails.length === 1 && slot.exceptionalClose === false" class="blank-space"></div>
      <div class="error-div">
        <div class="error-section" *ngIf="(slot.exceptionalClose === undefined || slot.exceptionalClose === null)&& slot.active">
          <img alt="warning" src="../../../../../assets/medias/warning.svg" class="warningImg" />
          <p>{{ NO_STATE_FACILITY_MESSAGE }}</p>
        </div>
        <div *ngFor="let error of slot.configError" class="error-section">
          <img alt="warning" src="../../../../../assets/medias/warning.svg" class="warningImg" />
          <p>{{ error }}</p>
        </div>
      </div>
    </div>
  <div *ngSwitchCase="'removing'">
    <div class="first-line">
      <mat-checkbox [(ngModel)]="slot.active" (click)="changeView()">
        {{ getFacilityLabel(facility.reference) }}
      </mat-checkbox>
      <div class="right-div">
        <div class="select-state" *ngIf="slot.active">
          <mat-select (selectionChange)="optionSelected($event.value)" [disableOptionCentering]="true" placeholder="État" [(ngModel)]="slot.exceptionalClose">
            <mat-option [value]="false">Ouverture</mat-option>
            <mat-option [value]="true">Fermeture</mat-option>
          </mat-select>
        </div>
        <div [ngClass]="{'second-line-with-button': slot.exceptionalClose === false,
           'first-slot': !slot.active}">
          <div *ngIf="!slot.active || slot.exceptionalClose === false">
        <span class="from">de</span>
        <input type="time" [disabled]="!slot.active" [(ngModel)]="slot.slotDetails[0].beginHour" required />
        <span class="to">à</span>
        <input type="time" [disabled]="!slot.active" [(ngModel)]="slot.slotDetails[0].endHour" required />
        <button mat-raised-button class="remove-slot-button" *ngIf="slot.active" (click)="removeSlot(0)">SUPPRIMER</button>
          </div>
      </div>
    <div class="second-line" *ngIf="slot.slotDetails.length === 2 && slot.active">
      <div class="second-slot">
        <span class="from">de</span>
        <input type="time" [disabled]="!slot.active" [(ngModel)]="slot.slotDetails[1].beginHour" required />
        <span class="to">à</span>
        <input type="time" [disabled]="!slot.active" [(ngModel)]="slot.slotDetails[1].endHour" required />
        <button mat-raised-button class="remove-slot-button" *ngIf="slot.active" (click)="removeSlot(1)">SUPPRIMER</button>
      </div>
    </div>
        <div class="cancel-delete-schedule">
        <label class="action" (click)="cancelRemoving()">Annuler</label>
        </div>
      </div>
    </div>
    <div class="last-line">
      <div *ngIf="!slot.active || slot.exceptionalClose === false || slot.exceptionalClose === true">
        <label class="openingHour-status-ouverture" *ngIf="slot.active && slot.exceptionalClose === false">OUVERTURE</label>
        <label class="openingHour-status-fermeture" *ngIf="slot.active && slot.exceptionalClose === true">FERMETURE</label>
      </div>
    </div>
  </div>
</div>
