import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DeliveryFeesInfosModalComponent } from './delivery-fees-infos-modal/delivery-fees-infos-modal.component';
import { Restaurant } from '../../../../../../models/restaurant/restaurant.model';
import { PersistanceService } from '../../../../../../service/persistance/persistance.service';
import { McDeliveryService } from '../../../../../../service/mcdelivery/mcdelivery-service';
import { McDeliveryPartnerDynamicFee } from '../../../../../../models/mcdelivery/mcdelivery-partner-dynamic-fee';
import { ErrorService } from '../../../../../../service/error/error.service';
import { ConsoleLoggerService } from '../../../../../../service/logger/console-logger.service';

@Component({
  selector: 'app-delivery-fees-list',
  templateUrl: './delivery-fees-list.component.html',
  styleUrls: ['./delivery-fees-list.component.css'],
})
export class DeliveryFeesListComponent implements OnInit {
  isLoading = true;

  viewFeesList = false;
  feesList: McDeliveryPartnerDynamicFee[];
  restaurant: Restaurant;
  errorLoading = false;

  constructor(
    private readonly persistanceService: PersistanceService,
    public mcDeliveryService: McDeliveryService,
    public matDialog: MatDialog,
    private readonly errorService: ErrorService,
    private readonly CONSOLE_LOGGER: ConsoleLoggerService,
  ) {
  }

  ngOnInit(): void {
    this.restaurant = this.persistanceService.get('current_restaurant');

    this.mcDeliveryService.getUberDirectPartnerDynamicFees(this.restaurant.ref).subscribe(
      (uberDirectFeesList) => {
        this.feesList = uberDirectFeesList;
        uberDirectFeesList.sort((fee1, fee2) => {
          return fee1.distance < fee2.distance ? -1 : 1;
        });
        let distanceMin = 0;
        for (const fee of this.feesList) {
          fee.distanceMin = distanceMin;
          distanceMin = fee.distance;
        }
        this.isLoading = false;
      },
      (error) => {
        this.CONSOLE_LOGGER.error('Error while getting UberDirect fees list');
        this.errorService.manageError(error);
        this.isLoading = false;
        this.errorLoading = true;
      },
    );
  }

  toggleView(): void {
    this.viewFeesList = !this.viewFeesList;
  }

  toggleLabel(): string {
    if (this.viewFeesList) {
      return 'Voir moins';
    } else {
      return 'Voir les informations';
    }
  }

  /**
   * Open the modal allowing to display delivery fees infos
   */
  displayFeesInfos(): void {
    const dialogConfig = new MatDialogConfig();
    // The user can't close the dialog by clicking outside its body
    dialogConfig.disableClose = true;
    dialogConfig.id = 'delivery-fees-infos-modal-component';
    dialogConfig.maxWidth = '100vw';
    dialogConfig.height = '96%';
    dialogConfig.width = '94%';
    this.matDialog.open(DeliveryFeesInfosModalComponent, dialogConfig);
  }

  hasElements(list: any[]): boolean {
    return list && list.length > 0;

  }
}
