<!-- HEADER -->
<div id="modal-header">
  <div class="modal-title">Informations restaurant</div>
  <a>
    <mat-icon id="close-modal-button" (click)="closeModal()">close</mat-icon>
  </a>
  <div class="consumption-type-name">Informations</div>
</div>

<div id="modal-body">
  <div>
    <div class="injection-wording">Informations supplémentaires</div>
    <p>
      Vous pouvez présenter votre restaurant de manière plus précise dans ce champ pour mettre en avant un service ou une caractéristique d’ouverture particulière (ouvert 7J/7, 24H/24…).
    </p>
  </div>
</div>
