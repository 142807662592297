<div *ngIf="listLoading">
  <app-loader></app-loader>
</div>
<div *ngIf="salesChannels && salesChannels.length; else noSalesChannels">
  <div id="filters-zone">
    <div id="filters-content">
      <!-- filtre par duree (1j,2,7j) -->
      <mat-select
        (selectionChange)="selectTime()"
        [(ngModel)]="selectedDurationFilter"
        class="select-duration"
        [disableOptionCentering]="true"
      >
        <mat-select-trigger>
          <div class="option-content">
            <img src="assets/medias/duration-select/duration-{{ selectedDurationFilter }}.svg" alt="duration-pic" />
            <span> {{ getSelectedOptionText(selectedDurationFilter) }}</span>
          </div>
        </mat-select-trigger>
        <mat-option *ngFor="let option of timeFilterOptions" [value]="option.duration">
          <div class="option-content">
            <img src="assets/medias/duration-select/duration-{{ option.duration }}.svg" alt="duration-pic" />
            <span> {{ option.text }}</span>
          </div>
        </mat-option>
      </mat-select>
      <!-- Filtre par type (C&C/McDelivery/LAD) -->
      <div class="sales-channel-filters">
        <mat-radio-group class="channel-radio-group" [(ngModel)]="channelFilter">
          <mat-radio-button
            #button
            *ngFor="let channel of salesChannels"
            [class.selected]="channelFilter === channel"
            class="channel-filter {{ channel }}"
            [value]="channel"
            (click)="checkChannelState($event, button)"
          >
            <span> {{ getValueOfChannel(channel) }} </span>
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
  </div>
  <div *ngIf="showNoContent(); else orders">
    <div class="orders-list-empty">
      <div id="no-orders-img">
        <div class="empty-image-div">
          <img src="assets/medias/no-orders.svg" id="img-no-orders" alt="mcdo_orders_empty" class="orders-empty-icon" />
        </div>
        <p class="no-result-text">Aucune commande digitale sur le restaurant sélectionné.</p>
      </div>
    </div>
  </div>
  <ng-template #orders>
    <div id="orders-zone">
      <!-- Liste des commandes -->
      <app-orders-list
        [orderErrors]="orderErrors"
        [isSeeMore]="isSeeMore"
        [loading]="listLoading"
        [orders]="filteredOrders"
        [historyMode]="true"
        (showMoreOrdersEvent)="showMoreOrders()"
        [inputSearch]="searchedOrderNumber"
        (searchedOrderNumber)="searchedOrderNumberChanged($event)"
        [statusFilter]="searchedOrderStatus"
        (searchedOrderStatus)="searchedOrderStatusChanged($event)"
      ></app-orders-list>
      <!-- Fin des commandes -->
    </div>
  </ng-template>
</div>
<ng-template #noSalesChannels>
  <div class="orders-list-empty">
    <div class="no-result-img">
      <div class="empty-image-div">
        <img src="assets/medias/no-orders.svg" alt="mcdo_orders_empty" class="orders-empty-icon" />
      </div>
      <p class="no-result-text">
        Aucun canal de vente digital n’est paramétré. Veuillez activer vos canaux de ventes sur MyLSM - modules pages locales.
      </p>
    </div>
  </div>
</ng-template>
