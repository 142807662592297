import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Facility } from '../../../../../models/facility/facility.model';
import { Restaurant } from '../../../../../models/restaurant/restaurant.model';
import { Role } from '../../../../../models/role.enum';
import { PersistanceService } from '../../../../../service/persistance/persistance.service';
import { RestaurantService } from '../../../../../service/restaurant/restaurant.service';
import { UserService } from '../../../../../service/user/user.service';
import { EditDelivererInfosModalComponent } from './edit-deliverer-infos-modal/edit-deliverer-infos-modal.component';

@Component({
  selector: 'app-mcdelivery-settings',
  templateUrl: './mcdelivery-settings.component.html',
  styleUrls: ['./mcdelivery-settings.component.scss'],
})
export class McDeliverySettingsComponent implements OnInit {
  @Input() mcdeliveryFacility: Facility;
  isLoading = true;
  restaurant: Restaurant;
  isUserRestaurantManager = false;

  constructor(
    public matDialog: MatDialog,
    private readonly userService: UserService,
    private readonly restaurantService: RestaurantService,
    private readonly persistanceService: PersistanceService
  ) {}

  ngOnInit(): void {
    this.checkUserRole();
    this.getRestaurantInfos();
  }

  /**
   * Call WS to find restaurant by its reference.
   */
  getRestaurantInfos(): void {
    this.isLoading = true;
    const persistedRestaurant = this.persistanceService.get('current_restaurant');
    this.restaurantService.getRestaurantById(persistedRestaurant.ref.toString()).subscribe((restaurant) => {
      this.restaurant = restaurant;
      this.isLoading = false;
    });
  }

  /**
   * Check if the user has edit role or not
   */
  private checkUserRole(): void {
    this.userService.getUserInfo().subscribe((userInfos) => {
      if (userInfos) {
        this.isUserRestaurantManager = userInfos.roles.includes(Role.MANAGER);
      }
    });
  }

  openModalEditInfos(): void {
    if (this.mcdeliveryFacility.nationalActivation && this.isUserRestaurantManager) {
      const dialogConfig = new MatDialogConfig();
      // The user can't close the dialog by clicking outside its body
      dialogConfig.disableClose = true;
      dialogConfig.id = 'edit-deliverer-infos-modal-component';
      dialogConfig.maxWidth = '100vw';
      dialogConfig.height = '96%';
      dialogConfig.width = '94%';
      dialogConfig.data = {
        restaurant: this.restaurant,
      };
      this.matDialog.open(EditDelivererInfosModalComponent, dialogConfig);
    }
  }
}
