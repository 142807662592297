import { Component, Input, OnInit } from '@angular/core';
import { OrderError } from '../../../models/order/order-error.model';

@Component({
  selector: 'app-order-error',
  templateUrl: './order-error.component.html',
  styleUrls: ['./order-error.component.scss'],
})
export class OrderErrorComponent implements OnInit {
  constructor() {}

  @Input() orderError: OrderError;

  ngOnInit(): void {}
}
