export class SlotDetails {
  /**
   * Begin hour of the slot.
   * Format : 'HH:mm'
   */
  beginHour: string;
  /**
   * End hour of the slot.
   * Format : 'HH:mm'
   */
  endHour: string;
  /**
   * Variable to store if there is an error on beginHour config.
   */
  startError = false;
  /**
   * Variable to store if there is an error on endHour config.
   */
  endError = false;

  /**
   * Variable to store if the slot is active or not.
   */
  active = false;

  constructor(beginHour: string, endHour: string) {
    this.beginHour = beginHour;
    this.endHour = endHour;
  }
}
