<!-- HEADER -->
<div id="modal-header">
  <div class="modal-title">Click & Collect</div>
  <a>
    <mat-icon id="close-modal-button" (click)="closeModal()">close</mat-icon>
  </a>
  <div class="consumption-type-name">Injection commandes</div>
</div>

<div id="modal-body">
  <div>
    <div class="injection-wording">Click & Ready</div>
    <p>
      En activant l’injection manuelle des commandes Click & Collect, vous autorisez vos clients à injecter leurs commandes à distance
      depuis leur smartphone.
    </p>
    <span id="more-information" (click)="showFaq()">Plus d'informations</span>
  </div>
  <div>
    <div class="injection-wording">Horaires de rush</div>
    <p>
      Paramétrer des horaires de rush vous permet de bénéficier de deux rayons kilométriques de géofencing différents selon l'affluence de
      votre restaurant.
    </p>
    <p>Veuillez contacter HELPLINE pour activer le géofencing sur votre restaurant.</p>
  </div>
  <div>
    <div class="injection-wording">C&C Service à table</div>
    <p>
      L’injection manuelle des commandes C&C Service à table autorise vos clients à injecter leurs commandes à distance
      depuis leur application Mcdo+. Il est par défaut actif et n’est pas éditable.
    </p>
  </div>
</div>
