import { Component, Input, OnInit } from '@angular/core';
import { Facility } from '../../../../models/facility/facility.model';

@Component({
  selector: 'app-edit-facility-information',
  templateUrl: './edit-facility-information.component.html',
  styleUrls: ['./edit-facility-information.component.scss'],
})
export class EditFacilityInformationComponent implements OnInit {
  @Input() facility: Facility;
  @Input() lastUpdateMessage: string;
  @Input() oneDayActive: boolean;
  @Input() isDeliveryPartner: boolean;
  @Input() isRushHours: boolean;

  NO_SLOT_SELECTED = 'Veuillez paramétrer les jours et heures de disponibilité de votre canal de vente.';

  constructor() {}

  ngOnInit(): void {
    this.changeMessage();
  }

  changeMessage(): void {
    if (this.isDeliveryPartner) {
      this.NO_SLOT_SELECTED = 'Veuillez paramétrer les jours et heures de disponibilité de votre partenaire de livraison.';
    } else if (this.isRushHours) {
      this.NO_SLOT_SELECTED = 'Veuillez paramétrer les jours et créneaux horaires de rush de votre restaurant.';
    }
  }
}
