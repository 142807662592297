import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './app/environments/environment';
import { environmentLoader as environmentLoaderPromise } from './app/environments/environmentLoader';

environmentLoaderPromise.then((env) => {
  if (env.production) {
    enableProdMode();
  }
  environment.settings = env.settings;

  platformBrowserDynamic().bootstrapModule(AppModule);
});
