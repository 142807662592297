<div *ngIf="isLoading; else loader">
    <app-loader></app-loader>
</div>

<ng-template #loader>
    <div id="restaurant-information">
        <div class="content">
            <div class="settings left-side">
                <div class="title">
                    <span class="settings-label">Description du restaurant</span>
                </div>
                <div id="information" [hidden]="!viewRestaurantInformation">
                    <div class="information">
                        <span class="information-title">Adresse</span>
                        <br/>
                        <span class="information-value">{{ address }}</span>
                        <br/>
                        <div id="woosmap"></div>
                    </div>
                    <div *ngIf="additionalInformation" class="information">
                        <span class="information-title">Informations supplémentaires</span>
                        <span class="infobulle green" (click)="displayAddtionalInformation()">
                            <div class="infobulle-content">?</div>
                        </span>
                        <br/>
                        <span class="information-value"> {{ additionalInformation }}</span>
                    </div>
                    <div class="information">
                        <span class="information-title">Téléphone</span>
                        <br/>
                        <span class="information-value">{{ phone }}</span>
                    </div>
                    <div class="information">
                        <span class="information-title">Numéro TVA</span>
                        <br/>
                        <span class="information-value">{{ TVANumber }}</span>
                    </div>
                    <div class="information">
                        <span class="information-title">Numéro RCS</span>
                        <br/>
                        <span class="information-value">{{ RCSNumber }}</span>
                    </div>
                    <div class="information">
                        <span class="information-title">A propos du restaurant</span>
                        <span class="infobulle green" (click)="displayAbout()">
                            <div class="infobulle-content">?</div>
                        </span>
                        <br/>
                        <span class="information-value">{{ about }}</span>
                    </div>
                    <div class="information">
                        <span class="information-title">Images de présentation</span>
                        <br/>
                        <span class="information-value inline">
                            <div class="picture" *ngIf="pictures[0]">
                                <img src="{{ getPicture(0) }}" alt="" />
                            </div>
                            <div class="picture" *ngIf="pictures[1]">
                                <img src="{{ getPicture(1) }}" alt="" />
                            </div>
                        </span>
                    </div>
                    <div  class="local-page-link">
                        <a href="{{restaurantUrl}}" target="_blank">Voir ma page</a>
                    </div>
                </div>
                <div class="left-side footer">
                    <a (click)="toggleView()">{{ toggleLabel() }}</a>
                    <button *ngIf="isUserRestaurantManager" id="edit-button" (click)="clickToEdit()">Editer</button>
                </div>
            </div>
        </div>
    </div>
</ng-template>
