import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import * as moment from 'moment';
import { forkJoin, timer } from 'rxjs';
import { OrderError } from '../../../models/order/order-error.model';
import { OrderType } from '../../../models/order/order-type.enum';
import { Order } from '../../../models/order/order.model';
import { RestaurantSalesChannels } from '../../../models/restaurant/restaurant-sales-channels.model';
import { Restaurant } from '../../../models/restaurant/restaurant.model';
import { OrderService } from '../../../service/order/order.service';
import { OrderErrorService } from '../../../service/orderError/order-error.service';
import { PersistanceService } from '../../../service/persistance/persistance.service';
import { RestaurantService } from '../../../service/restaurant/restaurant.service';

@Component({
  selector: 'app-orders-in-progress',
  templateUrl: './orders-in-progress.component.html',
  styleUrls: ['./orders-in-progress.component.scss'],
})
export class OrdersInProgressComponent implements OnInit, OnDestroy, OnChanges {
  currentRestaurant: Restaurant;
  orders: Order[];
  filteredOrders: Order[];
  isLoading = true;
  lastCheckedDate: Date;
  isRefreshEnabled: boolean;
  timerSub;
  nbOfNewOrders: number;

  salesChannels: RestaurantSalesChannels;
  orderErrors: OrderError[];

  @Input() pageLevel: number;
  @Input() orderTypeFilter: string;
  @Input() subPageMode: boolean;
  @Output() isSubCategoryPage: EventEmitter<any[]> = new EventEmitter<any[]>();

  constructor(
    private readonly orderService: OrderService,
    private readonly persistanceService: PersistanceService,
    private readonly restaurantService: RestaurantService,
    private readonly orderErrorService: OrderErrorService
  ) {}

  ngOnInit(): void {
    this.currentRestaurant = this.persistanceService.get('current_restaurant');
    this.findOrdersInProgress(true);
  }

  /**
   * Finds order in progress to display
   */
  private findOrdersInProgress(firstload: boolean = false): void {
    forkJoin([
      this.orderService.getOrdersInProgress(this.currentRestaurant.ref.toString()),
      this.restaurantService.findRestaurantSalesChannelsStatus(this.currentRestaurant.ref.toString()),
      this.orderErrorService.getOrderErrors(),
    ]).subscribe((rep) => {
      const orders = rep[0];
      const channels = rep[1];
      const errors = rep[2];

      const momentFromDate = moment.utc(orders.resultDate).local();
      this.lastCheckedDate = momentFromDate.toDate();

      this.isRefreshEnabled = orders.refreshEnable;
      orders.orders.sort((order1, order2) => {
        return order1.orderedDate > order2.orderedDate ? -1 : 1; // sort by orderedDate desc
      });
      this.orders = orders.orders;
      this.subPageMode = false;
      this.filteredOrders = this.orders;
      this.nbOfNewOrders = 0;
      if (firstload) {
        this.initiateRefreshTimer();
      }

      this.salesChannels = channels;
      this.orderErrors = errors;
      this.isLoading = false;
    });
  }

  /**
   * Call EDD WS every 2 minutes to check for new orders.
   */
  initiateRefreshTimer(): void {
    if (!this.isRefreshEnabled) {
      return;
    }
    if (this.timerSub) {
      this.timerSub.unsubscribe();
    }
    const twoMinutes = 2 * 1000 * 60;
    this.timerSub = timer(twoMinutes, twoMinutes).subscribe((_) => this.checkForNewOrders());
  }

  /**
   * Check if new orders have been made since the last time we got them.
   */
  checkForNewOrders(): void {
    this.orderService.getNumberOfNewOrdersSince(this.lastCheckedDate, this.currentRestaurant.ref.toString()).subscribe((res) => {
      this.nbOfNewOrders = res;
    });
  }

  /**
   * Check if there is changes from parent
   */
  ngOnChanges(changes: SimpleChanges): void {
    const subPageModeItem: SimpleChange = changes.subPageMode;
    // if we were in a sub-category page (ie. C&C/McDelivery/LAD) and user clicked on back button -> reset default orders
    if (subPageModeItem && !subPageModeItem.currentValue) {
      this.filteredOrders = this.orders;
    }
    const pageLevelItem: SimpleChange = changes.pageLevel;
    if (pageLevelItem && pageLevelItem.previousValue > 1 && pageLevelItem.currentValue === 1 && this.orderTypeFilter) {
      this.filteredOrders = this.orders.filter((order) => order.type === this.mapToOrderType(this.orderTypeFilter));
    }
  }

  toUpdateSubCategoryPage(event: any[]): void {
    this.isSubCategoryPage.emit(event);
  }

  /**
   * Called when filtered orders changes from child components
   */
  filteredOrdersChange(filteredOrders: Order[]): void {
    this.filteredOrders = filteredOrders;
  }

  /**
   * When component is detroyed, unsubscribe for timer.
   */
  ngOnDestroy(): void {
    if (this.timerSub) {
      this.timerSub.unsubscribe();
    }
  }

  /**
   * Event triggered when refresh button is clicked on orders graph.
   */
  refreshOrders(): void {
    this.findOrdersInProgress();
    this.isSubCategoryPage.emit();
  }

  hasActiveSalesChannels(): boolean {
    return (
      this.salesChannels &&
      (this.salesChannels.clickAndCollectEnabled || this.salesChannels.deliveryEnabled || this.salesChannels.externalDeliveryEnabled)
    );
  }

  private mapToOrderType(orderTypeString: string): OrderType {
    switch (orderTypeString) {
      case 'C&C':
        return OrderType.C_AND_C;
      case 'LAD McDo+':
        return OrderType.MC_DELIVERY;
      case 'LAD':
        return OrderType.LAD;
      default:
        return null;
    }
  }

  podIsChange(event: boolean): void {
    this.refreshOrders();
  }
}
