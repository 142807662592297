import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ExceptionalHoursView } from '../../../../models/exceptionalHoursView/exceptional-hours-view.model';
import { Facility } from '../../../../models/facility/facility.model';
import { Restaurant } from '../../../../models/restaurant/restaurant.model';

@Component({
  selector: 'app-exceptional-hours-configured-list',
  templateUrl: './exceptional-hours-configured-list.component.html',
  styleUrls: ['./exceptional-hours-configured-list.component.scss'],
})
export class ExceptionalHoursConfiguredListComponent implements OnInit {
  @Input() exceptionalHoursTab: ExceptionalHoursView[];
  @Input() restaurant: Restaurant;
  @Input() facilities: Facility[];

  @Output() exceptionalHoursUpdate: EventEmitter<boolean> = new EventEmitter<boolean>();

  isLoading = false;
  haveExceptionalHours = false;

  responseToUpdate: boolean;

  constructor() {}

  ngOnInit(): void {
    this.updateResponse();
    this.haveExceptionalHours = this.exceptionalHoursTab.length !== 0;
  }

  toReloadExceptionalHours(): void {
    this.responseToUpdate = true;
    this.updateResponse();
  }

  private updateResponse(): void {
    if (this.responseToUpdate) {
      this.exceptionalHoursUpdate.emit(true);
    }
  }
}
