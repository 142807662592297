<header class="header-faq">
  <button *ngIf="isAnswerView" class="return-button" aria-label="retour" (click)="goQuestionsView()">
    <mat-icon>keyboard_arrow_left</mat-icon>
  </button>
  <span class="header-title">Foire aux Questions</span>
  <button class="close-button" aria-label="fermer" (click)="quitFaq()">
    <mat-icon>close</mat-icon>
  </button>
</header>

<div *ngIf="!isAnswerView; else answerView" class="question-list">
  <div *ngFor="let category of faq.categories">
    <section class="questions">
      <div class="category">
        <div class="category-title">
          <div class="title"><img alt="category-picto" src="assets/faq/{{ category.picto }}" />{{ category.title }}</div>
          <div class="total">{{ category.Questions.length }} question(s)</div>
        </div>
        <div *ngFor="let question of category.Questions">
          <ul>
            <li>
              <a class="question" (click)="goAnswerView(question)">
                <span>{{ question.title }}</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </section>
  </div>
</div>
<ng-template #answerView>
  <div class="answer-div">
    <section class="answer">
      <p class="title">{{ currentQuestion.title }}</p>
      <div *ngFor="let answer of currentQuestion.answer">
        <p *ngIf="answer.text" class="text">{{ answer.text }}</p>
        <div *ngIf="answer.picture" class="picture">
          <img src="assets/faq/{{ answer.picture }}" alt="" />
        </div>
      </div>
    </section>
  </div>
</ng-template>
