import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { ConnectService } from '../service/connect/connect.service';
import { HttpCancelService } from '../service/http-cancel/http-cancel.service';
import { ConsoleLoggerService } from '../service/logger/console-logger.service';
import {ErrorService} from "../service/error/error.service";

@Injectable()
export class HeaderHttpInterceptor implements HttpInterceptor {
  isRefreshingToken = false;
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(
      private readonly connectService: ConnectService,
      private readonly httpCancelService: HttpCancelService,
      private readonly CONSOLE_LOGGER: ConsoleLoggerService,
      private readonly errorService: ErrorService
  ) {
  }

  /**
   * Checks if the url in current HttpRequest matches EDD WS endpoint.
   * @param url url to check
   */
  private isWSUrl(url: string): boolean {
    const found = url.startsWith(environment.settings.apiUrl);
    return !!found;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (!this.isWSUrl(request.url.toLowerCase())) {
      return next.handle(request);
    }

    return next.handle(this.addTokenToRequest(request, this.connectService.getToken())).pipe(
      catchError((err) => {
        if (err instanceof HttpErrorResponse) {
          switch ((err as HttpErrorResponse).status) {
            case 401:
              if (err.error.error === 'invalid_token') {
                return this.handle401Error(request, next);
              }
              return throwError(err);
            case 500:
              this.CONSOLE_LOGGER.error('technical error');
              return throwError(err);
            default:
              return throwError(err);
          }
        } else {
          return throwError(err);
        }
      })
    );
  }

  private addTokenToRequest(request: HttpRequest<any>, token: string): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
        'X-Oauth2-Tenant': 'backend_users',
      },
    });
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<unknown> {
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;

      // Reset here so that the following requests wait until the token
      // comes back from the refreshToken call.
      this.tokenSubject.next(null);

      return this.connectService.refreshToken().pipe(
        switchMap((newAccessToken: string) => {
          if (newAccessToken) {
            this.tokenSubject.next(newAccessToken);
            return next.handle(this.addTokenToRequest(request, newAccessToken));
          }
          return null;
        }),
        catchError((err) => {
          this.httpCancelService.cancelPendingRequests();
          return this.connectService.runInitialLoginSequence() as any;
        }),
        finalize(() => {
          this.isRefreshingToken = false;
        })
      );
    } else {
      this.isRefreshingToken = false;
      return this.tokenSubject.pipe(
        filter((token) => token != null),
        take(1),
        switchMap((token) => {
          return next.handle(this.addTokenToRequest(request, token));
        })
      );
    }
  }
}
