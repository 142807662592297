<!-- HEADER -->

<div class="consumption-type-header">
  <div class="consumption-type-title">
    {{ consumptionType.label }}
  </div>

  <!--STATUS-->

  <div
    [ngClass]="[
      consumptionType.nationalActivation ? 'status-consumption-national-activation' : 'status-consumption-national-desactivation',
      isUserRestaurantManager ? '' : 'switch-facility-national-notManager'
    ]"
  >
    {{ switchLabel }}
  </div>

  <!-- Slider -->

  <div *ngIf="consumptionType.nationalActivation && isUserRestaurantManager" class="switch">
    <label>
      <!-- Check if parent is active -->
      <input
        *ngIf="isParentActive; else disabledFacility"
        type="checkbox"
        [(ngModel)]="consumptionType.restaurantActivation"
        (change)="facilityStatusChanged()"
      />
      <ng-template #disabledFacility>
        <input disabled="true" />
      </ng-template>
      <span class="slider"></span>
    </label>
  </div>
</div>

<!-- INFO -->

<div class="consumption-type-info">
  <app-important-information
    [infoBulleColor]="'var(--green-background)'"
    *ngIf="myRoomActive && eatType.EAT_IN == consumptionType.eatType && !(consumptionType.restaurantActivation && consumptionType.nationalActivation) && isParentActive"
    [backgroundColor]="'var(--white-text-color)'"
    [message]="ROOM_ACTIVE_CANDC_DESACTIVATE_MESSAGE"
  ></app-important-information>
  <app-important-information
    [infoBulleColor]="'var(--red)'"
    *ngIf="!myRoomActive && eatType.EAT_IN == consumptionType.eatType"
    [backgroundColor]="'var(--white-text-color)'"
    [message]="ROOM_DESACTIVATE_MESSAGE"
  ></app-important-information>
  <app-important-information
    [infoBulleColor]="'var(--red)'"
    *ngIf="!consumptionType.nationalActivation"
    [backgroundColor]="'var(--white-text-color)'"
    [message]="NATIONAL_MESSAGE"
  ></app-important-information>
</div>

<!-- SUBTITLE -->

<div class="consumption-type-subtitle">
  <button *ngIf="downArrow; else upArrow" class="button-see-pod" (click)="toSeePod()">
    <mat-icon>keyboard_arrow_down</mat-icon>
  </button>
  <ng-template #upArrow>
    <button class="button-see-pod" (click)="toSeePod()">
      <mat-icon>keyboard_arrow_up</mat-icon>
    </button>
  </ng-template>
  <div class="consumption-type-pod-title">Points de retrait</div>
  <span class="infobulle green" (click)="displayPodInfos()">
    <div class="infobulle-content">?</div>
  </span>
</div>

<!-- pod list -->

<div *ngIf="!downArrow" class="pod-list">
  <app-pod-list [pods]="pods" [consumptionTypeActive]="consumptionTypeActive" [eatType]="consumptionType.eatType"></app-pod-list>
</div>
