import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Role } from '../../models/role.enum';
import { UserService } from '../user/user.service';
import { ConnectService } from './connect.service';
import { ErrorService } from '../error/error.service';
import { ConsoleLoggerService } from '../logger/console-logger.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService implements CanActivate {
  constructor(
    private readonly connectService: ConnectService,
    private readonly router: Router,
    private readonly userService: UserService,
    private readonly errorService: ErrorService,
    private readonly CONSOLE_LOGGER: ConsoleLoggerService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.connectService.isDoneLoading$.pipe(
      map((e) => {
        let userInfos;
        this.userService.getUserInfo().subscribe((data) => (userInfos = data));
        if (userInfos) {
          // check if the user has at least on role
          if (
            userInfos.roles.includes(Role.MANAGER) ||
            userInfos.roles.includes(Role.ORDER_UPDATER) ||
            userInfos.roles.includes(Role.ORDER_VIEWER) ||
            userInfos.roles.includes(Role.RESTAURANT_VIEWER)
          ) {
            // check if the user has the required role for the page
            for (const role of route.data.roles) {
              if (userInfos.roles.indexOf(role) > -1) {
                return true;
              }
            }
          } else {
            this.router.navigate(['/error-role']);
          }
        } else {
          this.router.navigate(['/error']);
        }
        return false;
      }),
      catchError((err) => {
        this.CONSOLE_LOGGER.error(err);
        this.errorService.manageError(err);
        this.router.navigate(['/']);
        return of(false);
      })
    );
  }
}
