import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ExceptionalHoursView } from '../../models/exceptionalHoursView/exceptional-hours-view.model';
import { Facility } from '../../models/facility/facility.model';
import { FacilityExceptionalHours } from '../../models/facilityExceptionalHours/facility-exceptional-hours.model';
import { OrderType } from '../../models/order/order-type.enum';
import { RestaurantSalesChannels } from '../../models/restaurant/restaurant-sales-channels.model';
import { Restaurant } from '../../models/restaurant/restaurant.model';
import { Slot } from '../../models/slot.model';
import {RestaurantInformation} from "../../models/restaurant/restaurant-information.model";
import {RestaurantMetadata} from "../../models/restaurant/restaurant-metadata.model";
import {RestaurantAddress} from "../../models/restaurant/restaurant-address.model";
import {RestaurantData} from "../../models/restaurant/restaurant-data.model";

@Injectable({
  providedIn: 'root',
})
export class RestaurantService {
  RESTAURANT_BASE_ENDPOINT = `${environment.settings.apiUrl}/restaurant`;

  constructor(private readonly httpClient: HttpClient) {}

  /**
   * Find a restaurant from EDD WS by its reference.
   * @param id Reference of the restaurant
   */
  getRestaurantById(id: string): Observable<Restaurant> {
    const opts = { params: new HttpParams({ fromString: 'responseGroups=RG.RESTAURANT.MANAGEMENT_CONFIG' }) };
    return this.httpClient.get<Restaurant>(this.RESTAURANT_BASE_ENDPOINT + `/management/${id}`, opts);
  }

  /**
   * Find a list of restaurants by references.
   * @param refs List of references
   */
  getRestaurantsByRefs(refs: string[]): Observable<Restaurant[]> {
    const body = { refs };
    const opts = { params: new HttpParams({ fromString: 'responseGroups=RG.RESTAURANT.MANAGEMENT_CONFIG' }) };
    return this.httpClient
      .post<Restaurant[]>(this.RESTAURANT_BASE_ENDPOINT, body, opts)
      .pipe(
        map((data) =>
          data.map(
            (x: Restaurant) =>
              new Restaurant(
                x.ref,
                x.name.toUpperCase(),
                x.injectionConfig,
                x.nationalMcDeliveryMaxOrderAmount,
                x.mcDeliveryMaxOrderAmount,
                x.mcDeliveryAmountLastUpdateDate,
                x.mcDeliveryAmountLastUpdatedPerson,
                x.nationalCAndCMaxOrderAmount,
                x.candCMaxOrderAmount,
                x.candCAmountLastUpdateDate,
                x.candCAmountLastUpdatedPerson,
                x.availablePaymentTypes,
                x.availableDeliveryPartnersIds,
                x.comment,
                x.status,
                x.ownerRef
              )
          )
        )
      );
  }

  /**
   * Update the status of the manual injection for the given restaurant.
   * @param restaurantRef The reference of the restaurant to update.
   * @param injectionStatus The new status of the manual injection.
   */
  updateRestaurantManualInjection(restaurantRef: string, injectionStatus: boolean): Observable<Restaurant> {
    return this.httpClient.put<Restaurant>(`${this.RESTAURANT_BASE_ENDPOINT}/${restaurantRef}/manualInjection/${injectionStatus}`, null);
  }

  /**
   * Update the max amount for the given restaurant
   */
  updateMaxAmountForRestaurants(orderType: OrderType, maxAmount: number, restaurantRefs: string[]): Observable<any> {
    const body = { orderType, maxAmount, restaurantRefs };
    return this.httpClient.put<any>(`${this.RESTAURANT_BASE_ENDPOINT}/updateOrderMaxAmount`, body);
  }

  /**
   * Find the activation status of the different sales channels for the given restaurant
   * @param restaurantRef Concerned restaurant
   */
  findRestaurantSalesChannelsStatus(restaurantRef: string): Observable<RestaurantSalesChannels> {
    return this.httpClient.get<RestaurantSalesChannels>(`${this.RESTAURANT_BASE_ENDPOINT}/${restaurantRef}/management/facilitiesStatus`);
  }

  getActivatedOrderTypeOfRestaurant(restaurantRef: string): OrderType[] {
    const orderTypes = new Array();
    this.findRestaurantSalesChannelsStatus(restaurantRef).subscribe((_) => {
      if (_.clickAndCollectEnabled) {
        orderTypes.push(OrderType.C_AND_C);
      }
      if (_.deliveryEnabled) {
        orderTypes.push(OrderType.MC_DELIVERY);
      }
      if (_.externalDeliveryEnabled) {
        orderTypes.push(OrderType.LAD);
      }
    });
    return orderTypes;
  }

  /**
   * Find the opening hours of the given restaurant
   * @param restaurantRef The concerned restaurant
   */
  getRestaurantSlots(restaurantRef: string): Observable<Slot[]> {
    return this.httpClient.get<Slot[]>(`${this.RESTAURANT_BASE_ENDPOINT}/${restaurantRef}/slots`);
  }

  /**
   * Find the exceptional hours of the given restaurant
   * @param restaurantRef The concerned restaurant
   */
  getRestaurantExceptionalHours(restaurantRef: string): Observable<ExceptionalHoursView[]> {
    return this.httpClient.get<ExceptionalHoursView[]>(`${this.RESTAURANT_BASE_ENDPOINT}/${restaurantRef}/facility/exceptionalHours`);
  }

  /**
   * Create exceptional hours of the given restaurant
   * @param exceptionalHour The new exceptional hour
   * @param restaurantRef The concerned restaurant
   */
  createExceptionalHoursForRestaurants(exceptionalHour: ExceptionalHoursView, restaurantRef: string): Observable<ExceptionalHoursView[]> {
    return this.httpClient.post<ExceptionalHoursView[]>(
      `${this.RESTAURANT_BASE_ENDPOINT}/${restaurantRef}/facility/exceptionalHours`,
      exceptionalHour
    );
  }
  /**
   * Update exceptional hours of the given restaurant
   * @param exceptionalHour The new exceptional hour
   * @param restaurantRef The concerned restaurant
   */
  updateExceptionalHoursForRestaurants(exceptionalHour: ExceptionalHoursView, restaurantRef: string): Observable<ExceptionalHoursView[]> {
    return this.httpClient.put<ExceptionalHoursView[]>(
      `${this.RESTAURANT_BASE_ENDPOINT}/${restaurantRef}/facility/exceptionalHours`,
      exceptionalHour
    );
  }

  /**
   * Delete exceptional hours of the given restaurant
   * @param exceptionalHour The exceptional hour to delete
   * @param restaurantRef The concerned restaurant
   */
  deleteExceptionalHoursForRestaurantsByExceptionalHourId(restaurantRef: string, exceptionalHourId: string): Observable<any> {
    return this.httpClient.delete<any>(`${this.RESTAURANT_BASE_ENDPOINT}/${restaurantRef}/facility/exceptionalHours/${exceptionalHourId}`);
  }

  /**
   * Update the deliverer information for the given restaurants
   */
  updateDelivererInformationForRestaurants(comment: string, restaurantRefs: string[]): Observable<any> {
    const body = { comment, restaurantRefs };
    return this.httpClient.put<any>(`${this.RESTAURANT_BASE_ENDPOINT}/updateDelivererInfos`, body);
  }

  /**
   * Map into ExceptionalHoursView
   * @param Map<Facility, Slot>
   */
  transformMapIntoExceptionalHoursView(mapToTransform: Map<Facility, Slot>): ExceptionalHoursView {
    const tmpExceptionalHoursView = new ExceptionalHoursView();

    const facilities = Array.from(mapToTransform.keys());
    const slots = Array.from(mapToTransform.values());
    let index = 0;
    tmpExceptionalHoursView.slots = [];

    for (const facility of facilities) {
      if (slots[index] && slots[index].active) {
        const tmpFacility = new FacilityExceptionalHours();
        tmpFacility.facilityRef = facility.reference;
        tmpFacility.slotsDetails = slots[index].slotDetails;
        tmpFacility.exceptionalClose = slots[index].exceptionalClose;
        tmpExceptionalHoursView.slots.push(tmpFacility);
      }
      index++;
    }

    return tmpExceptionalHoursView;
  }

  /**
   * ExceptionalHoursView into Map
   * @param ExceptionalHoursView
   */
  transformExceptionalHoursViewIntoMap(exceptionalHoursToTransform: ExceptionalHoursView): Map<Facility, Slot> {
    const tmpMap = new Map<Facility, Slot>();

    const slots = [];
    const facilityTab = [];

    for (const facilityForSlot of exceptionalHoursToTransform.slots) {
      const slotDuration = new Slot(0, true, facilityForSlot.slotsDetails);
      slotDuration.exceptionalClose = facilityForSlot.exceptionalClose;
      slotDuration.configError = [];
      slots.push(slotDuration);
    }

    for (const slotTmp of exceptionalHoursToTransform.slots) {
      const facilityTmp = new Facility();
      facilityTmp.reference = slotTmp.facilityRef;
      facilityTab.push(facilityTmp);
    }

    for (let index = 0; index < exceptionalHoursToTransform.slots.length; index++) {
      tmpMap.set(facilityTab[index], slots[index]);
    }

    return tmpMap;
  }

  getRestaurantInformationById(id: string): Observable<RestaurantInformation> {
    const opts = { params: new HttpParams({ fromString:
            `responseGroups=RG.RESTAURANT.METADATAS,RG.RESTAURANT.PICTURES&metaDataKeys=tva,rcs,localisation_motorway,description,url&no_cache=${this.generateRandomString(3)}` }) };
    return this.httpClient.get<RestaurantInformation>(this.RESTAURANT_BASE_ENDPOINT + `/${id}`, opts);
  }

  saveRestaurantMetadata(restaurantRef: string, metadataList: RestaurantMetadata[]): any {
    return this.httpClient.put<any>(this.RESTAURANT_BASE_ENDPOINT + `/${restaurantRef}/metadatas/add`, { metadatas: metadataList });
  }

  updateRestaurant(restaurantRef: string, restaurantData: RestaurantData): any {
    return this.httpClient.put<any>(this.RESTAURANT_BASE_ENDPOINT + `/${restaurantRef}/information`, restaurantData);
  }

  createRestaurantAddress(restaurantRef: string, address: RestaurantAddress): any {
    address.addressType = "RESTAURANT";
    address.country = "FR";
    address.label = "RESTAURANT";
    return this.httpClient.post<any>(this.RESTAURANT_BASE_ENDPOINT + `/${restaurantRef}/address`, address);
  }

  updateRestaurantAddress(restaurantRef: string, addressId: number, address: RestaurantAddress): any {
    if (!address.addressType) {
      address.addressType = "RESTAURANT";
    }
    if (!address.country) {
      address.country = "FR";
    }
    if (!address.label) {
      address.label = "RESTAURANT";
    }
    return this.httpClient.put<any>(this.RESTAURANT_BASE_ENDPOINT + `/${restaurantRef}/address/${addressId}`, address);
  }

  private generateRandomString(length: number): string {
    return Math.random().toString(36).substr(2, length);
  }

  getLocalPageLink(restaurantRef: number, restaurantUrl: string): string {
    return `${environment.settings.mcdofrBaseUrl}/restaurants${restaurantUrl}/${restaurantRef}`;
  }
}
