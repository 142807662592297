<div class="information">
    <label for="textarea" class="information-title">{{ textAreaLabel }}</label>
    <div class="characters-count" [ngClass]="text.length >= maxLength ? 'full' : ''">
        <div class="max-caracter">{{ maxLength }} caractères max.</div>
        <circle-progress
                [percent]="percentage()"
                [maxPercent]="100"
                [radius]="140"
                [outerStrokeWidth]="30"
                [innerStrokeWidth]="15"
                [space]="-10"
                [outerStrokeColor]="text.length >= maxLength ? 'var(--red)' : '#5d5d5d'"
                [innerStrokeColor]="'#e7e8ea'"
                [showSubtitle]="false"
                [showUnits]="false"
                [showTitle]="false"
                [animation]="true"
                [animationDuration]="300"
                [startFromZero]="false"
                [responsive]="true"
                style="{height: 32px;}"
        ></circle-progress>
    </div>
    <br/>
    <textarea id="textarea" class="information-value" [(ngModel)]="text" (input)="updateText()" maxlength="255"></textarea>
</div>