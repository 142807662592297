import { Component, Input, OnInit } from '@angular/core';
import { Slot } from '../../../../models/slot.model';
import { SlotService } from '../../../../service/slot/slot.service';

@Component({
  selector: 'app-days-section',
  templateUrl: './days-section.component.html',
  styleUrls: ['./days-section.component.scss'],
})
export class DaysSectionComponent implements OnInit {
  @Input() slots: Slot[];
  @Input() objectIsActive: boolean;

  constructor(private readonly slotService: SlotService) {}

  ngOnInit(): void {}

  /**
   * Get the first character of the string value of a day from its numeric value.
   * Example : 1=L, 2=M, etc...
   * @param day day as number
   */
  public dayAsChar(day: number): string {
    return this.slotService.dayAsString(day).charAt(0);
  }
}
