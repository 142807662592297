export enum FacilityAdministrationType {
  /**
   * The facility type is Restauration
   */
  RESTAURATION = 'RESTAURATION',

  /**
   * The facility type is Equipment
   */
  EQUIPMENT = 'EQUIPMENT',

  /**
   * The facility type is Other
   */
  OTHER = 'OTHER',
}
