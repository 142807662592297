<div *ngIf="isLoading; else loader">
    <app-loader></app-loader>
</div>

<ng-template #loader>
    <div class="modal-header">
        <label class="title">Modification du point <br /> de localisation</label>
        <div id="woosmap-drag"></div>
        <br />
        <div class="footer-content">
            <button id="footer-next-button" (click)="confirm()">Confirmer</button>
            <br />
            <button id="footer-back-button" (click)="backToEditInfos()">Précédent</button>
        </div>
    </div>
</ng-template>