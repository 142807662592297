import {Component, EventEmitter, OnInit, Output} from "@angular/core";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {get} from "scriptjs";
import {
    RestaurantInformationEditModalComponent
} from "./restaurant-information-edit-modal/restaurant-information-edit-modal.component";
import {UserService} from "../../../service/user/user.service";
import {Role} from "../../../models/role.enum";
import {RestaurantService} from "../../../service/restaurant/restaurant.service";
import {ErrorService} from "../../../service/error/error.service";
import {ConsoleLoggerService} from "../../../service/logger/console-logger.service";
import {PersistanceService} from "../../../service/persistance/persistance.service";
import {Restaurant} from "../../../models/restaurant/restaurant.model";
import {RestaurantAddress} from "../../../models/restaurant/restaurant-address.model";
import {environment} from "../../../environments/environment";
import {RestaurantCoordinates} from "../../../models/restaurant/restaurant-coordinates.model";
import {MultimediaService} from "../../../service/multimedia/multimedia.service";
import {MultimediaView} from "../../../models/multimedia/multimedia-view.model";
import {
    RestaurantAdditionalInformationModalComponent
} from "./restaurant-information-edit-modal/tooltip/restaurant-additional-information-modal.component";
import {
    RestaurantAboutModalComponent
} from "./restaurant-information-edit-modal/tooltip/restaurant-about-modal.component";

declare var woosmap;

@Component({
    selector: 'app-restaurant-information',
    templateUrl: './restaurant-information.component.html',
    styleUrls: ['./restaurant-information.component.scss']
})
export class RestaurantInformationComponent implements OnInit {
    isLoading = false;

    isUserRestaurantManager = false;

    viewRestaurantInformation = false;

    restaurant: Restaurant;

    address: string;
    coordinates: RestaurantCoordinates;
    additionalInformation: string;
    phone: string;
    TVANumber: string;
    RCSNumber: string;
    about: string;
    restaurantUrl: string;
    pictures: Array<MultimediaView> = [];
    @Output() exceptionalHours: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(
        public matDialog: MatDialog,
        private readonly userService: UserService,
        private readonly restaurantService: RestaurantService,
        private readonly errorService: ErrorService,
        private readonly CONSOLE_LOGGER: ConsoleLoggerService,
        private readonly persistanceService: PersistanceService,
        private readonly multimediaService: MultimediaService
    ) {
    }

    ngOnInit(): void {
        this.checkUserRole();
        this.restaurant = this.persistanceService.get('current_restaurant');
        get(`${environment.settings.woosmapUrl}?key=${environment.settings.woosmapKey}`, () => {
            this.initRestaurantInformation();
        });
    }

    private initRestaurantInformation(): void {
        this.restaurantService.getRestaurantInformationById(this.restaurant.ref.toString()).subscribe(
            (restaurantInformationTmp) => {
                this.isLoading = false;
                this.address = restaurantInformationTmp?.restaurantAddress && restaurantInformationTmp?.restaurantAddress.length > 0 ? this.parseAddressFromRestaurantAddress(restaurantInformationTmp.restaurantAddress[0]) : "";
                this.coordinates = restaurantInformationTmp?.coordinates ?? new RestaurantCoordinates();
                this.phone = restaurantInformationTmp?.phone ?? "";
                this.getMetadatas(restaurantInformationTmp.metadatas);
                for (const picture of restaurantInformationTmp.pictures) {
                    if (picture.types.includes("illustration0") || picture.types.includes("illustration1")) {
                        this.pictures.push(picture);
                    }
                }
            },
            (error) => {
                this.CONSOLE_LOGGER.error('error during update of restaurant informations', error);
                this.errorService.manageError(error);
            }
        );
    }

    private getMetadatas(metadatas: any): void {
        if (metadatas !== undefined) {
            this.TVANumber = metadatas.find(obj => obj.key === "tva")?.value ?? "";
            this.RCSNumber = metadatas.find(obj => obj.key === "rcs")?.value ?? "";
            this.additionalInformation = metadatas.find(obj => obj.key === "localisation_motorway")?.value ?? "";
            this.about = metadatas.find(obj => obj.key === "description")?.value ?? "";
            const url = metadatas.find(obj => obj.key === "url")?.value ?? "/mcdonalds";
            this.restaurantUrl = this.restaurantService.getLocalPageLink(this.restaurant.ref, url);
        }
    }

    initMap(coordinates: RestaurantCoordinates): void {
        if (coordinates) {
            try {
                const map = new woosmap.map.Map(document.getElementById('woosmap'), {
                    center: {
                        lat: coordinates?.latitude ?? 0,
                        lng: coordinates?.longitude ?? 0,
                    },
                    zoom: 18,
                });
                const marker = new woosmap.map.Marker({
                    position: map.getCenter(),
                    icon: {
                        url: environment.settings.woosmapDotMarkerUrl,
                        scaledSize: {
                            height: 64,
                            width: 46
                        }
                    }
                });
                marker.setMap(map);
            } catch (e) {
                this.CONSOLE_LOGGER.error('error during update of woosmap', e);
                this.errorService.manageError(e);
            }
        }
    }

    private parseAddressFromRestaurantAddress(restaurantAddress: RestaurantAddress): string {
        let address = "";
        address = address + restaurantAddress.address1 + " ";
        if (restaurantAddress.address2 !== undefined) {
            address = address + restaurantAddress.address2 + " ";
        }
        if (restaurantAddress.address3 !== undefined) {
            address = address + restaurantAddress.address3 + " ";
        }
        if (restaurantAddress.address4 !== undefined) {
            address = address + restaurantAddress.address4;
        }
        address = address + restaurantAddress.zipCode.toString();
        address = address + " - ";
        address = address + restaurantAddress.city;
        return address;
    }

    toggleView(): void {
        this.viewRestaurantInformation = !this.viewRestaurantInformation;
        this.initMap(this.coordinates);
    }

    toggleLabel(): string {
        if (this.viewRestaurantInformation) {
            return "Voir moins";
        } else {
            return "Voir les informations";
        }
    }

    clickToEdit(): void {
        this.editRestaurantInformation();
    }

    /**
     * Check if the user has edit role or not
     */
    private checkUserRole(): void {
        this.userService.getUserInfo().subscribe((userInfos) => {
            if (userInfos) {
                this.isUserRestaurantManager = userInfos.roles.includes(Role.MANAGER);
            }
        });
    }

    editRestaurantInformation(): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.id = 'restaurant-information-edit-modal';
        dialogConfig.maxWidth = '100vw';
        dialogConfig.height = '96%';
        dialogConfig.width = '96%';
        dialogConfig.data = {};
        if (this.viewRestaurantInformation) {
            this.toggleView();
        }
        this.matDialog
            .open(RestaurantInformationEditModalComponent, dialogConfig)
            .afterClosed()
            .subscribe((response) => {
                this.pictures = [];
                this.initRestaurantInformation();
            });
    }

    getPicture(index): string {
        return environment.settings.mediaUrl + this.pictures[index].url;
    }

    displayAddtionalInformation(): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.id = 'additional-information-modal-component';
        dialogConfig.maxWidth = '100vw';
        dialogConfig.height = '96%';
        dialogConfig.width = '94%';
        dialogConfig.data = {};
        this.matDialog.open(RestaurantAdditionalInformationModalComponent, dialogConfig);
    }

    displayAbout(): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.id = 'about-modal-component';
        dialogConfig.maxWidth = '100vw';
        dialogConfig.height = '96%';
        dialogConfig.width = '94%';
        dialogConfig.data = {};
        this.matDialog.open(RestaurantAboutModalComponent, dialogConfig);
    }
}
