<app-restaurant-header [forceCollapse]="true"></app-restaurant-header>
<div id="orders-parking">
    <div *ngIf="isLoading; else loaded">
        <app-loader></app-loader>
    </div>
    <ng-template #loaded>
        <div id="main-content" class="main-content" #content>
            <div *ngIf="allOrders.length > 0" id="search-zone" #searchZone>
                <div id="status-filter">
                    <mat-select class="mat-select-filter" (selectionChange)="applyStatusFilter()"
                                [(ngModel)]="statusFilter"
                                [disableOptionCentering]="true">
                        <mat-select-trigger>
                            <div class="option-content-status">
                                <img src="assets/medias/sliders.svg" alt="mcdo_orders_empty" class="icon-filter"/>
                                <span> {{ getSelectedFilter(statusFilter.value) }}</span>
                            </div>
                        </mat-select-trigger>
                        <mat-option *ngFor="let filter of filters" [value]="filter">
                            <div class="option-content-status">
                                <span> {{ getSelectedFilter(filter.value) }}</span>
                            </div>
                        </mat-option>
                    </mat-select>
                </div>

                <div class="search-order">
                    <button mat-icon-button id="search-order-button">
                        <mat-icon>search</mat-icon>
                    </button>
                    <input (click)="searchSticky()" (focusout)="loseFocus()" matInput type="search"
                           placeholder="{{ inputPlaceholder }}" [(ngModel)]="inputSearch"
                           (ngModelChange)="onEnterText()"
                           (keyup.enter)="searchValidated()" [maxlength]="orderNumberMaxLength"
                           id="search-order-input"/>
                </div>
            </div>

            <ng-container *ngIf="filteredOrders && filteredOrders.length; else noOrders">
                <div class="orders-list" #orderZone>
                    <div *ngFor="let order of filteredOrders">
                        <div class="day-separator" *ngIf="order.dayFilter && order.dayFilter.name">
                            <span class="day">{{ order.dayFilter.name }}</span>
                            <span class="date">{{ order.dayFilter.startDate | date: 'EEEE d MMMM':'':'fr' }}</span>
                        </div>
                        <app-order-parking-line [orderErrors]="orderErrors" [order]="order"></app-order-parking-line>
                    </div>
                </div>
            </ng-container>
            <ng-template #noOrders>
                <div class="search-list-empty" #orderZone>
                    <div class="no-result-img">
                        <div class="empty-image-div">
                            <img src="assets/medias/search-no-order.svg" alt="orders_list_empty"
                                 class="orders-list-empty-icon"/>
                        </div>
                        <p *ngIf="statusFilter.value == 'ALL' && inputSearch.length == 0" class="no-result-text">{{ emptyOrderError }}</p>
                        <p *ngIf="statusFilter.value != 'ALL' || (statusFilter.value == 'ALL' && inputSearch.length != 0)" class="no-result-text">{{ noOrderFilterError }}</p>
                    </div>
                </div>
            </ng-template>
        </div>
    </ng-template>
</div>

<app-scroll-to-top></app-scroll-to-top>
