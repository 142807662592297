<div *ngIf="isLoading">
  <app-loader></app-loader>
</div>

<div [ngSwitch]="screenToShow">
  <div id="modal-header">
    <div class="modal-title">POINT DE RETRAIT</div>
    <a>
      <mat-icon id="close-modal-button" (click)="closeModal()">close</mat-icon>
    </a>
    <div class="pod-name">
      {{ getWording() }}
    </div>
    <div class="restaurant-name">
      {{ restaurant?.name }}
    </div>
  </div>

  <!-- Edition des informations du POD (statut, message, horaires...) -->
  <app-edit-pod-informations
    [facility]="facility"
    *ngSwitchCase="'pod-infos'"
    [lastUpdateMessage]="getLastUpdateMessage()"
    [currentPod]="podCopy"
    [oneDayActive]="oneDayActive"
  ></app-edit-pod-informations>
  <!-- OU au click sur suivant (sauf profil mono-restaurant) -->
  <!-- Application des changements sur plusieurs restaurants  -->
  <app-edit-multi-restaurants
    style="box-sizing: border-box; overflow-x: hidden"
    *ngSwitchCase="'apply-to-restaurants'"
    [baseRestaurant]="restaurant.ref"
    [chosenRestaurants]="restaurantsToSave"
    [modalName]="'edit-pod-modal-component'"
  ></app-edit-multi-restaurants>

  <div *ngSwitchCase="'pod-infos'" class="modal-footer" id="modal-footer">
    <div class="footer-content">
      <button id="footer-next-button" (click)="next()">Suivant</button>
    </div>
  </div>
  <div *ngSwitchCase="'apply-to-restaurants'" class="modal-footer two-btn" id="modal-footer">
    <div class="footer-content">
      <button id="footer-next-button" (click)="next()">Suivant</button>
      <button id="footer-back-button" (click)="backToEditInfos()">Précédent</button>
    </div>
  </div>
</div>
