import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.css'],
})
export class ConfirmModalComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<ConfirmModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(): void {}

  /**
   * Close the confirm modal
   * @param quit True if user confirmed to quit, false if he cancelled
   */
  closeModal(quit: boolean): void {
    this.dialogRef.close(quit);
  }

  /**
   * Close the confirm modal and indicate to display the page: 'apply-to-restaurant'
   */
  applyToRestaurant(): void {
    this.dialogRef.close('apply-to-restaurants');
  }
}
