import { AfterViewChecked, Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { EatType } from '../../../models/eat-type.enum';
import { Pod } from '../../../models/pod/pod.model';

@Component({
  selector: 'app-pod-list',
  templateUrl: './pod-list.component.html',
  styleUrls: ['./pod-list.component.css'],
})
export class PodListComponent implements OnInit {
  @Input() eatType: EatType;
  @Input() consumptionTypeActive;
  @Input() pods: Pod[];

  constructor() {}

  ngOnInit(): void {}

  hasElements(list: any[]): boolean {
    return list && list.length > 0;
  }
}
