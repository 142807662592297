<div [ngClass]="haveExceptionalHours ? 'exceptional-hours-configured' : 'no-exceptional-hours-configured'">
  <!-- LOADER -->
  <div *ngIf="isLoading; else loader">
    <app-loader></app-loader>
  </div>

  <!-- ONGLET CONTENT -->

  <ng-template #loader>
    <div *ngFor="let exceptionalHour of exceptionalHoursTab">
      <app-exceptional-hours-configured
        [facilities]="facilities"
        [existingExceptionalHours]="exceptionalHoursTab"
        [exceptionalHour]="exceptionalHour"
        (exceptionalHoursUpdate)="toReloadExceptionalHours()"
      ></app-exceptional-hours-configured>
    </div>
  </ng-template>
</div>
