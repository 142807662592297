import { LogLevel } from './log-level.enum';

export class Log {
  /**
   * Error message logging level
   */
  level: LogLevel = LogLevel.DEBUG;
  /**
   * The error message
   */
  message: string;
  /**
   * Id of the error message to find it in log
   */
  extraInfo: any[] = [];

  buildLogString(): string {
    let ret = '';

    ret = new Date() + ' - ';

    ret += 'Type: ' + LogLevel[this.level];
    ret += ' - Message: ' + this.message;
    if (this.extraInfo.length) {
      ret += ' - Extra Info: ' + this.formatParams(this.extraInfo);
    }

    return ret;
  }

  private formatParams(params: any[]): string {
    let ret: string = params.join(',');

    // Is there at least one object in the array?
    if (params.some((p) => typeof p === 'object')) {
      ret = '';

      // Build comma-delimited string
      for (const item of params) {
        ret += JSON.stringify(item) + ',';
      }
    }

    return ret;
  }
}
