import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import { Facility } from '../../../../../models/facility/facility.model';
import { FacilityService } from '../../../../../service/facility/facility.service';
import { EatType } from '../../../../../models/eat-type.enum';
import { CustomSnackbarComponent } from '../../../../common/custom-snackbar/custom-snackbar.component';
import { SlotService } from '../../../../../service/slot/slot.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Restaurant } from '../../../../../models/restaurant/restaurant.model';
import { PersistanceService } from '../../../../../service/persistance/persistance.service';
import { Pod } from '../../../../../models/pod/pod.model';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { PodInfosModalComponent } from './pod-infos-modal/pod-infos-modal.component';
import { Role } from '../../../../../models/role.enum';
import { UserService } from '../../../../../service/user/user.service';
import { ErrorService } from '../../../../../service/error/error.service';
import { ConsoleLoggerService } from '../../../../../service/logger/console-logger.service';
import { FacilityType } from '../../../../../models/facility/facilityType.enum';
import { ConfirmModalComponent } from '../../../../common/confirm-modal/confirm-modal.component';
import { DialogConfigUtilsService } from '../../../../../service/utils/dialog.utils.service';

@Component({
  selector: 'app-consumption-type',
  templateUrl: './consumption-type.component.html',
  styleUrls: ['./consumption-type.component.scss'],
})
export class ConsumptionTypeComponent implements OnInit, OnChanges {
  @Input() consumptionType: Facility;
  @Input() isParentActive: boolean;
  @Input() pods: Pod[];

  @Output() reloadFacilities: EventEmitter<boolean> = new EventEmitter<boolean>();

  myRoomActive: boolean;
  downArrow = true;
  eatType: typeof EatType = EatType;
  restaurant: Restaurant;

  switchLabel: string;
  consumptionTypeActive: boolean;

  isUserRestaurantManager = false;

  ROOM_DESACTIVATE_MESSAGE = 'En raison de la désactivation du canal de vente Salle, le paramétrage est impossible';
  ROOM_ACTIVE_CANDC_DESACTIVATE_MESSAGE = 'Votre canal « Click & Collect -- Sur place » est désactivé alors que votre canal de vente « Ma salle » est disponible.';
  NATIONAL_MESSAGE = 'En raison d’une mesure nationale, le paramétrage n’est pas disponible.';

  constructor(
    private readonly facilityService: FacilityService,
    private readonly slotService: SlotService,
    private readonly snackBar: MatSnackBar,
    private readonly persistanceService: PersistanceService,
    public matDialog: MatDialog,
    private readonly userService: UserService,
    private readonly errorService: ErrorService,
    private readonly CONSOLE_LOGGER: ConsoleLoggerService
  ) {}

  ngOnInit(): void {
    this.checkUserRole();
    this.restaurant = this.persistanceService.get('current_restaurant');
  }

  // This method checks the changes and is called before the method ngOnInit()
  ngOnChanges(simplesChanges: SimpleChanges): void {
    // Initialize
    this.myRoomActive = this.facilityService.myRoomStatus;
    this.switchLabelUpdate();
  }

  /**
   * set the cursor sense
   */
  toSeePod(): void {
    this.downArrow = !this.downArrow;
  }

  /**
   * Check if the user has edit role or not
   */
  private checkUserRole(): void {
    this.userService.getUserInfo().subscribe((userInfos) => {
      if (userInfos) {
        this.isUserRestaurantManager = userInfos.roles.includes(Role.MANAGER);
      }
    });
  }

  /**
   * When switch button is triggered, call EDD WS to change Facility restaurant status.
   */
  facilityStatusChanged(): void {
    // Confirm modal for facilities "EAT IN" before update in case of deactivation
    if (this.consumptionType.reference === FacilityType.EAT_IN && !this.consumptionType.restaurantActivation) {
      const modalDialog = this.openDeactivationEatInConfirmModal();
      modalDialog.afterClosed().subscribe((message) => {
        if (message === true) {
          this.updateFacilityRestaurantActivation();
        } else {
          this.consumptionType.restaurantActivation = !this.consumptionType.restaurantActivation;
        }
      });
    } else {
      this.updateFacilityRestaurantActivation();
    }
  }

  /**
   * Do the status update of the current consumption type facility
   */
  private updateFacilityRestaurantActivation(): void {
    this.switchLabelUpdate();
    this.snackBar.openFromComponent(CustomSnackbarComponent, {
      data: `Le mode Click & Collect ${this.consumptionType.label} est ${this.switchLabel}`, // message to be shown
      duration: 5000, // duration in ms
    });
    CustomSnackbarComponent.subData = '';
    CustomSnackbarComponent.actionText = '';
    // Appel au Ws
    this.facilityService
      .updateFacilityRestaurantActivation(
        this.restaurant.ref.toString(),
        this.consumptionType.reference,
        this.consumptionType.restaurantActivation
      )
      .subscribe(
        (updateFacility) => {
          this.consumptionType = updateFacility;
          this.emitReloadFacilities();
        },
        (error) => {
          this.CONSOLE_LOGGER.error('error');
          this.errorService.manageError(error);
        }
      );
  }

  /**
   * Manage modal of deactivation confirmation for facility of consumption type "eat in"
   */
  private openDeactivationEatInConfirmModal(): MatDialogRef<ConfirmModalComponent> {
    const bodyText = 'La désactivation du mode de consommation “Sur place” entrainera l’indisponibilité de ses points de retrait.';

    const dialogConfig = new DialogConfigUtilsService().getDialogConfig('confirm-modal', 'Êtes-vous sûr ?', 'CONFIRMER', 'ANNULER', 'deactivation', '100%', false, null, null, bodyText);

    return this.matDialog.open(ConfirmModalComponent, dialogConfig);
  }

  /**
   * change status label
   */
  switchLabelUpdate(): void {
    if (this.consumptionType.eatType === EatType.EAT_IN) {
      this.isParentActive = this.isParentActive && this.myRoomActive;
    }
    this.switchLabel =
      this.consumptionType.restaurantActivation && this.consumptionType.nationalActivation && this.isParentActive ? 'actif' : 'inactif';
    this.setConsumptionTypeStatus();
  }

  /**
   * set the status of consumption type
   */
  setConsumptionTypeStatus(): void {
    this.consumptionTypeActive = this.switchLabel === 'actif' ? true : false;
  }

  /**
   * Open the modal allowing to display pod infos
   */
  displayPodInfos(): void {
    const dialogConfig = new MatDialogConfig();
    // The user can't close the dialog by clicking outside its body
    dialogConfig.disableClose = true;
    dialogConfig.id = 'pod-infos-modal-component';
    dialogConfig.maxWidth = '100vw';
    dialogConfig.height = '96%';
    dialogConfig.width = '94%';
    dialogConfig.data = { pods: this.pods, consumptionType: this.consumptionType };
    this.matDialog.open(PodInfosModalComponent, dialogConfig);
  }

  /**
   * Emit to parent to reload Facilities
   */
  emitReloadFacilities(): void {
    this.reloadFacilities.emit(true);
  }
}
