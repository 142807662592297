<!-- HEADER -->

<div id="modal-header">
  <div class="modal-title">POINTS DE RETRAIT</div>
  <a>
    <mat-icon id="close-modal-button" (click)="closeModal()">close</mat-icon>
  </a>
  <div class="consumption-type-name">
    {{ consumptionType?.label }}
  </div>
</div>

<div id="modal-body">
  <div *ngFor="let pod of pods">
    <app-pod-infos-line [pod]="pod"></app-pod-infos-line>
  </div>
</div>
