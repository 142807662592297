<div *ngIf="isLoading; else loader">
  <app-loader></app-loader>
</div>

<ng-template #loader>
  <div [ngSwitch]="screenToShow">
    <div id="modal-header">
      <div class="modal-title">POINTS DE VENTE</div>
      <a>
        <mat-icon id="close-modal-button" (click)="closeModal()">close</mat-icon>
      </a>
      <div class="consumption-type-name">Horaires exceptionnels</div>
      <div class="restaurant-name">
        {{ restaurant?.name }}
      </div>
    </div>

    <app-exceptional-hours-edit
      *ngSwitchCase="'create-hours'"
      #exceptionalHoursEditComponent
      [exceptionalHoursName]="exceptionalHoursName"
      [beginDate]="beginDate"
      [isDuration]="isDuration"
      [endDate]="endDate"
      (updateValue)="updateValue($event)"
    ></app-exceptional-hours-edit>
    <app-exceptional-hours-facilities
      *ngSwitchCase="'select-facilities'"
      #exceptionalHoursFacilityComponent
      [facilities]="facilities"
      [isDuration]="isDuration"
      [beginDate]="beginDate"
      [endDate]="endDate"
      [exceptionalHoursName]="exceptionalHoursName"
    >
    </app-exceptional-hours-facilities>

    <div *ngSwitchCase="'create-hours'" class="modal-footer" id="modal-footer">
      <div class="footer-content">
        <button id="footer-next-button" (click)="next()">Suivant</button>
      </div>
    </div>
    <div *ngSwitchCase="'select-facilities'" class="modal-footer two-btn" id="modal-footer">
      <div class="footer-content">
        <button id="footer-next-button" (click)="next()">Suivant</button>
        <button id="footer-back-button" (click)="backToEditInfos()">Précédent</button>
      </div>
    </div>
  </div>
</ng-template>
