export enum PodType {
  COMPTOIR_EAT_IN = 'COMPTOIR_EAT_IN',
  COMPTOIR_TAKE_AWAY = 'COMPTOIR_TAKE_AWAY',
  CURBSIDE = 'CURBSIDE',
  DRIVE_PIETON = 'DRIVE_PIETON',
  KIOSK_EAT_IN = 'KIOSK_EAT_IN',
  KIOSK_TAKE_AWAY = 'KIOSK_TAKE_AWAY',
  MCDRIVE = 'MCDRIVE',
  PARKING = 'PARKING',
  SAT = 'SAT',
}
