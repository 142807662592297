import { AfterViewChecked, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { switchMap } from 'rxjs/operators';
import { ExceptionalHoursView } from '../../models/exceptionalHoursView/exceptional-hours-view.model';
import { Facility } from '../../models/facility/facility.model';
import { OrderType } from '../../models/order/order-type.enum';
import { RestaurantSalesChannels } from '../../models/restaurant/restaurant-sales-channels.model';
import { Restaurant } from '../../models/restaurant/restaurant.model';
import { ErrorService } from '../../service/error/error.service';
import { FacilityService } from '../../service/facility/facility.service';
import { ConsoleLoggerService } from '../../service/logger/console-logger.service';
import { NavBarService } from '../../service/nav-bar/nav-bar.service';
import { PersistanceService } from '../../service/persistance/persistance.service';
import { RestaurantService } from '../../service/restaurant/restaurant.service';
import { ManualInjectionInfosModalComponent } from './manual-injection-infos-modal/manual-injection-infos-modal.component';

@Component({
  selector: 'app-restaurant-settings',
  templateUrl: './restaurant-settings.component.html',
  styleUrls: ['./restaurant-settings.component.scss'],
})
export class RestaurantSettingsComponent implements OnInit, OnDestroy, AfterViewChecked {
  constructor(
    private readonly navBarService: NavBarService,
    private readonly persistanceService: PersistanceService,
    private readonly restaurantService: RestaurantService,
    private readonly facilityService: FacilityService,
    private readonly errorService: ErrorService,
    public matDialog: MatDialog,
    private readonly CONSOLE_LOGGER: ConsoleLoggerService
  ) {}
  @ViewChild('content') content: ElementRef;
  restaurant: Restaurant;
  isLoading = true;
  showInjectionTitle = true;
  salesChannels: RestaurantSalesChannels;
  orderType: typeof OrderType = OrderType;
  downArrow = true;

  facilities: Facility[] = [];
  allFacilities: Facility[] = [];

  exceptionalHoursTab: ExceptionalHoursView[] = [];
  haveExceptionalHours = false;
  haveFacilities = false;

  ngOnInit(): void {
    this.getRestaurantInfos();
    this.navBarService.showNavBar(true);
    this.persistanceService.set('redirectPage', '/restaurant/settings');
  }

  /**
   * Call WS to find restaurant by its reference.
   */
  getRestaurantInfos(): void {
    this.isLoading = true;
    const persistedRestaurant = this.persistanceService.get('current_restaurant');
    this.restaurantService
      .getRestaurantById(persistedRestaurant.ref.toString())
      .pipe(
        switchMap((restaurant) => {
          this.restaurant = restaurant;
          return this.restaurantService.findRestaurantSalesChannelsStatus(this.restaurant.ref.toString());
        })
      )
      .subscribe((saleChannels) => {
        this.salesChannels = saleChannels;
        this.getExceptionalHoursTab();
        this.loadFacilities();
        this.isLoading = false;
      });
  }

  ngOnDestroy(): void {
    this.navBarService.showNavBar(false);
    this.persistanceService.remove('redirectPage');
  }

  ngAfterViewChecked(): void {
    this.setPaddingTop(document.getElementById('header').offsetHeight);
  }

  @HostListener('window:resize')
  onResize(): void {
    this.setPaddingTop(document.getElementById('header').offsetHeight);
  }

  @HostListener('window:scroll')
  onScroll(): void {
    if (document.documentElement.scrollTop > 1) {
      document.getElementById('main-content').style.paddingBottom = '150px';
    } else {
      document.getElementById('main-content').style.paddingBottom = '42px';
    }
    this.setPaddingTop(document.getElementById('header').offsetHeight);
  }

  @HostListener('click')
  onClick(): void {
    this.setPaddingTop(document.getElementById('header').offsetHeight);
  }

  setPaddingTop(height: number): void {
    this.content.nativeElement.style.setProperty('margin-top', `${height}px`);
    if (document.getElementById('nav-bar') && !document.getElementById('nav-bar-component').classList.contains('nav-bar-tablet-visible')) {
      this.content.nativeElement.style.setProperty('margin-bottom', `${document.getElementById('nav-bar').offsetHeight}px`);
    } else {
      this.content.nativeElement.style.setProperty('margin-bottom', '0px');
    }
  }
  reloadRestaurantInfos($event: any): void {
    if ($event) {
      this.getRestaurantInfos();
    }
  }

  /**
   * Open the modal allowing to display pod infos
   */
  displayManualInjectionInfos(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = 'manual-injection-modal-component';
    dialogConfig.maxWidth = '100vw';
    dialogConfig.height = '96%';
    dialogConfig.width = '94%';
    dialogConfig.data = {};
    this.matDialog.open(ManualInjectionInfosModalComponent, dialogConfig);
  }

  hideTitle($event: boolean): void {
    this.showInjectionTitle = !$event;
  }

  /**
   * set the cursor sense
   */
  toSeeExceptionalHours(): void {
    this.downArrow = !this.downArrow;
  }

  /**
   * get the exceptional hours tab of current Restaurant
   */
  getExceptionalHoursTab(): void {
    this.restaurantService.getRestaurantExceptionalHours(this.restaurant.ref.toString()).subscribe((exceptionalHoursViewTab) => {
      exceptionalHoursViewTab.sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime());
      this.exceptionalHoursTab = exceptionalHoursViewTab;
      this.haveExceptionalHours = this.exceptionalHoursTab.length !== 0;
      this.isLoading = false;
    });
  }

  /**
   *
   */
  loadFacilities(): void {
    this.facilityService.getFacilities(this.restaurant.ref).subscribe(
      (facilities) => {
        // get all facilities compatible with exceptional hours
        this.allFacilities = facilities;
        this.facilities = this.facilityService.getFacilitiesCompatibleWithExceptionalHours(facilities);
        this.isLoading = false;
        this.haveFacilities = true;
      },
      (error) => {
        this.CONSOLE_LOGGER.error('error : not loading all facilities of the current restaurant');
        this.errorService.manageError(error);
        this.isLoading = false;
      }
    );
  }

  toReloadExceptionalHours(): void {
    this.isLoading = true;
    this.getExceptionalHoursTab();
  }
}
