<div class="exceptional-hours-view">
  <div *ngFor="let slot of newExceptionalHours.slots" style="display: inline-block; margin-left: 2px">
    <div *ngIf="slotIsActive(slot.slotsDetails) then exceptional; else inactive" class="facility-day-letter-list">
    </div>
    <ng-template #exceptional>
      <div id="{{ slotIsExceptionalClosing(slot) ? 'service-exceptional-hours-closing' : 'service-exceptional-hours-active' }}"
           class="facility-day-letter-list">
        <p class="service-exceptional-hours">{{ getFacilityLegendLabel(slot.facilityRef) }}</p>
      </div>
    </ng-template>
    <ng-template #inactive>
      <div id="service-exceptional-hours-inactive" class="facility-day-letter-list">
        <p class="service-exceptional-hours">{{ getFacilityLegendLabel(slot.facilityRef) }}</p>
      </div>
    </ng-template>
    </div>
  </div>
<div class="exceptional-hours-details" style="color: var(--white-text-color)">
  <div *ngFor="let slot of newExceptionalHours.slots" style="margin-left: 5px">
    <div style="display: inline-flex; margin-bottom: 10px">
      <div *ngIf="slotIsActive(slot.slotsDetails); then exceptionalBlock else inactiveBlock">
      </div>
      <ng-template #exceptionalBlock>
        <span id="{{ slotIsExceptionalClosing(slot) ? 'service-exceptional-closing' : 'service-exceptional-active' }}">
        {{ getFacilityDetailLabel(slot.facilityRef) }}
      </span>
      </ng-template>
      <ng-template #inactiveBlock>
        <span id="service-exceptional-inactive">
        {{ getFacilityDetailLabel(slot.facilityRef) }}
      </span>
      </ng-template>
      <div class="exceptional-slots">
        <div *ngIf="slotIsActive(slot.slotsDetails); then exceptionalDay else inactiveDay" style="display: contents"></div>
        <ng-template #exceptionalDay>
          <div *ngIf="slotIsExceptionalClosing(slot); else details" class="restaurant-slots">
            <span class="exceptional-slot-closing">Fermé</span>
          </div>
        </ng-template>
          <ng-template #details>
            <div *ngIf="is24h(slot.slotsDetails)" class="restaurant-slots">
              <span class="exceptional-slot">24h/24</span>
            </div>
            <div *ngFor="let slotD of slot.slotsDetails">
              <span class="exceptional-slot"> {{ slotD.beginHour }} - {{ slotD.endHour }} </span>
            </div>
          </ng-template>
        <ng-template #inactiveDay>
          <span class="exceptional-slot-inactive">Par défaut</span>
        </ng-template>
      </div>
    </div>
  </div>
</div>
