import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { Facility } from '../../../../models/facility/facility.model';
import { Slot } from '../../../../models/slot.model';

@Component({
  selector: 'app-exceptional-hours-facilities',
  templateUrl: './exceptional-hours-facilities.component.html',
  styleUrls: ['./exceptional-hours-facilities.component.scss'],
})
export class ExceptionalHoursFacilitiesComponent implements OnInit {
  @Input()
  exceptionalHoursName: string;
  @Input()
  facilities: Map<Facility, Slot>;
  @Input()
  isDuration: boolean;
  @Input()
  isModification: boolean;
  @Input()
  beginDate: moment.Moment;
  @Input()
  endDate: moment.Moment;

  @Output()
  updateValue = new EventEmitter<{ facilitiesEventEmitter: Map<Facility, Slot> }>();

  constructor() {}

  ngOnInit(): void {
    this.setMinContentHeight();
    this.sortFacilities();
  }

  getKeys(): Facility[] {
    return Array.from(this.facilities.keys());
  }

  getSlot(facility: Facility): Slot {
    return this.facilities.get(facility);
  }

  /**
   * This method sets the height of our component so that there is not available space
   */
  setMinContentHeight(): void {
    document.getElementById('opening-hours-title').style.minHeight =
      document.getElementById('restaurant-exceptional-hours-modal').offsetHeight -
      document.getElementById('modal-header').offsetHeight -
      document.getElementById('modal-footer').offsetHeight -
      25 + // we delete the margin-top
      'px';
  }

  sortFacilities(): void {
    const tupleArray = [];
    for (const key of this.facilities.keys()) {
      tupleArray.push(key);
    }

    tupleArray.sort((a, b) => {
      return a.exceptionalHoursPosition - b.exceptionalHoursPosition;
    });

    const sortedMap = new Map<Facility, Slot>();
    tupleArray.forEach((el) => {
      sortedMap.set(el, this.facilities.get(el));
    });

    this.facilities = sortedMap;
  }
}
