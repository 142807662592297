import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { PersistanceService } from '../persistance/persistance.service';

@Injectable({
  providedIn: 'root',
})
export class GoogleTagManagerService {

    constructor(private readonly persistanceService: PersistanceService) {}

    private pingHome(obj): void {
        if (obj) {
            window[`dataLayer`] = window[`dataLayer`] || [];
            window[`dataLayer`].push(obj);
        }
    }

    logPageView(): void {

        if (this.persistanceService.get('user_infos') != null) {
            const userId = this.persistanceService.get('user_infos').id.toString();
            const pagePath = document.location.href.split('?');
            const pageTitle = document.location.pathname.split('/');
            let envWork = "dev";
            let modelPage = "";
            const pageName = pageTitle[pageTitle.length - 1];
            let referrer = "";

            if (environment.application.environment === "prod") {
                envWork = "prod";
            }

            if (pageName === "orders") {
                modelPage = "Commandes";
            } else if (pageName === "orders-parking") {
                modelPage = "Parking";
            } else if (pageName === "facilities") {
                modelPage = "Restaurant";
            } else if (pageName === "deliveries") {
                modelPage = "Livraison";
            } else if (pageName === "settings") {
                modelPage = "Parametres";
            } else if (pageName === "onboarding") {
                modelPage = "onboarding";
            } else if (pageName === "dashboard") {
                modelPage = "dashboard";
            } else if (pageName === "restaurant-list") {
                modelPage = "restaurant-list";
            }

            if (this.persistanceService.get('referrer') != null) {
                referrer = this.persistanceService.get('referrer');
            }

            const hit = {
                event: "page_view",  // event de la page
                page_path: pagePath[0], // url de la page sans paramètre
                page_title: `${pageTitle[pageTitle.length - 1]}`, // titre de la page
                page_url: document.location.href, // full page url
                page_referrer: referrer,  // url de la page précédente
                page_category: "module",  // valeur fixe
                env_work: `${envWork}`, // valeur fixe pour l’ensemble des pages dev or prod.
                module_name: "myStore",  // valeur fixe pour l’ensemble des pages.
                sub_menu: "Accéder à MyStore",  // valeur fixe pour l’ensemble des pages.
                model_page: modelPage, // voir table de correspondance
                user_id: userId // identifiant de l’utilisateur a récupérer auprès de worldline
            };

            this.persistanceService.set('referrer', document.location.href);

            this.pingHome(hit);
        }
    }
}
