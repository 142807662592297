import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ExceptionalHoursView } from '../../../../models/exceptionalHoursView/exceptional-hours-view.model';
import { Facility } from '../../../../models/facility/facility.model';
import { FacilityType } from '../../../../models/facility/facilityType.enum';
import { Restaurant } from '../../../../models/restaurant/restaurant.model';
import { PersistanceService } from '../../../../service/persistance/persistance.service';

@Component({
  selector: 'app-mcdrive-facility',
  templateUrl: './mcdrive-facility.component.html',
  styleUrls: ['./mcdrive-facility.component.scss'],
})
export class McdriveFacilityComponent implements OnInit {
  @Input() facility: Facility;
  @Input() drivePietonfacility: Facility;
  @Input() facilitiesList: Facility[];
  @Input() exceptionalHoursTabInput: ExceptionalHoursView[];
  @Output() reloadFacilities: EventEmitter<boolean> = new EventEmitter<boolean>();
  facilityType: typeof FacilityType = FacilityType;

  currentRestaurant: Restaurant;
  facilityChildrenRef: string[] = [];

  constructor(private readonly persistanceService: PersistanceService) {}

  ngOnInit(): void {
    this.currentRestaurant = this.persistanceService.get('current_restaurant');
    this.getFacilityChildrenRef();
  }

  /**
   * Emit to parent to reload Facilities
   */
  emitReloadFacilities(): void {
    this.reloadFacilities.emit(true);
  }

  /**
   * Get all ref of this.facility.childrenFacilities
   */
  getFacilityChildrenRef(): void {
    for (const facilityChild of this.facility.childrenFacilities) {
      this.facilityChildrenRef.push(facilityChild.label);
    }
  }
}
