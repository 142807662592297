import { Injectable } from '@angular/core';
import { Facility } from '../../models/facility/facility.model';
import { FacilityType } from '../../models/facility/facilityType.enum';
import { SlotDetails } from '../../models/slot-details.model';
import { Slot } from '../../models/slot.model';
import { FacilityService } from '../facility/facility.service';

@Injectable({
  providedIn: 'root',
})
export class SlotService {
  MIDNIGHT = '00:00';
  BEGIN_DAY = '00:01';
  END_DAY = '23:59';
  MINUTES_IN_A_DAY = 1440;
  NO_HOURS_ERROR = "Les deux champs heure de début et heure de fin doivent être renseignés pour passer à l'étape suivante";
  OVERLAP_HOURS_ERROR = 'Le deuxième créneau horaire ne peut pas chevaucher le premier créneau horaire.';
  ANTERIOR_HOURS_ERROR = 'Le deuxième créneau horaire ne peut pas être antérieur au premier créneau horaire.';
  OVERLAP_NEXT_DAY_ERROR = "Le dernier créneau horaire d'un jour ne peut pas chevaucher le premier créneau horaire du jour suivant.";
  START_FORBIDDEN_HOUR = '03:00';
  END_FORBIDDEN_HOUR = '06:00';

  constructor(private readonly facilityService: FacilityService) {}

  /**
   * Get the string value of a day from its numeric value.
   * Example : 1=Lundi, 2=Mardi, etc...
   */
  public dayAsString(day: number): string {
    switch (day) {
      case 1:
        return 'Lundi';
      case 2:
        return 'Mardi';
      case 3:
        return 'Mercredi';
      case 4:
        return 'Jeudi';
      case 5:
        return 'Vendredi';
      case 6:
        return 'Samedi';
      case 7:
        return 'Dimanche';
      default:
        return 'Unknown';
    }
  }

  /**
   * Check the opening hours of a specific slot based on his given details
   * @param slotDetails - the hour details to check
   * @return boolean : true if the hours are 24h/24 else false
   *
   * Cases :  - begin_hour = end_hour : 08:00 - 08:00
   *          - begin_hour - end_hour <= 1 : 00:00 - 23:59 or 00:01 - 00:00 or 00:00 - 00:01
   */
  is24h(slotDetails: SlotDetails[]): boolean {
    if (slotDetails && slotDetails.length === 1) {
      const detail = slotDetails[0];
      if (detail.beginHour && detail.endHour) {
        const diff = Math.abs(this.getTime(detail.beginHour).getTime() - this.getTime(detail.endHour).getTime());
        const diffMins = Math.floor(diff / 1000 / 60);
        return diffMins <= 1 || diffMins === this.MINUTES_IN_A_DAY - 1;
      }
    }

    return false;
  }

  /**
   * Check the slots validity.
   * @param days the days to validate
   * @param objectIsActive true if the facility or pod is active
   * @param facility optional, used to process the CandC & Mcdelivery case
   * @returns true if no error, false otherwise.
   */
  public validateSlotInformation(days: Slot[], objectIsActive: boolean, facility: Facility = null): boolean {
    this.resetOldErrors(days);

    // il doit y avoir un au moins un jour d'actif si le POD ou service est actif
    if (objectIsActive && !this.hasActiveOneDay(days)) {
      return false;
    }

    let isValid = true;

    for (let i = 0; i < days.length; i++) {
      const currentDay = days[i];
      const nextDay = days[(i + 1) % 7];
      const currentDayValid = this.checkCurrentDayValidity(currentDay, facility);
      const currentDayValidWithNexDay = this.checkOverlapsWithNextDay(currentDay, nextDay);
      isValid = isValid && currentDayValid;
      isValid = isValid && currentDayValidWithNexDay;
    }

    return isValid;
  }

  /**
   * Checks if the current day and its slots are valid
   */
  public checkCurrentDayValidity(day: Slot, facility: Facility): boolean {
    let isConfigValid = true;

    const firstSlot = day.slotDetails[0];

    isConfigValid = this.checkSlotValidity(firstSlot, null, facility, day);

    // si au moins 2 slots
    if (day.slotDetails.length > 1) {
      for (let i = day.slotDetails.length - 1; i > 0; i--) {
        const slotValid = this.checkSlotValidity(day.slotDetails[i], day.slotDetails[i - 1], facility, day);
        isConfigValid = isConfigValid && slotValid;
      }
    }

    return isConfigValid;
  }

  /**
   * Check if a slot has ots begin and end hour not empty
   * @param slot the slot to check
   * @param day the associated day to write errors
   */
  private checkHoursNotEmpty(slot: SlotDetails, day: Slot): boolean {
    let isValid = true;
    if (day.active) {
      if (!slot.beginHour || slot.beginHour === '') {
        slot.startError = true;
        this.addSlotError(day.configError, this.NO_HOURS_ERROR);
        isValid = false;
      }
      if (!slot.endHour || slot.endHour === '') {
        slot.endError = true;
        this.addSlotError(day.configError, this.NO_HOURS_ERROR);
        isValid = false;
      }
    }
    return isValid;
  }

  /**
   * Check if a slot is valid with a previous one (check for overlaps).
   */
  // tslint:disable-next-line:cyclomatic-complexity
  private checkSlotValidity(currentSlot: SlotDetails, previousSlot: SlotDetails, facility: Facility, day: Slot): boolean {
    let isValid = true;
    const isCandCOrMcDeliveryFacility =
      facility != null && (facility.reference == FacilityType.C_AND_C || facility.reference == FacilityType.MC_DELIVERY);

    if (!day.active) {
      return isValid;
    }
    if (previousSlot == null) {
      // check que le premier slot soit renseigné si jour actif
      if (day.slotDetails.length === 0) {
        // ne doit jamais arriver car controle dans la modale
        this.addSlotError(day.configError, 'Au moins un créneau horaire doit être paramétré.');
        isValid = false;
      }
      const hoursNotEmpty = this.checkHoursNotEmpty(currentSlot, day);
      isValid = isValid && hoursNotEmpty;
    }
    const getTime = (time) => new Date(2020, 1, 1, time.substring(0, 2), time.substring(3, 5), 0, 0);

    // beginHour = 23:59 is forbidden
    if (getTime(this.END_DAY) <= getTime(currentSlot.beginHour)) {
      currentSlot.startError = true;
      this.addSlotError(day.configError, `L'heure de début ne peut pas correspondre à 23h59.`);
      isValid = false;
    }

    // we can't have hours between 3am and 6am for C&C et McDelivery
    if (
      isCandCOrMcDeliveryFacility &&
      !this.isHourNull(currentSlot.beginHour) &&
      !this.isHourNull(currentSlot.endHour) &&
      ( (getTime(this.START_FORBIDDEN_HOUR) < getTime(currentSlot.beginHour) &&
        getTime(currentSlot.beginHour) < getTime(this.END_FORBIDDEN_HOUR)) ||
        (getTime(this.START_FORBIDDEN_HOUR) < getTime(currentSlot.endHour) &&
          getTime(currentSlot.endHour) <= getTime(this.END_FORBIDDEN_HOUR)) ||
        (getTime(currentSlot.endHour) <= getTime(currentSlot.beginHour) &&
          !(getTime(currentSlot.endHour) <= getTime(this.START_FORBIDDEN_HOUR))) ||
        (getTime(currentSlot.beginHour) <= getTime(this.START_FORBIDDEN_HOUR) &&
          getTime(this.END_FORBIDDEN_HOUR) <= getTime(currentSlot.endHour)) ||
        (getTime(currentSlot.beginHour) >= getTime(currentSlot.endHour) &&
          getTime(currentSlot.beginHour) <= getTime(this.START_FORBIDDEN_HOUR))) &&
      currentSlot.active === true
    ) {
      currentSlot.startError = true;
      currentSlot.endError = true;
      const facilityLabel = this.facilityService.retrieveFacilityLabel(facility);
      this.addSlotError(day.configError, `Pour le canal ${facilityLabel}, les horaires ne peuvent pas être compris entre 3h et 6h.`);
      isValid = false;
    }

    // au moins 2 créneaux
    isValid = this.handleTwoSlot(previousSlot, currentSlot, day, isValid, getTime);
    return isValid;
  }

  private handleTwoSlot(
    previousSlot: SlotDetails,
    currentSlot: SlotDetails,
    day: Slot,
    isValid: boolean,
    getTime: (time) => Date
  ): boolean {
    if (previousSlot != null) {
      const hoursFilled = this.checkHoursNotEmpty(currentSlot, day);
      isValid = isValid && hoursFilled;
      if (hoursFilled) {
        if (previousSlot.beginHour === currentSlot.beginHour && previousSlot.endHour === currentSlot.endHour) {
          currentSlot.startError = true;
          currentSlot.endError = true;
          this.addSlotError(day.configError, 'Le deuxième créneau horaire ne peut pas être identique au premier créneau horaire.');
          isValid = false;
        } else if (!this.isHourNull(currentSlot.beginHour) && !this.isHourNull(currentSlot.endHour)) {
          isValid = this.checkIfBeginHourOverlap(getTime, currentSlot, previousSlot, day, isValid);
          isValid = this.checkIfEndHourOverlap(getTime, currentSlot, previousSlot, day, isValid);
          isValid = this.checkIfAfterMidnightAndBeginHourOverlap(previousSlot, getTime, currentSlot, day, isValid);

          if (getTime(currentSlot.beginHour) < getTime(previousSlot.beginHour)) {
            currentSlot.startError = true;
            this.addSlotError(day.configError, this.ANTERIOR_HOURS_ERROR);
            isValid = false;
          }
        }
      }
    }
    return isValid;
  }

  private checkIfBeginHourOverlap(
    getTime: (time) => Date,
    currentSlot: SlotDetails,
    previousSlot: SlotDetails,
    day: Slot,
    isValid: boolean
  ): boolean {
    if (
      getTime(currentSlot.beginHour) >= getTime(previousSlot.beginHour) &&
      getTime(currentSlot.beginHour) <= getTime(previousSlot.endHour)
    ) {
      currentSlot.startError = true;
      this.addSlotError(day.configError, this.OVERLAP_HOURS_ERROR);
      isValid = false;
    }
    return isValid;
  }

  private checkIfEndHourOverlap(
    getTime: (time) => Date,
    currentSlot: SlotDetails,
    previousSlot: SlotDetails,
    day: Slot,
    isValid: boolean
  ): boolean {
    if (getTime(currentSlot.endHour) > getTime(previousSlot.beginHour) && getTime(currentSlot.endHour) <= getTime(previousSlot.endHour)) {
      currentSlot.endError = true;
      this.addSlotError(day.configError, this.OVERLAP_HOURS_ERROR);
      isValid = false;
    }
    return isValid;
  }

  private checkIfAfterMidnightAndBeginHourOverlap(
    previousSlot: SlotDetails,
    getTime: (time) => Date,
    currentSlot: SlotDetails,
    day: Slot,
    isValid: boolean
  ): boolean {
    if (
      (this.is24h24Slot(previousSlot) || this.isAfterMidnight(previousSlot)) &&
      getTime(currentSlot.beginHour) >= getTime(previousSlot.beginHour)
    ) {
      currentSlot.startError = true;
      this.addSlotError(day.configError, this.OVERLAP_HOURS_ERROR);
      isValid = false;
    }
    return isValid;
  }

  /**
   * Check if a day is valid with the next one. (ie no overlaps. Example J=10h-2h / J+1=1h-14h -> not valid)
   * @param currentDay the current day
   * @param nextDay the next day
   */
  private checkOverlapsWithNextDay(currentDay: Slot, nextDay: Slot): boolean {
    let isValid = true;

    if (!currentDay.active || !nextDay.active) {
      return isValid;
    }

    for (const currentDaySlot of currentDay.slotDetails) {
      if (!currentDaySlot.beginHour || currentDaySlot.beginHour === '' || !currentDaySlot.endHour || currentDaySlot.endHour === '') {
        // pas de config sur le jour courant donc pas de chevauchement possible
        continue;
      }
      for (const nextDaySlot of nextDay.slotDetails) {
        isValid = this.handleSlot(currentDaySlot, nextDaySlot, currentDay, nextDay, isValid);
      }
    }

    return isValid;
  }

  private handleSlot(currentDaySlot: SlotDetails, nextDaySlot: SlotDetails, currentDay: Slot, nextDay: Slot, isValid: boolean): boolean {
    if (!this.isAfterMidnight(currentDaySlot) && !this.is24h24Slot(currentDaySlot)) {
      // si le slot du jour n'est pas apres minuit ou 24/24 on ne chevauchera pas le lendemain
      return true;
    }
    if (!nextDaySlot.beginHour || nextDaySlot.beginHour === '' || !nextDaySlot.endHour || nextDaySlot.endHour === '') {
      // pas de config sur le jour suivant donc pas de chevauchement possible
      return true;
    }
    if (!this.isAfterMidnight(nextDaySlot) && !this.is24h24Slot(nextDaySlot) && this.isTimeSlotInAnother(nextDaySlot, currentDaySlot)) {
      // le slot du lendemain est compris dans celui de la veille donc il ne sert a rien
      currentDaySlot.endError = true;
      nextDaySlot.startError = true;
      nextDaySlot.endError = true;
      this.addSlotError(currentDay.configError, this.OVERLAP_NEXT_DAY_ERROR);
      this.addSlotError(nextDay.configError, this.OVERLAP_NEXT_DAY_ERROR);
      isValid = false;
    } else if (this.getTime(nextDaySlot.beginHour) <= this.getTime(currentDaySlot.endHour)) {
      // le slot du jour d'apres commence pendant le slot du jour courant (ex: J= 10h-2h / J+1 = 01h-14h)
      currentDaySlot.endError = true;
      nextDaySlot.startError = true;
      this.addSlotError(currentDay.configError, this.OVERLAP_NEXT_DAY_ERROR);
      this.addSlotError(nextDay.configError, this.OVERLAP_NEXT_DAY_ERROR);
      isValid = false;
    }
    return isValid;
  }

  private isTimeSlotInAnother(slotToCheckIfInto: SlotDetails, slotToCheckIfContains: SlotDetails): boolean {
    return (
      this.getTime(slotToCheckIfContains.endHour) > this.getTime(slotToCheckIfInto.beginHour) &&
      this.getTime(slotToCheckIfContains.endHour) >= this.getTime(slotToCheckIfInto.endHour)
    );
  }

  /**
   * Checks if a slot is after midnight (ie end_hour < begin_hour. Example : 10h - 2h)
   */
  private isAfterMidnight(slot: SlotDetails): boolean {
    return this.getTime(slot.endHour) < this.getTime(slot.beginHour);
  }

  /**
   * Checks if a slot is 24/24 (ie begin_hour = end_hour. Example : 2h - 2h)
   */
  private is24h24Slot(slot: SlotDetails): boolean {
    return slot.endHour === slot.beginHour;
  }

  public respectFacilitySlot(facility: Facility, podSlots: Slot[]): boolean {
    let hasError = false;
    // aucun traitement si le facility est null
    if (facility === undefined) {
      return !hasError;
    }
    for (let index = 0; index < facility.slots.length; index++) {
      hasError = this.checkIfFacilityHasError(facility, index, podSlots, hasError);
    }
    return !hasError;
  }

  private checkIfFacilityHasError(facility: Facility, index: number, podSlots: Slot[], hasError: boolean): boolean {
    const facilitySlot = facility.slots[index];
    const podSlot = podSlots[index];

    // Check if the facility is active
    if (this.checkFacilityActive(podSlot, facilitySlot, hasError, facility)) {
      hasError = true;
    }

    // if pod and facility are active
    if (podSlot.active && facilitySlot.active) {
      // we have only one facility slot
      if (facilitySlot.slotDetails.length === 1) {
        if (this.checkPodSlotForOneSlotFacility(podSlot, facilitySlot, facility)) {
          hasError = true;
        }
      }

      // we have two facility slots
      if (facilitySlot.slotDetails.length === 2) {
        if (this.checkPodSlotForTwoSlotsFacility(podSlot, facilitySlot, facility)) {
          hasError = true;
        }
      }
    }
    return hasError;
  }

  /**
   * check if the facility is active
   * @param podSlot
   * @param facilitySlot
   * @param hasError
   * @private
   */
  private checkFacilityActive(podSlot: Slot, facilitySlot: Slot, hasError: boolean, facility: Facility): boolean {
    if (!facilitySlot.active) {
      if (podSlot.active) {
        // le podslot can't be activated
        podSlot.activationError = true;
        this.addSlotError(
          podSlot.configError,
          'En raison de la désactivation du canal de vente ' +
            this.facilityService.retrieveFacilityLabel(facility) +
            ' sur cette journée, l’activation de votre point de retrait est impossible.'
        );
        return true;
      }
    }
    return false;
  }

  /**
   * check if the begin hour is in limits
   * @param slowerLimit
   * @param upperLimit
   * @param podSlotDetail
   * @param podSlot
   * @param facility
   * @param hasError
   * @private
   */
  private beginHourOverLimit(slowerLimit: Date, upperLimit: Date, podSlotDetail: SlotDetails, podSlot: Slot, facility: Facility): boolean {
    if (this.getTime(podSlotDetail.beginHour) < slowerLimit || this.getTime(podSlotDetail.beginHour) >= upperLimit) {
      podSlotDetail.startError = true;
      this.addSlotError(
        podSlot.configError,
        `Les heures de disponibilité de votre point de retrait doivent être comprises dans les heures de disponibilité de votre canal ${facility.label}.`
      );
      return true;
    }
    return false;
  }

  /**
   * check if the end hour is in limits
   * @param slowerLimit
   * @param upperLimit
   * @param podSlotDetail
   * @param podSlot
   * @param facility
   * @param hasError
   * @private
   */
  private endHourOverLimit(
    slowerLimit: Date,
    upperLimit: Date,
    dateToTest: Date,
    podSlotDetail: SlotDetails,
    podSlot: Slot,
    facility: Facility
  ): boolean {
    if (dateToTest > upperLimit || dateToTest <= slowerLimit) {
      podSlotDetail.endError = true;
      this.addSlotError(
        podSlot.configError,
        `Les heures de disponibilité de votre point de retrait doivent être comprises dans les heures de disponibilité de votre canal ${facility.label}.`
      );
      return true;
    }
    return false;
  }

  /**
   * Check if hours are in hollow area (interval between the 2 facility slot)
   * @param slowerLimit
   * @param upperLimit
   * @param dateToTest
   * @param podSlotDetail
   * @param podSlot
   * @param facility
   * @param beginHour
   * @private
   */
  private hourIsHollowArea(
    slowerLimit: Date,
    upperLimit: Date,
    dateToTest: Date,
    podSlotDetail: SlotDetails,
    podSlot: Slot,
    facility: Facility,
    beginHour: boolean
  ): boolean {
    if (dateToTest > slowerLimit && dateToTest < upperLimit) {
      podSlotDetail.startError = beginHour;
      podSlotDetail.endError = !beginHour;
      this.addSlotError(
        podSlot.configError,
        `Les heures de disponibilité de votre point de retrait doivent être comprises dans les heures de disponibilité de votre canal ${facility.label}.`
      );
      return true;
    }
    return false;
  }

  /**
   * check if the end hour of pod slot is after midnight and check limit for end hour
   * @param beginTime
   * @param endTime
   * @param podSlotDetail
   * @param podSlot
   * @param facility
   * @param facilitySlot
   * @private
   */
  private checkIfEndHourPodSlotAfterMidnight(
    beginTime: Date,
    endTime: Date,
    podSlotDetail: SlotDetails,
    podSlot: Slot,
    facility: Facility,
    facilitySlot: Slot
  ): boolean {
    if (
      // heure de fin après minuit
      this.getTime(podSlotDetail.endHour) < this.getTime(facilitySlot.slotDetails[0].beginHour)
    ) {
      // heure de fin du pod slot après minuit
      if (this.endHourOverLimit(beginTime, endTime, this.getTimeDayAfter(podSlotDetail.endHour), podSlotDetail, podSlot, facility)) {
        return true;
      }
    } else {
      // heure de fin du pod slot avant minuit
      if (this.endHourOverLimit(beginTime, endTime, this.getTime(podSlotDetail.endHour), podSlotDetail, podSlot, facility)) {
        return true;
      }
    }
    return false;
  }

  /**
   * This method checks if the slot cross the hollow area (interval between the 2 facilities slot)
   * @param podSlotDetail
   * @param podSlot
   * @param facility
   * @param facilitySlot
   * @private
   */
  private checkHourCrossHollowArea(podSlotDetail: SlotDetails, podSlot: Slot, facility: Facility, facilitySlot: Slot): boolean {
    if (this.getTime(podSlotDetail.beginHour) <= this.getTime(facilitySlot.slotDetails[0].endHour)) {
      if (this.getTime(podSlotDetail.endHour) >= this.getTime(facilitySlot.slotDetails[1].beginHour)) {
        podSlotDetail.endError = true;
        // change text
        this.addSlotError(
          podSlot.configError,
          `Les heures de disponibilité de votre point de retrait doivent être comprises dans les heures de disponibilité de votre canal ${facility.label}.`
        );
        return true;
      }
    }
    if (this.getTime(podSlotDetail.beginHour) >= this.getTime(facilitySlot.slotDetails[1].beginHour)) {
      if (
        this.getTime(podSlotDetail.endHour) <= this.getTime(facilitySlot.slotDetails[1].beginHour) &&
        this.getTime(podSlotDetail.endHour) >= this.getTime(facilitySlot.slotDetails[0].beginHour)
      ) {
        podSlotDetail.endError = true;
        // change text
        this.addSlotError(
          podSlot.configError,
          `Les heures de disponibilité de votre point de retrait doivent être comprises dans les heures de disponibilité de votre canal ${facility.label}.`
        );
        return true;
      }
    }
    return false;
  }

  /**
   * check the validity of pod Slot for one slot facility
   * @param podSlot
   * @param facilitySlot
   * @param facility
   * @private
   */
  private checkPodSlotForOneSlotFacility(podSlot: Slot, facilitySlot: Slot, facility: Facility): boolean {
    let hasError = false;
    const beginTime = this.getTime(facilitySlot.slotDetails[0].beginHour);
    const endTime =
      this.getTime(facilitySlot.slotDetails[0].endHour) < this.getTime(facilitySlot.slotDetails[0].beginHour)
        ? this.getTimeDayAfter(facilitySlot.slotDetails[0].endHour)
        : this.getTime(facilitySlot.slotDetails[0].endHour);
    podSlot.slotDetails.forEach((podSlotDetail) => {
      // begin hour
      if (this.beginHourOverLimit(beginTime, endTime, podSlotDetail, podSlot, facility)) {
        hasError = true;
      }
      // end hour
      if (this.checkIfEndHourPodSlotAfterMidnight(beginTime, endTime, podSlotDetail, podSlot, facility, facilitySlot)) {
        hasError = true;
      }
    });
    return hasError;
  }

  /**
   * check the validity of pod Slot for two slots facility
   * @param podSlot
   * @param facilitySlot
   * @param facility
   * @private
   */
  private checkPodSlotForTwoSlotsFacility(podSlot: Slot, facilitySlot: Slot, facility: Facility): boolean {
    let hasError = false;
    const beginTime = this.getTime(facilitySlot.slotDetails[0].beginHour);
    const endTime =
      this.getTime(facilitySlot.slotDetails[1].endHour) < this.getTime(facilitySlot.slotDetails[0].beginHour)
        ? this.getTimeDayAfter(facilitySlot.slotDetails[1].endHour)
        : this.getTime(facilitySlot.slotDetails[1].endHour);
    const facilityFirstSlot = facilitySlot.slotDetails[0];
    const facilitySecondSlot = facilitySlot.slotDetails[1];

    podSlot.slotDetails.forEach((podSlotDetail) => {
      // begin hour
      if (this.beginHourOverLimit(beginTime, endTime, podSlotDetail, podSlot, facility)) {
        hasError = true;
      }
      if (
        this.hourIsHollowArea(
          this.getTime(facilityFirstSlot.endHour),
          this.getTime(facilitySecondSlot.beginHour),
          this.getTime(podSlotDetail.beginHour),
          podSlotDetail,
          podSlot,
          facility,
          true
        )
      ) {
        hasError = true;
      }

      // end hour
      if (this.checkIfEndHourPodSlotAfterMidnight(beginTime, endTime, podSlotDetail, podSlot, facility, facilitySlot)) {
        hasError = true;
      }
      if (
        this.hourIsHollowArea(
          this.getTime(facilityFirstSlot.endHour),
          this.getTime(facilitySecondSlot.beginHour),
          this.getTime(podSlotDetail.endHour),
          podSlotDetail,
          podSlot,
          facility,
          false
        )
      ) {
        hasError = true;
      }

      // We check if slot cross a hollow area
      if (this.checkHourCrossHollowArea(podSlotDetail, podSlot, facility, facilitySlot)) {
        hasError = true;
      }
    });
    return hasError;
  }

  /**
   * Add an error to the given list
   * @param slotErrorList The list to add error in
   * @param error The error message to add
   */
  addSlotError(slotErrorList: string[], error: string): void {
    if (!slotErrorList) {
      slotErrorList = [];
    }
    if (slotErrorList.indexOf(error) === -1) {
      slotErrorList.push(error);
    }
  }

  /**
   * Resets old Slot error messages.
   *
   * @param slot The slot to reset errors
   */
  resetSlotOldErrors(slot: Slot): void {
    slot.configError = [];
    for (const slotDetail of slot.slotDetails) {
      if (slotDetail) {
        slotDetail.startError = false;
        slotDetail.endError = false;
      }
    }
  }

  /**
   * Resets old Pod error messages.
   * @param pod Pod
   * @param slots a list of slot
   */
  private resetOldErrors(slots: Slot[]): void {
    for (const slot of slots) {
      this.resetSlotOldErrors(slot);
    }
  }

  /**
   * Check if the given hour is null or empty
   * @param hour Hour to check
   * @returns True if null or empty, false otherwise
   */
  private isHourNull(hour: string): boolean {
    return hour == null || hour === '' || hour === undefined;
  }

  /**
   * Check if one day is active
   */
  public hasActiveOneDay(slots: Slot[]): boolean {
    let oneDayActive = false;
    for (const slot of slots) {
      if (slot.active) {
        oneDayActive = true;
      }
    }
    return oneDayActive;
  }

  private getTime(time: any): Date {
    return new Date(2020, 1, 1, time.substring(0, 2), time.substring(3, 5), 0, 0);
  }

  private getTimeDayAfter(time: any): Date {
    return new Date(2020, 1, 2, time.substring(0, 2), time.substring(3, 5), 0, 0);
  }
}
