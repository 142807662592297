import { FacilityExceptionalHours } from '../facilityExceptionalHours/facility-exceptional-hours.model';

export class ExceptionalHoursView {
  /**
   * Unique reference of the exceptional hours view.
   */
  id: number;

  /**
   * The starting date of the exceptional hours.
   */
  startDate: Date;

  /**
   * The ending date of the exceptional hours.
   */
  endDate: Date;

  /**
   * Label of the exceptional hours.
   */
  label: string;

  /**
   * List of exceptional facility slots.
   */
  slots: FacilityExceptionalHours[];
}
