import { Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';
import { ExceptionalHoursView } from '../../../models/exceptionalHoursView/exceptional-hours-view.model';
import { Facility } from '../../../models/facility/facility.model';
import { FacilityExceptionalHours } from '../../../models/facilityExceptionalHours/facility-exceptional-hours.model';
import { Restaurant } from '../../../models/restaurant/restaurant.model';
import { SlotDetails } from '../../../models/slot-details.model';
import { Slot } from '../../../models/slot.model';
import { FacilityService } from '../../../service/facility/facility.service';
import { PersistanceService } from '../../../service/persistance/persistance.service';
import { RestaurantService } from '../../../service/restaurant/restaurant.service';
import { ConfirmModalComponent } from '../../common/confirm-modal/confirm-modal.component';
import { ExceptionalHoursEditComponent } from '../exceptional-hours/exceptional-hours-edit/exceptional-hours-edit.component';
import { SlotService } from '../../../service/slot/slot.service';
import {DialogConfigUtilsService} from "../../../service/utils/dialog.utils.service";

@Component({
  selector: 'app-edit-exceptional-hours-configured-modal',
  templateUrl: './edit-exceptional-hours-configured-modal.component.html',
  styleUrls: ['./edit-exceptional-hours-configured-modal.component.scss'],
})
export class EditExceptionalHoursConfiguredModalComponent implements OnInit {
  @ViewChild('exceptionalHoursEditComponent')
  exceptionalHoursEditComponent: ExceptionalHoursEditComponent;

  @Output() reloadFacilities: EventEmitter<boolean> = new EventEmitter<boolean>();

  exceptionalHours: ExceptionalHoursView;
  isLoading = false;
  isDuration: boolean;
  screenToShow: string;

  beginDate: moment.Moment;
  endDate: moment.Moment;

  restaurant: Restaurant;

  facilitiesMap: Map<Facility, Slot>;
  isModification = true;
  facilitiesByRef: Facility[] = [];

  private readonly format = 'DD/MM/yyyy';
  exceptionalHoursName: string;
  private readonly EXCEPTIONAL_HOURS_INFOS_SCREEN = 'exceptional-hours-infos';

  constructor(
    public dialogRef: MatDialogRef<EditExceptionalHoursConfiguredModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly persistanceService: PersistanceService,
    public restaurantService: RestaurantService,
    public facilityService: FacilityService,
    public matDialog: MatDialog,
    private readonly slotService: SlotService
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.restaurant = this.persistanceService.get('current_restaurant');
    this.screenToShow = this.EXCEPTIONAL_HOURS_INFOS_SCREEN;
    this.exceptionalHours = this.data.exceptionalHours;
    this.exceptionalHoursName = this.exceptionalHours.label;
    this.beginDate = moment(this.exceptionalHours.startDate);
    this.endDate = moment(this.exceptionalHours.endDate);
    this.checkIsDuration();
    if (!this.isDuration) {
      this.endDate = moment(this.exceptionalHours.startDate).endOf('day');
    }
    if (!this.isModification || this.beginDate < moment()) {
      this.beginDate = moment().add(1, 'days'); // TODO: remove this sentence, get the starting date of the exceptional hours
    }

    this.setFacilitiesMap();
    this.isLoading = false;
  }

  /**
   * Close the edit exceptional hours modal
   */
  closeModal(): void {
    const title = 'Si vous quittez le paramétrage, aucune modification ne sera enregistrée.';
    const modalDialog = this.openConfirmModal(title, 'QUITTER SANS SAUVEGARDER', 'ANNULER', 'close', '100%', false);
    modalDialog.afterClosed().subscribe((result) => {
      if (result) {
        this.resetBodyScroll();
        this.dialogRef.close();
      }
    });
  }

  checkIsDuration(): void {
    this.isDuration = this.exceptionalHours.endDate !== undefined;
  }

  remove(): void {
    const title = this.getPopupDateTitle();
    this.deleteModalDialog('Suppression des <strong>horaires exceptionnels</strong>', 'CONFIRMER LA SUPPRESSION', title, null);
  }

  next(): void {
    if (
      this.screenToShow === this.EXCEPTIONAL_HOURS_INFOS_SCREEN &&
      this.exceptionalHoursEditComponent.validate(this.data.existingExceptionalHours, this.exceptionalHours.id)
    ) {
      this.screenToShow = 'select-facilities';
    } else if (this.screenToShow === 'select-facilities' && this.validateSlot()) {
      const title = this.getPopupDateTitle();
      this.updateModalDialog('Modification des horaires exceptionnels', 'ENREGISTER ET QUITTER', title, null);
    }
  }

  private validateSlot(): boolean {
    let atLeastOneSlotActive = false;
    this.facilitiesMap.forEach((value: Slot, key: Facility) => {
      if (value.active) {
        atLeastOneSlotActive = true;
      }
    });
    let isSlotsValide = true;
    this.facilitiesMap.forEach((value: Slot, key: Facility) => {
      this.slotService.resetSlotOldErrors(value);
      if (value.exceptionalClose === false && !this.slotService.checkCurrentDayValidity(value, key)) {
        isSlotsValide = false;
      } else if (value.active && (value.exceptionalClose === undefined || value.exceptionalClose === null)) {
        isSlotsValide = false;
      }
    });
    return atLeastOneSlotActive && isSlotsValide;
  }

  private getPopupDateTitle(): string {
    if (this.isDuration) {
      return `Du ${this.beginDate.format(this.format)} au ${this.endDate.format(this.format)}`;
    } else {
      return `Le ${this.beginDate.format(this.format)}`;
    }
  }

  backToEditInfos(): void {
    this.screenToShow = this.EXCEPTIONAL_HOURS_INFOS_SCREEN;
  }

  private openConfirmModal(
    title: string,
    confirmText: string,
    cancelText: string,
    panelClass: string,
    width: string,
    showWarningText: boolean,
    topText?: string,
    applyText?: string
  ): MatDialogRef<ConfirmModalComponent> {
    const dialogConfig = new DialogConfigUtilsService().getDialogConfig('confirm-modal', title, confirmText, cancelText, panelClass, width, showWarningText, topText, applyText);

    return this.matDialog.open(ConfirmModalComponent, dialogConfig);
  }

  private updateModalDialog(title: string, confirmText: string, topText: string, applyText): void {
    const modalDialog = this.openConfirmModal(title, confirmText, 'PRÉCÉDENT', 'save', '94%', false, topText, applyText);
    modalDialog.afterClosed().subscribe((message) => {
      if (message === true) {
        this.isLoading = true;
        this.updateExceptional();
      }
    });
  }

  private deleteModalDialog(title: string, confirmText: string, topText: string, applyText): void {
    const modalDialog = this.openConfirmModal(title, confirmText, 'PRÉCÉDENT', 'delete', '94%', false, topText, applyText);
    modalDialog.afterClosed().subscribe((message) => {
      if (message === true) {
        this.isLoading = true;
        this.deleteExceptional();
      }
    });
  }

  updateExceptional(): void {
    this.isLoading = true;
    this.exceptionalHours.slots = [];
    for (const elementt of this.facilitiesMap.keys()) {
      if (this.facilitiesMap.get(elementt).active) {
        const facilityExceptionalHoursTmp = new FacilityExceptionalHours();
        facilityExceptionalHoursTmp.active = this.facilitiesMap.get(elementt).active;
        facilityExceptionalHoursTmp.exceptionalClose = this.facilitiesMap.get(elementt).exceptionalClose;
        facilityExceptionalHoursTmp.facilityRef = elementt.reference;
        facilityExceptionalHoursTmp.slotsDetails = this.facilitiesMap.get(elementt).slotDetails;
        this.exceptionalHours.slots.push(facilityExceptionalHoursTmp);
      }
    }

    this.restaurantService.updateExceptionalHoursForRestaurants(this.exceptionalHours, this.restaurant.ref.toString()).subscribe(
      (data) => {
        this.reloadFacilitiesAndCloseModal();
      },
      (error) => {
        this.displayTechnicalErrorPopup();
      }
    );
  }

  deleteExceptional(): void {
    this.isLoading = true;
    this.restaurantService
      .deleteExceptionalHoursForRestaurantsByExceptionalHourId(this.restaurant.ref.toString(), this.exceptionalHours.id.toString())
      .subscribe(
        (data) => {
          this.reloadFacilitiesAndCloseModal();
        },
        (error) => {
          this.displayTechnicalErrorPopup();
        }
      );
  }

  private reloadFacilitiesAndCloseModal(): void {
    this.emitReloadFacilities();
    this.isLoading = false;
    // close modal
    this.resetBodyScroll();
    this.dialogRef.close();
  }

  /**
   * Reset body to be able to scroll in it.
   */
  private resetBodyScroll(): void {
    // When the modal is hidden...
    document.body.style.overflow = 'auto';
  }
  
  private displayTechnicalErrorPopup(): void {
    this.isLoading = false;
    const title = "En raison d'une erreur technique, vos paramètres n'ont pas été enregistrés. Veuillez réessayer ultérieurement";
    this.openConfirmModal(title, 'QUITTER', null, 'save-error', '94%', false, null);
  }

  updateValue($event: { exceptionalHoursName: string; beginDate: moment.Moment; endDate: moment.Moment; isDuration: boolean }): void {
    this.beginDate = $event.beginDate;
    this.endDate = $event.endDate;
    this.exceptionalHoursName = $event.exceptionalHoursName;
    this.isDuration = $event.isDuration;
    this.exceptionalHours.label = this.exceptionalHoursName;
    this.exceptionalHours.startDate = this.beginDate.toDate();
    this.exceptionalHours.endDate = this.endDate.toDate();
    if (!this.isDuration) {
      this.exceptionalHours.endDate = $event.beginDate.clone().endOf('day').toDate();
    }
  }

  updateSlots($event: { facilitiesEventEmitter: Map<Facility, Slot> }): void {
    this.facilitiesMap = $event.facilitiesEventEmitter;

    // remove facility, to avoid duplicates
    for (const facilityKey of this.facilitiesMap.keys()) {
      this.facilitiesByRef = this.facilitiesByRef.filter((facilityTmp) => facilityTmp.reference !== facilityKey.reference);
    }

    // add all elements
    for (const facilityToAdd of this.facilitiesByRef) {
      this.facilitiesMap.set(facilityToAdd, new Slot(1, false, [new SlotDetails('', '')]));
    }
  }

  setFacilitiesMap(): void {
    // get all facilities compatible with exceptional hours
    this.facilitiesByRef = this.facilityService.getFacilitiesCompatibleWithExceptionalHours(this.data.facilities);

    // transform exceptionalHours to facilitiesMap
    this.facilitiesMap = this.restaurantService.transformExceptionalHoursViewIntoMap(this.exceptionalHours);

    // remove facility, to avoid duplicates
    for (const facilityKey of this.facilitiesMap.keys()) {
      this.facilitiesByRef = this.facilitiesByRef.filter((facilityTmp) => facilityTmp.reference !== facilityKey.reference);
    }

    // add all elements
    for (const facilityToAdd of this.facilitiesByRef) {
      this.facilitiesMap.set(facilityToAdd, new Slot(1, false, [new SlotDetails('', '')]));
    }
  }

  /**
   * Emit to parent to reload Facilities
   */
  emitReloadFacilities(): void {
    this.reloadFacilities.emit(true);
  }
}
