import { Component, Input, OnInit } from '@angular/core';
import { McDeliveryPartnerDynamicFee } from '../../../../../../../models/mcdelivery/mcdelivery-partner-dynamic-fee';

@Component({
  selector: 'app-delivery-fees',
  templateUrl: './delivery-fees.component.html',
  styleUrls: ['./delivery-fees.component.css'],
})
export class DeliveryFeesComponent implements OnInit {
  @Input() fees: McDeliveryPartnerDynamicFee;

  constructor() {
  }

  ngOnInit(): void {
  }

  getFeePrice(price: number): string {
    if (price === null) {
      return '---';
    } else {
      return `${(price / 100).toFixed(2)}€`;
    }
  }

  getFeePMIX(pmix: string): string {
    return pmix ? `${pmix}` : '---';
  }

  getFeeStatus(isAvailable: boolean): string {
    return isAvailable ? 'Activé' : 'Désactivé';
  }
}
