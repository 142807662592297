import {Component, Input, OnInit} from "@angular/core";
import {Intercom} from "ng-intercom";
import {McDeliveryService} from "../../../service/mcdelivery/mcdelivery-service";
import {WidgetChatParameters} from "../../../models/mcdelivery/widget-chat-parameters";
import {PersistanceService} from "../../../service/persistance/persistance.service";
import {Restaurant} from "../../../models/restaurant/restaurant.model";

@Component({
  selector: 'app-order-widget-chat',
  templateUrl: './order-widget-chat.component.html',
  styleUrls: ['./order-widget-chat.component.scss'],
})

export class OrderWidgetChatComponent implements OnInit {
  @Input() vendor: string;
  restaurantRef: number;
  error: string;
  widgetActive: boolean;

  constructor(public intercom: Intercom,
              public mcDeliveryService: McDeliveryService,
              private readonly persistanceService: PersistanceService) {
  }

  ngOnInit(): void {
    this.widgetActive = false;
  }

  onWidgetChatClick(): void {
    const restaurant: Restaurant = this.persistanceService.get('current_restaurant');
    if (restaurant) {
      this.restaurantRef = restaurant.ref;
    }
    this.mcDeliveryService.getWidgetChatParameter(this.vendor.toUpperCase(), this.restaurantRef).subscribe({
      next: (parametersResponse) => {
        try {
          this.intercom.boot({
            app_id: `${parametersResponse.app_id}`,
            email: `${parametersResponse.email}`,
            user_id: `${parametersResponse.user_id}`,
            user_hash: `${parametersResponse.user_hash}`,
            widget: {
              activator: "#intercom"
            }
          });
        } catch (err) {
          this.error = 'Widget indisponible.';
        }
      }, error: err => {
        this.error = 'Widget indisponible.';
      }
    });
  }
}
