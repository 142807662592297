import { Component, Input, OnInit } from '@angular/core';
import { OrderItemType } from '../../../../models/order/order-item-type.enum';
import { OrderItem } from '../../../../models/order/order-item.model';

@Component({
  selector: 'app-order-item-line',
  templateUrl: './order-item-line.component.html',
  styleUrls: ['./order-item-line.component.scss'],
})
export class OrderItemLineComponent implements OnInit {
  orderItemType: typeof OrderItemType = OrderItemType;

  @Input() item: OrderItem;

  @Input() level = 1;

  constructor() {}

  ngOnInit(): void {}

  /**
   * Returns the order total amount in euros.
   */
  getTotalAmount(): number {
    if (!this.item || !this.item.totalAmount) {
      return 0;
    }
    return this.item.totalAmount / 100;
  }
}
