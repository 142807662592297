<div *ngIf="isLoading; else loader">
    <app-loader></app-loader>
</div>

<ng-template #loader>
    <div>
        <div id="modal-header">
            <div class="modal-title">INFORMATIONS RESTAURANT</div>
            <a>
                <mat-icon id="close-modal-button" (click)="closeModal()">close</mat-icon>
            </a>
            <div class="restaurant-name">
                {{ restaurant?.name }}
            </div>
        </div>
        <div id="informations">
            <div class="information">
                <label for="address-input" class="information-title">Adresse</label>
                <br />
                <div class='autocomplete-input-container'>
                    <div class="custom-wrapper-example" matAutocompleteOrigin #origin="matAutocompleteOrigin">
                        <input matInput id="address-input" [formControl]="control" class="information-value" [matAutocomplete]="auto"
                            [matAutocompleteConnectedTo]="origin" [(ngModel)]='address' (input)='validateAddress(); getLocalities()'
                               [ngClass]="errorAddress ? 'error' : ''">
                    </div>

                    <mat-autocomplete #auto="matAutocomplete">
                        <mat-option *ngFor="let locality of localities"
                            (click)='selectLocality(locality)'>{{locality.description}}</mat-option>
                    </mat-autocomplete>
                </div>
                <mat-card-content *ngIf="errorAddress">
                    <app-warning-message [infoBulleColor]="'var(--red)'" [message]="errorMessageAddress"></app-warning-message>
                </mat-card-content>
            </div>
            <div class="information">
                <label for="postalcode-input" class="information-title">Code postal</label>
                <br />
                <input [(ngModel)]="zipCode" id="postalcode-input" class="information-value" readonly autocomplete="off"
                    type="number" disabled/>
            </div>
            <div class="information">
                <label for="ville-input" class="information-title">Ville</label>
                <br />
                <input [(ngModel)]="city" id="ville-input" class="information-value" readonly autocomplete="off" type="text"
                     disabled/>
            </div>
            <div class="information">
                <label for="latitude-input" class="information-title">Longitude (X)</label>
                <br />
                <input [(ngModel)]="longitude" id="longitude-input" class="information-value" autocomplete="off" [ngClass]="errorLongitude ? 'error' : ''"
                    type="number" (input)="validateCoordinates(); displayNewLocation()"/>
                <mat-card-content *ngIf="errorLongitude">
                    <app-warning-message [infoBulleColor]="'var(--red)'" [message]="errorMessageLongitude"></app-warning-message>
                </mat-card-content>
            </div>
            <div class="information">
                <label for="latitude-input" class="information-title">Latitude (Y)</label>
                <br />
                <input [(ngModel)]="latitude" id="latitude-input" class="information-value" autocomplete="off" [ngClass]="errorLatitude ? 'error' : ''"
                       type="number" (input)="validateCoordinates(); displayNewLocation()" />
                <mat-card-content *ngIf="errorLatitude">
                    <app-warning-message [infoBulleColor]="'var(--red)'" [message]="errorMessageLatitude"></app-warning-message>
                </mat-card-content>
            </div>
            <div class="grad-map">
                <mat-icon>place</mat-icon>
                <label for="drag-button" class="information-title">Point de localisation</label>
                <button id="drag-button" (click)="clickToDrag()">DÉPLACER</button>
                <br /><br />
            </div>
            <div class="information">
                <div id="woosmap-edit"></div>
                <br />
            </div>
            <app-restaurant-textarea [textAreaLabel]="'Informations supplémentaires'"
                [maxLength]="maxAdditionalInformationLength" [text]="additionalInformation"
                (textOutput)="updateAdditionalInformation($event)"></app-restaurant-textarea>
            <div class="information">
                <label for="phone" class="information-title">Téléphone</label>
                <br />
                <input type="tel" id="phone" class="information-value" [(ngModel)]="phone"
                       [ngClass]="errorPhoneNumber ? 'error' : ''"
                       (input)="validatePhoneNumber()" (keypress)="keyPressNumeric($event)"/>
                <mat-card-content *ngIf="errorPhoneNumber">
                    <app-warning-message [infoBulleColor]="'var(--red)'" [message]="errorMessagePhoneNumber"></app-warning-message>
                </mat-card-content>
            </div>
            <div class="information">
                <label for="TVANumber" class="information-title">Numéro TVA</label>
                <br />
                <input type="text" id="TVANumber" class="information-value"
                       [ngClass]="errorTVA ? 'error' : ''"
                       (input)="validateTVA()"
                       [(ngModel)]="TVANumber" (keypress)="keyPressAlphaNumeric($event)"/>
                <mat-card-content *ngIf="errorTVA">
                    <app-warning-message [infoBulleColor]="'var(--red)'" [message]="errorMessageTVA"></app-warning-message>
                </mat-card-content>
            </div>
            <div class="information">
                <label for="RCSNumber" class="information-title">Numéro RCS</label>
                <br />
                <input type="text" id="RCSNumber" class="information-value"
                       [ngClass]="errorRCS ? 'error' : ''"
                       (input)="validateRCS()"
                    [(ngModel)]="RCSNumber" (keypress)="keyPressAlphaNumeric($event)"/>
                <mat-card-content *ngIf="errorRCS">
                    <app-warning-message [infoBulleColor]="'var(--red)'" [message]="errorMessageRCS"></app-warning-message>
                </mat-card-content>
            </div>
            <app-restaurant-textarea [textAreaLabel]="'A propos du restaurant'" [maxLength]="maxAboutLength"
                [text]="about" (textOutput)="updateAbout($event)"></app-restaurant-textarea>
            <div class="information">
                <span class="information-title">Images du restaurant</span>
                <br />
                <span>
                    <div *ngFor="let picture of pictures">
                        <div class="picture">
                            <img src="{{ getPicture(picture) }}" alt="illustration" />
                            <a class="close-button" (click)="deletePicture(picture)">
                                <mat-icon>close</mat-icon>
                            </a>
                        </div>
                    </div>
                    <div *ngFor="let image of imagesToSave; let i = index">
                        <div class="picture">
                            <img src="{{ image }}" alt="illustration" />
                            <a class="close-button" (click)="deleteImage(i)">
                                <mat-icon>close</mat-icon>
                            </a>
                        </div>
                    </div>
                    <div class="addPicture" *ngIf="canAddImage()">
                        <label for="file" id="add-image-button">Ajouter une image</label>
                        <input type="file" accept=".jpg,.png" id="file" (change)="createPicture($event.target.files)" (click)="$event.target.value=null">
                    </div>
                </span>
            </div>
            <div class="footer-content">
                <button id="footer-next-button" [ngClass]="formHasErrors() ? 'disabled' : ''"
                    (click)="save()">Suivant</button>
            </div>
        </div>
    </div>
</ng-template>