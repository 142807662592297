export class Multimedia {
    ref: string;
    label: string;
    mimeType: string;
    activationDate: Date;
    types: string[];
    originalFilename: string;
    expirationDate: Date;
    buttonPresent: boolean;
    uploadedFile: any;
}
