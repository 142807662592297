import { AfterViewChecked, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { ExceptionalHoursView } from '../../models/exceptionalHoursView/exceptional-hours-view.model';
import { Facility } from '../../models/facility/facility.model';
import { FacilityType } from '../../models/facility/facilityType.enum';
import { Restaurant } from '../../models/restaurant/restaurant.model';
import { FacilityService } from '../../service/facility/facility.service';
import { NavBarService } from '../../service/nav-bar/nav-bar.service';
import { PersistanceService } from '../../service/persistance/persistance.service';
import { RestaurantService } from '../../service/restaurant/restaurant.service';

@Component({
  selector: 'app-restaurant-deliveries',
  templateUrl: './restaurant-deliveries.component.html',
  styleUrls: ['./restaurant-deliveries.component.scss'],
})
export class RestaurantDeliveriesComponent implements OnInit, OnDestroy, AfterViewChecked {
  isLoading = true;
  hasMcDelivery: boolean;
  hasLAD: boolean;
  @ViewChild('content') content: ElementRef;
  @ViewChild('header') header: ElementRef;
  currentTab: FacilityType;
  facilityType: typeof FacilityType = FacilityType;
  mcDeliveryFacility: Facility;
  mcDeliveryPartnerFacilities: Facility[] = [];
  ladFacilities: Facility[] = [];
  facilities: Facility[];
  allFacilities: Facility[];
  private currentRestaurant: Restaurant;
  private readonly restaurantDeliveriesHeader = 'restaurant-deliveries-header';

  exceptionalHoursTab: ExceptionalHoursView[];

  constructor(
    private readonly navBarService: NavBarService,
    private readonly persistanceService: PersistanceService,
    private readonly facilityService: FacilityService,
    private readonly restaurantService: RestaurantService
  ) {}

  ngOnInit(): void {
    this.navBarService.showNavBar(true);
    this.persistanceService.set('redirectPage', '/restaurant/deliveries');
    this.currentRestaurant = this.persistanceService.get('current_restaurant');
    this.loadFacilities();
    this.currentTab = this.facilityType.MC_DELIVERY;
  }

  ngOnDestroy(): void {
    this.navBarService.showNavBar(false);
    this.persistanceService.remove('redirectPage');
  }

  ngAfterViewChecked(): void {
    this.setHeaderMarginTop(document.getElementById('header').offsetHeight);
    this.setMarginTop(
      document.getElementById('header').offsetHeight + document.getElementById(this.restaurantDeliveriesHeader).offsetHeight
    );
  }

  @HostListener('window:resize')
  onResize(): void {
    setTimeout(() => {
      this.setMarginTopFromHeader();
    }, 50);
  }

  @HostListener('window:scroll')
  onScroll(): void {
    this.setMarginTop(
      document.getElementById('header').offsetHeight + document.getElementById(this.restaurantDeliveriesHeader).offsetHeight
    );
    if (document.documentElement.scrollTop > 1) {
      document.getElementById('main-content').style.paddingBottom = '120px';
    } else {
      document.getElementById('main-content').style.paddingBottom = '42px';
    }
  }

  @HostListener('click')
  onClick(): void {
    this.setMarginTopFromHeader();
  }

  private setMarginTopFromHeader(): void {
    this.setMarginTop(
      document.getElementById('header').offsetHeight + document.getElementById(this.restaurantDeliveriesHeader).offsetHeight
    );
  }

  setMarginTop(height: number): void {
    this.content.nativeElement.style.setProperty('margin-top', `${height}px`);
    if (document.getElementById('nav-bar') && !document.getElementById('nav-bar-component').classList.contains('nav-bar-tablet-visible')) {
      this.content.nativeElement.style.setProperty('margin-bottom', `${document.getElementById('nav-bar').offsetHeight}px`);
    } else {
      this.content.nativeElement.style.setProperty('margin-bottom', '0px');
    }
  }

  setHeaderMarginTop(height: number): void {
    this.header.nativeElement.style.setProperty('margin-top', `${height}px`);
  }

  /**
   * Switch tab view to the given one (in progress or history)
   * @param tabView Tab to show
   */
  switchTabView(tabView: FacilityType): void {
    this.currentTab = tabView;
  }

  /**
   * Checks if the given tab view is the one active
   * @param tabView tab view
   * @returns true if this is the current shown view, false otherwise.
   */
  isTabActive(tabView: FacilityType): boolean {
    return this.currentTab === tabView;
  }

  /**
   * Load all facilities parents
   * mcDeliveryFacility get the parent of all partners mcDeliveries
   * LADFacilities get all partners LAD
   */
  loadFacilities(): void {
    this.isLoading = true;
    const deliveryPartnerIds = this.currentRestaurant.availableDeliveryPartnersIds;
    this.ladFacilities = new Array();
    this.facilities = [];

    if (deliveryPartnerIds.length > 0) {
      const facilities: Array<Observable<Facility>> = [];

      for (const deliveryPartnerId of deliveryPartnerIds) {
        facilities.push(this.facilityService.getRestaurantFacilityByRef(this.currentRestaurant.ref, deliveryPartnerId));
      }
      this.matchDifferentFacility(facilities);
    } else {
      (async () => {
        await new Promise((resolve) => setTimeout(resolve, 500));
      })();
    }

    this.facilities.push(this.mcDeliveryFacility);
    for (const ladFacilityTmp of this.ladFacilities) {
      this.facilities.push(ladFacilityTmp);
    }

    this.getAllFacilitiesForExceptionalHours();
  }

  /**
   * check if the facility is a McDelivery child
   */
  private isMcDeliveryPartner(facility: Facility): boolean {
    return facility.reference === FacilityType.MC_DELIVERY_STUART || facility.reference === FacilityType.MC_DELIVERY_UBERDIRECT;
  }

  private matchDifferentFacility(facilities: Array<Observable<Facility>>): void {
    this.mcDeliveryPartnerFacilities = [];
    forkJoin(facilities).subscribe((tmpFacilities) => {
      for (const facility of tmpFacilities) {
        if (facility) {
          if (facility.reference === FacilityType.MC_DELIVERY.valueOf()) {
            this.mcDeliveryFacility = facility;
          } else if (this.isMcDeliveryPartner(facility)) {
            this.mcDeliveryPartnerFacilities.push(facility);
          } else {
            this.addLadFacility(facility);
          }
        }
      }

      if (this.mcDeliveryFacility) {
        this.mcDeliveryFacility.childrenFacilities = this.mcDeliveryPartnerFacilities;
      }
      this.hasMcDelivery = this.mcDeliveryFacility != null && this.mcDeliveryFacility.childrenFacilities.length !== 0;

      this.hasLAD = this.ladFacilities.length > 0;
    });
  }

  /**
   * Add the facility to the facilities list if is not null
   */
  private addLadFacility(facilityToAdd: Facility): void {
    if (facilityToAdd) {
      this.ladFacilities.push(facilityToAdd);
    }
  }

  private getAllFacilitiesForExceptionalHours(): void {
    this.facilityService.getFacilities(this.currentRestaurant.ref).subscribe((facilities) => {
      this.allFacilities = facilities;
      this.restaurantService
        .getRestaurantExceptionalHours(this.currentRestaurant.ref.toString())
        .subscribe((exceptionalHoursViewTabTmp) => {
          exceptionalHoursViewTabTmp.sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime());
          this.exceptionalHoursTab = exceptionalHoursViewTabTmp;
          this.isLoading = false;
        });
    });
  }
}
