import { Component, Input, OnInit } from '@angular/core';
import { ExcludedIngredient } from '../../../../models/order/excluded-ingredient.model';

@Component({
  selector: 'app-excluded-ingredient-list',
  templateUrl: './excluded-ingredient-list.component.html',
  styleUrls: ['./excluded-ingredient-list.component.scss'],
})
export class ExcludedIngredientListComponent implements OnInit {
  @Input() excludedIngredients: ExcludedIngredient[];

  constructor() {}

  ngOnInit(): void {}
}
