import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Observable, of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { OrderError } from '../../../models/order/order-error.model';
import { OrderStatus } from '../../../models/order/order-status.enum';
import { OrderType } from '../../../models/order/order-type.enum';
import { Order } from '../../../models/order/order.model';
import { ModalMultiSelectionComponent } from '../modal-multi-selection/modal-multi-selection.component';
import { Role } from '../../../models/role.enum';
import { UserService } from '../../../service/user/user.service';

@Component({
  selector: 'app-orders-list',
  templateUrl: './orders-list.component.html',
  styleUrls: ['./orders-list.component.scss'],
})
export class OrdersListComponent implements OnInit, OnChanges {
  @ViewChild('searchZone') searchZone: ElementRef;

  @Input() historyMode: boolean;
  @Input() loading: boolean;
  @Input() orders: Order[];
  @Input() statusFilter: OrderStatus;
  @Input() inputSearch: string;
  @Input() isSeeMore: boolean;
  @Input() orderErrors: OrderError[];

  filteredOrders: Order[];
  selectedOrders: Order[];

  @Output() showMoreOrdersEvent = new EventEmitter<boolean>();
  @Output() searchedOrderNumber = new EventEmitter<string>();
  @Output() searchedOrderStatus = new EventEmitter<OrderStatus>();

  @Output() podChange = new EventEmitter<boolean>();
  searchTerm = new Subject<string>();
  inputPlaceholder: string;

  canUpdate = false;

  displayDateSeparator = false;
  headersHeight: number;
  preScrollOffset = 0;
  orderNumberMaxLength = 6;
  orderStatusType: typeof OrderStatus = OrderStatus;
  orderType: typeof OrderType = OrderType;

  filters = [];
  select = false;
  allLabel: string;

  constructor(public matDialog: MatDialog, private readonly userService: UserService) {
    this.inputPlaceholder = 'N° de commande';
    this.headersHeight = document.getElementById('main-header').offsetHeight + document.getElementById('header').offsetHeight;
    this.filters.push({ label: 'TOUTES', orderStatus: OrderStatus.ALL });
    this.filters.push({ label: 'OK', orderStatus: OrderStatus.OK });
    this.filters.push({ label: 'ERREUR', orderStatus: OrderStatus.ERROR });
  }

  ngOnInit(): void {
    this.selectedOrders = [];
    this.statusFilter = !this.statusFilter ? OrderStatus.ALL : this.statusFilter;
    this.filteredOrders = this.orders;
    this.filteredOrders = this.filterByStatus(this.filteredOrders, this.statusFilter);
    this.userService.getUserInfo().subscribe((userInfos) => {
      if (userInfos) {
        this.canUpdate = userInfos.roles.includes(Role.ORDER_UPDATER);
      }
    });
    this.search(this.searchTerm).subscribe((results) => {
      this.filteredOrders = results;
    });
  }

  /**
   * Check if there is changes for orders from parent
   */
  ngOnChanges(changes: SimpleChanges): void {
    const currentItem: SimpleChange = changes.orders;
    if (currentItem && currentItem.currentValue && currentItem.previousValue) {
      this.filteredOrders = changes.orders.currentValue;
      let currentOrders;
      this.searchEntries(this.inputSearch).subscribe((orders) => (currentOrders = orders));
      this.filteredOrders = this.filterByStatus(currentOrders, this.statusFilter);
      setTimeout(() => {
        // need to wait for filtererd orders to be loaded
        window.scrollTo(0, this.preScrollOffset);
        this.preScrollOffset = 0;
      }, 10);
    }
    const loading: SimpleChange = changes.loading;
    if (loading) {
      this.loading = loading.currentValue;
    }
  }

  /**
   * On click on 'show more' button, informs parent that more orders should be loaded and keep scroll position.
   */
  showMoreOrders(): void {
    this.preScrollOffset = window.pageYOffset;
    this.showMoreOrdersEvent.emit(true);
  }

  /**
   * Triggered when user search input changes.
   */
  onEnterText(): void {
    const value = this.inputSearch;

    if (!value || value === '') {
      document.getElementById('search-order-button').classList.remove('searching');
      document.getElementById('search-order-input').classList.remove('searching');
    } else {
      this.searchSticky();
      document.getElementById('search-order-button').classList.add('searching');
      document.getElementById('search-order-input').classList.add('searching');
    }
    if (this.historyMode) {
      if (!value || value === '') {
        this.searchedOrderNumber.emit('');
        this.loseFocus();
      } else if (value.length >= 4) {
        // informed parent component (orders-history) to search orders with that value
        this.searchedOrderNumber.emit(value);
      }
    } else {
      // search in current orders list
      this.searchTerm.next(value);
    }
  }

  /**
   * Triggered when user pressed enter in search bar
   */
  searchValidated(): void {
    if (screen.width <= 700) {
      // mobile
      document.getElementById('nav-bar').style.display = 'flex';
    }
    if (!this.inputSearch || this.inputSearch.length < 4) {
      return;
    }
    this.searchedOrderNumber.emit(this.inputSearch);
  }

  search(terms: Observable<string>): Observable<Order[]> {
    return terms.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      switchMap((term) => this.searchEntries(term))
    );
  }

  searchEntries(term: string): Observable<Order[]> {
    const ordersByStatus = this.filterByStatus(this.orders, this.statusFilter);
    if (!term || term.length < 2) {
      return of(ordersByStatus);
    }
    term = term.toUpperCase();
    return of(ordersByStatus.filter((order: Order) => this.orderNumberMatches(order, term)));
  }

  /**
   * Return true if the order number matches the order. (check order number LAD if not null, else normal orderNumber)
   */
  private orderNumberMatches(order: Order, orderNumber: string): boolean {
    if (!order) {
      return false;
    }
    if (order.orderNumberLAD) {
      return order.orderNumberLAD.indexOf(orderNumber) >= 0;
    }
    return order.orderNumber.indexOf(orderNumber) >= 0;
  }

  /**
   * When status filter changes, apply the choice to filter the list.
   */
  applyStatusFilter(): void {
    if (this.historyMode) {
      this.searchedOrderStatus.emit(this.statusFilter as OrderStatus);
    } else {
      let currentOrders;
      this.searchEntries(this.inputSearch).subscribe((orders) => (currentOrders = orders));
      this.filteredOrders = this.filterByStatus(currentOrders, this.statusFilter);
    }
  }

  /**
   * Filters orders matching the given status (ALL/OK/ERROR)
   * @param ordersToFilter orders list to filter
   * @param status Status to match
   */
  filterByStatus(ordersToFilter: Order[], status: string): Order[] {
    let orders;
    if (!status || status === OrderStatus.ALL) {
      orders = ordersToFilter.filter((order: Order) => order.status === OrderStatus.OK || order.status === OrderStatus.ERROR);
    } else {
      orders = ordersToFilter.filter((order: Order) => order.status === status);
    }
    this.selectedOrders = orders.filter((order) => order.isSelected);
    return orders;
  }

  /**
   * When search input is focused hide both orders-header and graph or filters
   */
  searchSticky(): void {
    if (screen.width <= 700) {
      // mobile
      document.getElementById('nav-bar').style.display = 'none';
    }
    const ordersHeader = document.getElementById('orders-header');
    const graphZone = document.getElementById('graph-zone');
    const filtersZone = document.getElementById('filters-zone');
    if (ordersHeader) {
      ordersHeader.style.display = 'none';
    }
    if (graphZone) {
      graphZone.style.display = 'none';
    }
    if (filtersZone) {
      filtersZone.style.display = 'none';
    }
    this.onScroll();
    window.scrollTo(0, 0);
  }

  /**
   * When search input focus is lost and search empty : display both orders-header and graph or filters
   */
  loseFocus(): void {
    if (this.inputSearch && this.inputSearch !== '') {
      return;
    }
    if (screen.width <= 700) {
      // mobile
      document.getElementById('nav-bar').style.display = 'flex';
    }
    const ordersHeader = document.getElementById('orders-header');
    const graphZone = document.getElementById('graph-zone');
    const filtersZone = document.getElementById('filters-zone');
    if (ordersHeader) {
      ordersHeader.style.display = 'block';
    }
    if (graphZone) {
      graphZone.style.display = 'block';
    }
    if (filtersZone) {
      filtersZone.style.display = 'block';
    }
    this.onScroll();
  }

  /**
   * Method triggered when page is scrolling.
   */
  @HostListener('window:scroll')
  onScroll(): void {
    if (Math.floor(this.searchZone.nativeElement.getBoundingClientRect().top) <= this.headersHeight) {
      this.searchZone.nativeElement.classList.add('sticky-search');
    } else {
      this.searchZone.nativeElement.classList.remove('sticky-search');
    }
  }

  /**
   * Return the label according to the selected filter
   * @param orderStatus, the filter status of orders
   */

  getSelectedFilter(orderStatus: OrderStatus): string {
    let label = '';
    if (orderStatus === OrderStatus.ALL) {
      label = 'TOUTES';
    }
    if (orderStatus === OrderStatus.OK) {
      label = 'OK';
    }
    if (orderStatus === OrderStatus.ERROR) {
      label = 'ERREUR';
    }
    return label;
  }

  /**
   * Method which allow to enter in select mode
   */
  applySelect(): void {
    this.select = true;
    this.allLabel = 'Toutes';
  }

  /**
   * Allows to open the edit modal
   */

  edit(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = 'order-multi-selection-component';
    dialogConfig.maxWidth = '100vw';
    dialogConfig.height = '96%';
    dialogConfig.width = '94%';
    dialogConfig.data = { selectedOrders: this.selectedOrders };
    this.matDialog
      .open(ModalMultiSelectionComponent, dialogConfig)
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.emitPodChange();
          this.select = false;
        }
      });
  }

  /**
   * Check/uncheck the allowed orders and change the label
   */

  selectAll(): void {
    if (this.allLabel === 'Toutes') {
      this.filteredOrders.forEach((order) => {
        if (order.type === this.orderType.C_AND_C && order.status === this.orderStatusType.OK && !order.orderPodUpdated) {
          order.isSelected = true;
          this.updateSelectedOrder(order);
        }
      });
      this.allLabel = 'Aucune';
    } else {
      this.filteredOrders.forEach((order) => {
        if (order.type === this.orderType.C_AND_C && order.status === this.orderStatusType.OK && !order.orderPodUpdated) {
          order.isSelected = false;
        }
      });
      this.allLabel = 'Toutes';
      this.selectedOrders = [];
    }
  }

  /**
   * Method which allow to quit the select mode and uncheck orders
   */
  cancel(): void {
    this.select = false;
    this.filteredOrders.forEach((order) => {
      order.isSelected = false;
    });
    this.selectedOrders = [];
  }

  /**
   * Sends to the "order-in-progress" component a boolean for indicating to reload orders
   */

  emitPodChange(): void {
    this.podChange.emit(true);
  }

  updateSelectedOrder(order: Order): void {
    const index = this.selectedOrders.findIndex((x) => x.ref === order.ref);
    if (order.isSelected && index === -1) {
      this.selectedOrders.push(order);
    } else if (!order.isSelected && index > -1) {
      this.selectedOrders.splice(index, 1);
    }
  }
}
