<div id="max-amount">
  <div class="amount-content">
    <div [ngClass]="isActivated ? 'amount-content-body' : 'amount-content-body-disabled'">
      <div [ngClass]="isActivated ? 'summary-amount' : 'summary-amount-disabled'">
        <div class="first-line-max-amount">{{ getOrderTypeValue(orderType, true) }} - Montant maximum</div>
        <div class="second-line-max-amount">
          <span *ngIf="isActivated" id="amount">{{ amount }}€</span>
          <span *ngIf="!isActivated" id="amount">-</span>
          <span *ngIf="isActivated && isNationalAmount" id="amount-level">
            <svg
              width="8px"
              height="8px"
              viewBox="0 0 8 8"
              version="1.1"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4 0C1.792 0 0 1.792 0 4C0 6.208 1.792 8 4 8C6.208 8 8 6.208 8 4C8 1.792 6.208 0 4 0Z"
                id="Shape"
                fill="#5d5d5d"
                stroke="none"
              />
            </svg>
            Montant national
          </span>
        </div>
      </div>

      <button class="modifyButton" *ngIf="isActivated && isUserRestaurantManager" (click)="openModalEditAmount()">Modifier</button>
    </div>
  </div>

  <div *ngIf="!isActivated" class="info-content">
    <div id="clientInfos">
      <span class="infobulle red">i</span>
      <p>
        Le canal {{ getOrderTypeValue(orderType, true) }} est inactif sur ce restaurant. Activer votre canal de vente pour accéder au
        paramétrage.
      </p>
    </div>
  </div>
</div>
