import { Component, OnInit } from '@angular/core';
import { ErrorService } from '../../../service/error/error.service';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css'],
})
export class ErrorComponent implements OnInit {
  constructor(private readonly errorService: ErrorService) {}
  messageId: string;

  ngOnInit(): void {
    this.messageId = this.errorService.messageId;
  }

  windowInnerHeight(): number {
    return window.innerHeight - document.getElementById('main-header').clientHeight;
  }
}
