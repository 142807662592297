<div id="nav-bar-content">
    <div id="nav-bar-loaded" *ngIf="showNavBar"></div>
    <div>
        <div id="nav-bar" [ngClass]="showNavBar ? '' : 'hide'">
            <button *ngIf="isUserOrder" class="nav-item" data-url="/restaurant/orders" (click)="goTo($event)">
                <mat-icon> receipt</mat-icon>
                <p class="nav-sub-text">Commandes</p>
            </button>
            <button *ngIf="isUserOrder" id='nav-button-orders-parking' class="nav-item" data-url="/restaurant/orders-parking" (click)="goTo($event)">
                <div>
                    <mat-icon id="nav-car-orders-parking"> directions_car</mat-icon>
                    <p class="nav-sub-text">C&R Parking</p>
                    <audio #audioComponent muted style='display: none'>
                        <source src='assets/sound/notification.mp3' type="audio/mp3">
                    </audio>
                </div>
            </button>
            <button *ngIf="isUserRestaurant" class="nav-item" data-url="/restaurant/facilities" (click)="goTo($event)">
                <mat-icon> storefront</mat-icon>
                <p class="nav-sub-text">Restaurant</p>
            </button>
            <button *ngIf="isUserRestaurant" class="nav-item" data-url="/restaurant/deliveries" (click)="goTo($event)">
                <mat-icon> place</mat-icon>
                <p class="nav-sub-text">Livraison</p>
            </button>
            <button *ngIf="isUserRestaurant" class="nav-item" data-url="/restaurant/settings" (click)="goTo($event)">
                <mat-icon> tune</mat-icon>
                <p class="nav-sub-text">Paramètres</p>
            </button>
        </div>
        <div *ngIf="showParkedNotification && showNavBar" id="new-parking-order">
            <div id="new-parking-order-info">
                <span>{{ nbCurrentlyParked }}</span>
            </div>
        </div>
    </div>
</div>
