import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OrderStatus } from '../../../models/order/order-status.enum';
import { Order } from '../../../models/order/order.model';
import { Pod } from '../../../models/pod/pod.model';
import { Restaurant } from '../../../models/restaurant/restaurant.model';
import { Role } from '../../../models/role.enum';
import { OrderService } from '../../../service/order/order.service';
import { PersistanceService } from '../../../service/persistance/persistance.service';
import { PodService } from '../../../service/pod/pod.service';
import { UserService } from '../../../service/user/user.service';

@Component({
  selector: 'app-order-pod',
  templateUrl: './order-pod.component.html',
  styleUrls: ['./order-pod.component.scss'],
})
export class OrderPodComponent implements OnInit {
  currentRestaurant: Restaurant;
  showEditButton = false;
  toEdit = false;
  @Input() currentOrder: Order;
  eligiblePods: Pod[];
  canUpdate = false;
  selectedPod: Pod;
  error: string;
  @Output() podupdated: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private readonly persistanceService: PersistanceService,
    private readonly userService: UserService,
    private readonly podService: PodService,
    private readonly orderService: OrderService
  ) {}

  ngOnInit(): void {
    this.currentRestaurant = this.persistanceService.get('current_restaurant');
    this.userService.getUserInfo().subscribe((userInfos) => {
      if (userInfos) {
        this.canUpdate = userInfos.roles.includes(Role.ORDER_UPDATER);
        this.showEditButton = this.canUpdate;
      }
    });
  }

  /**
   * Check if it is possible to change the POD
   */
  isDisabled(): boolean {
    return OrderStatus.PAYMENT_AUTHORIZED !== this.currentOrder.status || this.isUpdated() || !this.currentOrder.podLabel;
  }

  /**
   * Return the label of the pod
   */
  getPodLabel(): string {
    return this.currentOrder.podLabel || 'Inconnu';
  }

  /**
   * Change the status of the order pod edit
   */
  actionClicked(): void {
    this.toEdit = !this.toEdit;
    this.error = '';
    if (this.toEdit) {
      if (this.canUpdate) {
        this.podService.getEligiblePodsOfOrder(this.currentOrder.ref.toString()).subscribe((pods) => {
          this.eligiblePods = pods;
          if (this.eligiblePods && this.eligiblePods.length > 0) {
            this.selectedPod = this.eligiblePods[0];
          }
        });
      }
    }
  }

  /**
   * Check if the order is in the status 'PAID'
   */
  isOrderPaid(order: Order): boolean {
    return OrderStatus.PAID === order.status || OrderStatus.FINISHED === order.status;
  }

  /**
   * Check if the current order pod has been updated
   */
  isUpdated(): boolean {
    return this.currentOrder.oldPodLabel && this.currentOrder.oldPodLabel !== '';
  }

  /**
   * If the order status has not changed to 'PAID', the POD of the order is updated
   */
  updatePod(): void {
    this.actionClicked();
    this.orderService.getOrderDetailsByRef(this.currentOrder.ref).subscribe((order) => {
      if (this.isOrderPaid(order)) {
        this.error = 'La commande a déjà été retirée par le client. Le changement ne sera pas effectif.';
      } else {
        this.orderService
          .updatePodOfOrder(this.currentRestaurant.ref.toString(), this.currentOrder.ref, this.selectedPod.reference)
          .subscribe(
            (orderResponse) => {
              this.currentOrder.oldPodType = this.currentOrder.podTypeReference;
              this.currentOrder.oldPodLabel = this.currentOrder.podLabel;
              this.currentOrder.podTypeReference = this.selectedPod.reference;
              this.currentOrder.podLabel = this.selectedPod.wording;
              this.podupdated.emit(true);
            },
            (err) => (this.error = 'Une erreur est survenue')
          );
      }
    });
  }
}
