import {Component, Input, OnInit} from "@angular/core";
import {TrackingService} from "../../../service/tracking/tracking-service";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {Order} from "../../../models/order/order.model";

@Component({
  selector: 'app-order-tracking',
  templateUrl: './order-tracking.component.html',
  styleUrls: ['./order-tracking.component.scss'],
})

export class OrderTrackingComponent implements OnInit {
  @Input() selectedOrder: Order;
  error: string;
  trackingUrl: SafeResourceUrl;

  constructor(private readonly sanitizer: DomSanitizer,
              private readonly trackingService: TrackingService,
  ) {
    this.trackingUrl = this.sanitizer.bypassSecurityTrustResourceUrl("");
  }

  ngOnInit(): void {
    try {
      this.trackingService.getTrackingUrl(this.selectedOrder.ref).subscribe({
        next: (trackingResponse) => {
          this.trackingUrl = this.sanitizer.bypassSecurityTrustResourceUrl(trackingResponse.trackingUrl);
        }, error: err => {
          this.error = 'Tracking indisponible.';
        }
      });
    } catch (err) {
      this.error = 'Tracking indisponible.';
    }
  }
}
