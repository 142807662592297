import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { get } from 'scriptjs';
import { environment } from '../../../../../environments/environment';
import { RestaurantCoordinates } from '../../../../../models/restaurant/restaurant-coordinates.model';
import { ErrorService } from '../../../../../service/error/error.service';
import { ConsoleLoggerService } from '../../../../../service/logger/console-logger.service';

declare var woosmap;

@Component({
  selector: 'app-restaurant-information-pin-drag-modal',
  templateUrl: './restaurant-information-pin-drag-modal.component.html',
  styleUrls: ['./restaurant-information-pin-drag-modal.component.scss'],
})
export class RestaurantInformationPinDragModalComponent implements OnInit {
  constructor(
    public matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any, //NOSONAR
    public dialogRef: MatDialogRef<RestaurantInformationPinDragModalComponent>,
    private readonly errorService: ErrorService,
    private readonly CONSOLE_LOGGER: ConsoleLoggerService
  ) {}

  isLoading = false;

  myMap;
  markerAddress;

  ngOnInit(): void {
    // When the modal is shown, we want a fixed body
    document.body.style.overflow = 'hidden';
    get(`${environment.settings.woosmapUrl}?key=${environment.settings.woosmapKey}`, () => {
      this.initMap(this.data.restaurantCoordinates);
    });
  }

  initMap(coordinates: RestaurantCoordinates): void {
    if (coordinates) {
      try {
        const mapElement = document.getElementById('woosmap-drag');
        this.myMap = new woosmap.map.Map(mapElement, {
          center: {
            lat: coordinates?.latitude ?? 0,
            lng: coordinates?.longitude ?? 0,
          },
          zoom: 18,
        });

        this.markerAddress = new woosmap.map.Marker({
          position: this.myMap.getCenter(),
          icon: {
            url: environment.settings.woosmapDotMarkerUrl,
            scaledSize: {
              height: 64,
              width: 46
            },
          },
        });
        this.markerAddress.setDraggable(true);
        this.markerAddress.setMap(this.myMap);
      } catch (e) {
        this.CONSOLE_LOGGER.error('error during update of woosmap', e);
        this.errorService.manageError(e);
      }
    }
  }

  /**
   * When clicking on confirm button, new coordinates will be shown on the map
   */
  confirm(): void {
    this.dialogRef.close(this.markerAddress.position);
  }

  /**
   * When clicking on back button it goes back to edit information screen and scroll to top.
   */
  backToEditInfos(): void {
    this.dialogRef.close();
  }
}
