export enum ClickAndReadyParkingStatus {
    /**
     * The order is created but not injected yet.
     */
    CREATED = 'CREATED',
    /**
     * The order has been injected but the customer is still on the road.
     */
    INJECTED = 'INJECTED',
    /**
     * The order has been injected, the customer is parked and waiting for his
     * order.
     */
    PARKED = 'PARKED',
    /**
     * The customer gets his Click & Ready order.
     */
    DELIVERED = 'DELIVERED',
}
