import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OrderType } from '../../../../models/order/order-type.enum';
import { Restaurant } from '../../../../models/restaurant/restaurant.model';
import { RestaurantService } from '../../../../service/restaurant/restaurant.service';
import { UserService } from '../../../../service/user/user.service';
import { ConfirmModalComponent } from '../../../common/confirm-modal/confirm-modal.component';
import { ErrorService } from '../../../../service/error/error.service';
import { ConsoleLoggerService } from '../../../../service/logger/console-logger.service';
import {DialogConfigUtilsService} from "../../../../service/utils/dialog.utils.service";

@Component({
  selector: 'app-edit-max-amount-modal',
  templateUrl: './edit-max-amount-modal.component.html',
  styleUrls: ['./edit-max-amount-modal.component.scss'],
})
export class EditMaxAmountModalComponent implements OnInit {
  screenToShow: string;
  restaurant: Restaurant;
  restaurantsToSave: string[] = [];

  nationalAmount: number;
  localAmount: number;
  saleChannel: string;
  orderType: OrderType;

  amountLastUpdatedPerson: string;
  amountLastUpdatedDate: Date;
  updatedMessage: string;

  isLoading = false;

  resetAmount: boolean;

  constructor(
    public dialogRef: MatDialogRef<EditMaxAmountModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly userService: UserService,
    public matDialog: MatDialog,
    private readonly datePipe: DatePipe,
    private readonly restaurantService: RestaurantService,
    private readonly errorService: ErrorService,
    private readonly CONSOLE_LOGGER: ConsoleLoggerService
  ) {}

  ngOnInit(): void {
    // When the modal is shown, we want a fixed body
    document.body.style.overflow = 'hidden';
    this.screenToShow = 'edit-restaurant-max-amount-information';
    this.restaurant = this.data.restaurant;
    this.nationalAmount = this.data.nationalAmount;
    this.localAmount = this.data.localAmount;
    this.saleChannel = this.data.saleChannel;
    this.restaurantsToSave.push(this.restaurant.ref.toString());
    this.orderType = this.data.orderType;
    this.resetAmount = false;
    this.setLastUpdate();
  }

  /**
   * This method allows to close the modal
   */
  closeModal(): void {
    const title = 'Si vous quittez le paramétrage, aucune modification ne sera enregistrée.';
    const modalDialog = this.openConfirmModal(title, 'QUITTER SANS SAUVEGARDER', 'ANNULER', 'close', '100%', false);

    modalDialog.afterClosed().subscribe((result) => {
      if (result) {
        this.resetBodyScroll();
        this.dialogRef.close();
      }
    });
  }

  /**
   * This method allows to control the next button.
   * 2 cases:
   * If we have mono restaurant profil the save modal appeared
   * Else we can select other restaurant and an other click on next opens the save modal
   */
  next(): void {
    if (this.userService.isMonoRestaurantProfile() || this.screenToShow === 'apply-to-restaurants') {
      let title = 'Modification du montant maximum des commandes <b>' + this.saleChannel + '</b>';
      const topText = this.updatedMessage;
      const modalDialog = this.openConfirmModal(
        title,
        'ENREGISTRER ET QUITTER',
        'PRÉCÉDENT',
        'saveWithoutWarningText',
        '94%',
        false,
        topText
      );
      modalDialog.afterClosed().subscribe((toSave) => {
        if (toSave) {
          this.isLoading = true;
          // We multiply by 100 for having cts
          this.restaurantService
            .updateMaxAmountForRestaurants(this.orderType, this.getValueInCents(this.localAmount), this.restaurantsToSave)
            .subscribe(
              (rep) => {
                this.isLoading = false;
                this.resetBodyScroll();
                this.dialogRef.close(true);
              },
              (error) => {
                this.CONSOLE_LOGGER.error('error during max amount update !', error);
                this.errorService.manageError(error);
                this.isLoading = false;
                title = 'En raison d’une erreur technique, vos paramètres n’ont pas été enregistrés. Veuillez réessayer ultérieurement';
                this.openConfirmModal(title, 'QUITTER', null, 'save-error', '94%', false, null);
                this.resetBodyScroll();
              }
            );
        }
      });
    } else if (this.resetAmount || (this.localAmount > 0 && this.localAmount <= this.nationalAmount)) {
      this.screenToShow = 'apply-to-restaurants';
      document.getElementById('edit-max-amount-modal-component').scrollTop = 0;
    }
  }

  /**
   * Reset body to be able to scroll in it.
   */
  private resetBodyScroll(): void {
    // When the modal is hidden...
    document.body.style.overflow = 'auto';
  }

  /**
   * Get the value in cents
   * @param value
   */
  getValueInCents(value: number): number {
    if (value == null) {
      return null;
    }
    return value * 100;
  }

  /**
   * This method controls the back button. If we are on the page allowing to select several restaurants, we return on the previous page
   */
  backToEditInfos(): void {
    this.restaurantsToSave = [this.restaurant.ref.toString()]; // reset restaurants to save on back
    this.screenToShow = 'edit-restaurant-max-amount-information';
    document.getElementById('edit-max-amount-modal-component').scrollTop = 0;
  }

  /**
   * This method allows to open the save modal
   * @param title
   * @param confirmText
   * @param cancelText
   * @param panelClass
   * @param width
   * @param showWarningText
   * @param topText
   * @private
   */
  private openConfirmModal(
    title: string,
    confirmText: string,
    cancelText: string,
    panelClass: string,
    width: string,
    showWarningText: boolean,
    topText?: string
  ): MatDialogRef<ConfirmModalComponent> {
    const dialogConfig = new DialogConfigUtilsService().getDialogConfig('confirm-modal', title, confirmText, cancelText, panelClass, width, showWarningText, topText);

    return this.matDialog.open(ConfirmModalComponent, dialogConfig);
  }

  /**
   * This method allows to set the lastUpdate parameters (date + profil)
   */

  setLastUpdate(): void {
    if (this.saleChannel === 'Click & Collect' && this.restaurant.candCAmountLastUpdateDate) {
      this.amountLastUpdatedPerson = this.restaurant.candCAmountLastUpdatedPerson;
      this.amountLastUpdatedDate = this.restaurant.candCAmountLastUpdateDate;
    }
    if (this.saleChannel === 'McDelivery' && this.restaurant.mcDeliveryAmountLastUpdateDate) {
      this.amountLastUpdatedPerson = this.restaurant.mcDeliveryAmountLastUpdatedPerson;
      this.amountLastUpdatedDate = this.restaurant.mcDeliveryAmountLastUpdateDate;
    }
    this.updatedMessage = this.getLastUpdate();
  }

  /**
   * This method returns the message for last update (if exists)
   */
  getLastUpdate(): string {
    return this.amountLastUpdatedDate
      ? `Modifié le ${this.datePipe.transform(this.amountLastUpdatedDate, 'dd/MM/yyyy')} par ${this.amountLastUpdatedPerson}`
      : '';
  }

  /**
   * change the value of local amount thanks to the data in the child component
   * @param $event
   */
  changeLocalAmount($event: number): void {
    this.resetAmount = false;
    this.localAmount = $event;
  }

  /**
   * reset the local amount to allow the restaurant to use the national value
   */
  resetLocalAmount(): void {
    this.localAmount = null;
    this.resetAmount = true;
  }
}
