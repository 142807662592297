import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Order } from '../../../models/order/order.model';
import { Pod } from '../../../models/pod/pod.model';

interface PodDatas {
  name: string;
  value: number;
}

@Component({
  selector: 'app-order-multi-pod',
  templateUrl: './order-multi-pod.component.html',
  styleUrls: ['./order-multi-pod.component.scss'],
})
export class OrderMultiPodComponent implements OnInit {
  @Input() orders: Order[];
  @Input() eligiblePods: Pod[];
  @Input() isEatIn: boolean;

  @Output() outputEatIn: EventEmitter<Pod> = new EventEmitter<Pod>();
  @Output() outpputTakeOut: EventEmitter<Pod> = new EventEmitter<Pod>();

  selectedPod: Pod;
  podDatas: PodDatas[];

  constructor() {}

  ngOnInit(): void {
    this.podDatas = this.toSortPod();
  }

  /**
   * Sort the orders according to their pod
   * Return an list of PodData (label + number), object defines in an interface (see above)
   */

  toSortPod(): PodDatas[] {
    const podToReturn: PodDatas[] = [];
    const labelList: string[] = [];
    this.orders.forEach((order) => {
      const label = order.podLabel;
      if (labelList.includes(label)) {
        const i = labelList.indexOf(label);
        podToReturn[i].value += 1;
      } else {
        labelList.push(label);
        podToReturn.push({
          name: label,
          value: 1,
        });
      }
    });
    return podToReturn;
  }

  /**
   * Method which emit the new Pod to the parent (which process data)
   */

  selectNewPod(): void {
    if (this.isEatIn) {
      this.outputEatIn.emit(this.selectedPod);
    } else {
      this.outpputTakeOut.emit(this.selectedPod);
    }
  }
}
