import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {Order} from "../../../models/order/order.model";
import {OrderError} from "../../../models/order/order-error.model";
import {OrderStatus} from "../../../models/order/order-status.enum";
import {OrderType} from "../../../models/order/order-type.enum";
import {OrderService} from "../../../service/order/order.service";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {OrderModalParkingComponent} from "../order-modal-parking/order-modal-parking.component";

@Component({
    selector: 'app-order-parking-line',
    templateUrl: './order-parking-line.component.html',
    styleUrls: ['./order-parking-line.component.scss']
})
export class OrderParkingLineComponent implements OnInit {
    @Input() order: Order;
    @Input() orderErrors: OrderError[];
    @Input() select: boolean;

    @Output() podChange = new EventEmitter<boolean>();
    @Output() orderSelectChange = new EventEmitter<Order>();

    orderStatusType: typeof OrderStatus = OrderStatus;
    orderType: typeof OrderType = OrderType;
    vehiculeName: string;

    orderHasError = true;
    orderError;

    constructor(public readonly orderService: OrderService, public matDialog: MatDialog) {
    }

    ngOnInit(): void {
        this.orderHasError = this.order && this.order.status === OrderStatus.ERROR;
        if (this.orderHasError) {
            if (this.order.status === OrderStatus.ERROR) {
                const errorCode = this.order.errorCode ? this.order.errorCode : 'Code erreur non remonté';
                this.orderError = this.orderErrors.find((err) => err.errorCode === errorCode);
            }
        }
        this.truncateVehicleName();
    }

    /**
     * Method triggered when user clicks on an order item in the list.
     */
    onOrderClicked(): void {
        if (!this.select) {
            const dialogConfig = new MatDialogConfig();
            // The user can't close the dialog by clicking outside its body
            dialogConfig.disableClose = true;
            dialogConfig.id = 'order-modal-parking-component';
            dialogConfig.maxWidth = '100vw';
            dialogConfig.height = '96%';
            dialogConfig.width = '94%';
            this.orderService.getOrderDetailsByRef(this.order.ref).subscribe((order) => {
                dialogConfig.data = {currentOrder: order};
                this.matDialog
                    .open(OrderModalParkingComponent, dialogConfig)
                    .afterClosed()
                    .subscribe((result) => {
                        if (result) {
                            this.podChange.emit(true);
                        }
                    });
            });
        }
    }

    getErrorCause(): string {
        return this.orderError ? this.orderError.shortLabel : 'Erreur inconnue';
    }

    @HostListener('window:resize')
    onResize(): void {
        this.truncateVehicleName();
    }

    private truncateVehicleName(): void {
        if (window.innerWidth < 700) {
            if (this.orderHasError) {
                if (this.order.collectingDetails.associatedVehicleDetails.length > 17) {
                    this.vehiculeName = this.order.collectingDetails.associatedVehicleDetails.substring(0, 17) + "...";
                } else {
                    this.vehiculeName = this.order.collectingDetails.associatedVehicleDetails;
                }
            } else {
                if (this.order.collectingDetails.associatedVehicleDetails.length > 25) {
                    this.vehiculeName = this.order.collectingDetails.associatedVehicleDetails.substring(0, 25) + "...";
                } else {
                    this.vehiculeName = this.order.collectingDetails.associatedVehicleDetails;
                }
            }
        } else {
            this.vehiculeName = this.order.collectingDetails.associatedVehicleDetails;
        }
    }
}
