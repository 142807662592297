import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Facility } from '../../../../models/facility/facility.model';
import { FacilityType } from '../../../../models/facility/facilityType.enum';
import { Restaurant } from '../../../../models/restaurant/restaurant.model';
import { FacilityService } from '../../../../service/facility/facility.service';
import { PaymentService } from '../../../../service/payment/payment.service';
import { PersistanceService } from '../../../../service/persistance/persistance.service';
import { SlotService } from '../../../../service/slot/slot.service';
import { UserService } from '../../../../service/user/user.service';
import { ConfirmModalComponent } from '../../../common/confirm-modal/confirm-modal.component';
import { ErrorService } from '../../../../service/error/error.service';
import { ConsoleLoggerService } from '../../../../service/logger/console-logger.service';
import {DialogConfigUtilsService} from "../../../../service/utils/dialog.utils.service";

@Component({
  selector: 'app-edit-facility-modal',
  templateUrl: './edit-facility-modal.component.html',
  styleUrls: ['./edit-facility-modal.component.scss'],
})
export class EditFacilityModalComponent implements OnInit {
  isLoading = false;
  screenToShow: string;
  facilityCopy: Facility;
  restaurant: Restaurant;
  restaurantsToSave: string[] = [];
  facilityType: FacilityType;
  facilityParentType: FacilityType;
  oneDayActive: boolean;
  title: string;
  readonly applyToRestaurants = 'apply-to-restaurants';

  //NOSONAR
  constructor(
    public matDialog: MatDialog, //NOSONAR
    @Inject(MAT_DIALOG_DATA) public data: any, //NOSONAR
    public dialogRef: MatDialogRef<EditFacilityModalComponent>, //NOSONAR
    private readonly persistanceService: PersistanceService, //NOSONAR
    private readonly slotService: SlotService, //NOSONAR
    public facilityService: FacilityService, //NOSONAR
    private readonly userService: UserService, //NOSONAR
    private readonly datePipe: DatePipe, //NOSONAR
    private readonly paymentService: PaymentService, //NOSONAR
    private readonly errorService: ErrorService,
    private readonly CONSOLE_LOGGER: ConsoleLoggerService,
  ) {}

  ngOnInit(): void {
    // When the modal is shown, we want a fixed body
    document.body.style.overflow = 'hidden';
    this.facilityCopy = this.facilityService.cloneFacility(this.data.currentFacility);
    //we use the defaultSlots for the edit informations
    this.facilityCopy.slots = this.facilityCopy.defaultSlots;
    this.facilityType = this.data.facilityType;
    this.restaurant = this.persistanceService.get('current_restaurant');
    this.screenToShow = 'facility-info';
    this.oneDayActive = this.slotService.hasActiveOneDay(this.facilityCopy.slots);
    this.restaurantsToSave.push(this.restaurant.ref.toString());
    this.facilityParentType = this.data.facilityParentType;
    this.setTitle();
  }

  /**
   * Reset body to be able to scroll in it.
   */
  private resetBodyScroll(): void {
    // When the modal is hidden...
    document.body.style.overflow = 'auto';
  }

  /**
   * When clicking on cross in right top corner it show a popup to confirm the exit of POD settings.
   */
  closeModal(): void {
    const title = 'Si vous quittez le paramétrage, aucune modification ne sera enregistrée.';
    const modalDialog = this.openConfirmModal(title, 'QUITTER SANS SAUVEGARDER', 'ANNULER', 'close', '100%', false);
    modalDialog.afterClosed().subscribe((result) => {
      if (result) {
        if (this.data.currentFacility.restaurantActivation && !this.slotService.hasActiveOneDay(this.data.currentFacility.slots)) {
          // if pod is non-active when click.
          this.data.currentFacility.restaurantActivation = !this.data.currentFacility.restaurantActivation;
        }
        this.resetBodyScroll();
        this.dialogRef.close();
      }
    });
  }

  private openConfirmModal(
    title: string,
    confirmText: string,
    cancelText: string,
    panelClass: string,
    width: string,
    showWarningText: boolean,
    topText?: string,
    applyText?: string
  ): MatDialogRef<ConfirmModalComponent> {
    const dialogConfig = new DialogConfigUtilsService().getDialogConfig('confirm-modal', title, confirmText, cancelText, panelClass, width, showWarningText, topText, applyText);

    return this.matDialog.open(ConfirmModalComponent, dialogConfig);
  }

  /**
   * When clicking on next button :
   *    - if we are in mono-restaurant profile or on 2nd screen (apply-to-restaurants) => show save popup and save POD to EDD.
   *    - else show the 2nd screen (apply-to-restaurants) to allow user to save configuration on several restaurants.
   */
  next(): void {
    this.oneDayActive = this.slotService.hasActiveOneDay(this.facilityCopy.slots);
    if (this.validateSlotInformation()) {
      let confirmText = 'ENREGISTRER';
      let applyText = null;

      // to know if another restaurant has payment methods
      const otherRestaurants = this.persistanceService
        .get('user_restaurants')
        .filter((restaurant) => restaurant.ref !== this.restaurant.ref);
      const hasPaymentMethods = this.paymentService.getRestaurantWithPaymentMethods(otherRestaurants).length !== 0;

      if (!(this.userService.isMonoRestaurantProfile() || this.screenToShow === this.applyToRestaurants)) {
        if (
          (this.isCandCFacility() && hasPaymentMethods) ||
          (!this.isCandCFacility() && !this.isLADOrMcDeliveryFacility()) ||
          (this.isLADOrMcDeliveryFacility() && this.haveRestaurantsWithSameFacility())
        ) {
          applyText = 'APPLIQUER À PLUSIEURS';
        }
      } else {
        confirmText = 'ENREGISTRER ET QUITTER';
      }
      const title = this.getSavePopupTitle();
      const topText = this.getLastUpdateMessage();
      this.createModalDialog(title, confirmText, topText, applyText);
    }
  }

  /**
   * Get the popup title when saving a facility depending on facility type
   */
  private getSavePopupTitle(): string {
    if (this.isRushHours()) {
      return `Modification des <b> ${this.facilityCopy.label} </b>`;
    } else if (this.isLADFacility()) {
      return `Modification du partenaire <b> ${this.facilityCopy.label} </b>`;
    } else {
      return `Modification du canal de vente <b> ${this.facilityService.retrieveFacilityLabel(this.facilityCopy)}</b>`;
    }
  }

  private createModalDialog(title: string, confirmText: string, topText: string, applyText): void {
    const modalDialog = this.openConfirmModal(title, confirmText, 'PRÉCÉDENT', 'save', '94%', false, topText, applyText);
    modalDialog.afterClosed().subscribe((message) => {
      if (message === true) {
        this.isLoading = true;
        this.facilityService.updateFacilityForRestaurants(this.facilityCopy, this.restaurantsToSave).subscribe(
          (updatedFacility) => {
            this.isLoading = false;
            this.resetBodyScroll();
            this.dialogRef.close(updatedFacility);
          },
          (error) => {
            this.CONSOLE_LOGGER.error('error during facility update !', error);
            this.errorService.manageError(error);
            this.isLoading = false;
            title = 'En raison d’une erreur technique, vos paramètres n’ont pas été enregistrés. Veuillez réessayer ultérieurement';
            this.openConfirmModal(title, 'QUITTER', null, 'save-error', '94%', false, null);
            this.resetBodyScroll();
          }
        );
      }
      if (message === this.applyToRestaurants) {
        this.screenToShow = this.applyToRestaurants;
        document.getElementById('edit-facility-modal-component').scrollTop = 0;
        this.isLoading = false;
      }
    });
  }

  /**
   * When clicking on back button it goes back to edit information screen and scroll to top.
   */
  backToEditInfos(): void {
    this.restaurantsToSave = [this.restaurant.ref.toString()]; // reset restaurants to save on back
    this.screenToShow = 'facility-info';
    document.getElementById('edit-facility-modal-component').scrollTop = 0;
  }

  /**
   * This method returns the message for last update (if exists)
   */
  getLastUpdateMessage(): string {
    return this.facilityCopy.lastUpdatedDate
      ? `Modifié le ${this.datePipe.transform(this.facilityCopy.lastUpdatedDate, 'dd/MM/yyyy')} par ${this.facilityCopy.lastUpdatedPerson}`
      : '';
  }

  /**
   * return if the facility type is C&C
   */
  isCandCFacility(): boolean {
    return this.facilityType === FacilityType.C_AND_C;
  }

  /**
   * return if the facility type is LAD or McDelivery
   */
  isLADOrMcDeliveryFacility(): boolean {
    return this.isLADFacility() || this.isMcDeliveryFacility();
  }

  /**
   * return if the facility type is LAD
   */
  isLADFacility(): boolean {
    return (
      this.facilityType === FacilityType.LAD_EXTERNE ||
      this.facilityType === FacilityType.LAD_EXTERNE_DELIVEROO ||
      this.facilityType === FacilityType.LAD_EXTERNE_JUST_EAT ||
      this.facilityType === FacilityType.LAD_EXTERNE_UBER_EATS ||
      this.facilityType === FacilityType.LAD_EXTERNE_LYVEAT
    );
  }

  /**
   * return if the facility type is McDelivery
   * // todo: add mcdelivery partners SP9
   */
  isMcDeliveryFacility(): boolean {
    return this.facilityType === FacilityType.MC_DELIVERY;
  }

  /**
   * return if slot information are validate
   */
  validateSlotInformation(): boolean {
    // If the type of facility is C&C, the field afterMidnight of the method this.slotService.validateSlotInformation must be false
    return this.slotService.validateSlotInformation(
      this.facilityCopy.slots,
      this.facilityCopy.restaurantActivation && this.facilityCopy.nationalActivation,
      this.facilityCopy
    );
  }

  setTitle(): void {
    switch (this.facilityParentType) {
      case FacilityType.LAD_EXTERNE:
        this.title = 'LAD Partenaires';
        break;
      case FacilityType.RUSH:
        this.title = 'Click & Collect';
        break;
      default:
        this.title = 'Canal de vente';
    }
  }

  isDeliveryPartner(): boolean {
    return this.facilityParentType.valueOf() === FacilityType.MC_DELIVERY || this.facilityParentType.valueOf() === FacilityType.LAD_EXTERNE;
  }

  isRushHours(): boolean {
    return this.facilityParentType.valueOf() === FacilityType.RUSH;
  }

  /**
   * @param idFacilities
   * return if current facility is present in ids of facility list
   */
  haveSameFacility(idFacilities: string[]): boolean {
    for (const id of idFacilities) {
      if (id === this.facilityType.valueOf()) {
        return true;
      }
    }
    return false;
  }

  haveRestaurantsWithSameFacility(): boolean {
    let restaurants: Restaurant[];
    this.userService.getUserRestaurants().subscribe((data) => {
      restaurants = data.filter((x) => x.ref !== this.restaurant.ref).filter((x) => this.haveSameFacility(x.availableDeliveryPartnersIds));
    });
    return restaurants && restaurants.length > 0;
  }
}
