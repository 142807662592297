import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-edit-max-amount-information',
  templateUrl: './edit-max-amount-information.component.html',
  styleUrls: ['./edit-max-amount-information.component.scss'],
})
export class EditMaxAmountInformationComponent implements OnInit {
  @Input() localAmount: number;
  @Input() nationalAmount: number;
  @Input() updatedMessage: string;
  @Input() maxAmountFormControl: FormControl;

  @Output() maxAmount: EventEmitter<number> = new EventEmitter<number>();
  @Output() resetAmount: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {
    this.setMinContentHeigth();
  }

  /**
   * This method sets the height of our component so that there is not available space
   */
  setMinContentHeigth(): void {
    document.getElementById('edit-max-amount-body').style.minHeight =
      document.getElementById('edit-max-amount-modal-component').offsetHeight -
      document.getElementById('modal-header').offsetHeight -
      document.getElementById('modal-footer').offsetHeight -
      15 + // we delete the margin-top
      'px';
  }

  /**
   * send the new value to parent if the value of local amount is changed
   */
  inputChange(): void {
    this.maxAmount.emit(this.localAmount);
  }

  /**
   * This method checks the input length for max 3 chars.
   * @param event (object when press the keyboard)
   */
  checkInputLength(event: any): boolean {
    return this.isNumber(event) && event.srcElement?.value?.length < 3;
  }

  /**
   * Check if the typed key is a number
   * @param event the keyboard event
   */
  isNumber(event: any): boolean {
    return event?.keyCode > 47 && event?.keyCode < 58;
  }

  /**
   * informs parent that we want to reset the local amount
   */
  resetLocalAmount(): void {
    this.resetAmount.emit();
  }
}
