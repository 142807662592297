import { AfterViewChecked, Component, ElementRef, HostListener, Input, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { FacilityType } from '../../../models/facility/facilityType.enum';
import { Restaurant } from '../../../models/restaurant/restaurant.model';
import { PaymentService } from '../../../service/payment/payment.service';
import { UserService } from '../../../service/user/user.service';

@Component({
  selector: 'app-edit-multi-restaurants',
  templateUrl: './edit-multi-restaurants.component.html',
  styleUrls: ['./edit-multi-restaurants.component.scss'],
})
export class EditMultiRestaurantsComponent implements OnInit, OnDestroy, AfterViewChecked {
  @Input() chosenRestaurants: string[]; // restaurants in which to save POD configuration
  @Input() baseRestaurant: number;
  @Input() modalName: string;

  @Input() testPaymentMethod: boolean;
  @Input() isLADOrMcDelivery: boolean;

  @Input() facilityType: FacilityType;

  restaurants: Restaurant[]; // All user restaurants TF change to show if a restaurant have a delivery parner
  filteredRestaurants: Restaurant[]; // The filtered restaurants by search bar
  searchTerm = new Subject<string>();
  inputSearch: any;

  inputPlaceholder: string;
  contentElement: any;
  listener: any;
  allChecked = false;
  private readonly paddingTop = 'padding-top';
  private readonly searchButtonRestaurantElementId = 'search-button-restaurant';
  private readonly searchInputRestaurantElementId = 'search-input-restaurant';

  @ViewChild('restaurantList') restaurantList: ElementRef;
  @ViewChild('allRestaurantsDiv') allRestaurantsDiv: ElementRef;

  constructor(
    private readonly userService: UserService,
    private readonly renderer: Renderer2,
    private readonly paymentService: PaymentService
  ) {}

  ngOnInit(): void {
    this.contentElement = document.getElementById(this.modalName);
    this.inputPlaceholder = 'Rechercher (code ou nom)';
    this.userService.getUserRestaurants().subscribe((data) => {
      this.restaurants = data.filter((x) => x.ref !== this.baseRestaurant);
      if (this.isLADOrMcDelivery) {
        this.restaurants = data
          .filter((x) => x.ref !== this.baseRestaurant)
          .filter((x) => this.haveSameFacility(x.availableDeliveryPartnersIds));
      }
      // use in the case where we apply modifications for C&C to several restaurants
      if (this.testPaymentMethod) {
        this.restaurants = this.paymentService.getRestaurantWithPaymentMethods(this.restaurants);
      }
      this.restaurants.sort((a, b) => {
        return a.name < b.name ? -1 : 1; // sort by restaurant name
      });
      // then, we subscribe to "this.search" to filter list based on input search.
      this.search(this.searchTerm).subscribe((results) => (this.filteredRestaurants = results));
      this.searchTerm.next('');
    });

    this.listener = this.renderer.listen(this.contentElement, 'scroll', () => {
      this.checkScroll();
    });
  }

  /**
   * Selects a restaurant from the list and add it in the chosen list to save it later in EDD.
   * @param ref Selected restaurant reference
   */
  selectRestaurant(ref: string): void {
    const index = this.chosenRestaurants.indexOf(ref);
    if (index === -1) {
      this.chosenRestaurants.push(ref);
    } else {
      this.chosenRestaurants.splice(index, 1);
    }
    this.allChecked = this.allRestaurantsChecked();
  }

  /**
   * Selects all restaurants from the list. Add all in the chosen list to save them later in EDD.
   */
  selectAllRestaurants(): void {
    if (!this.allRestaurantsChecked()) {
      this.restaurants.forEach((x) => {
        const index = this.chosenRestaurants.indexOf(x.ref.toString());
        if (index === -1) {
          this.chosenRestaurants.push(x.ref.toString());
        }
      });
    } else {
      this.chosenRestaurants.length = 0;
      this.chosenRestaurants.push(this.baseRestaurant.toString());
    }
  }

  /**
   * Check if a restaurant is already selected
   * @param ref restaurant reference to check
   * @returns true if restaurant is selected, false otherwise
   */
  isRestaurantCheck(ref: number): boolean {
    return this.chosenRestaurants.indexOf(ref.toString()) > -1;
  }

  /**
   * Check if all restaurants are selected
   * @returns true if all restaurants are selected, false otherwise
   */
  allRestaurantsChecked(): boolean {
    return this.chosenRestaurants.length === this.restaurants.length + 1;
  }

  ngOnDestroy(): void {
    this.listener();
  }

  /**
   * Triggered when user search input changes.
   */
  onEnterText(): void {
    const value = this.inputSearch;

    if (!value || value === '') {
      document.getElementById(this.searchButtonRestaurantElementId).classList.remove('searching');
      document.getElementById(this.searchInputRestaurantElementId).classList.remove('searching');
    } else {
      document.getElementById(this.searchButtonRestaurantElementId).classList.add('searching');
      document.getElementById(this.searchInputRestaurantElementId).classList.add('searching');
    }
    this.searchTerm.next(value);

    // Ré-initialise le padding-top à chaque recherche permettant d'éviter des espaces non désirés entres les items de la liste
    if (this.restaurantList !== undefined) {
      this.restaurantList.nativeElement.style.setProperty(this.paddingTop, '0px');
    }
  }

  /**
   * Triggered when user search input is deleted with the cross.
   */
  onDeleteText(): void {
    this.inputSearch = '';
    document.getElementById(this.searchButtonRestaurantElementId).classList.remove('searching');
    document.getElementById(this.searchInputRestaurantElementId).classList.remove('searching');
    this.searchTerm.next('');
    if (this.restaurantList !== undefined) {
      this.restaurantList.nativeElement.style.setProperty(this.paddingTop, '0px');
    }
  }

  private search(text: Observable<string>): Observable<Restaurant[]> {
    return text.pipe(
      distinctUntilChanged(),
      switchMap((term) => this.searchEntries(term))
    );
  }

  /**
   * Filters restaurants list if its name or ref contains the given term.
   * @param term Searched term
   */
  private searchEntries(term: string): Observable<Restaurant[]> {
    if (!term || term === '') {
      return of(this.restaurants);
    }

    term = term.toUpperCase();
    return of(this.restaurants.filter((x) => x.name.indexOf(term) >= 0 || x.ref.toString().indexOf(term) >= 0)).pipe(
      map((data) => {
        data.sort((a, b) => {
          return a.name < b.name ? -1 : 1; // sort by restaurant name
        });
        return data;
      })
    );
  }

  /**
   * Calculates the height of the empty block when there is no result.
   */
  windowInnerHeight(): number {
    return window.innerHeight - document.getElementById('main-header').offsetHeight;
  }

  setMinContentHeight(): void {
    const listContentSize =
      this.contentElement.offsetHeight -
      document.getElementById('search-header').offsetHeight -
      document.getElementById('modal-footer').offsetHeight +
      32 +
      5;
    document.getElementById('list-content').style.minHeight = `${listContentSize}px`;
  }

  /**
   * Method triggered when content is scrolling.
   */
  checkScroll(): void {
    if (this.contentElement.scrollTop > 60) {
      document.getElementById('text-infos').style.display = 'none';
      document.getElementById('search-restaurant').classList.add('collapsed');
    } else {
      document.getElementById('text-infos').style.display = 'block';
      document.getElementById('search-restaurant').classList.remove('collapsed');
    }
  }

  modalInnerWidth(): number {
    // - 32 = padding du search-header
    return document.getElementById(this.modalName).clientWidth - 32;
  }

  ngAfterViewChecked(): void {
    this.setMarginTop();
    this.setMinContentHeight();
  }

  setMarginTop(): void {
    let height = document.getElementById('search-header').offsetHeight;
    if (this.allRestaurantsDiv !== undefined) {
      height += 15;
      this.allRestaurantsDiv.nativeElement.style.setProperty(this.paddingTop, `${height}px`);
    } else if (this.restaurantList !== undefined) {
      this.restaurantList.nativeElement.style.setProperty(this.paddingTop, `${height}px`);
    }
  }

  /**
   * Method triggered when user is resizing the window.
   */
  @HostListener('window:resize')
  onResize(): void {
    this.setMarginTop();
    this.setMinContentHeight();
  }

  /**
   * @param idFacilities
   * return if current facility is present in ids of facility list
   */
  haveSameFacility(idFacilities: string[]): boolean {
    for (const id of idFacilities) {
      if (id === this.facilityType.valueOf()) {
        return true;
      }
    }
    return false;
  }
}
