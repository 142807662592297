<div>
  <!-- HEADER -->
  <div id="modal-header">
    <div class="modal-title">POINTS DE VENTE</div>
    <a>
      <mat-icon id="close-modal-button" (click)="closeModal()">close</mat-icon>
    </a>
    <div class="consumption-type-name">Horaires exceptionnels</div>
  </div>

  <div id="modal-body">
    <app-important-information
      id="exceptional-hours-information"
      [message]="exceptionalHoursMessage"
      [infoBulleColor]="'#237e47'"
    ></app-important-information>
    <div class="restaurant-name">
      {{ restaurant?.name }}
    </div>
    <app-exceptional-hours-facilities-section
      [exceptionalHoursTabInput]="exceptionalHoursTabInput"
      [facilities]="facilities"
    ></app-exceptional-hours-facilities-section>
  </div>
</div>
