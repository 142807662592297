import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Log } from '../../models/log/log.model';
import { LogLevel } from '../../models/log/log-level.enum';

@Injectable({
  providedIn: 'root',
})
export abstract class LoggerService {
  private readonly logLevel: LogLevel;

  protected constructor(logLevel: LogLevel) {
    this.logLevel = logLevel;
  }

  debug(msg: string, ...optionalParams: any[]): any {
    return this.writeToLog(msg, LogLevel.DEBUG, optionalParams);
  }

  info(msg: string, ...optionalParams: any[]): any {
    return this.writeToLog(msg, LogLevel.INFO, optionalParams);
  }

  warn(msg: string, ...optionalParams: any[]): any {
    return this.writeToLog(msg, LogLevel.WARN, optionalParams);
  }

  error(msg: string, ...optionalParams: any[]): any {
    return this.writeToLog(msg, LogLevel.ERROR, optionalParams);
  }

  fatal(msg: string, ...optionalParams: any[]): any {
    return this.writeToLog(msg, LogLevel.FATAL, optionalParams);
  }

  protected shouldLog(targetLevel: LogLevel, authorizedLevel: LogLevel): boolean {
    return authorizedLevel === LogLevel.ALL || (authorizedLevel !== LogLevel.OFF && targetLevel >= authorizedLevel);
  }

  protected writeToLog(msg: string, level: LogLevel, params: any[]): any {
    const log: Log = new Log();
    log.message = msg;
    log.level = level;
    log.extraInfo = params;
    if (this.shouldLog(level, this.logLevel)) {
      return this.log(log);
    }
    return new Observable();
  }

  protected abstract log(log: Log): Observable<string>;
}
