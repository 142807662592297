import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { OrderError } from '../../../models/order/order-error.model';
import { OrderStatus } from '../../../models/order/order-status.enum';
import { OrderType } from '../../../models/order/order-type.enum';
import { Order } from '../../../models/order/order.model';
import { OrderService } from '../../../service/order/order.service';
import { OrderModalComponent } from '../order-modal/order-modal.component';

@Component({
  selector: 'app-order-line',
  templateUrl: './order-line.component.html',
  styleUrls: ['./order-line.component.scss'],
})
export class OrderLineComponent implements OnInit {
  @Input() order: Order;
  @Input() orderErrors: OrderError[];
  @Input() select: boolean;

  @Output() podChange = new EventEmitter<boolean>();
  @Output() orderSelectChange = new EventEmitter<Order>();

  orderStatusType: typeof OrderStatus = OrderStatus;
  orderType: typeof OrderType = OrderType;

  orderHasError = true;
  orderError;

  constructor(public readonly orderService: OrderService, public matDialog: MatDialog) {}

  ngOnInit(): void {
    this.orderHasError = this.order && this.order.status === OrderStatus.ERROR;
    if (this.orderHasError) {
      if (this.order.status === OrderStatus.ERROR) {
        const errorCode = this.order.errorCode ? this.order.errorCode : 'Code erreur non remonté';
        this.orderError = this.orderErrors.find((err) => err.errorCode === errorCode);
      }
    }
  }

  /**
   * Method triggered when user clicks on an order item in the list.
   */
  onOrderClicked(): void {
    if (!this.select) {
      const dialogConfig = new MatDialogConfig();
      // The user can't close the dialog by clicking outside its body
      dialogConfig.disableClose = true;
      dialogConfig.id = 'order-modal-component';
      dialogConfig.maxWidth = '100vw';
      dialogConfig.height = '96%';
      dialogConfig.width = '94%';
      this.orderService.getOrderDetailsByRef(this.order.ref).subscribe((order) => {
        dialogConfig.data = { currentOrder: order };
        this.matDialog
          .open(OrderModalComponent, dialogConfig)
          .afterClosed()
          .subscribe((result) => {
            if (result) {
              this.podChange.emit(true);
            }
          });
      });
    }
  }

  getErrorCause(): string {
    return this.orderError ? this.orderError.shortLabel : 'Erreur inconnue';
  }

  /**
   * Check if an order can be selected to change its POD.
   * @param order order to check
   */
  isOrderSelectable(order: Order): boolean {
    return !(order.type !== OrderType.C_AND_C || !order.podLabel || order.status !== OrderStatus.OK || order.orderPodUpdated);
  }

  /**
   * Inform its parent that the value of the selected field has changed for the order
   */
  orderSelectChanged(order: Order): void {
    this.orderSelectChange.emit(order);
  }
}
