<div *ngIf="isLoading; else loader">
  <app-loader></app-loader>
</div>

<ng-template #loader>

  <div class="align-subtitle-infobulle">
    <h1 class="settings-subtitle">Services</h1>
    <span class="infobulle green" (click)="displayServicesInfos()">
      <div class="infobulle-content">?</div>
    </span>
  </div>

  <app-restaurant-settings-facilities-list [facilities]="restaurationFacilities"
    [title]="restaurantTitle"></app-restaurant-settings-facilities-list>
  <br />
  <app-restaurant-settings-facilities-list [facilities]="equipmentFacilities"
    [title]="equipmentTitle"></app-restaurant-settings-facilities-list>
  <br />

</ng-template>
