<div *ngIf="isLoading; else loader">
    <app-loader></app-loader>
</div>

<ng-template #loader>
    <div class="facilities-type-subtitle">
        <button *ngIf="downArrow; else upArrow" class="facilities-see-list" (click)="displayFacilities()">
            <mat-icon>keyboard_arrow_down</mat-icon>
        </button>
        <ng-template #upArrow>
            <button class="facilities-see-list" (click)="displayFacilities()">
                <mat-icon>keyboard_arrow_up</mat-icon>
            </button>
        </ng-template>
        <div class="facilities-type-list-title">{{ title }}</div>
    </div>
    <div *ngIf="!downArrow">
        <div *ngIf="facilities.length != 0">
            <div *ngFor="let facility of facilities">
                <app-facility [facility]="facility" [isSuperParent]="facility.hasTime" [facilityType]="getFacilityType(facility)"
                    [parentIsActive]="isParentActive(facility)" [useFacilityName]="true" [displayChildrenSection]="true" [importantInformation]="getImportantInformation(facility)">
                </app-facility>
            </div>
        </div>
    </div>
</ng-template>